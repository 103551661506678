import type { FC } from "react";
import React from "react";

import type { EntitySoccerRoles, ManagerInvitation } from "shared/types";
import { InvitationStatus } from "shared/types";
import { ImageSizesEnum, Img, MenuDots, Text } from "shared/uikit";
import { MenuListPositionOpts, TextVariantsEnum } from "shared/uikit/types";
import { renderRole } from "utils/renderRole";

import ChipStatus from "../ChipStatus";

interface AdminRowProps {
	manager: ManagerInvitation;
	showEmail?: boolean;
	setDeleteAdmin: (
		data: {
			id: string;
			name: string;
			role: EntitySoccerRoles;
			withdraw?: boolean;
		} | null
	) => void;
}

const AdminRow: FC<AdminRowProps> = ({ manager, setDeleteAdmin, showEmail }) => (
	<div className="h-20 flex justify-between items-center border border-gray-100 rounded-xl px-4">
		<div className="flex items-center gap-4">
			<Img circle src={manager?.avatar} alt={manager?.name || manager?.email} size={ImageSizesEnum.Small56} />
			<div className="flex items-start flex-col gap-2">
				<div className="flex items-center gap-1">
					<Text variants={TextVariantsEnum.H7}>{manager?.name || manager?.email}</Text>
					{!!manager.roles?.length && (
						<Text variants={TextVariantsEnum.H7} className="text-primary-600">
							<span className="text-sm text-primary-600 mr-[2px]">•</span>
							{renderRole(manager.roles[0]!)}
						</Text>
					)}

					{/* TODO: remove me when roles are back to an array type again */}
					{!!manager.role && (
						<Text variants={TextVariantsEnum.H7} className="text-primary-600 ml-1">
							<span className="text-sm text-primary-600 mr-[2px]">•</span>
							{renderRole(manager.role)}
						</Text>
					)}

					{showEmail && !!manager?.name && !!manager?.email && (
						<Text variants={TextVariantsEnum.BodySmall} className="text-hint">
							({manager.email})
						</Text>
					)}
				</div>
				<ChipStatus status={manager.status} />
			</div>
		</div>
		<MenuDots
			popoverPosition={MenuListPositionOpts.TOP_CENTER}
			options={[
				// {
				// 	name: "View profile",
				// 	onClick: () => {}
				// },
				{
					name: manager.status === InvitationStatus.PENDING ? "Withdraw Invitation" : "Remove from admins",
					onClick: () => {
						setDeleteAdmin({
							id: manager.id,
							name: manager.name || manager.email,
							role: manager.roles[0]!,
							withdraw: manager.status === InvitationStatus.PENDING
						});
					}
				}
			]}
		/>
	</div>
);

export default AdminRow;
