import type { TFunction } from "i18next";

import {
	GroupStatusEnum,
	InvitationStatus,
	MatchCalculatedStatusEnum,
	MatchStatusEnum,
	OrderStatus
} from "shared/types";
import { PaymentStatus, PendingPaymentStatusEnum } from "shared/types/PaymentModel";

export const renderStatus = (
	value:
		| InvitationStatus
		| MatchStatusEnum
		| MatchCalculatedStatusEnum
		| PendingPaymentStatusEnum
		| GroupStatusEnum
		| PaymentStatus
		| null,
	t: TFunction
) => {
	if (!value || !t) return "";

	switch (value) {
		case InvitationStatus.ACCEPTED:
		case InvitationStatus.ACTIVE:
			return t("invitation:accepted");
		case InvitationStatus.PRIVATE:
			return t("invitation:private");
		case InvitationStatus.APPLIED:
			return t("invitation:applied");
		case InvitationStatus.PAID:
			return t("invitation:paid");
		case InvitationStatus.PENDING:
			return t("invitation:pending");
		case InvitationStatus.ASSIGNED:
			return t("invitation:assigned");
		case InvitationStatus.NOT_INVITED:
			return "";
		case MatchStatusEnum.NEEDS_TIME:
			return t("match:no_time");
		case MatchStatusEnum.NEEDS_LOCATION:
			return t("match:no_location");
		case GroupStatusEnum.SCHEDULE_CREATED:
		case MatchCalculatedStatusEnum.CREATED:
			return t("invitation:created");
		case MatchStatusEnum.NEEDS_TEAMS:
			return t("match:no_teams");
		case MatchStatusEnum.NEEDS_REFEREES:
			return t("match:no_referees");
		case MatchCalculatedStatusEnum.SCHEDULED:
			return t("match:scheduled");
		case MatchStatusEnum.READY_TO_BE_PLAYED:
		case MatchStatusEnum.READY_TO_PLAY:
			return t("match:ready_to_play");
		case MatchStatusEnum.CHECKED_IN:
			return t("match:checked_in");
		case MatchStatusEnum.ONGOING:
			return t("invitation:ongoing");
		case MatchStatusEnum.FINISHED:
			return t("match:finished");
		case MatchStatusEnum.COMPLETE:
			return t("match:completed");
		case GroupStatusEnum.NO_SCHEDULE:
			return t("match:not_scheduled");
		case PendingPaymentStatusEnum.subscriptionInProgress:
			return t("payment:in_progress_partial_payment");
		case PendingPaymentStatusEnum.lastPaymentFailed:
			return t("payment:last_payment_failed");
		case PaymentStatus.FAILED:
			return t("payment:failed");
		case PaymentStatus.PENDING:
			return t("payment:pending");
		case PaymentStatus.CANCELED:
			return t("payment:cancelled");
		case PaymentStatus.SUCCEEDED:
			return t("payment:paid");
		default:
			return t("invitation:rejected");
	}
};

export const renderPaymentRequestStatus = (value: OrderStatus | null, t: TFunction) => {
	if (!value || !t) return "";

	switch (value) {
		case OrderStatus.FULFILLED:
		case OrderStatus.ACTIVE_SUBSCRIPTION:
			return t("payment:paid");
		case OrderStatus.CANCELED:
			return t("payment:cancelled");
		case OrderStatus.PROCESSING_PAYMENT:
		case OrderStatus.ACTIVE:
			return t("payment:pending");
		case OrderStatus.FAULTY:
			return t("payment:error");
		default:
			return t("invitation:rejected");
	}
};
