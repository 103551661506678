import type { FC } from "react";
import React from "react";

import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

import colors from "theme/colors";

const BorderLinearProgress = styled(LinearProgress)(() => ({
	height: 4,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: colors.palette.colors.gray[100]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: colors.palette.colors.primary[500]
	}
}));

const LinearProgressBar: FC<{ progress: number }> = ({ progress }) => {
	return <BorderLinearProgress variant="determinate" className="w-full" value={progress} />;
};

export default LinearProgressBar;
