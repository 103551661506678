import type { FC } from "react";
import React, { memo } from "react";

import { styled } from "@mui/material";
import clsx from "clsx";

import colors from "theme/colors";

import Text from "../Text";
import { TextStylesEnum, TextVariantsEnum } from "../types";

const Divider = styled("div")`
	height: 1px;
	background-color: ${colors.palette.colors.gray[200]};
`;

interface DividerWithTextProps {
	text?: string;
	className?: string;
	vertical?: boolean;
}

const DividerWithText: FC<DividerWithTextProps> = memo(({ text, className, vertical }) => {
	if (!text) {
		return (
			<div className={clsx("flex items-center", vertical && "md:flex-col", className)}>
				<Divider className={clsx("flex-1", vertical && "w-[1px]")} />
			</div>
		);
	}

	return (
		<div className={clsx("flex items-center gap-2", vertical && "md:flex-col", className)}>
			<Divider className={clsx("flex-1", vertical && "w-[1px]")} />
			<Text variants={TextVariantsEnum.Caption2} style={TextStylesEnum.Hint}>
				{text}
			</Text>
			<Divider className={clsx("flex-1", vertical && "w-[1px]")} />
		</div>
	);
});

export default DividerWithText;
