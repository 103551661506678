import { AccordionSummary, styled } from "@mui/material";

export const StandingAccordionSummary = styled(AccordionSummary)`
	min-height: auto;
	padding: 0;

	.MuiAccordionSummary-content {
		width: 100%;
		margin: 0;
	}
`;
