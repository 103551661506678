import React, { useCallback, useMemo, useState } from "react";

import { isEmpty, symmetricDifference } from "ramda";

import type { SetupStructModel, TeamModel } from "shared/types";
import { Text } from "shared/uikit";

import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import type { LocalTeamModel } from "./TeamCategory";
import TeamCategory from "./TeamCategory";

export interface SetupClubTeamsProps {
	existingTeamNames?: string[];
	clubStruct: SetupStructModel;
	onChangeTeams: (teams: TeamModel[]) => void;
}

const SetupClubTeams: React.FC<SetupClubTeamsProps> = ({ existingTeamNames = [], clubStruct, onChangeTeams }) => {
	const [storageTempTeams, updateTempTeams] = useState<TeamModel[]>([]);

	const [active, setActive] = useState<number>();

	const getSelectedTeams = useCallback(
		(ageId: string) => storageTempTeams.filter(x => x.ageId === ageId),
		[storageTempTeams]
	);

	const replaceTeams = useCallback(
		(ageId: string, teamsInAge: LocalTeamModel[]) => {
			const newTeams = [...storageTempTeams].filter(x => x.ageId !== ageId);
			teamsInAge.forEach(localTeam => {
				newTeams.push({
					id: localTeam?.id,
					name: localTeam.name,
					ageId,
					levelId: localTeam.levelId,
					genderId: clubStruct!.genders.find(g => g.value === localTeam.gender)?.id || ""
				});
			});
			if (
				newTeams.length !== storageTempTeams.length ||
				!isEmpty(
					symmetricDifference(
						newTeams.map(x => ({ ...x, name: "" })),
						storageTempTeams.map(x => ({ ...x, name: "" }))
					)
				)
			) {
				updateTempTeams(newTeams);
				onChangeTeams(newTeams);
			}
		},
		[clubStruct, storageTempTeams, updateTempTeams, onChangeTeams]
	);

	const handleUpdateTeams = useCallback(
		(ageId: string, teams: LocalTeamModel[]) => {
			replaceTeams(ageId, teams);
		},
		[replaceTeams]
	);

	const handleOpenNext = useCallback(
		(index: number, ageId: string, teams: LocalTeamModel[]) => {
			replaceTeams(ageId, teams);

			if ((clubStruct?.ages || []).length - 1 >= index + 1) {
				setActive(index + 1);
			} else {
				setActive(undefined);
			}
		},
		[clubStruct?.ages, replaceTeams]
	);

	const openCategory = (index: number) => {
		setActive(index);
	};

	const closeCategory = (ageId: string, teams: LocalTeamModel[]) => {
		replaceTeams(ageId, teams);
		setActive(undefined);
	};

	const activeGenders = useMemo(() => (clubStruct?.genders || []).map(x => x.value), [clubStruct?.genders]);

	return (
		<>
			<Text variants={TextVariantsEnum.H7} style={TextStylesEnum.Default} className="mb-2 select-none">
				Team categories
			</Text>
			{(clubStruct?.ages || []).map((age, index) => (
				<div key={age.id} className="mb-2">
					<TeamCategory
						id={`accordion-team-category-${index + 1}`}
						ageCategory={age}
						levels={clubStruct?.levels || []}
						genders={activeGenders}
						open={active === index}
						onNext={(teams: LocalTeamModel[]) => handleOpenNext(index, age.id, teams)}
						onUpdateTeams={(teams: LocalTeamModel[]) => handleUpdateTeams(age.id, teams)}
						onOpen={() => openCategory(index)}
						onClose={(teams: LocalTeamModel[]) => closeCategory(age.id, teams)}
						selectedTeams={getSelectedTeams(age.id)}
						struct={clubStruct!}
						existingTeamNames={existingTeamNames}
					/>
				</div>
			))}
		</>
	);
};

export default React.memo(SetupClubTeams);
