import type { FC } from "react";

import clsx from "clsx";

import { DateTime } from "luxon";

import LocationIcon from "assets/icons/filled/location.svg?react";

import { CardWrapper } from "shared/Components";
import type { CalendarEventModel } from "shared/types";
import { ImageSizesEnum, Img, Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";

import EventType from "../EventType";

// import RSVPStatus from "../RSVPStatus";

interface SocialEventCardProps {
	event: CalendarEventModel;
}

const SocialEventCard: FC<SocialEventCardProps> = ({ event }) => (
	<CardWrapper noPadding className={clsx("flex", !event.image ? "flex-col gap-4 p-4" : "items-center")}>
		<EventType noFloating={!event.image} type={event.type} />
		{event.image && <Img src={event.image} alt={event.name} size={ImageSizesEnum.Small160} />}
		<div className="flex flex-col gap-2">
			<Text variants={TextVariantsEnum.BodySmall}>
				{DateTime.fromJSDate(new Date(event.startsAt)).toFormat("EEE, d MMM h:mm a")}
			</Text>
			<Text variants={TextVariantsEnum.H6}>{event.name}</Text>
			<div className="flex items-center">
				<LocationIcon className="w-4 h-4 mr-1 svg-paths:fill-primary-500" />
				<Text variants={TextVariantsEnum.BodySmall} className="text-hint">
					{event.location?.address}
				</Text>
			</div>
			{/*<RSVPStatus />*/}
		</div>
	</CardWrapper>
);

export default SocialEventCard;
