import { styled } from "@mui/material";

import colors from "theme/colors";

export const HeaderLine = styled("div")`
	border-bottom: 1px solid ${colors.palette.colors.gray["100"]};
`;
//
// export const Cell: any = styled("div")``;
//
// Cell.Wrapper = styled("div")`
// 	display: flex;
// 	flex-direction: row;
// 	align-items: center;
//
// 	width: 100%;
//
// 	&.column {
// 		flex-direction: column;
// 		justify-content: center;
// 		align-items: flex-start;
// 	}
//
// 	.with-image {
// 		max-width: calc(100% - 56px);
// 	}
//
// 	&.end {
// 		justify-content: flex-end;
// 	}
//
// 	&.center {
// 		justify-content: center;
// 	}
// `;
//
// Cell.Text = styled(Text)`
// 	word-break: break-all;
// 	overflow: hidden;
// 	white-space: nowrap;
// 	text-overflow: ellipsis;
// 	font-size: 13px;
//
// 	width: 100%;
//
// 	&.light {
// 		color: #8f9bb3;
// 	}
//
// 	&.bold {
// 		font-weight: 700;
// 	}
//
// 	&.capitalize {
// 		text-transform: capitalize;
// 	}
//
// 	&.pointer {
// 		cursor: pointer;
// 	}
//
// 	&.inline-flex {
// 		display: inline-flex;
// 	}
// `;
//
// Cell.HeaderText = styled(Text)`
// 	font-size: 12px;
// 	font-weight: 500;
// 	color: #8f9bb3;
// `;
//
// Cell.Date = styled("div")`
// 	word-break: break-all;
// 	align-self: center;
// 	p {
// 		font-size: 13px;
// 	}
// 	.secondary-date {
// 		color: #8f9bb3;
// 	}
// `;
//
// Cell.ImageWrapper = styled("div")`
// 	width: 40px;
// 	min-width: 40px;
// 	height: 40px;
//
// 	border-radius: 4px;
//
// 	overflow: hidden;
//
// 	margin-right: 16px;
//
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
//
// 	background-color: #f7f9fc;
// `;
//
// Cell.Icon = styled("div")`
// 	width: fit-content;
// `;
//
// Cell.Image = styled("div")<{ src?: string }>`
// 	width: 100%;
// 	height: 100%;
//
// 	background-color: #f7f9fc;
// 	flex: 0 0 auto;
//
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
//
// 	background-image: url(${({ src }) => src});
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	background-size: cover;
//
// 	img {
// 		width: 100%;
// 		height: 100%;
// 		object-fit: cover;
// 	}
// `;
