import colors from "theme/colors";

export const tooltipStyle = {
	background: colors.palette.colors.white,
	border: "1px solid rgba(220, 229, 245, 0.5)",
	boxShadow: "0 0 12px rgba(0, 0, 0, 0.1)",
	borderRadius: "12px",
	padding: "12px 16px",
	fontSize: "13px",
	lineHeight: "15px",
	fontWeight: 400,
	color: colors.palette.colors.hint
};

export const tooltipArrowStyle = {
	background: "transparent",
	"&:before": {
		background: colors.palette.colors.white,
		border: "1px solid rgba(220, 229, 245, 0.5)"
	}
};
