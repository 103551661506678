import { styled } from "@mui/material";

import LinearProgress from "@mui/material/LinearProgress";

import colors from "theme/colors";

export const StyledLinearProgress = styled(LinearProgress)`
	height: 4px;
	border-radius: 2px;

	background-color: ${colors.palette.colors.lightGreen};

	.MuiLinearProgress-bar {
		height: inherit;
		border-radius: inherit;

		background-color: ${colors.palette.colors.primary["500"]};
	}
`;
