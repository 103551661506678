import type { ReactNode } from "react";
import React, { useCallback, useRef } from "react";

import * as Sentry from "@sentry/react";

import clsx from "clsx";
import config from "config/appConfig";

import type { Scrollbars, positionValues } from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import { ScrollToTop } from "shared/Components";
import { AccessDenied } from "shared/Errorpages";

import { useGlobalScroll } from "shared/hooks";
import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

import { EnvStages } from "shared/types";

import { Loader } from "shared/uikit";

import AppWrapper from "./AppWrapper";

import { ScrollWrapper } from "./styles";

import "./i18n";

if (config.GLOBAL_CONSTANTS.ENV_STAGE !== EnvStages.LOCAL) {
	Sentry.init({
		environment: config.GLOBAL_CONSTANTS.ENV_STAGE,
		dsn: config.GLOBAL_CONSTANTS.SENTRY_DNS,
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		tracePropagationTargets: config.GLOBAL_CONSTANTS.SENTRY_TRACE_PROPAGATION_TARGET,
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

const RoutesBlock: React.FC<{ modules: IApp[] }> = ({ modules }) => {
	const scrollerRef = useRef<Scrollbars | null>(null);

	const { pageScrolledDown, getData: getGlobalScrollData } = useGlobalScroll();
	const { isDisabled: isDisabledCustomScroll } = getGlobalScrollData();

	const { ready } = useTranslation();

	const handleScroll = useCallback(
		(values: positionValues) => {
			values.top >= 0.99 && pageScrolledDown();
		},
		[pageScrolledDown]
	);

	if (!ready) {
		return <Loader />;
	}

	return (
		<AppWrapper>
			<BrowserRouter key="root">
				<ScrollToTop scrollRef={scrollerRef} />
				<ScrollWrapper
					ref={scrollerRef}
					height="100%"
					onUpdate={handleScroll}
					autoHide
					className={clsx(isDisabledCustomScroll && "disabled")}
				>
					<Routes>
						<Route key={"AccessDenied"} path="/AccessDenied" element={<AccessDenied />} />
						{modules.map(({ getRoutes }) => (getRoutes ? getRoutes() : null) as ReactNode)}
						{/* if user authorized, then redirect to home page otherwise to sign in page */}
						<Route element={<SecureRoute />}>
							<Route path="*" element={<Navigate to={{ pathname: config.CORE_URLS.HOME }} replace />} />
						</Route>
					</Routes>
				</ScrollWrapper>
			</BrowserRouter>
			<ToastContainer />
		</AppWrapper>
	);
};

export default RoutesBlock;
