import React, { memo } from "react";

import { Fade, Tooltip } from "@mui/material";

import { tooltipArrowStyle, tooltipStyle } from "./style";

export interface TooltipBoxProps {
	text: string;
	children: any;
	placement?:
		| "bottom-end"
		| "bottom-start"
		| "bottom"
		| "left-end"
		| "left-start"
		| "left"
		| "right-end"
		| "right-start"
		| "right"
		| "top-end"
		| "top-start"
		| "top";
	arrow?: boolean;
	className?: string;
	offset?: number[];
}

const TooltipBox: React.FC<TooltipBoxProps> = memo(
	({ text = "", className, children, placement = "bottom", arrow = false, offset = [0, 0] }) => (
		<Tooltip
			TransitionComponent={Fade}
			TransitionProps={{ timeout: 600 }}
			title={text}
			placement={placement}
			className={className}
			componentsProps={{
				tooltip: {
					sx: tooltipStyle
				},
				arrow: {
					sx: tooltipArrowStyle
				}
			}}
			arrow={arrow}
			slotProps={{
				popper: {
					modifiers: [
						{
							name: "offset",
							options: {
								offset
							}
						}
					]
				}
			}}
		>
			{children}
		</Tooltip>
	)
);

export default TooltipBox;
