import { ButtonBase, MenuItem, Select, TextField, css, styled } from "@mui/material";

import CaretIcon from "assets/icons/filled/caret-right.svg?react";
import CalendarIcon from "assets/icons/outlined/calendar-blank.svg?react";
import ChevronBigIcon from "assets/icons/outlined/chevron-right-big.svg?react";
import ChevronIcon from "assets/icons/outlined/chevron-right.svg?react";
import WatchIcon from "assets/icons/outlined/clock.svg?react";

import colors from "theme/colors";

import Text from "../Text";

const MuiInputLabelStyles = css`
	color: ${colors.palette.colors.hint};

	&.Mui-focused {
		color: ${colors.palette.colors.primary[500]};
	}

	&.Mui-error {
		color: ${colors.palette.colors.red[600]};
	}

	&.Mui-disabled {
		color: ${colors.palette.colors.inactive};
	}
`;

const MuiOutlinedInputStyles = css`
	font-size: 1rem;
	line-height: 1.5rem;
	border-radius: 8px;
	background-color: ${colors.palette.colors.white};

	textarea,
	input {
		color: ${colors.palette.colors.lightBlack};

		&::placeholder {
			font-size: 1rem;
			line-height: 1.5rem;
			color: ${colors.palette.colors.hint};
			opacity: 1;
		}
	}

	svg.searchIcon path {
		stroke: ${colors.palette.colors.gray[400]};
	}

	fieldset {
		border: 1px solid ${colors.palette.colors.gray[200]};
	}

	&:hover fieldset {
		border: 1px solid ${colors.palette.colors.primary[500]};
	}

	&.Mui-disabled {
		.MuiSelect-select {
			cursor: not-allowed;
		}

		input {
			cursor: not-allowed;
		}
		fieldset {
			border-color: ${colors.palette.colors.inactive};
		}
	}

	&.Mui-focused {
		fieldset,
		input {
			border-width: 1px;
		}
	}

	&.Mui-error {
		fieldset {
			color: ${colors.palette.colors.red[600]};
			border: 1px solid ${colors.palette.colors.red[600]};
			&:hover {
				border-color: ${colors.palette.colors.red[600]};
			}
		}
	}

	&.variant {
		&_ghost {
			.MuiSelect-select {
				.styles_hint {
					color: ${colors.palette.colors.gray["200"]};
				}
			}
			fieldset {
				border: none;
			}
			.MuiSelect-icon {
				path {
					stroke: ${colors.palette.colors.gray["100"]};
				}
			}

			&.value-set {
				.MuiSelect-icon {
					path {
						stroke: ${colors.palette.colors.gray["300"]};
					}
				}
			}
		}
	}
`;

const baseStyles = css`
	width: 100%;

	border-radius: 8px;

	.MuiInputLabel-root {
		${MuiInputLabelStyles}
	}

	.MuiOutlinedInput-root {
		${MuiOutlinedInputStyles}
	}

	&.size {
		&_xsmall {
			.MuiInputBase-root {
				height: 28px;

				padding-left: 8px;

				.MuiInputBase-input {
					padding: 0;
				}
			}
		}

		&_small {
			.MuiInputBase-root {
				height: 40px;
			}
		}

		&_under-medium {
			.MuiInputBase-root {
				height: 48px;
			}
		}

		&_medium {
			.MuiInputBase-root {
				height: 56px;
			}
		}

		&_large {
			.MuiInputBase-root {
				height: 64px;
			}
		}

		&_xxlarge {
			.MuiInputBase-root {
				height: 88px;
			}
		}
	}
`;

export const InputWrapper = styled("div")`
	&.should-style {
		border: 1px solid ${colors.palette.colors.gray[200]};
	}
`;

export const UIKitInput = styled(TextField, {
	shouldForwardProp: prop => prop !== "disableAutoComplete"
})`
	${baseStyles};

	&.hide-borders {
		.MuiOutlinedInput-root fieldset,
		&:hover .MuiOutlinedInput-root fieldset {
			border: 0;
		}
	}

	&.no-padding {
		.MuiOutlinedInput-root {
			padding: 0;
		}
	}

	&.autocomplete {
		.MuiInputBase-input {
			box-sizing: border-box;
		}
	}
`;

export const UIKitDropdown = styled(Select)`
	border-radius: 8px;

	&.hide-border fieldset {
		border: 0 !important;
		&:hover fieldset {
			border: 0;
		}
	}

	&.size {
		&_xsmall {
			height: 28px;
		}

		&_small {
			height: 40px;
		}

		&_under-medium {
			height: 48px;
		}

		&_large {
			height: 64px;
		}

		&_xxlarge {
			height: 88px;
		}
	}

	&.MuiOutlinedInput-root {
		${MuiOutlinedInputStyles}
	}

	.MuiPaper-root {
		border-radius: 20px;
	}
`;

export const UIKitDropdownPaperStyles = {
	borderRadius: "12px",
	boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)",
	border: "1px solid rgba(220, 229, 245, 0.5)",
	background: `${colors.palette.colors.white}`,
	padding: "2px 8px"
};

export const DatePickerWrapper = styled("div")`
	&.disabled {
		opacity: 0.32;
	}
`;

export const UIKitDatepickerPaperStyles = {
	borderRadius: "12px",
	boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)",
	border: "1px solid rgba(220, 229, 245, 0.5)",
	background: `${colors.palette.colors.white}`
};

export const StyledMenuItem = styled(MenuItem)`
	border-radius: 8px;

	padding: 10px 8px;

	&:hover {
		background-color: ${colors.palette.colors.gray["50"]};
	}
`;

export const StyledChevronIcon = styled(ChevronIcon)`
	top: calc(50% - 12px) !important;
	transform: rotate(90deg) !important;

	transition: transform 0.15s ease-in-out;

	&.MuiSelect-iconOpen {
		transform: rotate(-90deg) !important;
	}
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
	path {
		stroke: ${colors.palette.colors.gray["400"]};
	}
`;

export const StyledWatchIcon = styled(WatchIcon)`
	path {
		stroke: ${colors.palette.colors.gray["400"]};
	}
`;

export const StyledCalendarChevronIcon = styled(ChevronBigIcon)`
	width: 20px;
	height: 20px;

	&.left {
		transform: rotate(180deg);
	}
`;

export const StyledCalendarYearArrowIcon = styled(CaretIcon)`
	width: 16px;
	height: 16px;
`;

export const InfoText = styled(Text)`
	pointer-events: none;
	&.styles_default {
		color: ${colors.palette.colors.inactive};
	}
`;

export const UiKitDropdownOptionPaper = styled("div")`
	border-radius: ${UIKitDropdownPaperStyles.borderRadius};
	box-shadow: ${UIKitDropdownPaperStyles.boxShadow};
	border: ${UIKitDropdownPaperStyles.border};
	background: ${UIKitDropdownPaperStyles.background};
	padding: ${UIKitDropdownPaperStyles.padding};

	z-index: 10000;
	max-height: 200px;
	overflow-y: scroll;
`;

export const TimepickerIconBase = styled(ButtonBase)`
	padding: 8px;

	border-radius: 50%;

	margin-left: -12px;
	margin-right: 4px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;

export const TimepickerWrapper = styled("div")`
	&.disabled {
		opacity: 0.32;

		.MuiButtonBase-root {
			pointer-events: none;
		}
	}
`;
