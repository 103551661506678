import type { TournamentStructSettingsModel } from "shared/types";
import { TournamentSettingsTypes } from "shared/types";

export const getTournamentSetting = (
	key: string,
	defaultValue: any,
	settings: TournamentStructSettingsModel[] | null = []
) => {
	if (settings?.length) {
		const info = settings.find(x => x.name === key);
		if (info) {
			const val = info.value;
			if (info.type === TournamentSettingsTypes.NUMBER) {
				return Number(val);
			}
			if (info.type === TournamentSettingsTypes.STRING) {
				return val.toString();
			}
			if (info.type === TournamentSettingsTypes.BOOLEAN) {
				return val === "true";
			}
		}
	}
	return defaultValue;
};
