import React, { Suspense, lazy } from "react";

import config from "config/appConfig";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));

const CreateRefereeManagerLayout = lazy(() => import("modules/RefereeManager/View/layouts/CreateRefereeManagerLayout"));
const RefereeManagerLayout = lazy(() => import("modules/RefereeManager/View/layouts/RefereeManagerLayout"));

const FindSeasonPage = lazy(() => import("./Pages/FindSeason"));
const InvitePeoplePage = lazy(() => import("./Pages/InvitePeople"));
const InviteRefereePage = lazy(() => import("./Pages/InviteReferee"));
const RefereeManagerAddressPage = lazy(() => import("./Pages/RefereeManagerAddress"));
const RefereeManagerLogoPage = lazy(() => import("./Pages/RefereeManagerLogo"));
const RefereeManagerNamePage = lazy(() => import("./Pages/RefereeManagerName"));
const RefereeManagerStoryPage = lazy(() => import("./Pages/RefereeManagerStory"));
const RefereeMatchesPage = lazy(() => import("./Pages/Matches"));
const RefereeOverviewPage = lazy(() => import("./Pages/Overview"));
const RefereeRefereesPage = lazy(() => import("./Pages/Referees"));

import { routes } from "./constants";

const { CORE_URLS } = config;

class RefereeManagerApp implements IApp {
	getRoutes = () => [
		<Route path={routes.organization.getPath()} key="referee-manager-routes">
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key="referee-manager-form-routes"
			element={
				<Suspense>
					<CreateRefereeManagerLayout />
				</Suspense>
			}
		>
			<Route
				key={routes.organization.create.name.getElementKey()}
				path={routes.organization.create.name.getPath()}
				element={
					<Suspense>
						<RefereeManagerNamePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.organization.create.address.getElementKey()}
				path={routes.organization.create.address.getPath()}
				element={
					<Suspense>
						<RefereeManagerAddressPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.organization.create.logo.getElementKey()}
				path={routes.organization.create.logo.getPath()}
				element={
					<Suspense>
						<RefereeManagerLogoPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.organization.create.story.getElementKey()}
				path={routes.organization.create.story.getPath()}
				element={
					<Suspense>
						<RefereeManagerStoryPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.organization.invite.people.getElementKey()}
				path={routes.organization.invite.people.getPath()}
				element={
					<Suspense>
						<InvitePeoplePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.organization.invite.referee.getElementKey()}
				path={routes.organization.invite.referee.getPath()}
				element={
					<Suspense>
						<InviteRefereePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.organization.season.find.getElementKey()}
				path={routes.organization.season.find.getPath()}
				element={
					<Suspense>
						<FindSeasonPage />
					</Suspense>
				}
			/>
		</Route>,
		<Route
			key={routes.organization.getElementKey()}
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={`${routes.organization.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<RefereeManagerLayout homePageUrl={CORE_URLS.HOME} />
					</Suspense>
				}
			>
				<Route
					key={`${routes.organization.getElementKey()}/id/overview`}
					path={`${routes.organization.getPath()}/:id`}
					element={
						<Suspense>
							<RefereeOverviewPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.organization.getElementKey()}/id/referees`}
					path={`${routes.organization.getPath()}/:id/referees`}
					element={
						<Suspense>
							<RefereeRefereesPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.organization.getElementKey()}/id/matches`}
					path={`${routes.organization.getPath()}/:id/matches`}
					element={
						<Suspense>
							<RefereeMatchesPage />
						</Suspense>
					}
				/>
			</Route>
		</Route>
	];
}

export default RefereeManagerApp;
