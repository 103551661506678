import { BackendEntityType, EntityFollowListEnum } from "shared/types";

export const getBEEntityType = (entity: EntityFollowListEnum): BackendEntityType => {
	switch (entity) {
		case EntityFollowListEnum.CLUB:
			return BackendEntityType.SoccerClub;

		case EntityFollowListEnum.COMPETITION:
			return BackendEntityType.SoccerCompetition;

		case EntityFollowListEnum.GROUP:
			return BackendEntityType.SoccerGroup;

		case EntityFollowListEnum.LEAGUE:
			return BackendEntityType.SoccerLeague;

		case EntityFollowListEnum.MATCH:
			return BackendEntityType.SoccerMatch;

		case EntityFollowListEnum.TEAM:
			return BackendEntityType.SoccerTeam;

		case EntityFollowListEnum.ORG:
			return BackendEntityType.SoccerReforg;

		case EntityFollowListEnum.PLAYER:
		case EntityFollowListEnum.COACH:
		case EntityFollowListEnum.USER:
			return BackendEntityType.CorePerson;

		case EntityFollowListEnum.FACILITY:
			return BackendEntityType.SoccerFacility;

		default:
			return BackendEntityType.CorePost;
	}
};
