import type { FC } from "react";
import React, { Suspense, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useToast, useUser } from "shared/hooks";

import type { EntityFollowListEnum, EntitySoccerRoles, ManagerInvitation } from "shared/types";
import { Modal } from "shared/uikit";
import { ModalSizesEnum } from "shared/uikit/BaseDialog";
import { ButtonVariantsEnum } from "shared/uikit/types";

import AdminRow from "../AdminRow";

const InviteAdminsModal = React.lazy(() => import("../InviteAdminsModal"));
const ConfirmationDialog = React.lazy(() => import("../ConfirmationDialog"));

interface ManageEntityAdminsModalProps {
	id: string;
	entityName: string;
	handleClose: () => void;
	loadData: () => Promise<ManagerInvitation[] | undefined>;
	handleRemove: (userId: string, role: EntitySoccerRoles) => Promise<void>;
	entityType: EntityFollowListEnum;
}

const ManageEntityAdminsModal: FC<ManageEntityAdminsModalProps> = ({
	id,
	entityName,
	handleRemove,
	entityType,
	handleClose,
	loadData
}) => {
	const { t } = useTranslation();

	const { showToast } = useToast();

	const { isSystemAdmin } = useUser();

	const [deleteAdmin, setDeleteAdmin] = useState<{
		id: string;
		name: string;
		role: EntitySoccerRoles;
		withdraw?: boolean;
	} | null>(null);
	const [removing, setRemoving] = useState(false);
	const [inviteAdminsOpen, setInviteAdminsOpen] = useState(false);

	const [loading, setLoading] = useState(false);
	const [managers, setManagers] = useState<ManagerInvitation[]>([]);

	useEffect(() => {
		setLoading(true);
		loadData()
			.then(members => {
				members && setManagers(members);
			})
			.catch(() => {
				showToast({ text: t("failed_while_fetching_data") });
			})
			.finally(() => setLoading(false));
	}, [loadData, showToast, t]);

	return (
		<>
			<Modal
				title={`${t("manage")} ${t("admins")}`}
				open
				loading={loading}
				sizes={ModalSizesEnum.SMALL}
				onClose={handleClose}
				actions={[
					{
						text: `${t("invite")} ${t("admins").toLowerCase()}`,
						onClick: () => setInviteAdminsOpen(true)
					}
				]}
			>
				<div className="p-6 flex flex-col gap-3">
					{managers.map(manager => (
						<AdminRow
							manager={manager}
							setDeleteAdmin={data => setDeleteAdmin(data)}
							key={manager.id}
							showEmail={isSystemAdmin}
						/>
					))}
				</div>
			</Modal>
			{inviteAdminsOpen && (
				<Suspense>
					<InviteAdminsModal
						entityId={id}
						onInvite={() => setInviteAdminsOpen(false)}
						handleClose={() => setInviteAdminsOpen(false)}
						entityType={entityType}
					/>
				</Suspense>
			)}
			{deleteAdmin && (
				<Suspense>
					<ConfirmationDialog
						open
						onClose={() => setDeleteAdmin(null)}
						actions={[
							{
								text: t("cancel"),
								disabled: removing,
								onClick: () => setDeleteAdmin(null),
								variant: ButtonVariantsEnum.OUTLINED
							},
							{
								text: t("remove"),
								disabled: removing,
								loading: removing,
								onClick: async () => {
									setRemoving(true);
									await handleRemove(deleteAdmin.id, deleteAdmin.role);
									showToast({
										text: deleteAdmin.withdraw
											? t("invitation:invitation_has_been_withdrawn", { entity: t("admin") })
											: t("an_entity_was_removed_from_entity", {
													removedEntity: t("admin").toLowerCase(),
													entity: entityName
												}),
										noIcon: true
									});
									setRemoving(false);
									setDeleteAdmin(null);
								},
								variant: ButtonVariantsEnum.DANGER
							}
						]}
						title={t("remove_from", { entity: t("admins").toLowerCase() })}
						message={t("do_you_want_to_remove_from", {
							removedEntity: deleteAdmin.name,
							entity: `${entityName} ${t("admins").toLowerCase()}`
						})}
					/>
				</Suspense>
			)}
		</>
	);
};

export default ManageEntityAdminsModal;
