import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import GlobeIcon from "assets/icons/outlined/globe.svg?react";

import { getProperWebsiteUrl } from "utils/serviceUtils/helpers";

import type { InfoBoxProps } from "./index";
import { InfoBox } from "../index";

interface WebsiteInfoBoxProps extends Omit<InfoBoxProps, "title" | "subtitle"> {
	title?: string | null;
}

const WebsiteInfoBox: React.FC<WebsiteInfoBoxProps> = memo(({ title, ...rest }) => {
	const { t } = useTranslation();

	return (
		<InfoBox
			{...rest}
			title={title || "-"}
			titleHref={title ? getProperWebsiteUrl(title) : undefined}
			subtitle={t("website")}
			icon={<GlobeIcon className="w-5 h-5 svg-paths:fill-primary-600" />}
		/>
	);
});

export default WebsiteInfoBox;
