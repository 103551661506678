import type { FC } from "react";
import React, { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import type { Control, FieldErrors } from "react-hook-form";
import { Controller, useWatch } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { ErrorHint, FormBlock, OnboardingForm } from "shared/Components";
import type { ProfileModal, UserProfileModel } from "shared/types";
import { Button, InfoIcon, Input, Text } from "shared/uikit";
import { ButtonSizesEnum, ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { calculateAge } from "utils/calculateAge";
import { generateDaysOfMonth } from "utils/generateDaysOfMonth";
import { getCorrectGenderValList, getMonthOptions, getYearOptions } from "utils/serviceUtils/helpers";

const yearList = getYearOptions();

const FillAccountFormTemplate: FC<{
	title: React.ReactNode;
	subtitle?: string;
	control: Control<ProfileModal, any>;
	handleSubmit: () => void;
	errors: FieldErrors<ProfileModal>;
	isSubmitting: boolean;
	defaultValues?: UserProfileModel;
	guardianMode?: boolean;
	keepTitleTag?: boolean;
	skipEmail?: boolean;
}> = ({
	control,
	handleSubmit,
	errors,
	isSubmitting,
	title,
	subtitle,
	defaultValues,
	guardianMode,
	keepTitleTag,
	skipEmail
}) => {
	const { t } = useTranslation();

	const [age, setAge] = useState<number>();

	const { birthday } = useWatch({ control });

	const monthList = getMonthOptions(t);

	const correctGenderList = getCorrectGenderValList(t);

	const daysOfMonth = useMemo(() => generateDaysOfMonth(birthday?.month), [birthday?.month]);

	const dateDefaults = useMemo(() => {
		if (defaultValues?.birthDate) {
			const date = DateTime.fromJSDate(new Date(defaultValues.birthDate));
			return { month: date.get("month"), day: date.get("day"), year: date.get("year") };
		}

		return undefined;
	}, [defaultValues?.birthDate]);

	useEffect(() => {
		const age = calculateAge(birthday?.month, birthday?.day, birthday?.year);
		setAge(age);
	}, [birthday?.month, birthday?.day, birthday?.year]);

	const ageErrorHint = useMemo(() => {
		if (guardianMode && age && age < 18) {
			return t("error:you_must_be_over_to_continue", { val: 18 });
		}

		return t("error:enter_all_details");
	}, [age, guardianMode, t]);

	return (
		<OnboardingForm
			title={title}
			subtitle={subtitle || t("please_enter_your_personal_details_to_fill_your_profile")}
			onSubmit={handleSubmit}
			keepTitleTag={keepTitleTag}
		>
			<div>
				<div className="grid grid-cols-2 gap-4">
					<Controller
						rules={{ required: t("error:please_enter", { field: t("player:first_name") }), min: 2 }}
						name="firstName"
						control={control}
						defaultValue={defaultValues?.firstName}
						render={({ field: { onChange, value } }) => (
							<Input
								label={t("player:first_name")}
								size={ButtonSizesEnum.UNDER_MD}
								placeholder={t("player:first_name")}
								value={value}
								onChange={onChange}
								hintError
								error={!!errors?.firstName?.message}
								errorText={errors?.firstName?.message}
							/>
						)}
					/>
					<Controller
						rules={{ required: t("error:please_enter", { field: t("player:last_name") }), min: 2 }}
						name="lastName"
						control={control}
						defaultValue={defaultValues?.lastName}
						render={({ field: { onChange, value } }) => (
							<Input
								label={t("player:last_name")}
								size={ButtonSizesEnum.UNDER_MD}
								placeholder={t("player:last_name")}
								value={value}
								onChange={onChange}
								hintError
								error={!!errors?.lastName?.message}
								errorText={errors?.lastName?.message}
							/>
						)}
					/>
				</div>
				<FormBlock
					label={
						<div className="flex items-center gap-2">
							<Text variants={TextVariantsEnum.Caption2}>{t("player:date_of_birth")}</Text>
							<InfoIcon className="ml-1" text={t("player:providing_your_date_of_birth")} />
						</div>
					}
					containerClassName="mt-4"
					labelClassname="mb-2"
				>
					<div className="grid grid-cols-[38.5fr,26.5fr,31fr] gap-2">
						<Controller
							rules={{
								required: t("error:is_required", { field: t("date:month") }),
								validate: () => {
									if (age && guardianMode && age < 18) {
										return " ";
									}

									return true;
								}
							}}
							name="birthday.month"
							control={control}
							defaultValue={dateDefaults?.month}
							render={({ field: { onChange, value } }) => (
								<Input
									dropdown
									size={ButtonSizesEnum.UNDER_MD}
									placeholder={t("date:month")}
									value={value}
									options={monthList}
									onChange={onChange}
									error={!!errors?.birthday?.month?.message}
								/>
							)}
						/>
						<Controller
							rules={{
								required: t("error:is_required", { field: t("date:day") }),
								validate: () => {
									if (age && guardianMode && age < 18) {
										return " ";
									}

									return true;
								}
							}}
							name="birthday.day"
							defaultValue={dateDefaults?.day}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input
									dropdown
									size={ButtonSizesEnum.UNDER_MD}
									placeholder={t("date:day")}
									value={value}
									options={daysOfMonth}
									onChange={onChange}
									error={!!errors?.birthday?.day?.message}
								/>
							)}
						/>
						<Controller
							rules={{
								required: t("error:is_required", { field: t("date:year") }),
								validate: () => {
									if (age && guardianMode && age < 18) {
										return " ";
									}

									return true;
								}
							}}
							name="birthday.year"
							defaultValue={dateDefaults?.year}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input
									dropdown
									size={ButtonSizesEnum.UNDER_MD}
									placeholder={t("date:year")}
									value={value}
									options={yearList}
									onChange={onChange}
									error={!!errors?.birthday?.year?.message}
								/>
							)}
						/>
					</div>
					{(!!errors?.birthday?.year?.message ||
						!!errors?.birthday?.month?.message ||
						!!errors?.birthday?.day?.message) && <ErrorHint text={ageErrorHint} className="mt-2" />}
					{!!age && (
						<Text style={TextStylesEnum.Primary} variants={TextVariantsEnum.H7} className="mt-2">
							{t("years_old", { years: age })}
						</Text>
					)}
				</FormBlock>
				{!skipEmail && !guardianMode && (age || 0) > 13 && (
					<FormBlock
						label={<Text variants={TextVariantsEnum.Caption2}>Email</Text>}
						containerClassName="mt-4"
						labelClassname="mb-2"
					>
						<Controller
							rules={{ required: t("error:please_enter", { field: t("email") }) }}
							name="email"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input
									size={ButtonSizesEnum.UNDER_MD}
									placeholder={t("email")}
									value={value ? value.toLowerCase() : value}
									onChange={onChange}
									error={!!errors?.email?.message}
									errorText={errors?.email?.message}
								/>
							)}
						/>
					</FormBlock>
				)}
				<FormBlock
					label={
						<div className="flex items-center gap-2">
							<Text variants={TextVariantsEnum.Caption2}>{t("gender")}</Text>
							<InfoIcon className="ml-1" text={t("player:if_you_are_a_football_player_you_will_be_in_a_teams")} />
						</div>
					}
					containerClassName="mt-4"
					labelClassname="mb-2"
				>
					<Controller
						name="gender"
						defaultValue={defaultValues?.gender}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Input
								dropdown
								size={ButtonSizesEnum.UNDER_MD}
								placeholder={`${t("select")} ${t("gender")}`}
								value={value}
								options={correctGenderList}
								onChange={onChange}
								error={!!errors?.gender?.message}
								errorText={errors?.gender?.message}
							/>
						)}
					/>
					{/*<InfoIcon*/}
					{/*	className="mt-2"*/}
					{/*	regular*/}
					{/*	text={*/}
					{/*		"If you are a football player, you will be in a team of boys or girls according to your selected answer here"*/}
					{/*	}*/}
					{/*/>*/}
				</FormBlock>
			</div>
			<div className="flex justify-between mt-10">
				<Button variant={ButtonVariantsEnum.OUTLINED} size={ButtonSizesEnum.MD} id={"back"} disabled>
					{t("back")}
				</Button>
				<Button
					variant={ButtonVariantsEnum.FILLED}
					size={ButtonSizesEnum.MD}
					id={"next"}
					type="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
				>
					{t("next")}
				</Button>
			</div>
		</OnboardingForm>
	);
};

export default FillAccountFormTemplate;
