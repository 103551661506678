import type { SwitchProps } from "@mui/material";
import { Switch, styled } from "@mui/material";

import colors from "theme/colors";

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
	width: 48,
	height: 24,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(24px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: colors.palette.colors.primary[500],
				opacity: 1,
				border: 0
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5
			}
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff"
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color: colors.palette.colors.gray[200]
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: 0.7
		}
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 20,
		height: 20,
		boxShadow: "none"
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: colors.palette.colors.gray[200],
		opacity: 1
	}
}));

export default IOSSwitch;
