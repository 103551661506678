import React, { Suspense } from "react";

import ReactDOM from "react-dom/client";
import "./index.scss";

import configureApp from "./core/configureApp";
import Routes from "./core/routes";

const { apps } = configureApp();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<Suspense fallback={<p>Loading</p>}>
		<Routes modules={apps} />
	</Suspense>
);
