import type { FC } from "react";
import React from "react";

import usePagination from "@mui/material/usePagination";

import clsx from "clsx";

import ArrowIcon from "assets/icons/outlined/arrow-right.svg?react";

import { Button, Text } from "shared/uikit";
import { ButtonSizesEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import colors from "theme/colors";

import { PaginationWrapper } from "./style";

import { PaginationLoader } from "../index";

interface PaginationBlockProps {
	count: number;
	page: number;
	pageSize: number;
	onChangePage?: (newPage: number) => void;
	loading?: boolean;
}

const PaginationBlock: FC<PaginationBlockProps> = ({ count, page, pageSize, onChangePage, loading = false }) => {
	const totalPages = Math.ceil(count / pageSize);

	const { items } = usePagination({
		count: totalPages,
		page,
		onChange: onChangePage ? (event: React.ChangeEvent<unknown>, page: number) => onChangePage(page) : undefined
	});

	if (loading) return <PaginationLoader />;

	return (
		<PaginationWrapper className="flex items-center">
			{items.map(({ page, type, selected, ...item }, index) => (
				<li key={index}>
					{(type === "start-ellipsis" || type === "end-ellipsis") && "..."}
					{type === "page" && (
						<Button
							size={ButtonSizesEnum.XS}
							whiteBg={!selected}
							{...item}
							onClick={e => item.onClick(e!)}
							fillColor={selected ? colors.palette.colors.lightGreen : undefined}
							className={"px-3"}
						>
							<Text
								style={selected ? TextStylesEnum.Primary : TextStylesEnum.Default}
								variants={selected ? TextVariantsEnum.H7 : TextVariantsEnum.BodyMedium}
							>
								{page}
							</Text>
						</Button>
					)}
					{type !== "page" && type !== "start-ellipsis" && type !== "end-ellipsis" && (
						<Button
							size={ButtonSizesEnum.XS}
							dontFillSvg
							whiteBg
							endIcon={
								type === "next" && (
									<ArrowIcon
										className={clsx(
											"w-5 h-5 svg-paths:fill-transparent",
											item.disabled ? "svg-paths:stroke-gray-200" : "svg-paths:stroke-gray-500"
										)}
									/>
								)
							}
							startIcon={
								type === "previous" && (
									<ArrowIcon
										className={clsx(
											"w-5 h-5 rotate-180 svg-paths:fill-transparent",
											item.disabled ? "svg-paths:stroke-gray-200" : "svg-paths:stroke-gray-500"
										)}
									/>
								)
							}
							{...item}
							onClick={e => item.onClick(e!)}
							className={clsx(item.disabled && "cursor-not-allowed pointer-events-auto")}
						>
							<Text
								style={item.disabled ? TextStylesEnum.Disabled : TextStylesEnum.Default}
								variants={TextVariantsEnum.H7}
							>
								{type === "previous" ? "Prev" : "Next"}
							</Text>
						</Button>
					)}
				</li>
			))}
		</PaginationWrapper>
	);
};

export default PaginationBlock;
