import { FormControlLabel, styled } from "@mui/material";

import colors from "theme/colors";

export const StyledRadioBtn = styled(FormControlLabel)`
	.MuiRadio-root {
		padding: 8px;
	}

	.MuiSvgIcon-root {
		&:first-of-type {
			path {
				fill: ${colors.palette.colors.gray["200"]};
			}
		}
	}

	.Mui-checked {
		.MuiSvgIcon-root {
			path {
				fill: ${colors.palette.colors.primary["500"]};
			}
		}
	}

	&:hover {
		.MuiSvgIcon-root {
			&:first-of-type {
				path {
					fill: ${colors.palette.colors.gray["300"]};
				}
			}
		}

		.Mui-checked {
			.MuiSvgIcon-root {
				path {
					fill: ${colors.palette.colors.primary["600"]};
				}
			}
		}
	}

	.MuiTypography-root {
		font-weight: 400;
		font-size: 13px;
		line-height: 15px;
		color: ${colors.palette.colors.gray["500"]};
		text-transform: capitalize;
	}

	&.rtl {
		flex-direction: row-reverse;
	}
`;
