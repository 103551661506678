import { ButtonBase, styled } from "@mui/material";

import colors from "theme/colors";

export const EditIconWrapper = styled(ButtonBase)`
	background: ${colors.palette.colors.white};

	border: 1px solid ${colors.palette.colors.gray["200"]};
	border-radius: 6px;

	padding: 8px;

	cursor: pointer;
`;
