import type { FC } from "react";
import React, { Suspense, lazy, useMemo } from "react";

import clsx from "clsx";
import Markdown from "markdown-to-jsx";
import { renderToStaticMarkup } from "react-dom/server";

import { Autoplay, Keyboard, Navigation, Pagination } from "swiper/modules";

import { SwiperSlide } from "swiper/react";

import type { PostAttachmentModel } from "shared/types";
import { AttachmentKind } from "shared/types";

import { ContentWrapper, SwiperCarouselWrapper } from "./style";

import AttachmentSlide from "../PostSlide/AttachmentSlide";

const SwiperCarousel = lazy(() => import("shared/Components/SwiperCarousel"));

interface ContentProps {
	className?: string;
	postId: string;
	content?: string;
	attachments: PostAttachmentModel[];
}

export const supportedAttachmentTypes = [
	AttachmentKind.IMAGE,
	AttachmentKind.GIF,
	AttachmentKind.POLL,
	AttachmentKind.VIDEO,
	AttachmentKind.ENTITY
];

const Content: FC<ContentProps> = ({ postId, content, attachments = [], className }) => {
	const filteredAttachments = useMemo(
		() => attachments.filter(x => supportedAttachmentTypes.some(s => s === x.kind)),
		[attachments]
	);

	return (
		<div className={clsx("w-full", className)}>
			{!!filteredAttachments?.length && (
				<SwiperCarouselWrapper className="mb-4 h-[340px] rounded-2xl overflow-hidden border border-gray-100">
					<Suspense>
						<SwiperCarousel
							className="h-full"
							loop={filteredAttachments.length > 1}
							autoplay={{
								delay: 5000
							}}
							speed={1000}
							keyboard={{
								enabled: true
							}}
							pagination={{
								type: "custom",
								renderCustom: (swiper, current, total) => {
									let elSize = Math.floor((swiper.width * 0.9 - (total - 1) * 4) / total);
									elSize = elSize > 54 ? 54 : elSize;
									const isDark =
										filteredAttachments.length > current - 1
											? filteredAttachments[current - 1]?.kind === AttachmentKind.POLL
											: false;
									return renderToStaticMarkup(
										<div className="w-full flex justify-center gap-1">
											{Array.from(Array(total)).map((_, index) => (
												<div
													style={{ width: elSize }}
													className="py-1.5 cursor-pointer"
													key={`post-${postId}-pagination-${index}`}
												>
													<hr
														className={clsx(
															"border-0 h-1 rounded-sm shadow-[0_0_4px_rgba(0,0,0,0.16)]",
															isDark ? "bg-gray-100" : "bg-white",
															isDark && index === current - 1 && "bg-gray-300",
															!isDark && index !== current - 1 && "bg-opacity-50"
														)}
													/>
												</div>
											))}
										</div>
									);
								}
							}}
							modules={[Keyboard, Navigation, Pagination, Autoplay]}
						>
							{filteredAttachments.map((attachment, index) => (
								<SwiperSlide key={`post-${postId}-attachment-slide-${index}`}>
									<AttachmentSlide attachment={attachment} />
								</SwiperSlide>
							))}
						</SwiperCarousel>
					</Suspense>
				</SwiperCarouselWrapper>
			)}
			{content && (
				<ContentWrapper>
					<Markdown>{content}</Markdown>
				</ContentWrapper>
			)}
		</div>
	);
};

export default Content;
