import type { FC } from "react";
import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";

import { PaymentStatBlock } from "shared/Components";
import { useSoccerPayment } from "shared/hooks";

import type { MenuDotsOption } from "shared/uikit";

const WithdrawMoneyDialog = lazy(() => import("shared/Components/WithdrawMoneyDialog"));

interface PaymentMetricsProps {
	objectId: string;
}

const PaymentMetrics: FC<PaymentMetricsProps> = ({ objectId }) => {
	const { getPaymentAccountMetrics, getData: getSoccerPaymentData } = useSoccerPayment();
	const { loadingPaymentAccountMetrics, paymentAccountMetrics } = getSoccerPaymentData();

	const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);

	const fetchData = useCallback(
		(objectId: string) => {
			getPaymentAccountMetrics({ objectId });
		},
		[getPaymentAccountMetrics]
	);

	useEffect(() => {
		fetchData(objectId);
	}, [fetchData, objectId]);

	const balanceOptions: MenuDotsOption[] = useMemo(
		() =>
			paymentAccountMetrics?.balance?.total
				? [{ name: "Withdraw amount", onClick: () => setWithdrawDialogOpen(true) }]
				: [],
		[paymentAccountMetrics?.balance?.total]
	);

	return (
		<>
			<div className="grid grid-cols-3 gap-4">
				<PaymentStatBlock
					loading={loadingPaymentAccountMetrics}
					title="Cash In"
					currentAmount={paymentAccountMetrics?.inbound?.currentPeriod}
					previousAmount={paymentAccountMetrics?.inbound?.previousPeriod}
				/>
				<PaymentStatBlock
					loading={loadingPaymentAccountMetrics}
					title="Cash Out"
					currentAmount={paymentAccountMetrics?.outbound?.currentPeriod}
					previousAmount={paymentAccountMetrics?.outbound?.previousPeriod}
					inverted
				/>
				<PaymentStatBlock
					loading={loadingPaymentAccountMetrics}
					title="Balance"
					currentAmount={paymentAccountMetrics?.balance?.total}
					previousAmount={paymentAccountMetrics?.balance?.previousPeriod}
					opts={balanceOptions}
				/>
			</div>
			{withdrawDialogOpen && !!paymentAccountMetrics?.balance?.total && (
				<Suspense>
					<WithdrawMoneyDialog
						balance={paymentAccountMetrics.balance.total}
						onClose={() => setWithdrawDialogOpen(false)}
					/>
				</Suspense>
			)}
		</>
	);
};

export default PaymentMetrics;
