import React, { Suspense, lazy } from "react";

import config from "config/appConfig";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const CompetitionPageLayout = lazy(() => import("modules/Competition/View/layouts/CompetitionPageLayout"));

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));

const CompetitionDetailedStatsPage = lazy(() => import("./Pages/Competition/Stats/detailed"));
const CompetitionStatsPage = lazy(() => import("./Pages/Competition/Stats/index"));
const CompetitionMatchesPage = lazy(() => import("./Pages/Competition/Matches"));
const CompetitionOverviewPage = lazy(() => import("./Pages/Competition/Overview"));
const CompetitionTeamsPage = lazy(() => import("./Pages/Competition/Teams"));
const CompetitionDivisionsPage = lazy(() => import("./Pages/Competition/Divisions"));
const CompetitionGroupsPage = lazy(() => import("./Pages/Competition/Groups"));
const CompetitionFieldsPage = lazy(() => import("./Pages/Competition/Fields"));
const CompetitionSchedulePage = lazy(() => import("./Pages/Competition/Schedule"));
const CompetitionStructurePage = lazy(() => import("./Pages/Competition/Structure"));
const CompetitionScoutsPage = lazy(() => import("./Pages/Competition/Scouts"));
const CompetitionPlayersPage = lazy(() => import("./Pages/Competition/Players"));

import { routes } from "./constants";

const { CORE_URLS } = config;

class CompetitionApp implements IApp {
	getRoutes = () => [
		<Route path={routes.competition.getPath()} key="competition-routes">
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key={routes.competition.getElementKey()}
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={`${routes.competition.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<CompetitionPageLayout homePageUrl={CORE_URLS.HOME} />
					</Suspense>
				}
			>
				<Route
					key={`${routes.competition.getElementKey()}/id`}
					path={`${routes.competition.getPath()}/:id`}
					element={
						<Suspense>
							<CompetitionOverviewPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/structure`}
					path={`${routes.competition.getPath()}/:id/structure`}
					element={
						<Suspense>
							<CompetitionStructurePage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/matches`}
					path={`${routes.competition.getPath()}/:id/matches`}
					element={
						<Suspense>
							<CompetitionMatchesPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/teams`}
					path={`${routes.competition.getPath()}/:id/teams`}
					element={
						<Suspense>
							<CompetitionTeamsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/players`}
					path={`${routes.competition.getPath()}/:id/players`}
					element={
						<Suspense>
							<CompetitionPlayersPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/groups`}
					path={`${routes.competition.getPath()}/:id/groups`}
					element={
						<Suspense>
							<CompetitionGroupsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/divisions`}
					path={`${routes.competition.getPath()}/:id/divisions`}
					element={
						<Suspense>
							<CompetitionDivisionsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/schedule`}
					path={`${routes.competition.getPath()}/:id/schedule`}
					element={
						<Suspense>
							<CompetitionSchedulePage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/fields`}
					path={`${routes.competition.getPath()}/:id/fields`}
					element={
						<Suspense>
							<CompetitionFieldsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/stats`}
					path={`${routes.competition.getPath()}/:id/stats`}
					element={
						<Suspense>
							<CompetitionStatsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.competition.getElementKey()}/id/scouts`}
					path={`${routes.competition.getPath()}/:id/scouts`}
					element={
						<Suspense>
							<CompetitionScoutsPage />
						</Suspense>
					}
				/>
			</Route>
			<Route
				key={`${routes.competition.getElementKey()}/id/stats/entity/detail`}
				path={`${routes.competition.getPath()}/:id/stats/:entity/:stat`}
				element={
					<Suspense>
						<CompetitionDetailedStatsPage />
					</Suspense>
				}
			/>
		</Route>
	];
}

export default CompetitionApp;
