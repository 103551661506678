import type { ReactNode } from "react";
import React, { useState } from "react";

import clsx from "clsx";

import { DateTime } from "luxon";

import { useTranslation } from "react-i18next";

import { Chip } from "shared/Components";
import type { SetupCategoryModel } from "shared/types";
import { AgeGroupEnum } from "shared/types";
import { Button, InfoIcon, Text } from "shared/uikit";
import { ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";
import { uuidv4 } from "utils/serviceUtils/helpers";

interface StandardFormProps {
	isSelected: (name: string, behavior: AgeGroupEnum) => boolean;
	onToggle: (category?: SetupCategoryModel) => void;
	onUnselect: () => void;
	rootError?: ReactNode;
}

const maxUnderCategoriesCount = 26;
// TODO: uncomment open/over categories once we support it
// const maxOverCategoriesCount = 80;

// const openCategories = [{ id: uuidv4(), name: "Open", date: "" }];

const luxonDate = DateTime.now().set({ month: 1, day: 1 });
const currentYear = DateTime.now().year;

const underCategories = [...Array(maxUnderCategoriesCount)].map((x, i) => ({
	id: uuidv4(),
	name: `U${i + 4}`,
	date: luxonDate.set({ year: currentYear - (i + 4) + 1 }).toJSDate(),
	value: i + 4
}));

// const overCategories = [...Array(Math.ceil((maxOverCategoriesCount - maxUnderCategoriesCount) / 5))].map((x, i) => ({
// 	id: uuidv4(),
// 	name: `O${i * 5 + 30}`,
// 	date: luxonDate.set({ year: currentYear - (i * 5 + 30) }).toJSDate()
// }));

const StandardForm: React.FC<StandardFormProps> = ({ isSelected, onToggle, onUnselect, rootError }) => {
	const { t } = useTranslation();

	// const [openDisplayLimit, setOpenDisplayLimit] = useState(Math.ceil(openCategories.length / 2));
	const [underDisplayLimit, setUnderDisplayLimit] = useState(
		underCategories
			.slice(underCategories.length / 2, underCategories.length)
			.some(x => isSelected(x.name, AgeGroupEnum.UNDER))
			? underCategories.length
			: Math.ceil(underCategories.length / 2)
	);
	// const [overDisplayLimit, setOverDisplayLimit] = useState(Math.ceil(overCategories.length / 2));

	const renderSection = ({
		behavior,
		categories,
		limit,
		allCount,
		title,
		desc,
		onToggleVisibleCount
	}: {
		behavior: AgeGroupEnum;
		categories: SetupCategoryModel[];
		limit: number;
		allCount: number;
		title: string;
		desc: string;
		onToggleVisibleCount: () => void;
	}) => (
		<>
			<div className="flex items-center">
				<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.H7}>
					{title}
				</Text>
				<InfoIcon iconClassName="ml-1" text={desc} />
			</div>
			<div className="mt-2">
				{categories.slice(0, limit).map(category => {
					const isActive = isSelected(category.name, behavior);

					return (
						<Chip
							key={category.id}
							className={clsx(
								"w-20 h-10 mr-4 mb-4 capitalize rounded-xl inline-flex justify-center items-center cursor-pointer transition-colors",
								isActive ? "bg-lightGreen border-0" : "bg-white"
							)}
							onClick={() => onToggle({ ...category, behavior })}
							text={
								<Text
									style={isActive ? TextStylesEnum.Primary : TextStylesEnum.Default}
									variants={isActive ? TextVariantsEnum.H7 : TextVariantsEnum.BodyMedium}
								>
									{category.name}
								</Text>
							}
						/>
					);
				})}
			</div>
			{allCount > 1 && (
				<Button variant={ButtonVariantsEnum.LINK} className="-ml-4 mb-4" onClick={onToggleVisibleCount}>
					Show {limit === allCount ? "less" : "more"}
				</Button>
			)}
		</>
	);

	return (
		<>
			<div className="relative">
				<Button variant={ButtonVariantsEnum.LINK} className="absolute -top-4 right-1" onClick={onUnselect}>
					{t("unselect_all")}
				</Button>
				{/*{renderSection({*/}
				{/*	behavior: AgeGroupEnum.OPEN,*/}
				{/*	categories: openCategories,*/}
				{/*	limit: openDisplayLimit,*/}
				{/*	allCount: openCategories.length,*/}
				{/*	title: "Open",*/}
				{/*	desc: "Open category",*/}
				{/*	onToggleVisibleCount: () =>*/}
				{/*		setOpenDisplayLimit(*/}
				{/*			openDisplayLimit === openCategories.length ? Math.ceil(openCategories.length / 2) : openCategories.length*/}
				{/*		)*/}
				{/*})}*/}
				{renderSection({
					behavior: AgeGroupEnum.UNDER,
					categories: underCategories,
					limit: underDisplayLimit,
					allCount: underCategories.length,
					title: "Under",
					desc: "Under category",
					onToggleVisibleCount: () =>
						setUnderDisplayLimit(
							underDisplayLimit === underCategories.length
								? Math.ceil(underCategories.length / 2)
								: underCategories.length
						)
				})}
				{/*{renderSection({*/}
				{/*	behavior: AgeGroupEnum.OVER,*/}
				{/*	categories: overCategories,*/}
				{/*	limit: overDisplayLimit,*/}
				{/*	allCount: overCategories.length,*/}
				{/*	title: "Over",*/}
				{/*	desc: "Over category",*/}
				{/*	onToggleVisibleCount: () =>*/}
				{/*		setOverDisplayLimit(*/}
				{/*			overDisplayLimit === overCategories.length ? Math.ceil(overCategories.length / 2) : overCategories.length*/}
				{/*		)*/}
				{/*})}*/}
			</div>
			{!!rootError && <div className="mt-2">{rootError}</div>}
		</>
	);
};

export default React.memo(StandardForm);
