import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Collapse } from "@mui/material";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import CloseIcon from "assets/icons/filled/close-circle.svg?react";
import TickIcon from "assets/icons/filled/tick.svg?react";
import ArrowIcon from "assets/icons/outlined/chevron-right.svg?react";

import { Counter } from "shared/Components";
import type { GenderEnum, SetupLevelModel, SetupStructModel, StructAgeModel, TeamModel } from "shared/types";
import { Button, Checkbox, Text } from "shared/uikit";
import { ButtonSizesEnum, ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { capitalize, getGenderTitle, getSymbolByNumber } from "utils/serviceUtils/helpers";

import { uniqueifyDataOfObjects } from "utils/uniqueifyData";

import {
	GenderWrapper,
	GendersWrapper,
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
	TeamCountWrapper,
	TeamWrapper,
	TeamsBlock,
	TickIconWrapper
} from "./style";

export interface LocalTeamModel extends Omit<TeamModel, "genderId" | "ageId"> {
	gender: GenderEnum;
	suffix?: string;
	ageId?: string;
}

interface SelectedGender {
	levelId: string;
	gender: GenderEnum;
}

interface TeamCategoryProps {
	id: string;
	ageCategory: StructAgeModel;
	levels: SetupLevelModel[];
	genders: GenderEnum[];
	open: boolean;
	onNext: (teams: LocalTeamModel[]) => void;
	onUpdateTeams: (teams: LocalTeamModel[]) => void;
	onOpen: () => void;
	onClose: (teams: LocalTeamModel[]) => void;
	selectedTeams: TeamModel[];
	struct: SetupStructModel;
	existingTeamNames: string[];
}

const TeamCategory: React.FC<TeamCategoryProps> = ({
	id,
	ageCategory,
	levels,
	genders,
	open,
	onNext,
	onUpdateTeams,
	onOpen,
	onClose,
	selectedTeams,
	struct,
	existingTeamNames
}) => {
	const { t } = useTranslation();

	const [checkedLevels, setCheckedLevels] = useState<string[]>([]);
	const [teams, setTeams] = useState<LocalTeamModel[]>();
	const [selectedGenders, setSelectedGenders] = useState<SelectedGender[]>([]);

	useEffect(() => {
		teams && onUpdateTeams(teams);
	}, [teams, onUpdateTeams]);

	const updateLocalTeams = useCallback(
		({
			teams,
			ageCategoryName,
			gender,
			level,
			teamId
		}: {
			teams: LocalTeamModel[];
			ageCategoryName: string;
			gender: GenderEnum;
			level: SetupLevelModel;
			teamId?: string;
		}) => {
			const newTeams = [...teams];
			let teamsCount = newTeams.filter(x => x.gender === gender && x.levelId === level.id).length;
			let suffix = getSymbolByNumber(teamsCount);
			while (
				newTeams.some(x => x.gender === gender && x.levelId === level.id && x.suffix === suffix) ||
				existingTeamNames.indexOf(`${ageCategoryName} - ${level.name} - ${capitalize(gender)} (${suffix})`) > -1
			) {
				teamsCount++;
				suffix = getSymbolByNumber(teamsCount);
			}
			newTeams.push({
				id: teamId,
				name: `${ageCategoryName} - ${level.name} - ${capitalize(gender)} (${suffix})`,
				gender,
				levelId: level.id,
				suffix
			});
			return newTeams;
		},
		[existingTeamNames]
	);

	useEffect(() => {
		if (selectedTeams) {
			setCheckedLevels(lvls => uniqueifyDataOfObjects([...lvls, ...selectedTeams.map(x => x.levelId)]));

			const checkedGenders: SelectedGender[] = [];
			selectedTeams.forEach(team => {
				const teamGender = struct.genders.find(x => x.id === team.genderId)!.value;
				const index = checkedGenders.findIndex(x => x.levelId === team.levelId && x.gender === teamGender);
				if (index === -1) {
					checkedGenders.push({
						levelId: team.levelId,
						gender: struct.genders.find(x => x.id === team.genderId)!.value
					});
				} else {
					checkedGenders[index] = {
						...checkedGenders[index]
					} as SelectedGender;
				}
			});
			setSelectedGenders(gdrs => uniqueifyDataOfObjects([...gdrs, ...checkedGenders]));

			let newTeams: LocalTeamModel[] = [];
			selectedTeams.forEach(selectedTeam => {
				const gender = struct.genders.find(x => x.id === selectedTeam.genderId)!.value;
				const levelInfo = struct.levels.find(x => x.id === selectedTeam.levelId)!;

				newTeams = updateLocalTeams({
					teams: newTeams,
					ageCategoryName: ageCategory.name,
					gender,
					level: levelInfo,
					teamId: selectedTeam?.id
				});
			});
			setTeams(newTeams);
		}
	}, [selectedTeams, struct, ageCategory.name, updateLocalTeams]);

	const toggleAccordion = useCallback(() => {
		open ? onClose(teams || []) : onOpen();
	}, [open, onClose, onOpen, teams]);

	const toggleLevel = (id: string, checked: boolean) => {
		const newLevels = [...checkedLevels];
		if (checked) {
			newLevels.push(id);
		} else {
			const index = newLevels.indexOf(id);
			if (index > -1) {
				newLevels.splice(index, 1);

				setTeams(items => items?.filter(x => x.levelId !== id));

				const newSelectedGenders = [...selectedGenders].filter(x => x.levelId !== id);
				setSelectedGenders(newSelectedGenders);
			}
		}
		setCheckedLevels(newLevels);
	};

	const toggleGender = ({ levelId, gender, checked }: { levelId: string; gender: GenderEnum; checked: boolean }) => {
		const newSelectedGenders = [...selectedGenders];
		if (checked) {
			newSelectedGenders.push({ levelId, gender });
		} else {
			const index = newSelectedGenders.findIndex(x => x.levelId === levelId && x.gender === gender);
			if (index > -1) {
				newSelectedGenders.splice(index, 1);
				setTeams(items => items?.filter(x => !(x.levelId === levelId && x.gender === gender)));
			}
		}
		setSelectedGenders(newSelectedGenders);
	};

	const isLevelChecked = useCallback((id: string) => checkedLevels.indexOf(id) !== -1, [checkedLevels]);

	const isGenderChecked = useCallback(
		(levelId: string, gender: GenderEnum) => selectedGenders.some(x => x.levelId === levelId && x.gender === gender),
		[selectedGenders]
	);

	const addTeam = useCallback(
		({ level, gender }: { level: SetupLevelModel; gender: GenderEnum }) => {
			const newTeams = updateLocalTeams({ teams: teams || [], ageCategoryName: ageCategory.name, gender, level });
			setTeams(newTeams);
		},
		[updateLocalTeams, ageCategory.name, teams]
	);

	const removeTeam = useCallback(
		({ level, levelId, gender }: { level?: SetupLevelModel; levelId?: string; gender: GenderEnum }) => {
			const newTeams = [...(teams || [])];
			const lastIndex = newTeams
				.slice()
				.reverse()
				.findIndex(x => !x.id && x.gender === gender && (x.levelId === levelId || level?.id));
			if (lastIndex > -1) {
				const startIndex = newTeams.length - lastIndex - 1;
				newTeams.splice(startIndex, 1);
			}
			setTeams(newTeams);
		},
		[teams]
	);

	const getTeamCountForGenderLevel = useCallback(
		(levelId: string, gender: GenderEnum) => {
			return teams?.filter(x => x.levelId === levelId && x.gender === gender).length;
		},
		[teams]
	);

	const handleSave = useCallback(() => {
		onNext(teams || []);
	}, [onNext, teams]);

	const isFinished = useMemo(() => !open && !!teams?.length, [open, teams?.length]);

	const RenderCounter = useCallback(
		(level: SetupLevelModel, gender: GenderEnum) => (
			<Counter
				val={getTeamCountForGenderLevel(level.id, gender)}
				onAdd={() => addTeam({ level, gender })}
				onRemove={() => removeTeam({ level, gender })}
			/>
		),
		[getTeamCountForGenderLevel, addTeam, removeTeam]
	);

	const createdTeams = useMemo(() => {
		return teams?.filter(x => !x?.id);
	}, [teams]);

	return (
		<div className={"relative flex items-start justify-between"}>
			<TickIconWrapper>
				<TickIcon
					className={clsx(
						"w-[2rem] h-[2rem]",
						isFinished
							? "svg-first-path:stroke-primary-500 svg-first-path:fill-primary-500"
							: "svg-first-path:stroke-gray-400 svg-first-path:fill-gray-400"
					)}
				/>
			</TickIconWrapper>
			<StyledAccordion expanded={open} onChange={toggleAccordion}>
				<StyledAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
					<div className="flex justify-between w-full">
						<Text variants={TextVariantsEnum.BodyMedium} style={TextStylesEnum.Default} className="mb-2">
							{ageCategory.name}
						</Text>
						<div className="flex">
							<Text variants={TextVariantsEnum.BodyMedium} style={TextStylesEnum.Default} className="mb-2">
								<span className="capitalize">{ageCategory?.type}</span> {ageCategory.value}
							</Text>
							<div id={id} className="ml-4">
								<ArrowIcon className={open ? "-rotate-90" : "rotate-90"} />
							</div>
						</div>
					</div>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					{levels.map((level, idx) => (
						<div key={level.id} className="mb-2">
							<Checkbox
								id={`checkbox-level-${idx + 1}`}
								label={level.name}
								value={isLevelChecked(level.id)}
								onChange={(checked: boolean) => toggleLevel(level.id, checked)}
							/>
							<Collapse in={isLevelChecked(level.id)} timeout="auto">
								<GendersWrapper>
									{genders.map((gender, index) => {
										const isChecked = isGenderChecked(level.id, gender);
										return (
											<GenderWrapper key={index}>
												<Checkbox
													id={`checkbox-gender-${index + 1}`}
													value={isChecked}
													label={getGenderTitle(gender, t)}
													onChange={(checked: boolean) => toggleGender({ levelId: level.id, gender, checked })}
												/>
												{isChecked && RenderCounter(level, gender)}
											</GenderWrapper>
										);
									})}
								</GendersWrapper>
							</Collapse>
						</div>
					))}
					{!!createdTeams?.length && (
						<TeamsBlock>
							<Text variants={TextVariantsEnum.H7} style={TextStylesEnum.Default} className="mt-3 mb-2 select-none">
								Created teams
							</Text>
							<div>
								{createdTeams.map((team, index) => (
									<TeamWrapper key={index} className="select-none">
										<Text variants={TextVariantsEnum.BodyMedium} style={TextStylesEnum.Default} className="mt-3 mb-2">
											{team.name}
										</Text>
										<div
											id={`remove-team-${index + 1}`}
											className="cursor-pointer ml-1"
											onClick={() => removeTeam({ levelId: team.levelId, gender: team.gender })}
										>
											<CloseIcon className="w-[1rem] h-[1rem] svg-first-path:stroke-gray-400 svg-first-path:fill-gray-400" />
										</div>
									</TeamWrapper>
								))}
							</div>
							<Button
								id="save-and-open-next-category"
								variant={ButtonVariantsEnum.FILLED}
								size={ButtonSizesEnum.MD}
								className="mb-3"
								onClick={handleSave}
							>
								Save & Open next category
							</Button>
						</TeamsBlock>
					)}
				</StyledAccordionDetails>
			</StyledAccordion>
			<TeamCountWrapper className="select-none">
				<Text variants={TextVariantsEnum.BodyMedium} style={TextStylesEnum.Default}>
					{teams?.length || 0} teams
				</Text>
			</TeamCountWrapper>
		</div>
	);
};

export default React.memo(TeamCategory);
