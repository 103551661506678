import React, { forwardRef, useImperativeHandle, useRef } from "react";

import { Scrollbars } from "react-custom-scrollbars-2";

import type { BaseBlockFilterModel } from "shared/types/FilterModel";

import type { FilterBlockModel } from "./FilterBlock";
import FilterBlock from "./FilterBlock";

export interface FilterBlocksMethods {
	getSelectedOptions: () => BaseBlockFilterModel[];
	clearFilters: () => void;
}

export interface FilterBlocksProps {
	blocks: FilterBlockModel[];
	defaultFiltersData: BaseBlockFilterModel[];
}

const FilterBlocks = forwardRef<FilterBlocksMethods, FilterBlocksProps>(({ blocks, defaultFiltersData }, ref) => {
	type FilterBlockMethodsHandlers = React.ElementRef<typeof FilterBlock>;
	const filterBlockRef = useRef<(FilterBlockMethodsHandlers | null)[]>([]);

	useImperativeHandle(ref, () => ({
		getSelectedOptions: () => {
			if (filterBlockRef.current.length) {
				return filterBlockRef.current.map(block => ({
					blockId: block!.getBlockId(),
					values: block!.getSelectedOptions()
				}));
			}

			return [];
		},
		clearFilters: () => {
			filterBlockRef.current.forEach(block => block!.clearFilter());
		}
	}));

	return (
		<Scrollbars className="w-full" height="100%" autoHeight autoHeightMin={100} autoHeightMax={"50vh"} autoHide>
			<div className="flex flex-col">
				{blocks
					.filter(block => !!block.options.length)
					.map((block, index) => (
						<div key={`filter-block-${index}`} className="border-t border-gray-100">
							<FilterBlock
								ref={el => (filterBlockRef.current ? (filterBlockRef.current[index] = el) : null)}
								block={block}
								index={index}
								defaultFilterValues={defaultFiltersData.find(x => x.blockId === block.id)?.values}
							/>
						</div>
					))}
			</div>
		</Scrollbars>
	);
});

export default FilterBlocks;
