import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import type { DropResult } from "react-beautiful-dnd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";

import { useTranslation } from "react-i18next";

import CloseIcon from "assets/icons/filled/close-circle.svg?react";
import DragIcon from "assets/icons/outlined/drag.svg?react";
import PlusIcon from "assets/icons/outlined/plus.svg?react";

import { ErrorHint } from "shared/Components";
import type { SetupLevelModel } from "shared/types";
import { Input, Text } from "shared/uikit";
import { ButtonSizesEnum, ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";
import { levelRules } from "shared/validators/SetupEntity";
import { getDraggableItemStyle, vibrateDevice } from "utils/dnd";
import { uuidv4 } from "utils/serviceUtils/helpers";

import { LevelManageIconWrapper, StyledButton } from "./style";

const maxLevelsCount = 5;

export interface LevelFormMethods {
	getInfo: () => {
		levels: SetupLevelModel[];
		isValid: boolean;
	};
	triggerSubmit: () => void;
	triggerForm: () => void;
}

interface LevelFormProps {
	defaultValues?: SetupLevelModel[];
	values?: SetupLevelModel[];
	onValidateForm?: (valid: boolean, { levels }: { levels: SetupLevelModel[] }) => void;
	onSubmit?: () => void;
	modalView?: boolean;
	includeDescriptions?: boolean;
}

const LevelForm = forwardRef<LevelFormMethods, LevelFormProps>(
	({ defaultValues, values, onValidateForm, modalView = false, includeDescriptions, onSubmit }, ref) => {
		const { t } = useTranslation();

		const [isRevalidating, setIsRevalidating] = useState(false);

		const { control, setValue, trigger, formState, getValues, handleSubmit } = useForm<{ levels: SetupLevelModel[] }>({
			defaultValues: {
				levels: values ||
					defaultValues || [
						{ id: uuidv4(), name: "" },
						{ id: uuidv4(), name: "" },
						{ id: uuidv4(), name: "" }
					]
			}
		});

		const { errors, isValid } = formState;

		useEffect(() => {
			onValidateForm && onValidateForm(isValid, getValues());
		}, [isValid, getValues, onValidateForm]);

		const { fields, append, remove } = useFieldArray({
			name: "levels",
			control,
			rules: levelRules
		});

		const { levels } = useWatch({ control });

		useImperativeHandle(ref, () => ({
			getInfo: () => ({
				levels: levels as SetupLevelModel[],
				isValid
			}),
			triggerSubmit: () => {
				setIsRevalidating(true);
				onSubmit && handleSubmit(onSubmit)();
			},
			triggerForm: () => {
				setIsRevalidating(true);
				trigger();
			}
		}));

		const getLevelNamePlaceholder = (index: number) => {
			switch (index) {
				case 0:
					return "Premiere";
				case 1:
					return "Championship";
				case 2:
					return "Third Division";

				default:
					return "";
			}
		};

		const RenderLevel = (level: SetupLevelModel, index: number, arr: SetupLevelModel[]) => {
			const { id } = level;
			const placeholder = getLevelNamePlaceholder(index);

			const showManage = arr.length > 1;

			return (
				<Draggable draggableId={`draggable-${index}`} index={index} key={id}>
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							style={getDraggableItemStyle(snapshot?.isDragging, provided.draggableProps.style)}
							className={`${modalView ? "mb-4" : "mb-6"} last:mb-0`}
						>
							<Text
								variants={modalView ? TextVariantsEnum.Caption2 : TextVariantsEnum.H7}
								style={TextStylesEnum.Default}
							>
								{t("level")} {index + 1}
							</Text>
							<div className="flex w-full relative mt-2">
								{showManage && (
									<LevelManageIconWrapper {...provided.dragHandleProps} className="drag">
										<DragIcon />
									</LevelManageIconWrapper>
								)}
								<Controller
									name={`levels.${index}.name`}
									control={control}
									rules={{ required: t("error:is_required", { field: t("name") }), min: 2 }}
									render={({ field: { value, onChange } }) => (
										<Input
											size={modalView ? ButtonSizesEnum.UNDER_MD : ButtonSizesEnum.MD}
											onChange={val => {
												onChange(val);
												isRevalidating && trigger();
											}}
											value={value}
											placeholder={`Enter level name${placeholder ? `, e.g. ${placeholder}` : ""}`}
											className="w-full"
											wrapClassName="w-full"
											error={!!errors?.["levels"]?.[index]?.["name"]?.["message"]}
											id={`level-${index + 1}`}
										/>
									)}
								/>
								{showManage && (
									<LevelManageIconWrapper
										id={`remove-level-${index + 1}`}
										onClick={() => remove(index)}
										className="remove"
									>
										<CloseIcon />
									</LevelManageIconWrapper>
								)}
							</div>
							{includeDescriptions && (
								<Controller
									control={control}
									name={`levels.${index}.description`}
									render={({ field: { onChange, value } }) => (
										<Input
											size={modalView ? ButtonSizesEnum.UNDER_MD : ButtonSizesEnum.MD}
											onChange={onChange}
											value={value}
											placeholder={`Short description of level ${index + 1}`}
											className="w-full"
											wrapClassName="w-full mt-4"
											headline={t("description")}
											id={`level-${index + 1}-description`}
										/>
									)}
								/>
							)}
						</div>
					)}
				</Draggable>
			);
		};

		const reorder = (list: SetupLevelModel[], startIndex: number, endIndex: number): SetupLevelModel[] => {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed as SetupLevelModel);

			return result;
		};

		const onDragEnd = (result: DropResult) => {
			if (!result.destination || result.destination.index === result.source.index) {
				return;
			}

			const items = reorder(levels as SetupLevelModel[], result.source.index, result.destination.index);

			setValue("levels", items, { shouldValidate: true });
			isRevalidating && trigger();
		};

		const handleAppend = () => {
			if (fields.length < maxLevelsCount) {
				append({ id: uuidv4(), name: "" });
			}
		};

		return (
			<>
				<DragDropContext onDragStart={vibrateDevice} onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{provided => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{fields.map(RenderLevel)}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				{!!errors?.levels?.length && (
					<div className="mt-3">
						<ErrorHint text={errors?.levels["root"]?.message || t("error:enter_all_details")} />
					</div>
				)}
				{fields.length < maxLevelsCount && (
					<div data-animate>
						<StyledButton
							size={ButtonSizesEnum.SM}
							variant={ButtonVariantsEnum.LINK}
							startIcon={<PlusIcon />}
							onClick={handleAppend}
							className="-ml-5 mt-2"
							id="add-level"
						>
							{t("add_level")}
						</StyledButton>
					</div>
				)}
			</>
		);
	}
);

export default React.memo(LevelForm);
