import type { FC } from "react";
import React from "react";

import { ConfirmLeaveDialog } from "shared/Components";

import type { DialogProps } from "../BaseDialog";
import BaseDialog, { ModalSizesEnum } from "../BaseDialog";

type ConfirmLeaveProps = {
	open: boolean;
	onClose: () => void;
	onQuit: () => void;
	title: string;
	message: string;
};

interface ModalProps extends DialogProps {
	confirmLeave?: ConfirmLeaveProps;
	fullWidth?: boolean;
}

const Modal: FC<ModalProps> = ({ children, sizes = ModalSizesEnum.MEDIUM, confirmLeave, ...rest }) => (
	<>
		<BaseDialog sizes={sizes} {...rest}>
			{children}
		</BaseDialog>
		{confirmLeave && (
			<ConfirmLeaveDialog
				open={confirmLeave.open}
				onClose={confirmLeave.onClose}
				onQuit={confirmLeave.onQuit}
				title={confirmLeave.title}
				message={confirmLeave.message}
			/>
		)}
	</>
);

export default Modal;
