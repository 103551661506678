import type { FC } from "react";
import React, { useMemo } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import { CalendarEventEnum } from "shared/types/MatchStatusEnum";
import { Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

const EventType: FC<{ noFloating?: boolean; type?: CalendarEventEnum }> = ({ type, noFloating }) => {
	const { t } = useTranslation();

	const text = useMemo(
		() =>
			type === CalendarEventEnum.SOCIAL
				? t("match:social_event")
				: type === CalendarEventEnum.TRAINING
					? t("match:training")
					: type === CalendarEventEnum.PRACTICE
						? t("match:practice")
						: type === CalendarEventEnum.FRIENDLY_MATCH
							? t("match:friendly_match")
							: t("match"),
		[type, t]
	);

	return (
		<Text
			style={TextStylesEnum.Hint}
			variants={TextVariantsEnum.Caption2}
			className={clsx("z-20 text-inactive w-fit h-6 flex items-center px-2 rounded-lg", [
				!noFloating && "absolute top-4 left-4",
				type === CalendarEventEnum.SOCIAL && "bg-red-50",
				(type === CalendarEventEnum.TRAINING || type === CalendarEventEnum.PRACTICE) && "bg-yellow-50",
				type === CalendarEventEnum.FRIENDLY_MATCH && "bg-green-50",
				(!type || type === CalendarEventEnum.MATCH) && "bg-blue-50"
			])}
		>
			{text}
		</Text>
	);
};

export default EventType;
