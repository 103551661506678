import type { RegisterOptions } from "react-hook-form/dist/types/validator";

import type { GenderEnum, SetupCategoryModel, SetupEntityModel, SetupLevelModel } from "shared/types";
import { AgeGroupEnum } from "shared/types";

export const levelRules = {
	validate: (data: SetupLevelModel[]) => {
		for (let i = 0; i < data.length; i++) {
			const level = data[i];
			if (!level?.name) {
				return "Please enter all data";
			}
		}
		return true;
	}
};

export const categoryRules = {
	validate: (data: SetupCategoryModel[]) => {
		for (let i = 0; i < data.length; i++) {
			const category = data![i] as SetupCategoryModel;

			if (new Date(category.date).getFullYear() === new Date().getFullYear()) {
				return "Cutoff can not be same as current year";
			}

			if (!category?.name || !category?.behavior || (!category?.date && category?.behavior !== AgeGroupEnum.OPEN)) {
				return "Please enter all data";
			}
		}

		if (
			data.filter(
				(item, index, arr) =>
					arr.findIndex(
						x => x.behavior === item.behavior && new Date(x.date).getFullYear() === new Date(item.date).getFullYear()
					) !== index
			)?.length
		) {
			return "Some categories have the same settings";
		}
		return true;
	}
};

export const genderRules: Pick<
	RegisterOptions<SetupEntityModel>,
	"maxLength" | "minLength" | "validate" | "required"
> = {
	validate: data => {
		return (data as GenderEnum[])?.length ? true : "Select at least one gender.";
	}
};
