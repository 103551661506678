import type { FC, ReactNode } from "react";
import React from "react";

import clsx from "clsx";

import { Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

interface FormBlockProps {
	label: string | ReactNode;
	labelClassname?: string;
	containerClassName?: string;
	textVariant?: TextVariantsEnum;
	children: React.ReactNode;
	manageBlock?: React.ReactNode;
}

const FormBlock: FC<FormBlockProps> = ({
	label,
	labelClassname,
	containerClassName,
	children,
	textVariant,
	manageBlock
}) => (
	<div className={containerClassName}>
		<div className={clsx(labelClassname, "flex justify-between items-center")}>
			{typeof label === "string" ? (
				<Text style={TextStylesEnum.Default} variants={textVariant || TextVariantsEnum.H7}>
					{label}
				</Text>
			) : (
				label
			)}
			{manageBlock}
		</div>
		{children}
	</div>
);

export default FormBlock;
