import type { FC } from "react";
import React, { Suspense, lazy, useMemo, useState } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";

import { getPaths } from "core/getPaths";

import { usePost, useUser } from "shared/hooks";
import type { PostModel } from "shared/types";
import type { MenuDotsOption } from "shared/uikit";
import { ImageSizesEnum, Img, MenuDots, Text } from "shared/uikit";

import type { ActionModel } from "shared/uikit/BaseDialog";
import {
	ButtonSizesEnum,
	ButtonVariantsEnum,
	MenuListPositionOpts,
	TextStylesEnum,
	TextVariantsEnum
} from "shared/uikit/types";

import { PostActions } from "./index";

const ConfirmationDialog = lazy(() => import("shared/Components/ConfirmationDialog"));
const CreatePostModal = lazy(() => import("../../Components/CreatePostModal"));

interface HeaderProps {
	post: PostModel;
	className?: string;
	onPostAction: (params: { postId: string; action: PostActions; data?: PostModel }) => void;
}

const { member: memberRoutes } = getPaths();

const Header: FC<HeaderProps> = ({ post, className, onPostAction }) => {
	const { t } = useTranslation();

	const { deletePost } = usePost();

	const { isSystemAdmin, getData: getUserData } = useUser();
	const { myProfile } = getUserData();

	const [showUpdatePostDialog, setShowUpdatePostDialog] = useState(false);
	const [warningModal, setWarningModal] = useState<{
		open: boolean;
		title: string;
		message: string;
		onClose: () => void;
		primary: ActionModel;
	}>();

	const postOptions: MenuDotsOption[] = useMemo(() => {
		const optionList: MenuDotsOption[] = [
			// {
			// 	name: "Report",
			// 	onClick: () => {}
			// },
			// {
			// 	name: "Share",
			// 	onClick: () => {}
			// },
		];

		if (myProfile?.id === post.creator?.id) {
			optionList.push({
				name: t("edit"),
				onClick: () => {
					setShowUpdatePostDialog(true);
				}
			});
		}

		if (myProfile?.id === post.creator?.id || isSystemAdmin) {
			optionList.push({
				name: t("del"),
				onClick: () => {
					setWarningModal({
						open: true,
						title: t("feed:remove_post"),
						message: t("feed:do_you_want_to_remove_your_post"),
						onClose: () => setWarningModal(undefined),
						primary: {
							text: t("remove"),
							variant: ButtonVariantsEnum.DANGER,
							onClick: async () => {
								setWarningModal(undefined);
								deletePost(post.id);
								onPostAction({ postId: post!.id, action: PostActions.REMOVE });
							}
						}
					});
				}
			});
		}

		return optionList;
	}, [deletePost, post, onPostAction, myProfile?.id, isSystemAdmin, t]);

	const closeUpdatePostModal = (updatedPost?: PostModel) => {
		setShowUpdatePostDialog(false);
		updatedPost &&
			onPostAction({
				postId: post.id,
				action: PostActions.UPDATE_POST,
				data: updatedPost
			});
	};

	return (
		<>
			<div className={clsx("flex justify-between items-start", className)}>
				<Link to={`${memberRoutes.profile.getPath()}/${post.creator.id}`}>
					<div className="flex items-center">
						<Img
							src={post.creator?.avatar}
							alt={post.creator?.name || post.creator?.shortName || ""}
							circle
							size={ImageSizesEnum.Small48}
							className="mr-4"
						/>
						<div>
							<Text variants={TextVariantsEnum.H7}>{post.creator?.name}</Text>
							<Text variants={TextVariantsEnum.BodySmall} style={TextStylesEnum.Hint} className="mt-1">
								<TimeAgo
									key={`posted-at-${post.id}`}
									date={post.createdAt}
									live
									formatter={(value, unit, suffix) =>
										value < 60 && unit === "second" ? "Just now" : `${value} ${unit} ${suffix}`
									}
								/>
							</Text>
						</div>
					</div>
				</Link>
				<MenuDots
					bordered
					horizontal
					size={ButtonSizesEnum.XL}
					options={postOptions}
					menuListPosition={MenuListPositionOpts.BOTTOM_RIGHT}
					popoverPosition={MenuListPositionOpts.TOP_RIGHT}
				/>
			</div>
			{warningModal?.open && (
				<Suspense>
					<ConfirmationDialog
						open
						onClose={warningModal.onClose}
						actions={[
							{
								text: t("close"),
								variant: ButtonVariantsEnum.OUTLINED,
								onClick: warningModal.onClose
							},
							warningModal.primary
						]}
						title={warningModal.title}
						message={warningModal.message}
					/>
				</Suspense>
			)}
			{showUpdatePostDialog && (
				<Suspense>
					<CreatePostModal post={post} onClose={closeUpdatePostModal} />
				</Suspense>
			)}
		</>
	);
};

export default Header;
