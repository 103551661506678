import { styled } from "@mui/material";

export const IconWrapper = styled("div")<{ color?: string }>`
	& > svg {
		${({ color }) =>
			color &&
			`
			fill: ${color};
		`}
	}

	&.reaction-icon {
		& > svg {
			${({ color }) =>
				color &&
				`
			fill: ${color} !important;
		`}// TODO: fix important
		}
	}
`;
