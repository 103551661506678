import { Suspense, forwardRef, lazy, useEffect, useImperativeHandle, useRef } from "react";

import type { Control, FieldErrors } from "react-hook-form";
import { useForm, useWatch } from "react-hook-form";

import { type CategoryFormMethodsHandlers } from "shared/Components/TournamentAgeCategories";
import { type LevelFormMethodsHandlers } from "shared/Components/TournamentsLevelsModal";
import { useCheckIsEndDateValid } from "shared/hooks";
import type { CreateCompetitionObject } from "shared/types/SetupSeasonModel";
import { CompetitionOpenRegistrationTypes, CompetitionRegistrationTypes } from "shared/types/SetupSeasonModel";

import type { CreateCompetitionFormMethods } from "../../Components/CreateCompetition";
import { CompetitionTypes, TieBreakerEnums } from "../../types";

const ChooseCompetitionType = lazy(() => import("../CreateLeagueCompetitionModal/ChooseCompetitionType"));
const CreateCompetition = lazy(() => import("shared/Components/CreateCompetition"));
const DefineCompetitionSettings = lazy(() => import("shared/Components/DefineCompetitionSettings"));
const TournamentAgeCategoriesModal = lazy(() => import("shared/Components/TournamentAgeCategories"));
const TournamentGendersModal = lazy(() => import("shared/Components/TournamentGendersModal"));
const TournamentsLevelsModal = lazy(() => import("shared/Components/TournamentsLevelsModal"));

interface SetupLeagueCompetitionFormMethods {
	isValid: () => boolean;
	getValues: () => CreateCompetitionObject | void;
	triggerForm: () => void;
}

interface SetupLeagueCompetitionProps {
	season?: CreateCompetitionObject;
	step: number;
}

export interface CreateCompetitionStepsProps {
	control: Control<CreateCompetitionObject, any>;
	errors: FieldErrors<CreateCompetitionObject>;
}

const SetupLeagueCompetition = forwardRef<SetupLeagueCompetitionFormMethods, SetupLeagueCompetitionProps>(
	({ season, step }, ref) => {
		const { control, getValues, formState, setValue, setError, trigger } = useForm<CreateCompetitionObject>({
			mode: "onChange",
			defaultValues: season || {
				name: "",
				tieBreakerRules: Object.keys(TieBreakerEnums).map(key => key) as TieBreakerEnums[],
				type: CompetitionTypes.SEASON,
				registrationType: CompetitionRegistrationTypes.REGULAR,
				openRegistrationType: CompetitionOpenRegistrationTypes.TEAM
			}
		});

		const { errors, isValid } = formState;

		const [startsOn, endsOn, type] = useWatch({
			control,
			name: ["startsOn", "endsOn", "type"]
		});

		const isValidEndDate = useCheckIsEndDateValid({
			startDate: startsOn,
			endDate: endsOn
		});

		const categoryRef = useRef<CategoryFormMethodsHandlers>(null);
		const createCompetitionFormRef = useRef<CreateCompetitionFormMethods>(null);
		const levelFormRef = useRef<LevelFormMethodsHandlers | null>(null);

		type SetupCompetitionGenders = React.ElementRef<typeof TournamentGendersModal>;
		const setupCompetitionGendersRef = useRef<SetupCompetitionGenders | null>(null);

		useEffect(() => {
			if (!isValidEndDate) {
				setError("endsOn", { message: "End date must be after start date" });
			}
		}, [isValidEndDate, setError]);

		useImperativeHandle(ref, () => ({
			isValid: () => isValid,
			getValues: () => {
				let data = getValues();

				if (step === 2) {
					if (!createCompetitionFormRef.current!.isValidPartialSettings()) {
						return createCompetitionFormRef.current!.triggerPartialSettingsForm();
					}
					const partialPayment = createCompetitionFormRef.current!.getPartialSettingsValues();
					if (partialPayment?.partialPayment) {
						setValue("partialPayment", [partialPayment]);
						data = {
							...data,
							partialPayment: [partialPayment]
						};
					}
				}

				if (step === 4) {
					const categoryLevel = categoryRef.current?.getInfo();
					if (!categoryLevel?.isValid) return categoryRef.current?.triggerForm();
					setValue("ages", categoryLevel?.categories);
				}

				if (step === 5) {
					const genderLevels = setupCompetitionGendersRef.current?.getValues();
					if (!genderLevels?.length) return;
					setValue("genders", genderLevels);
				}

				if (step === 6) {
					const levels = levelFormRef.current?.getInfo();

					if (!levels?.isValid) {
						levelFormRef.current?.triggerForm();
						return;
					}

					return {
						...data,
						levels: levels?.levels
					};
				}

				return data;
			},
			triggerForm: () => {
				trigger();
			}
		}));

		return (
			<>
				{step === 1 && (
					<Suspense>
						<ChooseCompetitionType errors={errors} control={control} />
					</Suspense>
				)}
				{step === 2 && (
					<Suspense>
						<CreateCompetition
							type={type}
							control={control}
							setValue={setValue}
							errors={errors}
							ref={createCompetitionFormRef}
						/>
					</Suspense>
				)}
				{step === 3 && (
					<Suspense>
						<DefineCompetitionSettings setValue={setValue} control={control} errors={errors} />
					</Suspense>
				)}
				{step === 4 && (
					<Suspense>
						<TournamentAgeCategoriesModal defaultValues={getValues("ages")} ref={categoryRef} />
					</Suspense>
				)}
				{step === 5 && (
					<Suspense>
						<TournamentGendersModal defaultValues={getValues("genders")} ref={setupCompetitionGendersRef} />
					</Suspense>
				)}
				{step === 6 && (
					<Suspense>
						<TournamentsLevelsModal ref={levelFormRef} />
					</Suspense>
				)}
			</>
		);
	}
);

export default SetupLeagueCompetition;
