import type { ReactNode } from "react";
import React, { memo, useCallback } from "react";

import clsx from "clsx";

import PencilIcon from "assets/icons/filled/pencil.svg?react";
import PlusIcon from "assets/icons/outlined/plus.svg?react";

import { CardWrapper } from "shared/Components";

import { Button, SkeletonBase, Text } from "shared/uikit";

import { ButtonSizesEnum, ButtonVariantsEnum, TextVariantsEnum } from "shared/uikit/types";

import { EditIconWrapper } from "./styles";

export interface EditCardInfoProps {
	children?: ReactNode;
	className?: string;
	titleClassName?: string;
	title?: string;
	allowEdit?: boolean;
	onEdit?: () => void;
	loading?: boolean;
	titleBorder?: boolean;
	allowAdd?: boolean;
	onAdd?: () => void;
}

const EditCardInfo: React.FC<EditCardInfoProps> = memo(
	({
		children,
		className,
		title,
		titleClassName,
		titleBorder,
		allowEdit = true,
		onEdit,
		loading,
		allowAdd = false,
		onAdd
	}) => {
		const handleEdit = useCallback(() => {
			onEdit && onEdit();
		}, [onEdit]);

		const handleAdd = useCallback(() => {
			onAdd && onAdd();
		}, [onAdd]);

		return (
			<CardWrapper className={clsx(className)}>
				{(title || allowEdit) && (
					<div
						className={clsx(
							`flex items-start mb-1 ${!title ? "justify-end" : "justify-between"}`,
							titleBorder && "border-b border-gray-100 p-3",
							titleClassName
						)}
					>
						{title && (
							<>
								{loading ? (
									<SkeletonBase width={100} height={36} />
								) : (
									<Text variants={TextVariantsEnum.H6}>{title}</Text>
								)}
							</>
						)}
						{allowEdit && (
							<>
								{loading ? (
									<SkeletonBase width={34} height={34} variant="rounded" />
								) : (
									<EditIconWrapper onClick={handleEdit} aria-label="Edit info">
										<PencilIcon className="w-4 h-4 svg-paths:fill-gray-500" />
									</EditIconWrapper>
								)}
							</>
						)}
						{allowAdd && (
							<>
								{loading ? (
									<SkeletonBase width={34} height={34} variant="rounded" />
								) : (
									<Button
										onClick={handleAdd}
										aria-label="Edit info"
										variant={ButtonVariantsEnum.GHOST}
										size={ButtonSizesEnum.XS}
										iconButton={{
											icon: <PlusIcon className="svg-paths:fill-gray-500" />
										}}
									/>
								)}
							</>
						)}
					</div>
				)}
				{children}
			</CardWrapper>
		);
	}
);

export default EditCardInfo;
