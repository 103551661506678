import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import clsx from "clsx";

import AddIcon from "assets/icons/filled/add.svg?react";
import TickIcon from "assets/icons/filled/tick.svg?react";
import SearchIcon from "assets/icons/outlined/search-thin.svg?react";
import { ActivateCard, Transition } from "shared/Components";
import { useClub, useCompetition, useDebounce } from "shared/hooks";
import { Headlines } from "shared/templates";

import type { ClubModel, CompetitionModel } from "shared/types";
import { ImageSizesEnum, Img, Input, SkeletonBase, Text } from "shared/uikit";

import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { getRoundedDistance } from "utils/serviceUtils/helpers";

import { CardWrapper, SeasonItem } from "./style";

export type FindSeasonTemplateMethods = {
	getSelected: () => string;
};

interface FindSeasonTemplateProps {
	isMounted: boolean;
	step?: number;
	title?: string;
	subtitle?: string;
	placeholderSearch?: string;
	searchForClub?: boolean;
	inModal?: boolean;
	onSelectItem?: (id?: string) => void;
}

const FindSeasonTemplate = forwardRef<FindSeasonTemplateMethods, FindSeasonTemplateProps>(
	({ isMounted, step, title, subtitle, placeholderSearch, searchForClub, inModal, onSelectItem }, ref) => {
		const { findCompetitions, getData: getCompetitionData } = useCompetition();
		const { competitions, loading: loadingCompetitions } = getCompetitionData();

		const { getClubs, getData: getClubData } = useClub();
		const { clubs, loading: loadingClubs } = getClubData();

		const [keyword, setKeyword] = useState("");
		const [selectedItem, setSelectedItem] = useState<string>();

		const debouncedKeyword = useDebounce(keyword, 250);

		useImperativeHandle(ref, () => ({
			getSelected: () => selectedItem || ""
		}));

		const handleChangeName = event => {
			setKeyword(event.target.value);
		};

		useEffect(() => {
			if (searchForClub) {
				getClubs({ search: debouncedKeyword });
			} else {
				findCompetitions({ search: debouncedKeyword });
			}
		}, [debouncedKeyword, searchForClub, getClubs, findCompetitions]);

		const toggleCompetition = useCallback(
			(id: string) => {
				const newSelected = selectedItem === id ? undefined : id;
				setSelectedItem(newSelected);
				onSelectItem && onSelectItem(newSelected);
			},
			[selectedItem, onSelectItem]
		);

		const customRender = useCallback(
			(item: CompetitionModel | ClubModel) => {
				const isSelected = selectedItem === `${item.id}`;

				return (
					<SeasonItem>
						<div className="info-row">
							<Img src={item?.logo} alt={item.name} circle size={ImageSizesEnum.Small24} className="mr-2" />
							<div>
								<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.Caption3}>
									{item?.name}
								</Text>
								<div className={"address-row"}>
									<div className={"address"}>
										<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.Caption}>
											{(item as ClubModel)?.location?.address || (item as CompetitionModel)?.league?.location?.address}
										</Text>
									</div>
									{(item as CompetitionModel)?.league && "distance" in (item as CompetitionModel).league! && (
										<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.Caption3}>
											{getRoundedDistance((item as CompetitionModel).league!.distance!)} miles away
										</Text>
									)}
								</div>
							</div>
						</div>
						<div className="cursor-pointer ml-1">
							{isSelected ? (
								<TickIcon className="w-[1rem] h-[1rem] svg-first-path:stroke-gray-500 svg-first-path:fill-gray-500" />
							) : (
								<AddIcon className="w-[1rem] h-[1rem] svg-first-path:stroke-gray-500 svg-first-path:fill-gray-500" />
							)}
						</div>
					</SeasonItem>
				);
			},
			[selectedItem]
		);

		const getOptionLabel = useCallback((option: Partial<CompetitionModel>): string => option?.name || "", []);

		return (
			<Transition isMounted={isMounted}>
				{step && (
					<div className="create-process-padding">
						<div data-animate>
							<Headlines
								step={step}
								question={title || "Find seasons/tournaments to apply for"}
								description={subtitle || "Discover nearby seasons and tournaments and apply for them."}
							/>
						</div>
					</div>
				)}
				<div className={clsx(!inModal && "create-process-padding")} data-animate>
					<Input
						search
						defaultValue={undefined}
						onChange={(_, val) => {
							if (val?.id && selectedItem !== `${val.id}`) {
								toggleCompetition(val.id);
							}
						}}
						options={searchForClub ? clubs : competitions}
						placeholder={placeholderSearch || "Search for seasons/tournaments"}
						className="w-full"
						LeftSideIcon={<SearchIcon />}
						customDropdownItemRender={customRender}
						onInputChange={handleChangeName}
						getOptionLabel={getOptionLabel}
						id="search-season-tournaments"
					/>
					<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.Caption3} className={"mt-6"}>
						Discover nearby {searchForClub ? "clubs" : "competitions"}
					</Text>
					<div className="grid gap-4 grid-cols-3 mt-2">
						{(searchForClub ? clubs : competitions).map((item, index) => {
							const isSelected = selectedItem === `${item.id}`;
							return (
								<CardWrapper key={index}>
									<ActivateCard
										icon={<Img src={item?.logo} alt={item.name} size={ImageSizesEnum.Small48} circle />}
										title={item.name}
										description={
											!searchForClub
												? item?.league?.distance
													? `${getRoundedDistance(item.league.distance!)} mi away`
													: undefined
												: undefined
										}
										active={isSelected}
										onClick={() => toggleCompetition(item.id)}
										checkbox
										id={`${searchForClub ? "club" : "competition"}-${item.id}`}
									/>
								</CardWrapper>
							);
						})}
						{(loadingClubs || loadingCompetitions) &&
							Array.from(Array(6)).map((item, index) => (
								<CardWrapper key={index} className={"skeleton"}>
									<SkeletonBase variant="circular" width={64} height={64} fullScale className="mt-5" />
									<SkeletonBase width={"90%"} height={12} fullScale className="mt-5" />
									<SkeletonBase width={"80%"} height={12} fullScale className="mt-2" />
								</CardWrapper>
							))}
					</div>
				</div>
			</Transition>
		);
	}
);

export default FindSeasonTemplate;
