import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import PhoneIcon from "assets/icons/outlined/phone.svg?react";

import type { InfoBoxProps } from "./index";

import { InfoBox } from "../index";

interface PhoneInfoBoxProps extends Omit<InfoBoxProps, "title" | "subtitle"> {
	title?: string | null;
}

const PhoneInfoBox: React.FC<PhoneInfoBoxProps> = memo(({ title, ...rest }) => {
	const { t } = useTranslation();

	return (
		<InfoBox
			{...rest}
			title={title || "-"}
			titleHref={title ? `tel:${title}` : undefined}
			subtitle={t("phone")}
			icon={<PhoneIcon className="w-5 h-5 svg-paths:fill-primary-600" />}
		/>
	);
});

export default PhoneInfoBox;
