import { Skeleton, styled } from "@mui/material";

import colors from "theme/colors";

export const SkeletonForm = styled(Skeleton)`
	background-color: ${colors.palette.colors.gray["100"]};

	&.side-offset {
		margin: 0 5px;
	}

	&.full-scale {
		transform: scale(1);
	}
`;
