import React, { memo, useMemo } from "react";

import clsx from "clsx";

import StarIcon from "assets/icons/filled/star.svg?react";

import { SkeletonBase, Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";

interface BaseProps {
	className?: string;
}

export interface RatingBlockProps extends BaseProps {
	loading?: boolean;
	rating?: number | null;
	reviewsCount?: number;
}

export interface RatingBlockLoadingProps extends BaseProps {
	loading?: boolean;
	rating: never;
	reviewsCount: number;
}

const RatingBlock: React.FC<RatingBlockProps | RatingBlockLoadingProps> = memo(
	({ loading, rating, reviewsCount, className }) => {
		const score = useMemo(() => {
			let properScore = rating || 0;
			if (properScore.toString().includes(".")) {
				properScore = Number(Number(properScore).toFixed(2));
			}
			return properScore;
		}, [rating]);

		if (loading) {
			return (
				<div className={clsx("flex items-center", className)}>
					<SkeletonBase width={20} height={20} fullScale className="mr-2" />
					<SkeletonBase width={100} height={15} fullScale />
				</div>
			);
		}

		return (
			<div className={clsx("flex items-center", className)}>
				<StarIcon className="w-5 h-5 svg-paths:fill-yellow-500 mr-2" />
				{!!score ? (
					<>
						<Text variants={TextVariantsEnum.BodySmall} className="font-bold">
							{score}
						</Text>
						{reviewsCount && (
							<Text variants={TextVariantsEnum.BodySmall} className="ml-1">
								({reviewsCount} reviews)
							</Text>
						)}
					</>
				) : (
					<Text variants={TextVariantsEnum.BodySmall}>No reviews yet</Text>
				)}
			</div>
		);
	}
);

export default RatingBlock;
