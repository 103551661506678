import type { FC } from "react";
import React from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import { InvitationStatus } from "shared/types";

import { Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";
import { renderStatus } from "utils/renderStatus";

const ChipStatus: FC<{ status: InvitationStatus }> = ({ status }) => {
	const { t } = useTranslation();

	const statusLabel = renderStatus(status, t);

	if (!statusLabel) {
		return null;
	}

	return (
		<div
			className={clsx(
				"h-6 w-auto flex items-center justify-center px-2 rounded-lg",
				status === InvitationStatus.PENDING ? "bg-yellow-100" : "bg-lightGreen"
			)}
		>
			<Text
				variants={TextVariantsEnum.Caption3}
				className={clsx(status === InvitationStatus.PENDING ? "text-yellow-600" : "text-primary-600")}
			>
				{statusLabel}
			</Text>
		</div>
	);
};

export default ChipStatus;
