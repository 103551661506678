import type { FC } from "react";
import React from "react";

import { TableColumnLoaderType } from "shared/types/Table";
import { SkeletonBase } from "shared/uikit";

import SkeletonColumnLoader from "./Column";

import type { SkeletonLoaderProps } from "./index";
import { Column } from "../SimpleTable/style";
import { TableSpecialColumnType } from "../index";

const SkeletonHeaderLoader: FC<Pick<SkeletonLoaderProps, "headerHeight" | "columnList" | "columnWidth">> = ({
	headerHeight,
	columnList,
	columnWidth
}) => (
	<div className="flex">
		<div className="w-full flex" style={{ height: headerHeight! }}>
			{columnList.map((column, columnIndex) => (
				<Column
					key={columnIndex}
					style={{ width: columnWidth(columnIndex) }}
					className={`${column?.alignment ? `alignment_${column?.alignment}` : ""}`}
				>
					{column?.loaderTemplate ? (
						<>{column.loaderTemplate}</>
					) : column?.type === TableSpecialColumnType.checkbox ? (
						<SkeletonColumnLoader type={TableColumnLoaderType.checkbox} />
					) : column?.type !== TableSpecialColumnType.expand ? (
						<SkeletonBase width="50%" height={26} />
					) : null}
				</Column>
			))}
		</div>
	</div>
);

export default SkeletonHeaderLoader;
