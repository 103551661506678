import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";

import type { AgeGroupEnum, SetupCategoryModel } from "shared/types";
import { categoryRules } from "shared/validators/SetupEntity";
import { uuidv4 } from "utils/serviceUtils/helpers";

import AdvancedForm from "./AdvancedForm";
import StandardForm from "./StandardForm";

import { ErrorHint } from "../../Components";

const maxCategoriesCount = 20;

export interface CategoryFormMethods {
	getInfo: () => {
		categories: SetupCategoryModel[];
		isValid: boolean;
	};
	triggerSubmit: () => void;
	triggerForm: () => void;
}

interface CategoryFormProps {
	defaultValues?: SetupCategoryModel[];
	values?: SetupCategoryModel[];
	onSubmit?: () => void;
	modalView?: boolean;
	advancedView?: boolean;
	categoryOptions?: boolean;
}

const CategoryForm = forwardRef<CategoryFormMethods, CategoryFormProps>(
	({ defaultValues, values, modalView = false, categoryOptions, advancedView = false, onSubmit }, ref) => {
		const { t } = useTranslation();

		const [isRevalidating, setIsRevalidating] = useState(false);

		const { control, trigger, formState, getValues, handleSubmit, setValue } = useForm<{
			categories: SetupCategoryModel[];
		}>({
			defaultValues: {
				categories: values || defaultValues || []
			}
		});

		const { errors, isValid } = formState;

		const { fields, append, remove } = useFieldArray({
			name: "categories",
			keyName: "fieldId",
			control,
			rules: categoryRules
		});

		useEffect(() => {
			if (advancedView && !fields.length) {
				append({ id: uuidv4(), name: "", date: "" });
			}
			if (!advancedView && fields?.length) {
				const removeIndexes: number[] = fields
					.map((x, index) => (!x.name || !x.date || !x.behavior ? index : -1))
					.filter(x => x > -1);

				const fieldWithDuplicates = fields
					.filter((x, index) => !removeIndexes.some(x => x === index))
					.filter(
						(item, index, arr) =>
							arr.findIndex(
								x =>
									x.behavior === item.behavior && new Date(x.date).getFullYear() === new Date(item.date).getFullYear()
							) !== index
					);

				if (fieldWithDuplicates?.length) {
					fieldWithDuplicates.forEach(duplicated => {
						const index = fields.findIndex(x => x.id === duplicated.id);
						if (index > -1) {
							removeIndexes.push(index);
						}
					});
				}

				if (removeIndexes?.length) {
					remove(removeIndexes);
				}
			}
		}, [advancedView, fields, append, remove]);

		useImperativeHandle(ref, () => ({
			getInfo: () => ({
				categories: getValues()?.categories as SetupCategoryModel[],
				isValid
			}),
			triggerSubmit: () => {
				onSubmit && handleSubmit(onSubmit)();
				setIsRevalidating(true);
			},
			triggerForm: () => {
				trigger();
				setIsRevalidating(true);
			}
		}));

		const isSelected = (name: string, behavior: AgeGroupEnum) => {
			return fields.some(x => x.name === name && x.behavior === behavior);
		};

		const toggleCategory = (category?: SetupCategoryModel) => {
			let isAdd = !category;
			if (category) {
				const index = fields.findIndex(x => x.name === category.name && x.behavior === category.behavior);
				if (index > -1) {
					remove(index);
				} else {
					isAdd = true;
				}
			}

			if (isAdd && fields.length < maxCategoriesCount) {
				append(category || { id: uuidv4(), name: "", date: "", behavior: null });
			}
		};

		const clearCategories = () => {
			setValue("categories", []);
		};

		const rootError = useMemo(
			() => (
				<>
					{(!!errors?.categories?.length || !!errors?.categories?.root?.message) && (
						<ErrorHint text={errors?.categories["root"]?.message || t("error:enter_all_details")} />
					)}
				</>
			),
			[errors?.categories, t]
		);

		return (
			<div className="animate-children-bottom">
				{advancedView ? (
					<div data-animate>
						<AdvancedForm
							rootError={rootError}
							onToggle={toggleCategory}
							maxCategoriesCount={maxCategoriesCount}
							fields={fields}
							errors={errors}
							control={control}
							setValue={setValue}
							trigger={trigger}
							isRevalidating={isRevalidating}
							modalView={modalView}
							categoryOptions={categoryOptions}
						/>
					</div>
				) : (
					<div data-animate>
						<StandardForm
							isSelected={isSelected}
							onToggle={toggleCategory}
							onUnselect={clearCategories}
							rootError={rootError}
						/>
					</div>
				)}
			</div>
		);
	}
);

export default React.memo(CategoryForm);
