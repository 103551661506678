const colors = {
	variants: {
		dark: "dark",
		light: "light",
		transparent: "transparent"
	},
	palette: {
		colors: {
			white: "#ffffff",
			lightGray: "#ECF3FC",
			lightBlack: "#222B45",
			lightGreen: "#E0EFEF",
			lightGreenHover: "#B3D8D6",
			inactive: "#8A9DC7",
			titlePurple: "#4F46E5",
			hint: "#58688F",
			gray: {
				50: "#F7F9FC",
				100: "#DCE5F5",
				200: "#BCCCEC",
				300: "#8A9DC7",
				400: "#58688F",
				500: "#222B45",
				600: "#18203B",
				700: "#111731",
				800: "#0A1028",
				900: "#060A21"
			},
			primary: {
				40: "#EEFDF7",
				50: "#f0fdf4",
				100: "#DCFAEE",
				200: "#BBF5E3",
				300: "#91E1CF",
				400: "#6DC4B8",
				500: "#409D99",
				600: "#2E8287",
				700: "#206671",
				800: "#144B5B",
				900: "#0C374B"
			},
			red: {
				50: "#FEF2F2",
				100: "#FEE2E2",
				200: "#FECACA",
				300: "#FCA5A5",
				400: "#F87171",
				500: "#EF4444",
				600: "#DC2626",
				700: "#B91C1C",
				800: "#991B1B",
				900: "#7F1D1D"
			},
			orange: {
				50: "#FFF8E8",
				100: "#FFF1D0",
				200: "#FFDFA1",
				300: "#FFC873",
				400: "#FFB250",
				500: "#FF8E16",
				600: "#DB6E10",
				700: "#B7530B",
				800: "#933B07",
				900: "#7A2A04"
			},
			yellow: {
				50: "#FEFCE8",
				100: "#FEF9C3",
				200: "#FEF08A",
				300: "#FDE047",
				400: "#FACC15",
				500: "#EAB308",
				600: "#CA8A04",
				700: "#A16207",
				800: "#854D0E",
				900: "#713F12"
			},
			blue: {
				50: "#eff6ff",
				100: "#DCEEFF",
				200: "#BADBFF",
				300: "#98C5FF",
				400: "#7EB2FF",
				500: "#5492FF",
				600: "#3D70DB",
				700: "#2A53B7",
				800: "#1A3993",
				900: "#10267A"
			},
			green: {
				50: "#EFFDEF",
				100: "#DFFBDF",
				200: "#BFF7C5",
				300: "#9AE8A9",
				400: "#79D293",
				500: "#4FB477",
				600: "#399A69",
				700: "#27815D",
				800: "#196850",
				900: "#0F5647"
			},
			purple: {
				50: "#eef2ff",
				100: "#e0e7ff",
				200: "#c7d2fe",
				300: "#A5B4FC",
				400: "#818cf8",
				500: "#6366f1",
				600: "#4f46e5",
				700: "#4338ca",
				800: "#3730a3",
				900: "#312e81"
			},
			reactionColors: {
				default: "#FFD16D",
				light: "#fee6cd",
				mediumLight: "#e2be98",
				mediumDark: "#c69971",
				dark: "#745b4d"
			}
		}
	}
};

export default colors;
