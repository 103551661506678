import type { ReactNode } from "react";
import React, { memo, useCallback, useMemo } from "react";

import clsx from "clsx";

import CheckboxIcon from "assets/icons/filled/checkbox.svg?react";
import CloseIcon from "assets/icons/filled/close-circle.svg?react";
import TickIcon from "assets/icons/filled/tick.svg?react";
import type { LogoModel } from "shared/types";
import { ImageSizesEnum, Img, SkeletonBase } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { Card, CardCheckmarkWrapper, CardIconWrapper, Description } from "./styles";

export interface ActivateCardProps {
	icon?: LogoModel | ReactNode;
	title?: string;
	titleExtra?: ReactNode;
	description?: string;
	middleContent?: ReactNode;
	checkbox?: boolean;
	className?: string;
	disabled?: boolean;
	notSelected?: boolean;
	onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
	active?: boolean;
	id?: string;
	bigIcon?: boolean;
	horizontal?: boolean;
	removeable?: boolean;
	onRemove?: () => void;
	maxWidth?: boolean;
	loading?: boolean;
	customAction?: ReactNode;
	alignment?: "left" | "center" | "right";
	customPaddingClass?: string;
}

const ActivateCard: React.FC<ActivateCardProps> = memo(
	({
		icon,
		title,
		titleExtra,
		description,
		middleContent,
		checkbox = false,
		className,
		disabled,
		notSelected,
		bigIcon,
		onClick,
		active,
		id,
		horizontal,
		removeable,
		onRemove,
		maxWidth,
		loading,
		customAction,
		alignment = "center",
		customPaddingClass
	}) => {
		const handleClick = useCallback(
			(event: React.MouseEvent<HTMLElement>) => {
				!disabled && onClick && onClick(event);
			},
			[onClick, disabled]
		);

		const handleRemove = useCallback(() => {
			removeable && onRemove && onRemove();
		}, [removeable, onRemove]);

		const cardIcon = useMemo(
			() =>
				icon ? (
					Array.isArray((icon as LogoModel)?.variants) ? (
						<Img src={icon as LogoModel} size={ImageSizesEnum.Small64} circle alt={title} className="mb-3" />
					) : (
						<CardIconWrapper
							className={clsx(
								!bigIcon && "w-12 h-12",
								"shrink-0 flex role-icon",
								alignment === "left" ? "justify-start" : "justify-center",
								horizontal ? "mb-0 mr-4" : "mx-auto",
								alignment !== "left" && !horizontal && "mb-3"
							)}
						>
							{icon as ReactNode}
						</CardIconWrapper>
					)
				) : null,
			[icon, title, bigIcon, alignment, horizontal]
		);

		if (loading) {
			return (
				<Card
					id={id}
					className={clsx(
						className,
						disabled && "disabled",
						active && "active",
						`text-${alignment}`,
						customPaddingClass ? customPaddingClass : "p-6",
						notSelected && "opacity-30",
						horizontal && "horizontal h-fit",
						maxWidth && "maxWidth"
					)}
				>
					<CardIconWrapper
						className={clsx(
							!bigIcon && "w-12 h-12",
							"shrink-0 flex justify-center role-icon",
							horizontal ? "mb-0 mr-4" : "mb-3 mx-auto"
						)}
					>
						<SkeletonBase
							fullScale
							width={bigIcon ? 64 : 48}
							height={bigIcon ? 64 : 48}
							variant="circular"
							className="shrink-0"
						/>
					</CardIconWrapper>
					<div className={clsx(horizontal ? "flex flex-col items-start shrink" : "w-full")}>
						<Description className="mb-1" style={TextStylesEnum.Default} variants={TextVariantsEnum.H7}>
							<SkeletonBase fullScale width={100} className={clsx(!horizontal && "mx-auto")} />
						</Description>
						<Description style={TextStylesEnum.Hint} variants={TextVariantsEnum.BodySmall}>
							<SkeletonBase fullScale width={200} className={clsx(!horizontal && "mx-auto")} />
						</Description>
					</div>
				</Card>
			);
		}

		return (
			<Card
				id={id}
				className={clsx(
					className,
					disabled && "disabled",
					active && "active",
					`text-${alignment}`,
					customPaddingClass ? customPaddingClass : "p-6",
					notSelected && "opacity-30",
					horizontal && "horizontal h-fit",
					maxWidth && "maxWidth"
				)}
				onClick={handleClick}
			>
				<div
					className={clsx(
						alignment === "center" ? "justify-center" : alignment === "left" ? "justify-start" : "justify-end"
					)}
				>
					{cardIcon}
				</div>
				<div
					className={clsx(
						horizontal ? "flex flex-col items-start shrink" : "w-full",
						horizontal && icon && "max-w-[70%]",
						horizontal && !icon && "max-w-full"
					)}
				>
					{title && (
						<div
							className={clsx(
								"flex items-center mb-1 max-w-full",
								alignment === "left" ? "justify-start" : "justify-center"
							)}
						>
							<Description
								className="max-w-full truncate block"
								style={TextStylesEnum.Default}
								variants={TextVariantsEnum.H7}
							>
								{title}
							</Description>
							{titleExtra}
						</div>
					)}
					{middleContent}
					{description && (
						<Description style={TextStylesEnum.Hint} variants={TextVariantsEnum.BodySmall}>
							{description}
						</Description>
					)}
				</div>
				{customAction ? (
					customAction
				) : (
					<CardCheckmarkWrapper
						id={`checkbox-${id}`}
						onClick={handleRemove}
						className={clsx("checkmark-wrapper", checkbox && "checkbox", removeable && "active cursor-pointer")}
					>
						{checkbox ? (
							<CheckboxIcon />
						) : removeable ? (
							<CloseIcon className="svg-first-path:fill-hint svg-first-path:stroke-hint" />
						) : (
							<TickIcon className="svg-first-path:fill-green-600 svg-first-path:stroke-green-600" />
						)}
					</CardCheckmarkWrapper>
				)}
			</Card>
		);
	}
);

export default ActivateCard;
