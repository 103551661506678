import { Suspense, lazy } from "react";

import config from "config/appConfig";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));

const ClubPageLayout = lazy(() => import("modules/Club/View/layouts/ClubPageLayout"));
const CreateClubLayout = lazy(() => import("modules/Club/View/layouts/CreateClubLayout"));

const ClubAddressPage = lazy(() => import("./Pages/ClubAddress"));
const ClubCoachesPage = lazy(() => import("./Pages/Club/Coaches"));
const ClubCompetitionsPage = lazy(() => import("./Pages/Club/Competitions"));
const ClubFinancePage = lazy(() => import("./Pages/Club/Finance"));
const ClubDetailedStatsPage = lazy(() => import("./Pages/Club/Stats/detailed"));
const ClubMatchesPage = lazy(() => import("./Pages/Club/Matches"));
const ClubNamePage = lazy(() => import("./Pages/ClubName"));
const ClubOverviewPage = lazy(() => import("./Pages/Club/Overview"));
const ClubPlayersPage = lazy(() => import("./Pages/Club/Players"));
const ClubStatsPage = lazy(() => import("./Pages/Club/Stats/index"));
const ClubStoryPage = lazy(() => import("./Pages/ClubStory"));
const ClubStructurePage = lazy(() => import("./Pages/Club/Structure"));
const ClubTeamsPage = lazy(() => import("./Pages/Club/Teams"));
const ClubStorePage = lazy(() => import("./Pages/Club/Store"));
const CreateTeamPage = lazy(() => import("./Pages/CreateTeam"));
const InvitePeoplePage = lazy(() => import("./Pages/InvitePeople"));
const SetupCategoryPage = lazy(() => import("./Pages/Setup/Category"));
const SetupGenderPage = lazy(() => import("./Pages/Setup/Gender"));
const SetupLevelPage = lazy(() => import("./Pages/Setup/Level"));
const UploadClubLogoPage = lazy(() => import("./Pages/UploadClubLogo"));

import { routes } from "./constants";

const { CORE_URLS, GLOBAL_CONSTANTS } = config;

class ClubApp implements IApp {
	getRoutes = () => [
		<Route path={routes.club.getPath()} key="club-routes">
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key="club-form-routes"
			element={
				<Suspense>
					<CreateClubLayout />
				</Suspense>
			}
		>
			<Route
				key={routes.club.create.name.getElementKey()}
				path={routes.club.create.name.getPath()}
				element={
					<Suspense>
						<ClubNamePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.club.create.address.getElementKey()}
				path={routes.club.create.address.getPath()}
				element={
					<Suspense>
						<ClubAddressPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.club.create.logo.getElementKey()}
				path={routes.club.create.logo.getPath()}
				element={
					<Suspense>
						<UploadClubLogoPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.club.create.story.getElementKey()}
				path={routes.club.create.story.getPath()}
				element={
					<Suspense>
						<ClubStoryPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.club.invite.people.getElementKey()}
				path={routes.club.invite.people.getPath()}
				element={
					<Suspense>
						<InvitePeoplePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.club.setup.level.getElementKey()}
				path={routes.club.setup.level.getPath()}
				element={
					<Suspense>
						<SetupLevelPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.club.setup.category.getElementKey()}
				path={routes.club.setup.category.getPath()}
				element={
					<Suspense>
						<SetupCategoryPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.club.setup.gender.getElementKey()}
				path={routes.club.setup.gender.getPath()}
				element={
					<Suspense>
						<SetupGenderPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.club.team.commands.getElementKey()}
				path={routes.club.team.commands.getPath()}
				element={
					<Suspense>
						<CreateTeamPage />
					</Suspense>
				}
			/>
		</Route>,
		<Route
			key={routes.club.getElementKey()}
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={`${routes.club.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<ClubPageLayout homePageUrl={CORE_URLS.HOME} />
					</Suspense>
				}
			>
				<Route
					key={`${routes.club.getElementKey()}/id`}
					path={`${routes.club.getPath()}/:id`}
					element={
						<Suspense>
							<ClubOverviewPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.club.getElementKey()}/id/structure`}
					path={`${routes.club.getPath()}/:id/structure`}
					element={
						<Suspense>
							<ClubStructurePage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.club.getElementKey()}/id/players`}
					path={`${routes.club.getPath()}/:id/players`}
					element={
						<Suspense>
							<ClubPlayersPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.club.getElementKey()}/id/coaches`}
					path={`${routes.club.getPath()}/:id/coaches`}
					element={
						<Suspense>
							<ClubCoachesPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.club.getElementKey()}/id/teams`}
					path={`${routes.club.getPath()}/:id/teams`}
					element={
						<Suspense>
							<ClubTeamsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.club.getElementKey()}/id/store`}
					path={`${routes.club.getPath()}/:id/store`}
					element={
						<Suspense>
							<ClubStorePage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.club.getElementKey()}/id/matches`}
					path={`${routes.club.getPath()}/:id/matches`}
					element={
						<Suspense>
							<ClubMatchesPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.club.getElementKey()}/id/competitions`}
					path={`${routes.club.getPath()}/:id/competitions`}
					element={
						<Suspense>
							<ClubCompetitionsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.club.getElementKey()}/id/stats`}
					path={`${routes.club.getPath()}/:id/stats`}
					element={
						<Suspense>
							<ClubStatsPage />
						</Suspense>
					}
				/>
				{GLOBAL_CONSTANTS.ENABLE_PAYMENT_FUNCTIONALITY && (
					<Route
						key={`${routes.club.getElementKey()}/id/finance`}
						path={`${routes.club.getPath()}/:id/finance`}
						element={
							<Suspense>
								<ClubFinancePage />
							</Suspense>
						}
					/>
				)}
			</Route>
			<Route
				key={`${routes.club.getElementKey()}/id/stats/competition/entity/detail`}
				path={`${routes.club.getPath()}/:id/stats/:competitionId/:entity/:stat`}
				element={
					<Suspense>
						<ClubDetailedStatsPage />
					</Suspense>
				}
			/>
		</Route>
	];
}

export default ClubApp;
