import {
	EntitySoccerClubRoles,
	EntitySoccerFacilityRoles,
	EntitySoccerLeagueRoles,
	EntitySoccerRefereeOrgRoles
} from "shared/types";

export const renderRole = (
	role: EntitySoccerClubRoles | EntitySoccerLeagueRoles | EntitySoccerFacilityRoles | EntitySoccerRefereeOrgRoles
) => {
	switch (role) {
		case EntitySoccerClubRoles.COACH:
			return "Coach";
		case EntitySoccerClubRoles.PLAYER:
			return "Player";
		case EntitySoccerClubRoles.CREATOR:
		case EntitySoccerLeagueRoles.CREATOR:
		case EntitySoccerFacilityRoles.CREATOR:
		case EntitySoccerRefereeOrgRoles.CREATOR:
			return "Creator";
		case EntitySoccerClubRoles.OWNER:
		case EntitySoccerLeagueRoles.OWNER:
		case EntitySoccerFacilityRoles.OWNER:
		case EntitySoccerRefereeOrgRoles.OWNER:
			return "Owner";
		case EntitySoccerClubRoles.MANAGER:
		case EntitySoccerLeagueRoles.MANAGER:
		case EntitySoccerFacilityRoles.MANAGER:
		case EntitySoccerRefereeOrgRoles.MANAGER:
			return "Admin";
		case EntitySoccerClubRoles.FOLLOWER:
		case EntitySoccerLeagueRoles.FOLLOWER:
		case EntitySoccerFacilityRoles.FOLLOWER:
		case EntitySoccerRefereeOrgRoles.FOLLOWER:
			return "Follower";

		case EntitySoccerRefereeOrgRoles.REFEREE:
			return "Referee";

		default:
			return role;
	}
};
