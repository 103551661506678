import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";

import { Transition } from "shared/Components";
import { useCreateFlowRouter, useLeague, useUpdateStepData } from "shared/hooks";
import { Headlines } from "shared/templates";
import type { GenderEnum, SetupEntityModel } from "shared/types";

const SetupGenderTemplate = lazy(() => import("shared/templates/SetupGender"));

const SetupGender = () => {
	const { setAllowGoNext, checkIfMounted, getCreateFlowData: getSetupLeagueData } = useCreateFlowRouter();
	const { step } = getSetupLeagueData();

	const { updateNewSetupLeague, setupLeague, getData: getNewSetupLeagueData } = useLeague();
	const { league, newSetupLeague } = getNewSetupLeagueData();

	const isMounted = useMemo(() => checkIfMounted("gender"), [checkIfMounted]);

	const [selectedGenders, setSelectedGenders] = useState<GenderEnum[]>([]);

	const onChangeData = useCallback((genders: GenderEnum[]) => {
		setSelectedGenders(genders);
	}, []);

	useEffect(() => {
		if (newSetupLeague?.genders?.length) {
			setSelectedGenders(newSetupLeague.genders);
		}
	}, [newSetupLeague]);

	const updateState = useCallback(() => {
		updateNewSetupLeague({ genders: selectedGenders });
		return true;
	}, [selectedGenders, updateNewSetupLeague]);

	const submitData = useCallback(async () => {
		if (selectedGenders && league?.id) {
			updateState();
			await setupLeague({ ...newSetupLeague, genders: selectedGenders, id: league.id } as SetupEntityModel & {
				id: string;
			});
			return true;
		}
		return false;
	}, [selectedGenders, updateState, setupLeague, newSetupLeague, league?.id]);

	useEffect(() => {
		setAllowGoNext(!!selectedGenders.length);
	}, [setAllowGoNext, selectedGenders.length]);

	useUpdateStepData({
		nextCb: submitData,
		backCb: updateState
	});

	return (
		<Transition isMounted={isMounted}>
			<div className="create-process-padding">
				<div data-animate>
					<Headlines
						step={step}
						question="What genders are in your league?"
						description="Select all genders that are available in your league."
					/>
				</div>
			</div>
			<Suspense>
				<SetupGenderTemplate onChange={onChangeData} values={selectedGenders} />
			</Suspense>
		</Transition>
	);
};

export default React.memo(SetupGender);
