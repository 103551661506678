import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Link } from "@mui/material";

import clsx from "clsx";

import { DateTime } from "luxon";

import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import ClockIcon from "assets/icons/outlined/clock.svg?react";
import LocationIcon from "assets/icons/outlined/location.svg?react";

import { getPaths } from "core/getPaths";

import type { MatchDay } from "modules/Competition/View/Components/ScheduleGroupModal/editSchedule";

import { CardWrapper, Chip } from "shared/Components";
import { HeaderLine } from "shared/Components/Table/style";
import { useOnScreen } from "shared/hooks";
import type { AdvancingTeamRuleModel, FullTeamModel, MatchMetaModel, MatchModel } from "shared/types";
import { ImageSizesEnum, Img, SkeletonBase, Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { getDateTime } from "utils/getDateTime";
import { getMatchGroupRoundName, getTeamNameByAdvancedRule } from "utils/getMatchInfo";
import { getMatchTime } from "utils/matchTime";

import { ChipStyles } from "../../Components/Chip";

const { match: matchRoutes } = getPaths();

interface MatchesListingTemplateProps {
	matchListings: MatchModel[];
	loading?: boolean;
	loadingMore?: boolean;
	onLoadMore?: () => void;
	isTournamentMatches?: boolean;
}

const MatchesListingTemplate: FC<MatchesListingTemplateProps> = ({
	matchListings,
	loading,
	loadingMore,
	onLoadMore,
	isTournamentMatches
}) => {
	const { t } = useTranslation();

	const [matches, setMatches] = useState<MatchDay[]>([]);

	const lastItemRef = useRef<HTMLHRElement | null>(null);
	const onScreen = useOnScreen(lastItemRef);

	useEffect(() => {
		onScreen && !loading && !loadingMore && onLoadMore && onLoadMore();
	}, [onScreen, loading, loadingMore, onLoadMore]);

	useEffect(() => {
		let newDateList: MatchDay[] = [];

		if (matchListings.length) {
			const matchListingsWithLocalDates = matchListings.map(x => {
				let date = x.date;
				if (x.startsAt) {
					const dateTime = getDateTime(x.startsAt);
					if (!dateTime.invalidReason) {
						date = dateTime.toISODate()!;
					}
				}
				return {
					...x,
					date
				};
			});

			const dates = [...new Set(matchListingsWithLocalDates.map(item => item.date))];

			newDateList = dates.map(date => {
				const matches = matchListingsWithLocalDates.filter(item => item.date === date) || [];
				return { date, matches } as MatchDay;
			});
		}

		setMatches(newDateList);
	}, [matchListings]);

	const renderTeam = useCallback(
		({
			isHome,
			team,
			advancingRule,
			matchMeta,
			matchGroupName
		}: {
			isHome: boolean;
			team?: FullTeamModel | null;
			advancingRule?: AdvancingTeamRuleModel | null;
			matchMeta?: MatchMetaModel | null;
			matchGroupName?: string;
		}) => {
			if (team) {
				return (
					<div className={clsx("w-1/2 flex flex-col", isHome && "items-end")}>
						<Text variants={TextVariantsEnum.BodyMedium}>{team?.name}</Text>
						<div className="flex items-center gap-2 mt-1">
							<Img size={ImageSizesEnum.Small24} src={team?.club?.logo} alt={team?.club?.name} circle />
							<Text variants={TextVariantsEnum.Caption3} className="text-hint">
								{team?.club?.name}
							</Text>
						</div>
					</div>
				);
			}

			if (advancingRule) {
				const teamName = getTeamNameByAdvancedRule({
					isHome,
					advancingRule,
					matchMeta,
					matchGroupName,
					t
				});

				return (
					<div className={clsx("w-1/2 flex flex-col", isHome && "items-end")}>
						<Text variants={TextVariantsEnum.BodyMedium}>{teamName}</Text>
						<div className="flex items-center gap-2 mt-1">
							<Img size={ImageSizesEnum.Small24} src={null} alt={"?"} circle />
						</div>
					</div>
				);
			}

			return null;
		},
		[t]
	);

	const renderMatch = useCallback(
		(match: MatchModel, addBorder: boolean) => {
			const facilityName = match?.field?.facility?.name || match?.facility?.name || "";
			const fieldName = match?.field?.name;

			return (
				<Link
					key={match.id}
					to={`${matchRoutes.match.getPath()}/${match.id}`}
					component={RouterLink}
					className={clsx("no-underline p-4", addBorder && "border-t border-gray-100")}
				>
					{isTournamentMatches && (
						<div className="flex items-center justify-between mb-4">
							<div className="w-4/12 flex">
								<Chip text={getMatchGroupRoundName(match, t)} chipStyle={ChipStyles.LIGHT_FILL} />
								{match?.ord && <Chip text={`#${match.ord}`} chipStyle={ChipStyles.LIGHT_FILL} className="ml-2" />}
							</div>
							<div className="w-4/12 flex justify-center">
								{match?.group?.competition?.name && (
									<Chip text={match.group.competition.name} chipStyle={ChipStyles.LIGHT_FILL} />
								)}
							</div>
							<div className="w-4/12 flex justify-end">
								{match?.division?.name && <Chip text={match.division.name} chipStyle={ChipStyles.LIGHT_FILL} />}
							</div>
						</div>
					)}
					{(match?.homeTeam || match?.awayTeam || match?.homeTeamAdvancingRule || match?.awayTeamAdvancingRule) && (
						<div className="flex align-center justify-center gap-7">
							{renderTeam({
								team: match?.homeTeam,
								isHome: true,
								advancingRule: match?.homeTeamAdvancingRule,
								matchMeta: match?.meta,
								matchGroupName: match?.group?.name
							})}
							<Text variants={TextVariantsEnum.BodyMedium} className="shrink-0">
								{match.result &&
								((match?.homeTeam && match?.homeTeam?.score !== null) ||
									(match?.awayTeam && match?.awayTeam?.score !== null))
									? `${match?.homeTeam?.score || "0"} - ${match?.awayTeam?.score || "0"}`
									: "-"}
							</Text>
							{renderTeam({
								team: match?.awayTeam,
								isHome: false,
								advancingRule: match?.awayTeamAdvancingRule,
								matchMeta: match?.meta,
								matchGroupName: match?.group?.name
							})}
						</div>
					)}
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-1 w-1/2">
							<ClockIcon className="w-5 h-5 svg-paths:stroke-gray-400" />
							<Text variants={TextVariantsEnum.BodyMedium} className="text-hint">
								{getMatchTime(match?.playedAt || null, match?.endsAt || null)}
							</Text>
						</div>
						<div className="flex items-center gap-1 w-1/2">
							<Text variants={TextVariantsEnum.BodyMedium} className="text-hint truncate w-3/4 ml-auto text-right">
								{facilityName || fieldName
									? `${facilityName ? facilityName : ""} ${fieldName ? `/ ${fieldName}` : ""}`
									: "-"}
							</Text>
							<LocationIcon className="w-5 h-5 svg-paths:stroke-gray-400" />
						</div>
					</div>
				</Link>
			);
		},
		[renderTeam, isTournamentMatches, t]
	);

	const renderMatchDay = useCallback(
		(matchDay: MatchDay) => (
			<div className="flex flex-col" key={matchDay?.date ? matchDay.date : "unscheduled matches date"}>
				<div className="px-4">
					<div className="rounded-lg py-3 px-4 bg-gray-50 border border-gray-100">
						<Text variants={TextVariantsEnum.Caption3}>
							{matchDay.date
								? DateTime.fromISO(matchDay.date)?.toFormat("cccc, d LLLL")
								: t("match:unscheduled_matches")}
						</Text>
					</div>
				</div>
				{matchDay.matches.map((match, i) => renderMatch(match, i > 0))}
			</div>
		),
		[renderMatch, t]
	);

	const renderTeamSkeleton = useCallback(
		(home?: boolean) => (
			<div className={clsx("w-1/2 flex flex-col", home && "items-end")}>
				<SkeletonBase width={130} height={24} fullScale />
				<div className="flex items-center gap-2 mt-1">
					<SkeletonBase width={24} height={24} variant="circular" />
					<SkeletonBase width={60} height={16} fullScale />
				</div>
			</div>
		),
		[]
	);

	const matchSkeleton = useMemo(
		() => (
			<div className="flex flex-col mb-4">
				<div className="rounded-lg py-3 px-4 bg-gray-50 border border-gray-100 mx-4">
					<SkeletonBase width={150} height={16} fullScale />
				</div>
				<div className="pt-4 border-t border-gray-100 mt-4">
					<div className="flex align-center justify-center gap-7">
						{renderTeamSkeleton(true)}
						<div className="self-start flex items-center gap-1">
							<SkeletonBase width={40} height={16} fullScale />
						</div>
						{renderTeamSkeleton(false)}
					</div>
					<div className="flex items-center justify-between px-4">
						<div className="flex items-center gap-1">
							<SkeletonBase width={20} height={20} fullScale />
							<SkeletonBase width={140} height={24} fullScale />
						</div>
						<div className="flex items-center gap-1">
							<SkeletonBase width={180} height={24} fullScale />
							<SkeletonBase width={20} height={20} fullScale />
						</div>
					</div>
				</div>
			</div>
		),
		[renderTeamSkeleton]
	);

	return (
		<CardWrapper noPadding className="p-0 mb-6">
			<HeaderLine className="flex items-center justify-between p-4">
				{loading ? (
					<SkeletonBase width={120} height={33} fullScale />
				) : (
					<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.H6}>
						{t("matches")}
					</Text>
				)}
				{loading && <SkeletonBase width={150} height={40} fullScale />}
			</HeaderLine>
			<div className="py-4">
				<div className="flex items-center justify-between px-4 mb-4">
					{loading ? (
						<>
							<SkeletonBase width={60} height={19} fullScale />
							<div className="flex items-center gap-6">
								<SkeletonBase width={60} height={19} fullScale />
								<SkeletonBase width={40} height={19} fullScale />
								<SkeletonBase width={60} height={19} fullScale />
							</div>
							<SkeletonBase width={60} height={19} fullScale />
						</>
					) : (
						<div className="flex justify-between items-center w-full relative">
							<Text variants={TextVariantsEnum.H7} className="styles_hint">
								{t("date")}
							</Text>
							<div className="absolute top-0 left-0 w-full flex items-center justify-center gap-6">
								<Text variants={TextVariantsEnum.H7} className="styles_hint">
									{t("match:home_team")}
								</Text>
								<Text variants={TextVariantsEnum.H7} className="styles_hint">
									{t("match:score")}
								</Text>
								<Text variants={TextVariantsEnum.H7} className="styles_hint">
									{t("match:away_team")}
								</Text>
							</div>
							<Text variants={TextVariantsEnum.H7} className="styles_hint">
								{t("location")}
							</Text>
						</div>
					)}
				</div>
				<div className="flex flex-col">
					{matches.map(matchDay => renderMatchDay(matchDay))}
					{loadingMore && (
						<>
							{matchSkeleton}
							{matchSkeleton}
						</>
					)}
					<hr ref={lastItemRef} className="opacity-0 h-0 border-0" />
				</div>
			</div>
		</CardWrapper>
	);
};

export default MatchesListingTemplate;
