import type { FC, ReactNode } from "react";
import React, { memo, useCallback, useMemo } from "react";

import clsx from "clsx";

import PlayersIcon from "assets/icons/illustrations/players.svg?react";

import { CardWrapper, EmptyCard } from "shared/Components";
import { SkeletonBase, Text } from "shared/uikit";

import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { PaginationBlock, SimpleTable } from "./Components";

import type { SimpleTableProps } from "./Components/SimpleTable";
import { HeaderLine } from "./style";

export interface EmptyTableConfigModel {
	icon?: ReactNode;
	text?: string;
	btnText?: string;
	onBtnClick?: () => void;
}

export interface TableProps
	extends Omit<SimpleTableProps, "headerHeight" | "rowHeight" | "pageSize" | "checkableColumnWidth"> {
	headerHeight?: number;
	rowHeight?: number;
	checkableColumnWidth?: number;

	label?: string | ReactNode;
	filterBlock?: ReactNode;
	keepTableHead?: boolean;
	paginated?: boolean; // TODO: deprecated?!
	totalDataCount?: number;
	page?: number;
	pageSize?: number;
	onChangePage?: (newPage: number) => void;
	emptyStateConfig?: EmptyTableConfigModel;

	manageBlock?: ReactNode;
	hideBorderWrapper?: boolean;

	footer?: ReactNode;
}

const TableComponent: FC<TableProps> = memo(
	({
		data,

		width,
		height,

		label,
		filterBlock,
		keepTableHead,
		paginated,
		totalDataCount,
		page,
		pageSize = 10,
		onChangePage,
		loading,
		loadingMore,

		headerHeight = 53,
		rowHeight = 55,

		checkableColumnWidth = 46,

		emptyStateConfig,

		manageBlock,
		noColumnsBottomBorder,

		hideBorderWrapper = false,

		onLoadMore,

		footer,
		...rest
	}) => {
		const customWrapperStyle = useMemo(
			() => (width && height ? { width, height } : width ? { width } : height ? { height } : {}),
			[width, height]
		);

		const showPagination = useMemo(
			() => paginated && !!totalDataCount && !!page && !!pageSize,
			[paginated, totalDataCount, page, pageSize]
		);

		const handleLoadMore = useCallback(() => {
			!loading && !loadingMore && !!data?.length && onLoadMore && onLoadMore();
		}, [onLoadMore, loading, loadingMore, data?.length]);

		const emptyState = useMemo(
			() => (
				<EmptyCard
					icon={emptyStateConfig?.icon || <PlayersIcon />}
					text={emptyStateConfig?.text || "You don’t have any data here yet."}
					buttonText={emptyStateConfig?.btnText}
					onBtnClick={emptyStateConfig?.onBtnClick}
					className={clsx((hideBorderWrapper || keepTableHead) && "border-0")}
				/>
			),
			[emptyStateConfig, hideBorderWrapper, keepTableHead]
		);

		if (!data?.length && !loading && !keepTableHead) {
			return emptyState;
		}

		return (
			<CardWrapper noPadding className={clsx("p-0 overflow-hidden", hideBorderWrapper && "border-0")}>
				{(label || filterBlock) && (
					<HeaderLine className="flex items-center justify-between p-4">
						{label && (
							<>
								{loading && !keepTableHead ? (
									<SkeletonBase width={120} height={33} fullScale />
								) : (
									<>
										{typeof label === "string" ? (
											<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.H6}>
												{label}
											</Text>
										) : (
											label
										)}
									</>
								)}
							</>
						)}
						{filterBlock && loading && !keepTableHead ? (
							<SkeletonBase width={150} height={40} fullScale />
						) : (
							filterBlock
						)}
					</HeaderLine>
				)}
				{!!manageBlock && <HeaderLine className="py-2">{manageBlock}</HeaderLine>}
				{!data.length && !loading ? (
					emptyState
				) : (
					<>
						<div className="w-full overflow-hidden" style={customWrapperStyle}>
							<SimpleTable
								data={data}
								width={width}
								height={height}
								headerHeight={headerHeight}
								rowHeight={rowHeight}
								pageSize={pageSize}
								loading={loading}
								loadingMore={loadingMore}
								checkableColumnWidth={checkableColumnWidth}
								noColumnsBottomBorder={noColumnsBottomBorder}
								onLoadMore={handleLoadMore}
								{...rest}
							/>
						</div>
						{showPagination && (
							<div className="pt-2 pb-6">
								<PaginationBlock
									loading={loading}
									count={totalDataCount!}
									page={page!}
									pageSize={pageSize!}
									onChangePage={onChangePage}
								/>
							</div>
						)}
						{footer}
					</>
				)}
			</CardWrapper>
		);
	}
);

export default TableComponent;
