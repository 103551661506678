import type { ReactNode } from "react";
import React, { memo, useCallback } from "react";

import clsx from "clsx";

import { Button, Text } from "shared/uikit";
import { ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { CardWrapper } from "../index";

export interface EmptyCardProps {
	icon?: ReactNode;
	text?: string;
	buttonText?: string;
	className?: string;
	onBtnClick?: () => void;
}

const EmptyCard: React.FC<EmptyCardProps> = memo(({ icon, text, buttonText, onBtnClick, className }) => {
	const handleClick = useCallback(() => {
		onBtnClick && onBtnClick();
	}, [onBtnClick]);

	return (
		<CardWrapper noPadding className={clsx("pt-[88px] pb-[133px]", className)}>
			<div className="mx-auto flex flex-col items-center max-w-[200px]">
				{icon && <div className="mb-2">{icon}</div>}
				<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.Caption3} className="text-center">
					{text || "Oops. Looks like we have nothing to show here."}
				</Text>
				{buttonText && (
					<div className="mt-4">
						<Button variant={ButtonVariantsEnum.FILLED} onClick={handleClick}>
							{buttonText}
						</Button>
					</div>
				)}
			</div>
		</CardWrapper>
	);
});

export default EmptyCard;
