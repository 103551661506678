import type { FC } from "react";
import React, { useCallback, useMemo } from "react";

import clsx from "clsx";

import { Blurhash } from "react-blurhash";

import type { LogoModel, MediaModel } from "shared/types";
import { MediaModelSizeEnum } from "shared/types";
import { generateFileUrl } from "utils/serviceUtils/helpers";

export enum ImageSizesEnum {
	Small16 = "Small16",
	Small20 = "Small20",
	Small24 = "Small24",
	Small32 = "Small32",
	Small40 = "Small40",
	Small48 = "Small48",
	Small56 = "Small56",
	Small64 = "Small64",
	Small80 = "Small80",
	Small96 = "Small96",
	Small112 = "Small112",
	Small120 = "Small120",
	Small160 = "Small160",
	Small240 = "Small240",
	FULL = "FULL",
	SOURCE = "SOURCE"
}

export interface ImgProps {
	src?: LogoModel | MediaModel | null | string;
	alt?: string;
	containWithBg?: boolean;
	circle?: boolean;
	noBg?: boolean;
	size?: ImageSizesEnum;
	className?: string;
	placeholderClassName?: string;
	hidePlaceholder?: boolean;
	alwaysDisplayPlaceholder?: boolean;
	showNative?: boolean;
}

const srcIsMediaModel = (src: LogoModel | MediaModel | string): src is MediaModel => {
	return typeof src !== "string" && !!(src as MediaModel).name;
};

const Img: FC<ImgProps> = ({
	src,
	alt,
	circle,
	containWithBg,
	noBg,
	size,
	className,
	hidePlaceholder,
	placeholderClassName,
	alwaysDisplayPlaceholder,
	showNative
}) => {
	const [showImage, setShowImage] = React.useState(!!showNative);
	const [removeImage, setRemoveImage] = React.useState(false);

	const determineWidthHeight = useCallback(
		(returnNumber?: boolean) => {
			switch (size) {
				case ImageSizesEnum.FULL:
					return returnNumber
						? {
								width: "100%",
								height: "100%"
							}
						: "w-full h-full";

				case ImageSizesEnum.Small16:
					return returnNumber
						? {
								width: 16,
								height: 16
							}
						: "w-4 h-4";
				case ImageSizesEnum.Small20:
					return returnNumber
						? {
								width: 20,
								height: 20
							}
						: "w-5 h-5";
				case ImageSizesEnum.Small24:
					return returnNumber
						? {
								width: 24,
								height: 24
							}
						: "w-6 h-6";
				case ImageSizesEnum.Small32:
					return returnNumber
						? {
								width: 32,
								height: 32
							}
						: "w-8 h-8";
				case ImageSizesEnum.Small40:
					return returnNumber
						? {
								width: 40,
								height: 40
							}
						: "w-10 h-10";
				case ImageSizesEnum.Small48:
					return returnNumber
						? {
								width: 48,
								height: 48
							}
						: "w-12 h-12";
				case ImageSizesEnum.Small56:
					return returnNumber
						? {
								width: 56,
								height: 56
							}
						: "w-14 h-14";
				case ImageSizesEnum.Small64:
					return returnNumber
						? {
								width: 64,
								height: 64
							}
						: "w-16 h-16";
				case ImageSizesEnum.Small80:
					return returnNumber
						? {
								width: 80,
								height: 80
							}
						: "w-[80px] h-[80px]";
				case ImageSizesEnum.Small96:
					return returnNumber
						? {
								width: 96,
								height: 96
							}
						: "w-[6rem] h-[6rem]";
				case ImageSizesEnum.Small112:
					return returnNumber
						? {
								width: 112,
								height: 112
							}
						: "w-[7rem] h-[7rem]";
				case ImageSizesEnum.Small120:
					return returnNumber
						? {
								width: 120,
								height: 120
							}
						: "w-[7.5rem] h-[7.5rem]";
				case ImageSizesEnum.Small160:
					return returnNumber
						? {
								width: 160,
								height: 160
							}
						: "w-[10rem] h-[10rem]";
				case ImageSizesEnum.Small240:
					return returnNumber
						? {
								width: 240,
								height: 240
							}
						: "w-[15rem] h-[15rem]";
				default:
					return returnNumber
						? {
								width: "100%",
								height: "100%"
							}
						: "w-auto h-auto";
			}
		},
		[size]
	);

	const widthHeightClasses = useMemo(() => determineWidthHeight(), [determineWidthHeight]) as string;

	const handleImageOnLoad = useCallback(() => setShowImage(true), []);

	const handleImageFailed = useCallback(() => setRemoveImage(true), []);

	const fs = useMemo(() => {
		switch (size) {
			case ImageSizesEnum.Small16:
				return "text-[5px]";
			case ImageSizesEnum.Small20:
				return "text-[6px]";
			case ImageSizesEnum.Small24:
				return "text-[7px]";
			case ImageSizesEnum.Small32:
				return "text-[8px]";
			case ImageSizesEnum.Small40:
				return "text-[12px]";
			case ImageSizesEnum.Small48:
				return "text-[14px]";
			case ImageSizesEnum.Small56:
				return "text-[16px]";
			case ImageSizesEnum.Small64:
			case ImageSizesEnum.Small80:
				return "text-[18px]";
			case ImageSizesEnum.Small96:
				return "text-[22px]";
			case ImageSizesEnum.Small112:
				return "text-[32px]";
			case ImageSizesEnum.Small120:
			case ImageSizesEnum.Small160:
			case ImageSizesEnum.Small240:
				return "text-[38px]";
			default:
				return "text-[24px]";
		}
	}, [size]);

	const initials = useMemo(
		() =>
			(alt || "")
				.replace(/[^a-zA-Z0-9? ]/g, "") // replace special characters
				.replace(/  +/g, " ") // combine multiple spaces into one
				.split(" ")
				.map((x, i) => (i > 3 ? null : x[0]))
				.filter(x => x)
				.join("")
				.toUpperCase(),
		[alt]
	);

	const imgSrc = useMemo(() => {
		if (src) {
			if (typeof src === "string") {
				return src;
			}

			if (srcIsMediaModel(src)) {
				return src.path;
			}

			if (typeof src.variants === "string") {
				return src.variants;
			}
			if (!Array.isArray(src.variants) && (src?.variants?.path || src?.variants?.url)) {
				return src?.variants?.path || src?.variants?.url;
			}
			if (Array.isArray(src.variants) && src.variants.length) {
				let imgSize: MediaModelSizeEnum;
				switch (size) {
					case ImageSizesEnum.Small16:
					case ImageSizesEnum.Small20:
					case ImageSizesEnum.Small24:
					case ImageSizesEnum.Small32:
					case ImageSizesEnum.Small40:
					case ImageSizesEnum.Small48:
						imgSize = MediaModelSizeEnum.small;
						break;
					case ImageSizesEnum.Small56:
					case ImageSizesEnum.Small64:
						imgSize = MediaModelSizeEnum.medium;
						break;
					case ImageSizesEnum.SOURCE:
						imgSize = MediaModelSizeEnum.source;
						break;
					default:
						imgSize = MediaModelSizeEnum.large;
				}
				const properMediaModel =
					src.variants.find(x => x.name === imgSize) ||
					src.variants.find(x => x.name === MediaModelSizeEnum.source) ||
					src.variants[0];

				// NOTE: Dmitriy Golovchenko: I have added "!properMediaModel?.url" validation for gifs. Let me know if it breaks anything
				return properMediaModel?.name !== MediaModelSizeEnum.external && !properMediaModel?.url
					? generateFileUrl(properMediaModel!)
					: properMediaModel?.url;
			}
		}
		return undefined;
	}, [src, size]);

	const blurhash = useMemo((): { blurhash: string; width: number; height: number } | undefined => {
		if (Array.isArray((src as LogoModel)?.variants)) {
			const blurhashObj = ((src as LogoModel)!.variants as MediaModel[])!.find(
				s => s.name === MediaModelSizeEnum.blurhash && !!s.blurhash
			);
			if (blurhashObj?.blurhash) {
				return {
					blurhash: blurhashObj.blurhash,
					...(determineWidthHeight(true) as {
						width: number;
						height: number;
					})
				};
			}
		}
		return undefined;
	}, [src, determineWidthHeight]);

	const _sharedClasses = useMemo(
		() => clsx("transition-all ease-in-out duration-300 overflow-hidden", widthHeightClasses, circle && "rounded-full"),
		[circle, widthHeightClasses]
	);

	return (
		<div className={clsx("shrink-0 overflow-hidden relative", widthHeightClasses, className)}>
			<div
				className={clsx(
					"flex items-center justify-center",
					_sharedClasses,
					imgSrc && "absolute z-10",
					showImage && !alwaysDisplayPlaceholder ? "opacity-0" : "opacity-100",
					noBg ? "bg-transparent" : containWithBg ? "bg-gray-200" : "bg-primary-600",
					hidePlaceholder && "hidden",
					placeholderClassName
				)}
			>
				{!!blurhash ? (
					<Blurhash width={blurhash.width} height={blurhash.height} hash={blurhash.blurhash} />
				) : !containWithBg ? (
					<p className={clsx("font-semibold text-white", fs)}>{initials}</p>
				) : null}
			</div>
			{!removeImage && imgSrc && (
				<img
					key={imgSrc}
					onLoad={handleImageOnLoad}
					onError={handleImageFailed}
					src={imgSrc}
					alt={alt || ""}
					className={clsx(
						"bg-white",
						_sharedClasses,
						showImage ? "blur-none" : "opacity-0",
						containWithBg ? "object-contain" : "object-cover"
					)}
					loading="lazy"
				/>
			)}
		</div>
	);
};

export default Img;
