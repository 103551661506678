import React, { memo } from "react";

import { StatCard } from "shared/Components";
import type { StatEntityEnum, StatItemModel } from "shared/types";
import { StatTypeEnum } from "shared/types";
import { convertToStat } from "utils/statsConverted";

interface StatisticBlockProps {
	statEntity: StatEntityEnum;
	data: StatItemModel[];
	loadingStats?: boolean;
	detailLink: string;
	teamPageUrl: string;
	profilePageUrl: string;
}

const StatisticBlock: React.FC<StatisticBlockProps> = memo(
	({ statEntity, data, loadingStats, detailLink, ...urls }) => (
		<div className="grid grid-cols-3 gap-4">
			<StatCard
				stat={StatTypeEnum.GOALS_SCORED}
				entity={statEntity}
				loading={loadingStats}
				data={data.map(x => ({ ...convertToStat(x, statEntity, x.stats?.goalsScored) }))}
				link={`${detailLink}/${StatTypeEnum.GOALS_SCORED}`}
				{...urls}
			/>
			<StatCard
				stat={StatTypeEnum.GOALS_PER_MATCH}
				entity={statEntity}
				loading={loadingStats}
				data={data.map(x => ({ ...convertToStat(x, statEntity, x.stats?.goalsPerMatch) }))}
				link={`${detailLink}/${StatTypeEnum.GOALS_PER_MATCH}`}
				{...urls}
			/>
			<StatCard
				stat={StatTypeEnum.STARS_RECEIVED}
				entity={statEntity}
				loading={loadingStats}
				data={data.map(x => ({ ...convertToStat(x, statEntity, x.stats?.starsReceived) }))}
				link={`${detailLink}/${StatTypeEnum.STARS_RECEIVED}`}
				{...urls}
			/>
			<StatCard
				stat={StatTypeEnum.MATCHES_PLAYED}
				entity={statEntity}
				loading={loadingStats}
				data={data.map(x => ({ ...convertToStat(x, statEntity, x.stats?.matchesPlayed) }))}
				link={`${detailLink}/${StatTypeEnum.MATCHES_PLAYED}`}
				{...urls}
			/>
			<StatCard
				stat={StatTypeEnum.RED_CARDS_RECEIVED}
				entity={statEntity}
				loading={loadingStats}
				data={data.map(x => ({ ...convertToStat(x, statEntity, x.stats?.redCardsReceived) }))}
				link={`${detailLink}/${StatTypeEnum.RED_CARDS_RECEIVED}`}
				{...urls}
			/>
			<StatCard
				stat={StatTypeEnum.YELLOW_CARDS_RECEIVED}
				entity={statEntity}
				loading={loadingStats}
				data={data.map(x => ({ ...convertToStat(x, statEntity, x.stats?.yellowCardsReceived) }))}
				link={`${detailLink}/${StatTypeEnum.YELLOW_CARDS_RECEIVED}`}
				{...urls}
			/>
		</div>
	)
);

export default StatisticBlock;
