export const formatMoney = (
	amount: number,
	customOptions: {
		minimumFractionDigits?: number;
		maximumFractionDigits?: number;
		currency?: string;
		style?: "decimal" | "currency" | "percent" | "unit";
	} = {
		minimumFractionDigits: 2
	}
) =>
	new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		...customOptions
	}).format(amount / 100);
