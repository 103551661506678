import { Suspense, lazy } from "react";

import config from "config/appConfig";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));
const ProfilePageLayout = lazy(() => import("modules/Member/View/layouts/ProfilePageLayout"));

const HomePage = lazy(() => import("./Pages/Home"));
const FeedPage = lazy(() => import("./Pages/Feed"));
const MyOrganizationsPage = lazy(() => import("./Pages/MyOrganizations"));
const MyKidsPage = lazy(() => import("./Pages/MyKids"));
const MyFollowingPage = lazy(() => import("./Pages/MyFollowing"));
const StorePage = lazy(() => import("./Pages/Store"));
const FeedPostPage = lazy(() => import("./Pages/Feed/Post"));

const ProfileCareerPage = lazy(() => import("./Pages/Profile/Career"));
const ProfileMatchesPage = lazy(() => import("./Pages/Profile/Matches"));
const ProfileOverviewPage = lazy(() => import("./Pages/Profile/Overview"));
const ProfilePaymentsPage = lazy(() => import("./Pages/Profile/Payments"));
const ProfileStandingsPage = lazy(() => import("./Pages/Profile/Standings"));
const ProfileStatsPage = lazy(() => import("./Pages/Profile/Stats"));
const ProfileSocialPage = lazy(() => import("./Pages/Profile/Social"));

const UnsubscribePage = lazy(() => import("./Pages/Unsubscribe"));

const SearchPage = lazy(() => import("./Pages/Search"));

import { routes } from "./constants";

const { CORE_URLS, GLOBAL_CONSTANTS } = config;

class MemberApp implements IApp {
	getRoutes = () => [
		<Route key="member-routes">
			<Route element={<SecureRoute roles={[]} />}>
				{this.getSecuredRoutes()}
				{this.getSearchRoutes()}
			</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key="member-routes"
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={routes.home.getElementKey()}
				path={routes.home.getPath()}
				element={
					<Suspense>
						<FeedPage />
					</Suspense>
				}
			/>
			<Route
				key={`${routes.profile.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<ProfilePageLayout homePageUrl={CORE_URLS.HOME} />
					</Suspense>
				}
			>
				<Route
					key={`${routes.profile.getElementKey()}/id`}
					path={`${routes.profile.getPath()}/:id?`}
					element={
						<Suspense>
							<ProfileOverviewPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.profile.getElementKey()}/id/matches`}
					path={`${routes.profile.getPath()}/:id/matches`}
					element={
						<Suspense>
							<ProfileMatchesPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.profile.getElementKey()}/id/standings`}
					path={`${routes.profile.getPath()}/:id/standings`}
					element={
						<Suspense>
							<ProfileStandingsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.profile.getElementKey()}/id/stats`}
					path={`${routes.profile.getPath()}/:id/stats`}
					element={
						<Suspense>
							<ProfileStatsPage />
						</Suspense>
					}
				/>
				{GLOBAL_CONSTANTS.ENABLE_PAYMENT_FUNCTIONALITY && (
					<Route
						key={`${routes.profile.getElementKey()}/id/payments`}
						path={`${routes.profile.getPath()}/:id/payments`}
						element={
							<Suspense>
								<ProfilePaymentsPage />
							</Suspense>
						}
					/>
				)}
				<Route
					key={`${routes.profile.getElementKey()}/id/career`}
					path={`${routes.profile.getPath()}/:id/career`}
					element={
						<Suspense>
							<ProfileCareerPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.profile.getElementKey()}/id/social`}
					path={`${routes.profile.getPath()}/:id/social`}
					element={
						<Suspense>
							<ProfileSocialPage />
						</Suspense>
					}
				/>
			</Route>
			<Route
				key={routes.myOrganizations.getElementKey()}
				path={routes.myOrganizations.getPath()}
				element={
					<Suspense>
						<MyOrganizationsPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.myKids.getElementKey()}
				path={routes.myKids.getPath()}
				element={
					<Suspense>
						<MyKidsPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.email.unsubscribe.getElementKey()}
				path={routes.email.unsubscribe.getPath()}
				element={
					<Suspense>
						<UnsubscribePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.following.getElementKey()}
				path={routes.following.getPath()}
				element={
					<Suspense>
						<MyFollowingPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.feed.getElementKey()}
				path={routes.feed.getPath()}
				element={
					<Suspense>
						<FeedPage />
					</Suspense>
				}
			/>
			<Route
				key={`${routes.feed.getElementKey()}/id`}
				path={`${routes.feed.getPath()}/:id`}
				element={
					<Suspense>
						<FeedPostPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.store.getElementKey()}
				path={routes.store.getPath()}
				element={
					<Suspense>
						<StorePage />
					</Suspense>
				}
			/>
		</Route>,
		<Route
			key="home-page-route"
			element={
				<Suspense>
					<LoggedLayout noMainPadding />
				</Suspense>
			}
		>
			<Route
				key={routes.events.getElementKey()}
				path={routes.events.getPath()}
				element={
					<Suspense>
						<HomePage />
					</Suspense>
				}
			/>
		</Route>
	];

	getSearchRoutes = () => [
		<Route
			key="league-start-route"
			element={
				<Suspense>
					<LoggedLayout isSearchPage />
				</Suspense>
			}
		>
			<Route
				key={routes.search.getElementKey()}
				path={`${routes.search.getPath()}/:query`}
				element={
					<Suspense>
						<SearchPage />
					</Suspense>
				}
			/>
		</Route>
	];
}

export default MemberApp;
