import React, { Suspense, lazy } from "react";

import { Route } from "react-router-dom";

import type { IApp } from "shared/types";

const BasicLayout = lazy(() => import("shared/templates/Layouts/BasicLayout"));

const InvitationPage = lazy(() => import("./Pages/Invitation"));
const AcceptAsPage = lazy(() => import("./Pages/AcceptAs"));

import { routes } from "./constants";

class InvitationApp implements IApp {
	getRoutes = () => [
		<Route
			path={routes.invitation.getPath()}
			key="invitation-routes"
			element={
				<Suspense>
					<BasicLayout />
				</Suspense>
			}
		>
			{this.getPublicRoutes()}
		</Route>
	];

	getPublicRoutes = () => [
		<Route
			key={routes.invitation.getElementKey()}
			path={routes.invitation.getPath()}
			element={
				<Suspense>
					<InvitationPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.invitation.acceptAs.getElementKey()}
			path={routes.invitation.acceptAs.getPath()}
			element={
				<Suspense>
					<AcceptAsPage />
				</Suspense>
			}
		/>
	];
}

export default InvitationApp;
