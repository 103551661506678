import type { ReactNode } from "react";
import React, { memo, useCallback, useMemo } from "react";

import type { PopoverOrigin } from "@mui/material";
import { Popover } from "@mui/material";

import { getAnchorOriginPos, getTransformOriginPos } from "utils/serviceUtils/helpers";

import { paperStyle } from "./style";

import { MenuListPositionOpts } from "../types";

interface PopoverBoxProps {
	anchorEl: any;
	show?: boolean;
	onClose?: () => void;
	popoverPosition?: MenuListPositionOpts;
	contentPosition?: MenuListPositionOpts;
	customMinWidth?: number;
	customMaxWidth?: number;
	children?: ReactNode;
}

const PopoverBox: React.FC<PopoverBoxProps> = memo(
	({
		anchorEl,
		show = false,
		onClose,
		popoverPosition = MenuListPositionOpts.BOTTOM_LEFT,
		contentPosition = MenuListPositionOpts.BOTTOM_CENTER,
		customMinWidth = 200,
		customMaxWidth,
		children
	}) => {
		const handleClose = useCallback(() => {
			onClose && onClose();
		}, [onClose]);

		const anchorOriginPos: PopoverOrigin = useMemo(() => getAnchorOriginPos(contentPosition), [contentPosition]);

		const transformOriginPos: PopoverOrigin = useMemo(() => getTransformOriginPos(popoverPosition), [popoverPosition]);

		const customStyles = useMemo(() => {
			const custom = {
				minWidth: `${customMinWidth}px`,
				"& .MuiList-root": {
					padding: 0
				}
			};
			if (customMaxWidth) {
				custom["maxWidth"] = `${customMaxWidth}px`;
			}
			return custom;
		}, [customMinWidth, customMaxWidth]);

		return (
			<Popover
				open={show}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={anchorOriginPos}
				transformOrigin={transformOriginPos}
				PaperProps={{
					style: paperStyle,
					sx: customStyles
				}}
			>
				{children}
			</Popover>
		);
	}
);

export default PopoverBox;
