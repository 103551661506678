import type { FC } from "react";
import { Suspense, lazy, useCallback, useEffect, useMemo, useRef, useState } from "react";

const SwiperCarousel = lazy(() => import("shared/Components/SwiperCarousel"));

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Keyboard, Navigation, Pagination } from "swiper/modules";

import type { SwiperRef } from "swiper/react";
import { SwiperSlide } from "swiper/react";

import CheckboxIcon from "assets/icons/filled/checkbox.svg?react";

import LocationIcon from "assets/icons/filled/location.svg?react";
import ArrowRightIcon from "assets/icons/outlined/arrow-right.svg?react";
import HeightIcon from "assets/icons/outlined/height.svg?react";
import PreferredPositionIcon from "assets/icons/outlined/player.svg?react";
import PreferredFootIcon from "assets/icons/outlined/preferred-foot.svg?react";

import { CardWrapper } from "shared/Components";
import { useUser } from "shared/hooks";
import { Button, ProgressBar, Text } from "shared/uikit";
import { ButtonSizesEnum, ButtonVariantsEnum, TextVariantsEnum } from "shared/uikit/types";

import { SwiperCarouselWrapper } from "../Post/Content/style";

const AddHeightModal = lazy(() => import("./AddHeight"));
const AddPreferredFootModal = lazy(() => import("./AddPreferredFoot"));
const AddPreferredPositionModal = lazy(() => import("./AddPreferredPosition"));
const AddCountryModal = lazy(() => import("./AddCountry"));

interface CompleteProfileSliderProps {
	className?: string;
	boxed?: boolean;
}

const CompleteProfileSlider: FC<CompleteProfileSliderProps> = ({ className, boxed }) => {
	const { t } = useTranslation();

	const { getOverview, getUserInfo, getData: getUserData } = useUser();
	const { myProfile, overview } = getUserData();

	const [initiallyLoaded, setInitiallyLoaded] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const [completeProfileModals, setCompleteProfileModals] = useState({
		height: false,
		preferredFoot: false,
		preferredSoccerPosition: false,
		country: false
	});

	const slides = useMemo(
		() => [
			{
				title: t("player:what_is_your_height"),
				isDone: !!myProfile?.height,
				icon: <HeightIcon className="w-10 h-10" />,
				action: t("player:add_height"),
				onActionClicked: () => setCompleteProfileModals(ctx => ({ ...ctx, height: true })),
				successText: t("player:height_added")
			},
			{
				title: t("player:what_is_your_preferred_foot"),
				isDone: !!myProfile?.preferredFoot,
				icon: <PreferredFootIcon className="w-10 h-10" />,
				action: t("player:add_preferred_foot"),
				onActionClicked: () => setCompleteProfileModals(ctx => ({ ...ctx, preferredFoot: true })),
				successText: t("player:preferred_foot_added")
			},
			{
				title: t("player:what_is_your_preferred_position"),
				isDone: !!myProfile?.preferredSoccerPosition,
				icon: <PreferredPositionIcon className="w-10 h-10 svg-paths:stroke-primary-600 svg-paths:fill-primary-600" />,
				action: t("player:add_preferred_position"),
				onActionClicked: () => setCompleteProfileModals(ctx => ({ ...ctx, preferredSoccerPosition: true })),
				successText: t("player:preferred_position_added")
			},
			{
				title: t("what_is_your_country"),
				isDone: !!myProfile?.country,
				icon: <LocationIcon className="w-10 h-10 svg-paths:fill-primary-600" />,
				action: t("add_country"),
				onActionClicked: () => setCompleteProfileModals(ctx => ({ ...ctx, country: true })),
				successText: t("country_added")
			}
		],
		[myProfile, t]
	);

	useEffect(() => {
		if (
			myProfile &&
			(!myProfile.height || !myProfile.preferredFoot || !myProfile.preferredSoccerPosition || !myProfile.country)
		) {
			setInitiallyLoaded(true);
		}
	}, [myProfile]);

	const allSlidesDone = useMemo(() => slides.every(x => x.isDone), [slides]);

	const doneSlides = useMemo(() => slides.filter(x => x.isDone).length, [slides]);

	const handleCloseModal = useCallback(
		(created?: boolean) => {
			setCompleteProfileModals({ country: false, height: false, preferredFoot: false, preferredSoccerPosition: false });

			if (created) {
				boxed ? getUserInfo() : myProfile?.id && getOverview(myProfile.id);
				ref.current?.swiper.slideNext();
			}
		},
		[boxed, getOverview, getUserInfo, myProfile?.id]
	);

	const slidesContent = useMemo(
		() =>
			slides.map(slide => {
				const content = (
					<>
						{slide.isDone ? (
							<div className="flex items-center justify-center flex-col h-[168px]">
								<CheckboxIcon className="w-8 h-8 rounded-full svg-rects:fill-primary-500" />
								<Text variants={TextVariantsEnum.H7} className="mt-3">
									{slide.successText}
								</Text>
							</div>
						) : (
							<div className="min-h-[168px] flex flex-col justify-between items-start">
								<div>
									{slide.icon}
									<Text variants={TextVariantsEnum.H7} className="mt-4 mb-8">
										{slide.title}
									</Text>
								</div>
								<Button variant={ButtonVariantsEnum.OUTLINED} size={ButtonSizesEnum.XS} onClick={slide.onActionClicked}>
									{slide.action}
								</Button>
							</div>
						)}
					</>
				);

				return (
					<SwiperSlide key={`complete-profile-slide-${slide.title}`} className="border border-gray-100 rounded-xl p-4">
						{content}
					</SwiperSlide>
				);
			}),

		[slides]
	);

	const ref = useRef<SwiperRef>(null);

	return !overview?.player || (allSlidesDone && !initiallyLoaded) ? null : (
		<>
			<CardWrapper className={clsx("relative", boxed && "max-w-[365px]", className)}>
				<div className="flex items-center justify-between">
					<Text variants={TextVariantsEnum.H6}>{t("player:complete_profile")}</Text>
					<div className="flex items-center gap-1">
						<Button
							dontFillSvg
							size={ButtonSizesEnum.XS}
							variant={ButtonVariantsEnum.GHOST}
							onClick={() => ref.current?.swiper.slidePrev()}
							iconButton={{
								icon: (
									<ArrowRightIcon
										className={clsx("w-5 h-5 transform rotate-180", activeIndex === 0 && "svg-paths:stroke-gray-200")}
									/>
								),
								circular: true
							}}
						/>
						<Button
							dontFillSvg
							size={ButtonSizesEnum.XS}
							variant={ButtonVariantsEnum.GHOST}
							onClick={() => ref.current?.swiper.slideNext()}
							iconButton={{
								icon: (
									<ArrowRightIcon
										className={clsx("w-5 h-5", activeIndex === slides.length - 1 && "svg-paths:stroke-gray-200")}
									/>
								),
								circular: true
							}}
						/>
					</div>
				</div>

				<div className="flex items-center mt-1 mb-2">
					<Text variants={TextVariantsEnum.ButtonSmall} className="text-hint">
						{doneSlides}/{slides.length}
					</Text>
					<ProgressBar value={(doneSlides / slides.length) * 100} className="w-20 ml-2" variant="determinate" />
				</div>
				<SwiperCarouselWrapper className="w-full">
					<Suspense>
						<SwiperCarousel
							slidesPerView={boxed ? 1 : 2.8}
							spaceBetween={24}
							className="h-full"
							keyboard
							speed={1000}
							onSlideChange={({ activeIndex }) => setActiveIndex(activeIndex)}
							ref={ref}
							modules={[Keyboard, Navigation, Pagination, Keyboard]}
						>
							{slidesContent}
						</SwiperCarousel>
					</Suspense>
				</SwiperCarouselWrapper>
			</CardWrapper>
			{completeProfileModals.height && (
				<Suspense>
					<AddHeightModal onClose={handleCloseModal} />
				</Suspense>
			)}
			{completeProfileModals.preferredFoot && (
				<Suspense>
					<AddPreferredFootModal onClose={handleCloseModal} />
				</Suspense>
			)}
			{completeProfileModals.preferredSoccerPosition && (
				<Suspense>
					<AddPreferredPositionModal onClose={handleCloseModal} />
				</Suspense>
			)}
			{completeProfileModals.country && (
				<Suspense>
					<AddCountryModal onClose={handleCloseModal} />
				</Suspense>
			)}
		</>
	);
};

export default CompleteProfileSlider;
