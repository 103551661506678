import React, { Suspense, lazy, useCallback, useState } from "react";

import { DateTime } from "luxon";

import { pick } from "ramda";

import { useTranslation } from "react-i18next";

import CategoryIcon from "assets/icons/outlined/category.svg?react";
import CoedIcon from "assets/icons/outlined/coed.svg?react";
import FemaleIcon from "assets/icons/outlined/female.svg?react";
import LevelsIcon from "assets/icons/outlined/levels.svg?react";
import MaleIcon from "assets/icons/outlined/male.svg?react";
import { EditInfoCard, InfoBox } from "shared/Components";

const EditCategoryModal = lazy(() => import("shared/templates/EditCategoryModal"));
const EditGenderModal = lazy(() => import("shared/templates/EditGenderModal"));
const EditLevelModal = lazy(() => import("shared/templates/EditLevelModal"));

import type {
	SetupCategoryModel,
	SetupEntityModel,
	SetupLevelModel,
	SetupStructModel,
	StructAgeModel,
	TournamentStructAgeModel
} from "shared/types";
import { GenderEnum } from "shared/types";
import { Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { getYearDateByAgeValue } from "utils/getAge";
import { getTournamentSetting } from "utils/getTournamentSetting";
import { getGenderTitle } from "utils/serviceUtils/helpers";

interface StructurePageTemplateProps {
	struct?: SetupStructModel | null;
	loading: boolean;
	leagueView?: boolean;
	tournamentView?: boolean;
	onChangeStruct?: (data: SetupEntityModel) => void;
}

const StructurePageTemplate: React.FC<StructurePageTemplateProps> = ({
	struct,
	loading,
	leagueView = false,
	tournamentView = false,
	onChangeStruct
}) => {
	const { t } = useTranslation();

	const [editModal, setEditModal] = useState<{
		type: "gender" | "category" | "level";
		onClose: (data?: (SetupLevelModel | GenderEnum | SetupCategoryModel)[]) => void;
	}>();

	const editEntity = useCallback(
		(type: "gender" | "category" | "level") => {
			setEditModal({
				type,
				onClose: (data?: (SetupLevelModel | GenderEnum | SetupCategoryModel)[]) => {
					setEditModal(undefined);
					if (data && onChangeStruct) {
						onChangeStruct({
							levels:
								type === "level"
									? (data as SetupLevelModel[])
									: (struct?.levels || []).map(x => ({
											id: x.id,
											name: x.name,
											description: (x as SetupLevelModel)?.description
										})),
							categories:
								type === "category"
									? (data as SetupCategoryModel[])
									: (struct?.ages || []).map(x => {
											let date = DateTime.now();
											if (x.day) {
												date = date.set({ day: x.day });
											}
											if (x.month) {
												date = date.set({ month: x.month });
											}
											if (x.value) {
												date = date.plus({ year: -x.value + (x?.yearOffset || 0) });
											}

											if (tournamentView) {
												return {
													...pick(["id", "name"], x),
													matchFormat: getTournamentSetting(
														"playersPerMatch",
														11,
														(x as TournamentStructAgeModel)?.settings
													),
													duration: getTournamentSetting(
														"matchDuration",
														90,
														(x as TournamentStructAgeModel)?.settings
													),
													includeHalftime: getTournamentSetting(
														"halfTime",
														false,
														(x as TournamentStructAgeModel)?.settings
													),
													matchBuffer: getTournamentSetting(
														"matchBuffer",
														40,
														(x as TournamentStructAgeModel)?.settings
													),
													refereePerGame: getTournamentSetting(
														"refereesPerMatch",
														1,
														(x as TournamentStructAgeModel)?.settings
													),
													behavior: x.type,
													date: date.toISO()!
												};
											}

											return {
												...pick(["id", "name", "value"], x),
												behavior: x.type,
												date: date.toISO()!
											};
										}),
							genders: type === "gender" ? (data as GenderEnum[]) : (struct?.genders || []).map(x => x.value)
						});
					}
				}
			});
		},
		[onChangeStruct, struct, tournamentView]
	);

	const getAgeSubtitle = useCallback(
		(age: StructAgeModel) => (
			<div className="flex mt-1">
				<div className="flex mr-4">
					<Text style={TextStylesEnum.Inactive} variants={TextVariantsEnum.BodySmall} className="mr-1">
						{t("age")}
					</Text>
					<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.BodySmall}>
						{`${age.type?.slice(0, 1)}${age.value}`}
					</Text>
				</div>
				<div className="flex mr-4">
					<Text style={TextStylesEnum.Inactive} variants={TextVariantsEnum.BodySmall} className="mr-1">
						{t("player:birth_year")}
					</Text>
					<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.BodySmall}>
						{getYearDateByAgeValue(age?.value || 0)?.year + (age?.yearOffset || 0)}
					</Text>
				</div>
				<div className="flex mr-4">
					<Text style={TextStylesEnum.Inactive} variants={TextVariantsEnum.BodySmall} className="mr-1">
						{t("player:cutoff")}
					</Text>
					<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.BodySmall}>
						{DateTime.now()
							.set({ month: age.month || 0, day: age.day || 0 })
							.toFormat("LLLL, d")}
					</Text>
				</div>
			</div>
		),
		[t]
	);

	const getGenderIcon = useCallback((val: GenderEnum) => {
		switch (val) {
			case GenderEnum.MALE:
				return <MaleIcon className="w-5 h-5 svg-paths:fill-primary-600 svg-paths:stroke-primary-600" />;

			case GenderEnum.FEMALE:
				return <FemaleIcon className="w-5 h-5 svg-paths:fill-primary-600 svg-paths:stroke-primary-600" />;

			default:
				return <CoedIcon className="w-5 h-5 svg-paths:fill-primary-600 svg-paths:stroke-primary-600" />;
		}
	}, []);

	return (
		<>
			<div className="flex flex-col sm:flex-row gap-4">
				<div className="flex flex-col gap-4">
					<EditInfoCard
						title={`${t("age")} ${t("categories").toLowerCase()}`}
						className="p-4 w-full md:w-[37.5rem]"
						loading={loading}
						allowEdit={!!onChangeStruct}
						onEdit={() => editEntity("category")}
					>
						<div className="flex flex-col gap-5">
							{loading ? (
								<>
									<InfoBox title="" subtitle="" icon="" loading={loading} />
									<InfoBox title="" subtitle="" icon="" loading={loading} />
									<InfoBox title="" subtitle="" icon="" loading={loading} />
									<InfoBox title="" subtitle="" icon="" loading={loading} />
									<InfoBox title="" subtitle="" icon="" loading={loading} />
								</>
							) : (
								<>
									{(struct?.ages || []).map((item, index) => (
										<InfoBox
											key={index}
											title={item.name}
											subtitle={getAgeSubtitle(item)}
											icon={<CategoryIcon className="w-5 h-5 svg-paths:fill-primary-600" />}
										/>
									))}
								</>
							)}
						</div>
					</EditInfoCard>
					<EditInfoCard
						title={t("levels")}
						className="p-4 w-full md:w-[37.5rem]"
						loading={loading}
						allowEdit={!!onChangeStruct}
						onEdit={() => editEntity("level")}
					>
						<div className="flex flex-col gap-5">
							{loading ? (
								<>
									<InfoBox title="" subtitle="" icon="" loading={loading} />
									<InfoBox title="" subtitle="" icon="" loading={loading} />
									<InfoBox title="" subtitle="" icon="" loading={loading} />
								</>
							) : (
								<>
									{(struct?.levels || []).map((item, index) => (
										<InfoBox
											key={index}
											title={item.name}
											subtitle={`Level ${index + 1}`}
											icon={<LevelsIcon className="w-5 h-5 svg-rects:fill-primary-600" />}
										/>
									))}
								</>
							)}
						</div>
					</EditInfoCard>
				</div>
				<EditInfoCard
					title={t("genders")}
					className="p-4 flex-1"
					loading={loading}
					allowEdit={!!onChangeStruct}
					onEdit={() => editEntity("gender")}
				>
					<div className="flex flex-col gap-5">
						{loading ? (
							<>
								<InfoBox title="" subtitle="" icon="" loading={loading} />
								<InfoBox title="" subtitle="" icon="" loading={loading} />
							</>
						) : (
							<>
								{(struct?.genders || []).map((item, index) => (
									<InfoBox
										key={index}
										title={getGenderTitle(item.value, t)}
										subtitle={t("gender")}
										icon={getGenderIcon(item.value)}
									/>
								))}
							</>
						)}
					</div>
				</EditInfoCard>
			</div>
			{editModal?.type === "level" && (
				<Suspense>
					<EditLevelModal
						title={
							leagueView
								? `${t("league")} ${t("levels").toLowerCase()}`
								: tournamentView
									? t("tournament")
									: `${t("club")} ${t("levels").toLowerCase()}`
						}
						tournamentView={tournamentView}
						levels={struct?.levels || []}
						onClose={editModal.onClose}
					/>
				</Suspense>
			)}
			{editModal?.type === "gender" && (
				<Suspense>
					<EditGenderModal genders={struct?.genders || []} onClose={editModal.onClose} />
				</Suspense>
			)}
			{editModal?.type === "category" && (
				<Suspense>
					<EditCategoryModal ages={struct?.ages || []} tournamentView={tournamentView} onClose={editModal.onClose} />
				</Suspense>
			)}
		</>
	);
};

export default StructurePageTemplate;
