import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import type { UploadDataOutput } from "@aws-amplify/storage";

import clsx from "clsx";

import CloseIcon from "assets/icons/filled/close-circle.svg?react";
import ImgPenIcon from "assets/icons/outlined/img-pen.svg?react";

import { HiddenFileSelector } from "shared/Components";
import { useS3Uploader } from "shared/hooks";
import type { ImgProps, MenuDotsOption } from "shared/uikit";
import { Button, Img, Loader, MenuDots } from "shared/uikit";

import { ButtonSizesEnum, ButtonVariantsEnum, MenuListPositionOpts } from "shared/uikit/types";

import { WrapperBtn } from "./styles";

import type { MediaModel } from "../../types";

interface EditableLogoProps extends ImgProps {
	onChange: (newLogoSrc: string | null) => void;
}

const EditableLogo: FC<EditableLogoProps> = ({ src, circle, size, onChange, ...rest }) => {
	const { uploadFile, cancelUpload } = useS3Uploader();

	const [showMenu, setShowMenu] = useState(false);
	const [openFileSelect, setOpenFileSelect] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [logoSrc, setLogoSrc] = useState(src);
	const [uploader, setUploader] = useState<UploadDataOutput>();

	useEffect(() => {
		setLogoSrc(src);
	}, [src]);

	const toggleMenu = useCallback(() => {
		setShowMenu(!showMenu);
	}, [showMenu]);

	const rollbackUpload = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();

		setUploading(false);
		setLogoSrc(src);
		uploader && cancelUpload(uploader);
	};

	const options: MenuDotsOption[] = useMemo(() => {
		return !!logoSrc
			? [
					{
						name: "Change logo",
						onClick: () => {
							setOpenFileSelect(true);
						}
					},
					{
						name: "Remove logo",
						onClick: () => {
							setLogoSrc(src);
							onChange(null);
						}
					}
				]
			: [
					{
						name: "Upload logo",
						onClick: () => {
							setOpenFileSelect(true);
						}
					}
				];
	}, [logoSrc, onChange, src]);

	const handleSelectNewLogo = useCallback(
		<T extends File>(files: T[] | FileList | null) => {
			setOpenFileSelect(false);

			if (files?.length) {
				const logoFile = files[0];

				setUploading(true);

				const upload = uploadFile({
					file: logoFile!,
					// cancellable: true,
					handleComplete: (logoInfo: MediaModel) => {
						logoInfo?.path && onChange(logoInfo.path);
						setLogoSrc(window.URL.createObjectURL(logoFile!));
						setUploading(false);
					},
					handleError: () => {
						setLogoSrc("");
						setUploading(false);
					}
				});
				upload && setUploader(upload);
			}
		},
		[uploadFile, onChange]
	);

	return (
		<>
			<div className={clsx("relative cursor-pointer")}>
				<Img src={logoSrc} size={size} circle={circle} noBg={uploading} {...rest} />
				<WrapperBtn
					onClick={toggleMenu}
					className={clsx(
						"z-20",
						showMenu && "hover",
						uploading && "uploading",
						circle && "rounded-full",
						circle && !uploading && "overflow-hidden"
					)}
				>
					{uploading && (
						<div className="absolute top-0 right-0 cursor-pointer z-20" onClick={rollbackUpload}>
							<CloseIcon />
						</div>
					)}
					<div className={clsx("hover-el", uploading && "hover-el_show")}>
						<Loader className="w-[70px] h-[70px]" />
					</div>
					<div className={clsx("hover-el", !showMenu && !uploading && "hover-el_show")}>
						<ImgPenIcon />
					</div>
					<div className={clsx("hover-el", showMenu && !uploading && "hover-el_show")}>
						<MenuDots
							options={options}
							bordered
							externalOpen
							open={showMenu}
							onClose={toggleMenu}
							menuListPosition={MenuListPositionOpts.BOTTOM_CENTER}
							popoverPosition={MenuListPositionOpts.TOP_CENTER}
							customActionEl={
								<Button
									variant={ButtonVariantsEnum.OUTLINED}
									size={ButtonSizesEnum.LG}
									iconButton={{
										icon: <ImgPenIcon className="svg-paths:fill-primary-500" />
									}}
									whiteBg
									className="outline-[3px] outline-primary-300"
									aria-label="Edit logo"
								>
									<ImgPenIcon />
								</Button>
							}
						/>
					</div>
				</WrapperBtn>
				{openFileSelect && <HiddenFileSelector open onSelect={handleSelectNewLogo} disabled={!openFileSelect} />}
			</div>
		</>
	);
};

export default EditableLogo;
