import type { ReactNode } from "react";
import React, { memo } from "react";

import clsx from "clsx";

import { SkeletonBase, Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";

export interface CardWrapperProps {
	children?: ReactNode;
	className?: string;
	title?: string;
	loading?: boolean;
	titleWrapperClassName?: string;
	noPadding?: boolean;
	id?: string;
	onClick?: () => void;
}

const CardWrapper: React.FC<CardWrapperProps> = memo(
	({ children, className, title, loading, noPadding, titleWrapperClassName, id, onClick }) => (
		<div
			id={id}
			className={clsx(
				"w-full h-fit bg-white border border-gray-100 relative rounded-xl",
				!noPadding && "p-3",
				className
			)}
			onClick={onClick}
		>
			{title && (
				<div className={clsx(titleWrapperClassName || (loading ? "mb-3" : "mb-5"))}>
					{loading ? <SkeletonBase width={100} height={36} /> : <Text variants={TextVariantsEnum.H6}>{title}</Text>}
				</div>
			)}
			{children}
		</div>
	)
);

export default CardWrapper;
