import { styled } from "@mui/material";

import colors from "theme/colors";

export const DigitCircle = styled("div")`
	width: 32px;
	height: 32px;

	border: 1px solid ${colors.palette.colors.gray["400"]};
	border-radius: 50%;

	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: center;
`;
