import type { FC } from "react";
import { Suspense, lazy, useCallback, useMemo, useRef, useState } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import HeartIcon from "assets/icons/outlined/heart.svg?react";
import MessageIcon from "assets/icons/outlined/message.svg?react";
import ShareIcon from "assets/icons/outlined/share.svg?react";

import { getPaths } from "core/getPaths";
import { Repeatable } from "shared/Components";
import type { ShareContentMethods } from "shared/Components/ShareContentBlock";
import ShareContentBlock from "shared/Components/ShareContentBlock";
import { usePost, usePrevious, useShare } from "shared/hooks";
import type { PostReactionModel, PostReactionsInfoModel } from "shared/types";
import { AttachmentKind, EntityFollowListEnum } from "shared/types";
import { Button, DividerWithText, ImageSizesEnum, MenuDots, Text } from "shared/uikit";

import {
	ButtonSizesEnum,
	ButtonVariantsEnum,
	MenuDotsVariants,
	MenuListPositionOpts,
	TextVariantsEnum
} from "shared/uikit/types";

const PostReactionsModal = lazy(() => import("./ReactionModal"));

import colors from "theme/colors";
import { getBEEntityType } from "utils/getBEEntityType";
import { getReactionInfo } from "utils/getReactionInfo";
import { uuidv4 } from "utils/serviceUtils/helpers";

import Like from "./Like";
import ReactionIcon from "./ReactionModal/ReactionIcon";

const Comments = lazy(() => import("./Comments/Comments"));

interface FooterProps {
	postId: string;
	className?: string;
	reactionsInfo: PostReactionsInfoModel;
	commentCount?: number;
	creator: string;
}

const { member: memberRoutes } = getPaths();

const Footer: FC<FooterProps> = ({ postId, className, reactionsInfo, creator, commentCount = 0 }) => {
	const { t } = useTranslation();
	const { reactOnPost } = usePost();
	const { copyLink } = useShare();

	const [showPostComments, setShowPostComments] = useState(false);
	const [showPostReactionsModal, setPostReactionsModal] = useState(false);
	const [userReaction, setUserReaction] = useState<PostReactionModel | null>(reactionsInfo?.viewer);
	const [totalReactionsCount, setTotalReactionsCount] = useState(reactionsInfo?.count || 0);
	const [totalCommentsCount, setTotalCommentsCount] = useState(commentCount);

	const prevReaction: PostReactionModel | null | undefined = usePrevious(userReaction);

	const userReactionInfo = useMemo(() => getReactionInfo(userReaction?.type), [userReaction?.type]);

	const shareRef = useRef<ShareContentMethods>(null);

	const toggleReactionsModal = () => {
		setPostReactionsModal(show => !show);
	};

	const toggleCommentsSection = () => {
		setShowPostComments(show => !show);
	};

	const removeUserReaction = () => {
		setUserReaction(null);
		setTotalReactionsCount(t => t - 1);
	};

	const likePost = (reaction: PostReactionModel) => {
		reactOnPost(postId, reaction);
		if (!userReaction) {
			setTotalReactionsCount(t => t + 1);
		}
		setUserReaction(reaction);
	};

	const handleAddRemoveComment = useCallback((isAdd?: boolean) => {
		setTotalCommentsCount(t => t + (isAdd ? 1 : -1));
	}, []);

	const lastReactions = useMemo(() => {
		const items: PostReactionModel[] = reactionsInfo?.preview || [];
		if (userReaction && !items.some(x => x.skin === userReaction!.skin && x.type === userReaction!.type)) {
			items.unshift({ skin: userReaction.skin, type: userReaction.type });
		}

		if (
			prevReaction &&
			(userReaction?.type !== (prevReaction as PostReactionModel).type ||
				userReaction?.skin !== (prevReaction as PostReactionModel).skin)
		) {
			const index = items.findIndex(
				x => x.type === (prevReaction as PostReactionModel).type && x.skin === (prevReaction as PostReactionModel).skin
			);
			if (index > -1) {
				items.splice(index, 1);
			}
		}

		return items.map(reaction => ({
			id: `${postId}-reaction-${uuidv4()}`,
			el: <ReactionIcon size={ImageSizesEnum.Small16} type={reaction.type} skin={reaction.skin} />
		}));
	}, [postId, reactionsInfo?.preview, prevReaction, userReaction]);

	const menuOpts = [
		{
			name: t("copy_link"),
			onClick: () =>
				copyLink({
					customLink: `${memberRoutes.feed.getPath()}/${postId}`,
					socialMetaTagInfo: {
						socialTitle: `Post from ${creator}`,
						socialDescription: `Check out this post from ${creator}`
					},
					analyticsInfo: {
						googlePlayAnalytics: {
							utmCampaign: "share",
							utmMedium: "link",
							utmTerm: "sports",
							utmSource: "link",
							utmContent: "share link"
						}
					}
				})
		},
		{
			name: t("share_with_connections"),
			onClick: () => shareRef.current?.handleOpenConnectionModal()
		}
		// TODO: Uncomment when feed component is ready
		// {
		// 	name: "Share to feed",
		// 	onClick: () => shareRef.current?.handleOpenFeedModal()
		// }
	];

	return (
		<>
			<div className={clsx("w-full px-4 py-2", className)}>
				<div className="flex justify-between">
					<div className="flex items-center cursor-pointer" onClick={toggleReactionsModal}>
						{!!totalReactionsCount && (
							<>
								<Repeatable repeatableEls={lastReactions} imgSize={ImageSizesEnum.Small16} bordered />
								<Text variants={TextVariantsEnum.Caption3} className="ml-2">
									{totalReactionsCount}
								</Text>
							</>
						)}
					</div>
					{!!totalCommentsCount && (
						<Text variants={TextVariantsEnum.Caption3}>
							{totalCommentsCount} Comment{totalCommentsCount > 1 && "s"}
						</Text>
					)}
				</div>
				<DividerWithText className="py-1.5" />
				<div className="flex items-center">
					<Like className="flex-1" onLike={likePost}>
						<Button
							size={ButtonSizesEnum.SM}
							variant={ButtonVariantsEnum.GHOST}
							startIcon={
								userReaction?.type ? (
									<ReactionIcon size={ImageSizesEnum.Small24} type={userReaction.type} skin={userReaction.skin} />
								) : (
									<HeartIcon />
								)
							}
							dontFillSvg
							textColor={userReaction ? colors.palette.colors.primary["600"] : undefined}
							className="w-full"
						>
							{userReaction?.type ? userReactionInfo?.label || t("feed:like") : t("feed:like")}
						</Button>
					</Like>
					<Button
						size={ButtonSizesEnum.SM}
						variant={ButtonVariantsEnum.GHOST}
						startIcon={<MessageIcon />}
						dontFillSvg
						className="flex-1"
						onClick={toggleCommentsSection}
					>
						{t("feed:comment")}
					</Button>

					<MenuDots
						options={menuOpts}
						menuListPosition={MenuListPositionOpts.BOTTOM_RIGHT}
						popoverPosition={MenuListPositionOpts.TOP_RIGHT}
						variant={MenuDotsVariants.ACTIVE}
						className="flex-1"
						customActionEl={
							<Button
								size={ButtonSizesEnum.SM}
								variant={ButtonVariantsEnum.GHOST}
								startIcon={<ShareIcon />}
								dontFillSvg
								className="w-full"
							>
								{t("feed:share")}
							</Button>
						}
					/>
				</div>
			</div>
			{showPostComments && (
				<Suspense>
					<Comments
						postId={postId}
						totalCommentsCount={totalCommentsCount}
						onAddRemoveComment={handleAddRemoveComment}
					/>
				</Suspense>
			)}
			{showPostReactionsModal && (
				<Suspense>
					<PostReactionsModal postId={postId} onClose={toggleReactionsModal} onRemoveReaction={removeUserReaction} />
				</Suspense>
			)}

			<ShareContentBlock
				entityType={EntityFollowListEnum.POST}
				creatorName={creator}
				key={`share-block-${postId}`}
				noIntroText
				ref={shareRef}
				attachments={[
					{
						id: uuidv4(),
						kind: AttachmentKind.ENTITY,
						entity: {
							id: postId,
							name: "Post",
							type: getBEEntityType(EntityFollowListEnum.POST),
							info: null
						}
					}
				]}
			/>
		</>
	);
};

export default Footer;
