import React, { Suspense, lazy, useCallback, useEffect, useMemo, useRef } from "react";

import { useCreateFlowRouter, useLeague, useUpdateStepData } from "shared/hooks";

const SetupLevelTemplate = lazy(() => import("shared/templates/SetupLevel"));

const SetupLevel = () => {
	const { setAllowGoNext, checkIfMounted, getCreateFlowData: getSetupLeagueData } = useCreateFlowRouter();
	const { step } = getSetupLeagueData();

	const { updateNewSetupLeague, getData: getNewSetupLeagueData } = useLeague();
	const { newSetupLeague } = getNewSetupLeagueData();

	const isMounted = useMemo(() => checkIfMounted("level"), [checkIfMounted]);

	type SetupLevelMethodsHandlers = React.ElementRef<typeof SetupLevelTemplate>;
	const levelsFormRef = useRef<SetupLevelMethodsHandlers | null>(null);

	useEffect(() => {
		setAllowGoNext(true);
	}, [setAllowGoNext]);

	const submitData = useCallback(() => {
		const formData = levelsFormRef.current?.getInfo();
		if (formData?.isValid && formData?.levels?.length) {
			updateNewSetupLeague({ levels: formData!.levels });
			return true;
		} else {
			levelsFormRef.current?.triggerForm();
		}
		return false;
	}, [updateNewSetupLeague]);

	const goBack = useCallback(() => {
		const formData = levelsFormRef.current?.getInfo();
		if (formData?.isValid && formData?.levels?.length) {
			updateNewSetupLeague({ levels: formData!.levels });
		}
		return true;
	}, [updateNewSetupLeague]);

	useUpdateStepData({
		nextCb: submitData,
		backCb: goBack
	});

	return (
		<Suspense>
			<SetupLevelTemplate
				isMounted={isMounted}
				step={step}
				values={newSetupLeague?.levels}
				ref={levelsFormRef}
				leagueMode
			/>
		</Suspense>
	);
};

export default React.memo(SetupLevel);
