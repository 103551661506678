import type { ReactNode } from "react";
import React, { Suspense, lazy, memo, useCallback, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import LocationIcon from "assets/icons/outlined/location.svg?react";
import { CardWrapper, Chip, EditableLogo } from "shared/Components";
import { useInvitation, useShare, useUser } from "shared/hooks";
import type { InvitationPersonaModel, LocationModel, LogoModel } from "shared/types";
import { AttachmentKind, EntityFollowListEnum } from "shared/types";
import type { MenuDotsOption } from "shared/uikit";
import { Button, ImageSizesEnum, Img, MenuDots, SkeletonBase, Text } from "shared/uikit";
import { ButtonSizesEnum, MenuListPositionOpts, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { getBEEntityType } from "utils/getBEEntityType";
import { getGoogleMapsUrl } from "utils/getGoogleMapsUrl";

import { uuidv4 } from "utils/serviceUtils/helpers";

import { TypeBox } from "./styles";

import type { ShareContentMethods } from "../ShareContentBlock";
import ShareContentBlock from "../ShareContentBlock";

const InviteToFollowModal = lazy(() => import("shared/Components/InviteToFollowModal"));

export interface EntityCardInfoProps {
	icon?: LogoModel | string | null;
	title?: string;
	type: string;
	location?: LocationModel | null;
	loading?: boolean;
	chips?: string[];
	middleContent?: ReactNode;
	secondaryContent?: ReactNode;
	entityType?: EntityFollowListEnum;
	id?: string;
	options?: MenuDotsOption[];
	onChangeLogo?: (imgSrc: string | null) => void;
	extraActions?: ReactNode;
	isFollow?: boolean;
	onChangeFollow?: (following: boolean) => void;
	shareOpts?: {
		copyLink?: boolean;
		shareToFeed?: boolean;
		shareToConnections?: boolean;
		socialTitle: string;
		socialDescription: string;
	};
	isFollowable?: boolean;
}

const EntityCardInfo: React.FC<EntityCardInfoProps> = memo(
	({
		icon,
		title,
		type,
		location,
		loading,
		chips,
		middleContent,
		secondaryContent,
		entityType,
		id,
		options,
		onChangeLogo,
		extraActions,
		isFollow,
		onChangeFollow,
		shareOpts = {
			copyLink: true,
			shareToFeed: true,
			shareToConnections: true,
			socialTitle: "Tapin Sports",
			socialDescription: "Create a Sports Community in minutes"
		},
		isFollowable
	}) => {
		const { t } = useTranslation();

		const { followEntity, unfollowEntity, getData: getUserData } = useUser();
		const { following } = getUserData();

		const { copyLink } = useShare();

		const { inviteToFollowEntity } = useInvitation();

		const shareRef = useRef<ShareContentMethods>(null);

		const [showInviteToFollowModal, setShowInviteToFollowModal] = useState<{
			open: true;
			handleInvite: (invitations: Partial<InvitationPersonaModel>[]) => Promise<void>;
		} | null>(null);

		const canInviteToFollow = useMemo(
			() =>
				[
					EntityFollowListEnum.CLUB,
					EntityFollowListEnum.LEAGUE,
					EntityFollowListEnum.COMPETITION,
					EntityFollowListEnum.TEAM,
					EntityFollowListEnum.USER,
					EntityFollowListEnum.GROUP
				].some(entity => entity === entityType),
			[entityType]
		);

		const handleInviteToFollow = useCallback(
			async (invitations: Partial<InvitationPersonaModel>[]) => {
				await inviteToFollowEntity({
					emails: invitations.map(invitation => invitation.email!),
					entityId: id!,
					entityType: entityType!
				});
			},
			[entityType, id, inviteToFollowEntity]
		);

		const optsList: MenuDotsOption[] = useMemo(() => {
			const opts: MenuDotsOption[] = options ? [...options] : [];

			if (shareOpts.copyLink) {
				opts.push({
					name: t("copy_link"),
					onClick: () =>
						copyLink({
							socialMetaTagInfo: {
								socialTitle: shareOpts.socialTitle,
								socialDescription: shareOpts.socialDescription
							},
							analyticsInfo: {
								googlePlayAnalytics: {
									utmCampaign: "share",
									utmMedium: "link",
									utmTerm: "sports",
									utmSource: "link",
									utmContent: "share link"
								}
							}
						})
				});
			}

			if (shareOpts.shareToConnections) {
				opts.push({
					name: t("share_with_connections"),
					onClick: () => shareRef.current?.handleOpenConnectionModal()
				});
			}

			if (shareOpts.shareToFeed) {
				opts.push({
					name: t("share_to_feed"),
					onClick: () => shareRef.current?.handleOpenFeedModal()
				});
			}

			if (canInviteToFollow) {
				opts.push({
					name: t("invitation:invite_to_follow"),
					onClick: () =>
						setShowInviteToFollowModal({
							open: true,
							handleInvite: handleInviteToFollow
						})
				});
			}

			return opts;
		}, [canInviteToFollow, copyLink, handleInviteToFollow, options, shareOpts, t]);

		const canBeFollowed = useMemo(
			() =>
				[
					EntityFollowListEnum.PLAYER,
					EntityFollowListEnum.COACH,
					EntityFollowListEnum.CLUB,
					EntityFollowListEnum.TEAM,
					EntityFollowListEnum.LEAGUE,
					EntityFollowListEnum.GROUP,
					EntityFollowListEnum.COMPETITION
				].includes(entityType!),
			[entityType]
		);

		const handleFollowEntity = useCallback(() => {
			isFollow ? unfollowEntity(id!, entityType!) : followEntity(id!, entityType!);
			onChangeFollow && onChangeFollow(!isFollow);
		}, [entityType, followEntity, unfollowEntity, isFollow, id, onChangeFollow]);

		if (loading) {
			return (
				<CardWrapper noPadding className="flex items-center p-6">
					<SkeletonBase width={112} height={112} variant="circular" fullScale className="mr-6" />
					<div>
						<div className="flex items-center">
							<SkeletonBase width={100} height={34} />
							<SkeletonBase width={50} height={28} className="ml-2" />
						</div>
						<div className="flex items-center mt-1">
							<div className="mr-2">
								<SkeletonBase width={20} height={20} fullScale />
							</div>
							<SkeletonBase width={170} height={34} />
						</div>
					</div>
				</CardWrapper>
			);
		}
		return (
			<>
				<CardWrapper noPadding className="flex items-center p-6">
					<div className="mr-6">
						{onChangeLogo ? (
							<EditableLogo
								src={icon || null}
								alt={title || ""}
								size={ImageSizesEnum.Small112}
								circle
								onChange={onChangeLogo}
							/>
						) : (
							<Img src={icon} alt={title || ""} size={ImageSizesEnum.Small112} circle />
						)}
					</div>
					<div className="max-w-[70%]">
						<div className="flex items-center w-full">
							<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.H4} className="w-fit truncate">
								{title}
							</Text>
							<TypeBox className="ml-2">
								<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.Caption3}>
									{type}
								</Text>
							</TypeBox>
						</div>
						{secondaryContent && <div className="my-1">{secondaryContent}</div>}
						{location?.address && (
							<div className="flex items-center mt-1">
								<div className="mr-2">
									<LocationIcon className="w-[20px] h-[20px] svg-paths:stroke-gray-400" />
								</div>
								<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.BodyMedium}>
									<a href={getGoogleMapsUrl(location.lat, location.lng)} target="_blank" rel="noreferrer noopener">
										{location?.address}
									</a>
								</Text>
							</div>
						)}
						{!!chips?.length && (
							<div className="flex items-center gap-2 mt-1">
								{chips.map((chip, i) => (
									<Chip text={chip} key={i} />
								))}
							</div>
						)}
						<div className="my-2">{middleContent}</div>
					</div>
					<div className="absolute top-3 right-4 flex items-center gap-2">
						<MenuDots
							options={optsList}
							horizontal
							bordered
							size={ButtonSizesEnum.XS}
							menuListPosition={MenuListPositionOpts.BOTTOM_LEFT}
						/>
					</div>
					<div className="absolute bottom-3 right-4 flex items-center gap-2">
						{extraActions}
						{isFollowable && canBeFollowed && entityType && id && (
							<Button
								onClick={handleFollowEntity}
								disabled={following === id}
								loading={following === id}
								secondary
								size={ButtonSizesEnum.XS}
							>
								{isFollow ? t("unfollow") : t("follow")}
							</Button>
						)}
					</div>
				</CardWrapper>
				<ShareContentBlock
					entityType={entityType}
					key={`share-block-${id}}`}
					ref={shareRef}
					attachments={[
						{
							id: uuidv4(),
							kind: AttachmentKind.ENTITY,
							entity: {
								id: id!,
								type: getBEEntityType(entityType!),
								name: title!,
								info: {
									logo: icon,
									name: title!
								}
							}
						}
					]}
				/>
				{showInviteToFollowModal?.open && (
					<Suspense>
						<InviteToFollowModal
							handleInvite={showInviteToFollowModal.handleInvite}
							handleClose={() => setShowInviteToFollowModal(null)}
						/>
					</Suspense>
				)}
			</>
		);
	}
);

export default EntityCardInfo;
