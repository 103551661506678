import React, { Suspense, lazy, useCallback, useEffect, useMemo, useRef } from "react";

import { useCreateFlowRouter, useLeague, useUpdateStepData } from "shared/hooks";
const SetupCategoryTemplate = lazy(() => import("shared/templates/SetupCategory"));

const SetupCategory = () => {
	const { setAllowGoNext, checkIfMounted, getCreateFlowData: getSetupLeagueData } = useCreateFlowRouter();
	const { step } = getSetupLeagueData();

	const { updateNewSetupLeague, getData: getNewSetupLeagueData } = useLeague();
	const { newSetupLeague } = getNewSetupLeagueData();

	const isMounted = useMemo(() => checkIfMounted("category"), [checkIfMounted]);

	type SetupCategoryMethodsHandlers = React.ElementRef<typeof SetupCategoryTemplate>;
	const categoriesFormRef = useRef<SetupCategoryMethodsHandlers | null>(null);

	useEffect(() => {
		setAllowGoNext(true);
	}, [setAllowGoNext]);

	const submitData = useCallback(() => {
		const formData = categoriesFormRef.current?.getInfo();
		if (formData?.isValid && formData?.categories?.length) {
			updateNewSetupLeague({ categories: formData?.categories });
			return true;
		} else {
			categoriesFormRef.current?.triggerForm();
		}
		return false;
	}, [updateNewSetupLeague]);

	const goBack = useCallback(() => {
		const formData = categoriesFormRef.current?.getInfo();
		if (formData?.isValid && formData?.categories?.length) {
			updateNewSetupLeague({ categories: formData?.categories });
		}
		return true;
	}, [updateNewSetupLeague]);

	useUpdateStepData({
		nextCb: submitData,
		backCb: goBack
	});

	return (
		<Suspense>
			<SetupCategoryTemplate
				isMounted={isMounted}
				step={step}
				values={newSetupLeague?.categories}
				ref={categoriesFormRef}
			/>
		</Suspense>
	);
};

export default React.memo(SetupCategory);
