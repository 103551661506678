import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { resendSignUpCode } from "@aws-amplify/auth";
import appConfig from "config/appConfig";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import GoogleIcon from "assets/icons/colored/google.svg?react";
import AppleIcon from "assets/icons/filled/apple.svg?react";
import { AppConfig } from "config";
import { getPaths } from "core/getPaths";
import { StyledLink } from "modules/App/View/Container/SignIn/styles";
import { OnboardingForm } from "shared/Components";
import { useUser } from "shared/hooks";
import type { InvitationModel } from "shared/types";
import { InvitationTypes } from "shared/types";
import type {
	InvitationCreator1Resolver,
	InvitationCreator2Resolver,
	InvitationTarget2Resolver
} from "shared/types/InvitationModel";
import { Button, DividerWithText, ImageSizesEnum, Img, Input, Text } from "shared/uikit";
import { ButtonSizesEnum, ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";
import { validateEmail } from "utils/serviceUtils/validators";

const { auth, member } = getPaths();

interface Props {
	invitationInfo?: InvitationModel;
	loginWithGoogle: () => void;
	loginWithApple: () => void;
	redirectUrl?: string;
}

const SignInTemplate: FC<Props> = ({ invitationInfo, loginWithGoogle, loginWithApple, redirectUrl }) => {
	const { t } = useTranslation();
	const { checkEmailAvailability, setTempCredentials, getData: getUserData } = useUser();
	const { myProfile } = getUserData();

	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		if (email.length) {
			if (!validateEmail(email)) {
				setEmailError(t("error:not_valid", { field: t("email") }));
				return;
			}

			setEmailError("");
		}
	}, [email, t]);

	const navigate = useNavigate();

	const handleUserCurrentlyLoggedIn = () =>
		navigate(myProfile?.onboarded ? member.home.getPath() : auth.account.fill.getPath());

	const invitationTextInfo = useMemo(() => {
		if (!invitationInfo) return null;

		const soccerTargetArrVal = invitationInfo?.targets?.find(t => t.soccer);

		if (invitationInfo?.type === InvitationTypes.SOCCER_REFEREE_ORGANIZATION_REFEREE) {
			return (
				<>
					{t("invitation:you_have_been_invited_to_be_an", { role: t("referee") })}{" "}
					<b>
						{(invitationInfo?.target as InvitationTarget2Resolver)?.soccer.reforg?.name ||
							(invitationInfo as InvitationCreator2Resolver)?.creator?.name ||
							soccerTargetArrVal?.soccer?.reforg?.name}
					</b>
				</>
			);
		}

		if (invitationInfo?.type === InvitationTypes.SOCCER_CLUB_PLAYER) {
			return (
				<>
					{t("invitation:you_have_been_invited_to_be_an", { role: t("player") })}{" "}
					<b>
						{soccerTargetArrVal
							? soccerTargetArrVal.soccer?.club?.name
							: (invitationInfo?.target as InvitationTarget2Resolver)?.soccer?.club?.name
								? (invitationInfo!.target as InvitationTarget2Resolver)!.soccer!.club!.name
								: "."}
					</b>
				</>
			);
		}

		if (invitationInfo?.type === InvitationTypes.SOCCER_CHILD_INVITATION) {
			return (
				<>
					<b>{(invitationInfo.creator as InvitationCreator1Resolver).name}</b>{" "}
					{t("invitation:has_invited_you_to_be_a_child")}
				</>
			);
		}

		if (invitationInfo?.type === InvitationTypes.SOCCER_CLUB_COACH) {
			return (
				<>
					{t("invitation:you_have_been_invited_to_be_an", { role: t("coach") })}{" "}
					<b>
						{soccerTargetArrVal
							? soccerTargetArrVal.soccer?.club?.name
							: (invitationInfo?.target as InvitationTarget2Resolver)?.soccer?.club?.name
								? (invitationInfo!.target as InvitationTarget2Resolver)!.soccer!.club!.name
								: "."}
					</b>
				</>
			);
		}

		return (
			<>
				{t("invitation:you_have_been_invited_to")} <b>{t("tapin_sports")}</b> {t("platform")}.
			</>
		);
	}, [invitationInfo, t]);

	const generateLink = useCallback(
		(pageLink: string) => {
			if (redirectUrl) {
				return `${pageLink}?redirectUrl=${redirectUrl}`;
			}
			return pageLink;
		},
		[redirectUrl]
	);

	const onSubmit = async () => {
		setSubmitting(true);

		try {
			const { available, login, emailVerified, emailLogin, providers } = await checkEmailAvailability(email);

			setTempCredentials({
				email,
				username: login
			});

			if (available) {
				setTempCredentials({
					email,
					username: !!providers.length && !providers.includes("Cognito") ? emailLogin : login
				});

				navigate(generateLink(auth.register.getPath()));
			} else {
				if (emailVerified) {
					navigate(generateLink(auth.login.getPath()));
				} else {
					login &&
						(await resendSignUpCode({
							username: login
						}));
					navigate(generateLink(auth.otp.getPath()));
				}
			}
		} catch (error: any) {
			console.log({ error });
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<div className="max-h-full mt-8">
			{myProfile && invitationInfo && (
				<div className="border border-gray-100 rounded-xl bg-white p-8 mb-4 flex flex-col justify-between items-center gap-4">
					<Img src={myProfile?.avatar} alt={myProfile?.name || ""} circle size={ImageSizesEnum.Small56} />
					<Text variants={TextVariantsEnum.Caption3}>
						{t("you_are_logged_in_as")} {myProfile?.firstName} {myProfile?.lastName}
					</Text>
					<Button onClick={handleUserCurrentlyLoggedIn} size={ButtonSizesEnum.LG} secondary className="w-full">
						{t("continue_as")} {myProfile?.firstName}
					</Button>
				</div>
			)}
			<OnboardingForm
				title={`${t("welcome_to")} ${t("tapin_sports")}`}
				subtitle={`${t("continue_with_any_login_methods")} ${
					invitationInfo ? t("invitation:to_accept_the_invitation") : ""
				}.`}
				onSubmit={onSubmit}
			>
				<div className="flex flex-col gap-4">
					{invitationInfo && (
						<>
							<DividerWithText />
							<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.BodyMedium} className="my-3 text-center">
								{invitationTextInfo}
							</Text>
							<DividerWithText className="mb-6" />
						</>
					)}
					{appConfig.GLOBAL_CONSTANTS.ALLOW_GOOGLE_AUTH && (
						<Button
							size={ButtonSizesEnum.LG}
							variant={ButtonVariantsEnum.OUTLINED}
							startIcon={<GoogleIcon />}
							onClick={loginWithGoogle}
							id="continue-with-google"
						>
							{t("continue_with")} Google
						</Button>
					)}
					{appConfig.GLOBAL_CONSTANTS.ALLOW_APPLE_AUTH && (
						<Button
							size={ButtonSizesEnum.LG}
							variant={ButtonVariantsEnum.OUTLINED}
							startIcon={<AppleIcon />}
							onClick={loginWithApple}
							id="continue-with-apple"
						>
							{t("continue_with")} Apple
						</Button>
					)}
				</div>

				{(appConfig.GLOBAL_CONSTANTS.ALLOW_GOOGLE_AUTH || appConfig.GLOBAL_CONSTANTS.ALLOW_APPLE_AUTH) && (
					<DividerWithText text="OR" className="my-6" />
				)}

				<Input
					size={ButtonSizesEnum.UNDER_MD}
					placeholder={t("email")}
					value={email}
					onChange={e => setEmail(e.target.value.toLowerCase())}
					error={!!emailError}
					errorText={emailError}
				/>

				<Button
					className="mt-6"
					size={ButtonSizesEnum.LG}
					variant={ButtonVariantsEnum.FILLED}
					id={"continue-with-email-btn"}
					disabled={!!emailError || submitting}
					type="submit"
					loading={submitting}
				>
					{t("contin")}
				</Button>

				<div className="mt-6">
					<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.BodyMedium}>
						{t("by_continue_you_agree_to_the")}{" "}
						{AppConfig.GLOBAL_CONSTANTS.TERMS_AND_CONDITIONS_LINK && (
							<StyledLink
								id="terms-conditions"
								href={AppConfig.GLOBAL_CONSTANTS.TERMS_AND_CONDITIONS_LINK}
								target="_blank"
							>
								{t("terms_and_conditions")}
							</StyledLink>
						)}{" "}
						{AppConfig.GLOBAL_CONSTANTS.PRIVACY_POLICY_LINK && (
							<StyledLink id="privacy-policy" href={AppConfig.GLOBAL_CONSTANTS.PRIVACY_POLICY_LINK} target="_blank">
								{t("privacy_policy")}
							</StyledLink>
						)}{" "}
						{AppConfig.GLOBAL_CONSTANTS.COOKIE_USE_LINK && (
							<StyledLink id="cookie-usage" href={AppConfig.GLOBAL_CONSTANTS.COOKIE_USE_LINK} target="_blank">
								{t("cookie_use")}
							</StyledLink>
						)}{" "}
						{t("of_tapin_sports_platform")}.
					</Text>
				</div>
			</OnboardingForm>
		</div>
	);
};

export default SignInTemplate;
