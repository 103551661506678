import type { FC, ReactNode } from "react";
import React, { Suspense, lazy, useMemo, useRef, useState } from "react";

import clsx from "clsx";

import { useUser } from "shared/hooks";
import type { PostReactionModel, PostReactionSkins, PostReactionTypes } from "shared/types";
import { Tooltip } from "shared/uikit";
import PopoverBox from "shared/uikit/PopoverBox";

import { MenuListPositionOpts } from "shared/uikit/types";
import colors from "theme/colors";

import { emotionIcons, skinOptions } from "./constants";
import { IconWrapper } from "./style";

const SkinTone = lazy(() => import("./SkinTone"));

interface LikeProps {
	className?: string;
	children: ReactNode;
	onLike: (reaction: PostReactionModel) => void;
}

const Like: FC<LikeProps> = ({ className, children, onLike }) => {
	const { setReactionSkin, getData: getUserData } = useUser();
	const { reactionSkin } = getUserData();

	const [showReactions, setShowReactions] = useState(false);
	const [showSkinTone, setShowSkinTone] = useState(false);

	const anchorEl = useRef<HTMLDivElement | null>(null);
	const skinAnchorEl = useRef<HTMLDivElement | null>(null);

	const reactionSkinColor = useMemo(
		() => skinOptions.find(x => x.value === reactionSkin)?.color || colors.palette.colors.reactionColors.default,
		[reactionSkin]
	);

	const toggleReactionBox = () => {
		if (showReactions) {
			setShowSkinTone(false);
		}
		setShowReactions(!showReactions);
	};

	const toggleSkinTone = (newSkin?: PostReactionSkins) => {
		if (newSkin) {
			setReactionSkin(newSkin);
		}
		setShowSkinTone(show => !show);
	};

	const onChooseReaction = (reactionType: PostReactionTypes) => {
		toggleReactionBox();
		onLike({
			skin: reactionSkin,
			type: reactionType
		});
	};

	return (
		<>
			<div ref={anchorEl} onClick={toggleReactionBox} className={clsx(className)}>
				{children}
			</div>
			<PopoverBox
				anchorEl={anchorEl.current}
				onClose={toggleReactionBox}
				show={showReactions}
				popoverPosition={MenuListPositionOpts.BOTTOM_LEFT}
				contentPosition={MenuListPositionOpts.TOP_LEFT}
			>
				<div className="bg-white flex items-end gap-2 p-2 rounded-xl shadow-[0_0_8px_rgba(0,0,0,0.12)]">
					{emotionIcons.map(emotion => (
						<Tooltip key={emotion.value} text={emotion.label} placement="bottom">
							<IconWrapper
								className="w-8 h-8 cursor-pointer [&>svg]:w-full [&>svg]:h-full transition-transform	origin-bottom hover:scale-[1.75]"
								onClick={() => onChooseReaction(emotion.value)}
								color={emotion?.colorized ? reactionSkinColor : undefined}
							>
								{emotion.icon}
							</IconWrapper>
						</Tooltip>
					))}
					<Tooltip text="Choose skin tone" placement="bottom">
						<div className="w-8 h-8 cursor-pointer" ref={skinAnchorEl} onClick={() => toggleSkinTone()}>
							<div className="w-full h-full rounded-full overflow-hidden flex border border-blue-500">
								<div className="w-full h-full border border-white flex rounded-full overflow-hidden">
									<hr className="w-1/4 h-8 -translate-y-px bg-[#7C3A26]" />
									<hr className="w-1/4 h-8 -translate-y-px bg-[#BB6841]" />
									<hr className="w-1/4 h-8 -translate-y-px bg-[#D0855C]" />
									<hr className="w-1/4 h-8 -translate-y-px bg-[#E9A986]" />
								</div>
							</div>
						</div>
					</Tooltip>
				</div>
			</PopoverBox>
			{showSkinTone && (
				<Suspense>
					<SkinTone anchorEl={skinAnchorEl.current} onChooseTone={toggleSkinTone} className="mt-3 -mr-2" />
				</Suspense>
			)}
		</>
	);
};

export default Like;
