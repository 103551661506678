import { styled } from "@mui/material";

import colors from "theme/colors";

export const CardWrapper = styled("div")`
	width: auto;
	height: 168px;

	.role-icon {
		width: 64px;
		height: 64px;
	}

	&.skeleton {
		background: ${colors.palette.colors.white};

		border: 1px solid ${colors.palette.colors.gray["100"]};

		padding: 16px;

		border-radius: 12px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export const AvatarWrapper = styled("div")`
	width: 24px;
	height: 24px;

	border-radius: 50%;

	overflow: hidden;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	margin-right: 8px;
`;

export const SeasonItem = styled("div")`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;

	.address-row,
	.info-row {
		display: flex;
		align-items: center;
	}

	.address {
		margin-right: 8px;
	}
`;
