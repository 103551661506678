import type { FC } from "react";
import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { LinearProgressBar, Table } from "shared/Components";
import type { TableColumnType } from "shared/Components/Table/Components/SimpleTable";
import { useLeague } from "shared/hooks";
import type { PaginationTokenType, PaymentRequestModel } from "shared/types";
import { PaymentRequestAudienceTypes } from "shared/types";
import { TableColumnLoaderType } from "shared/types/Table";
import type { MenuDotsOption } from "shared/uikit";
import { MenuDots, Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";
import { formatMoney } from "utils/formatMoney";

const TransactionDetailsDialog = lazy(() => import("shared/Components/TransactionDetailsDialog"));
const ChooseTeamModal = lazy(() => import("shared/Components/PaymentModal/ChooseTeamModal"));

const PaymentRequestsTable: FC<{ leagueId: string }> = ({ leagueId }) => {
	const { t } = useTranslation();

	const { getPaymentRequests, getData: getLeagueData } = useLeague();
	const { loadingPaymentRequests, paymentRequests, nextListingPaymentRequestsToken } = getLeagueData();

	const [transactionDetailsOpen, setTransactionDetailsOpen] = useState<string | null>(null);
	const [seeTeamsModal, setSeeTeamsModal] = useState<string | null>(null);

	const fetchData = useCallback(
		(leagueId: string, token?: PaginationTokenType) => {
			getPaymentRequests({ leagueId, after: token });
		},
		[getPaymentRequests]
	);

	useEffect(() => {
		fetchData(leagueId, null);
	}, [fetchData, leagueId]);

	const handleLoadMore = useCallback(() => {
		nextListingPaymentRequestsToken && fetchData(nextListingPaymentRequestsToken);
	}, [nextListingPaymentRequestsToken, fetchData]);

	const getOptions = useCallback(
		(rowData: PaymentRequestModel): MenuDotsOption[] => {
			const { totalAmount, collectedAmount } = rowData;
			const options: MenuDotsOption[] = [];

			if ((collectedAmount ?? 0) > 0) {
				options.push({
					name: "See payments",
					onClick: () => setTransactionDetailsOpen(rowData.id)
				});
			}

			if (totalAmount !== collectedAmount) {
				options.push({
					name: "See pending payments",
					onClick: () => setSeeTeamsModal(rowData.id)
				});
			}

			return options;
		},
		[setTransactionDetailsOpen]
	);

	const tableColumns: TableColumnType[] = useMemo(
		() => [
			{
				label: t("product"),
				minWidth: 260,
				Cell: ({ rowData: { name, linkedObject } }: { rowData: PaymentRequestModel }) => (
					<div>
						<Text>{name}</Text>
						{linkedObject?.competition?.name && <Text className="text-hint">{linkedObject.competition.name}</Text>}
					</div>
				),
				dataKey: "product"
			},
			{
				label: t("payment:invoice_to"),
				width: 120,
				Cell: ({ rowData: { audiences, relatedPaymentRequests } }: { rowData: PaymentRequestModel }) => {
					if (audiences?.length) {
						const firstAudience = audiences[0]!;
						switch (firstAudience.type) {
							case PaymentRequestAudienceTypes.SOCCER_CLUB_GENERIC:
								return t("club");
						}
					}

					if (relatedPaymentRequests?.length) {
						const firstAudience = relatedPaymentRequests[0].audiences?.length
							? relatedPaymentRequests[0].audiences[0]!
							: null;
						if (firstAudience?.type === PaymentRequestAudienceTypes.SOCCER_TEAM_PLAYER) {
							return t("players");
						}
						if (firstAudience?.type === PaymentRequestAudienceTypes.SOCCER_CLUB_GENERIC) {
							return t("clubs");
						}
					}

					return "-";
				},
				dataKey: "invoiceTo"
			},
			{
				label: t("invited"),
				width: 120,
				Cell: ({ rowData: { audiences, relatedPaymentRequests } }: { rowData: PaymentRequestModel }) => {
					if (audiences?.length) {
						const firstAudience = audiences[0]!;
						if (firstAudience.type === PaymentRequestAudienceTypes.SOCCER_CLUB_GENERIC) {
							return (
								<div>
									<Text variants={TextVariantsEnum.BodyMedium}>{firstAudience.club!.teams.length || 0}</Text>
									<Text variants={TextVariantsEnum.BodyMedium} className="mt-2 text-hint">
										{t("teams")}
									</Text>
								</div>
							);
						}
					}

					if (relatedPaymentRequests?.length) {
						const firstAudience = relatedPaymentRequests[0].audiences?.length
							? relatedPaymentRequests[0].audiences[0]!
							: null;
						if (firstAudience?.type === PaymentRequestAudienceTypes.SOCCER_TEAM_PLAYER) {
							const playersCount = relatedPaymentRequests.reduce((acc, pr) => acc + (pr?.audiences?.length || 0), 0);

							return (
								<div>
									<Text variants={TextVariantsEnum.BodyMedium}>{playersCount}</Text>
									<Text variants={TextVariantsEnum.BodyMedium} className="mt-2 text-hint">
										{t("players")}
									</Text>
								</div>
							);
						}

						if (firstAudience?.type === PaymentRequestAudienceTypes.SOCCER_CLUB_GENERIC) {
							const teamsCount = relatedPaymentRequests.reduce(
								(acc, pr) =>
									acc + (pr.audiences?.reduce((accAud, prAud) => accAud + (prAud?.club?.teams?.length || 0), 0) || 0),
								0
							);

							return (
								<div>
									<Text variants={TextVariantsEnum.BodyMedium}>{teamsCount}</Text>
									<Text variants={TextVariantsEnum.BodyMedium} className="mt-2 text-hint">
										{t("teams")}
									</Text>
								</div>
							);
						}
					}

					return "-";
				},
				dataKey: "invited"
			},
			{
				label: t("collected"),
				width: 180,
				Cell: ({
					rowData: { totalAmount, collectedAmount, relatedPaymentRequests }
				}: {
					rowData: PaymentRequestModel;
				}) => {
					let collected = collectedAmount ?? 0;
					let total = totalAmount ?? 0;

					if (relatedPaymentRequests?.length) {
						const firstAudience = relatedPaymentRequests[0].audiences?.length
							? relatedPaymentRequests[0].audiences[0]!
							: null;
						if (
							[
								PaymentRequestAudienceTypes.SOCCER_TEAM_PLAYER,
								PaymentRequestAudienceTypes.SOCCER_CLUB_GENERIC
							].includes(firstAudience?.type)
						) {
							collected = relatedPaymentRequests.reduce((acc, pr) => acc + pr.collectedAmount, 0);
							total = relatedPaymentRequests.reduce((acc, pr) => acc + pr.totalAmount, 0);
						}
					}

					return (
						<Text variants={TextVariantsEnum.BodyMedium}>
							{formatMoney(collected)} / <span className="text-hint">{formatMoney(total)}</span>
						</Text>
					);
				},
				dataKey: "collected"
			},
			{
				label: "Progress",
				minWidth: 200,
				Cell: ({
					rowData: { totalAmount, collectedAmount, relatedPaymentRequests }
				}: {
					rowData: PaymentRequestModel;
				}) => {
					let collected = collectedAmount ?? 0;
					let total = totalAmount ?? 0;

					if (relatedPaymentRequests?.length) {
						const firstAudience = relatedPaymentRequests[0].audiences?.length
							? relatedPaymentRequests[0].audiences[0]!
							: null;
						if (
							[
								PaymentRequestAudienceTypes.SOCCER_TEAM_PLAYER,
								PaymentRequestAudienceTypes.SOCCER_CLUB_GENERIC
							].includes(firstAudience?.type)
						) {
							collected = relatedPaymentRequests.reduce((acc, pr) => acc + pr.collectedAmount, 0);
							total = relatedPaymentRequests.reduce((acc, pr) => acc + pr.totalAmount, 0);
						}
					}

					const progress = Number(parseFloat(`${((collected ?? 0) / (total || 1)) * 100}`).toFixed(2));

					return (
						<div className="w-full flex items-center gap-4">
							<Text variants={TextVariantsEnum.Caption3}>{progress}%</Text>
							<LinearProgressBar progress={progress} />
						</div>
					);
				},
				dataKey: "progress"
			},
			{
				label: "",
				width: 60,
				Cell: ({ rowData }: { rowData: PaymentRequestModel }) => <MenuDots options={getOptions(rowData)} />,
				dataKey: "options",
				loaderType: TableColumnLoaderType.menuDots
			}
		],
		[getOptions, t]
	);

	// const filterBlock = useMemo(
	// 	() => (
	// 		<Button size={ButtonSizesEnum.SM} onClick={() => setCreatePaymentDialog(true)}>
	// 			Create payment
	// 		</Button>
	// 	),
	// 	[setCreatePaymentDialog]
	// );

	return (
		<>
			<Table
				// filterBlock={filterBlock}
				columns={tableColumns}
				data={paymentRequests}
				loading={loadingPaymentRequests}
				loadingMore={loadingPaymentRequests && !!nextListingPaymentRequestsToken}
				label={t("payment:payment_requests")}
				tableSpacingClasses="px-4"
				rowHeight={80}
				onLoadMore={handleLoadMore}
			/>
			{seeTeamsModal && (
				<Suspense>
					<ChooseTeamModal infoView reqId={seeTeamsModal} onClose={() => setSeeTeamsModal(null)} />
				</Suspense>
			)}
			{transactionDetailsOpen && (
				<Suspense>
					<TransactionDetailsDialog
						reqId={transactionDetailsOpen}
						handleClose={() => setTransactionDetailsOpen(null)}
					/>
				</Suspense>
			)}
		</>
	);
};

export default PaymentRequestsTable;
