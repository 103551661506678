import type { FC } from "react";
import React from "react";

import clsx from "clsx";

import ExclamationIcon from "assets/icons/filled/exclamation-mark.svg?react";

import Text from "../Text";
import { ErrorMessageTypes, TextVariantsEnum } from "../types";

export interface ErrorMessageProps {
	text?: string;
	noIcon?: boolean;
	className?: string;
	type?: ErrorMessageTypes;
}

const ErrorMessage: FC<ErrorMessageProps> = ({
	text,
	noIcon = false,
	className = "",
	type = ErrorMessageTypes.DEFAULT
}) => (
	<div className={clsx("flex", `${type === ErrorMessageTypes.WARNING ? "items-start" : "items-center"}`, className)}>
		{!noIcon && type !== ErrorMessageTypes.HINT && (
			<div className="mr-1">
				<ExclamationIcon
					className={clsx(
						"w-4 h-4",
						type === ErrorMessageTypes.WARNING ? "svg-first-path:fill-yellow-500" : "svg-first-path:fill-red-500"
					)}
				/>
			</div>
		)}
		<Text
			variants={
				type === ErrorMessageTypes.WARNING
					? TextVariantsEnum.H7
					: type === ErrorMessageTypes.HINT
						? TextVariantsEnum.BodySmall
						: TextVariantsEnum.BodySmall
			}
			className={clsx(
				(type === ErrorMessageTypes.WARNING || type === ErrorMessageTypes.HINT) && "text-hint",
				type === ErrorMessageTypes.DEFAULT && "text-red-600"
			)}
		>
			{text}
		</Text>
	</div>
);

export default ErrorMessage;
