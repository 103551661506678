import React, { memo, useMemo } from "react";

import { Link } from "@mui/material";

import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import FootballIcon from "assets/icons/outlined/player.svg?react";
import StarIcon from "assets/icons/outlined/star.svg?react";
import { FootballCard, FootballCardType, Table } from "shared/Components";
import type { TableColumnType } from "shared/Components/Table/Components/SimpleTable";
import type { StatItemModel } from "shared/types";

import { ImageSizesEnum, Img, Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { convertToDecimal } from "utils/serviceUtils/helpers";

interface CompetitionStatisticTableProps {
	data: StatItemModel[];
	loading: boolean;
	pageSize?: number;
	competitionPageUrl?: string;
	competitionGroupPageUrl?: string;
	aggregatedByGroup?: boolean;
}

const CompetitionStatisticTable: React.FC<CompetitionStatisticTableProps> = memo(
	({ data, loading, pageSize, competitionPageUrl, competitionGroupPageUrl, aggregatedByGroup }) => {
		const { t } = useTranslation();

		const tableColumns: TableColumnType[] = useMemo(() => {
			const columns: TableColumnType[] = [
				{
					label: <FootballCard type={FootballCardType.YELLOW} />,
					width: 70,
					Cell: ({ rowData: { stats } }: { rowData: StatItemModel }) => stats?.yellowCardsReceived || 0,
					dataKey: "yellowCard"
				},
				{
					label: <FootballCard type={FootballCardType.RED} />,
					width: 70,
					Cell: ({ rowData: { stats } }: { rowData: StatItemModel }) => stats?.redCardsReceived || 0,
					dataKey: "redCard"
				},
				{
					label: "PL",
					width: 70,
					Cell: ({ rowData: { stats } }: { rowData: StatItemModel }) => stats?.matchesPlayed || 0,
					dataKey: "played"
				},
				{
					label: <FootballIcon className="w-4 h-4 svg-paths:stroke-gray-400" />,
					width: 70,
					Cell: ({ rowData: { stats } }: { rowData: StatItemModel }) => stats?.goalsScored || 0,
					dataKey: "goals"
				},
				{
					label: "G/M",
					width: 70,
					Cell: ({ rowData: { stats } }: { rowData: StatItemModel }) => convertToDecimal(stats?.goalsPerMatch || 0),
					dataKey: "goalsPerGame"
				},
				{
					label: <StarIcon className="w-4 h-4 svg-paths:fill-gray-400" />,
					width: 70,
					Cell: ({ rowData: { stats } }: { rowData: StatItemModel }) => stats?.starsReceived || 0,
					dataKey: "stars"
				}
			];

			if (aggregatedByGroup) {
				columns.unshift({
					label: <span className="px-4">{t("summary")}</span>,
					minWidth: 300,
					Cell: ({ rowData: { group } }: { rowData: StatItemModel }) => (
						<Link
							to={`${competitionGroupPageUrl}/${group!.id}`}
							component={RouterLink}
							className="no-underline w-full flex items-center px-4"
						>
							<div className="w-full">
								<Text variants={TextVariantsEnum.Caption3} className="truncate">
									{group?.name}
								</Text>
							</div>
						</Link>
					),
					dataKey: "name"
				});
			} else {
				columns.unshift({
					label: t("competitions"),
					minWidth: 300,
					Cell: ({ rowData: { competition } }: { rowData: StatItemModel }) => (
						<Link
							to={`${competitionPageUrl}/${competition!.id}`}
							component={RouterLink}
							className="no-underline w-full flex items-center"
						>
							<Img
								src={competition?.logo}
								alt={competition?.name}
								size={ImageSizesEnum.Small32}
								circle
								className="mr-4"
							/>
							<div className="w-8/12">
								<Text variants={TextVariantsEnum.Caption3} className="truncate">
									{competition?.name}
								</Text>
								{competition?.league?.name && (
									<Text variants={TextVariantsEnum.BodySmall} style={TextStylesEnum.Hint} className="truncate mt-0.5">
										{competition.league.name}
									</Text>
								)}
							</div>
						</Link>
					),
					dataKey: "name"
				});
			}

			return columns;
		}, [competitionPageUrl, competitionGroupPageUrl, aggregatedByGroup, t]);

		return <Table columns={tableColumns} loading={loading} data={data} pageSize={pageSize} />;
	}
);

export default CompetitionStatisticTable;
