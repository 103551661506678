import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import SearchIcon from "assets/icons/outlined/search.svg?react";

import { Chip } from "shared/Components";
import { ChipStyles } from "shared/Components/Chip";
import { useDebounce } from "shared/hooks";
import { Button, Input, Text } from "shared/uikit";
import AccordionBox from "shared/uikit/AccordionBox";

import { ButtonSizesEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

export interface FilterBlockOptionModel {
	label: string;
	value: any;
}

export interface FilterBlockModel {
	id: string;
	title: string;
	options: FilterBlockOptionModel[];
	maxVisibleCount?: number;
	onChangeSelected?: (vals: any[]) => void;
}

export interface FilterBlockMethods {
	getBlockId: () => string;
	getSelectedOptions: () => any[];
	clearFilter: () => void;
}

export interface FilterBlockProps {
	block: FilterBlockModel;
	index: number;
	defaultFilterValues?: any[];
}

const FilterBlock = forwardRef<FilterBlockMethods, FilterBlockProps>(
	({ block, index, defaultFilterValues = [] }, ref) => {
		const { t } = useTranslation();

		const [showAll, setShowAll] = useState(false);
		const [keyword, setKeyword] = useState("");
		const [selectedOptions, setSelectedOptions] = useState<any[]>(defaultFilterValues);
		const debouncedKeyword = useDebounce(keyword, 250);

		const maxVisibleCount = useMemo(() => block?.maxVisibleCount || 10, [block?.maxVisibleCount]);

		useEffect(() => {
			setShowAll(false);
		}, [debouncedKeyword]);

		const filteredOptions = useMemo(() => {
			if (debouncedKeyword.length) {
				const key = debouncedKeyword.toLowerCase();
				return block.options.filter(x => x.label.toLowerCase().includes(key));
			}

			return block.options;
		}, [block.options, debouncedKeyword]);

		const isOptionSelected = useCallback((val: any) => selectedOptions.some(x => x === val), [selectedOptions]);

		const toggleOption = useCallback(
			(option: FilterBlockOptionModel) => {
				const updated = [...selectedOptions];
				const index = selectedOptions.findIndex(x => x === option.value);
				if (index === -1) {
					updated.push(option.value);
				} else {
					updated.splice(index, 1);
				}
				setSelectedOptions(updated);
				block?.onChangeSelected && block?.onChangeSelected(updated);
			},
			[block, selectedOptions]
		);

		const toggleAll = () => {
			setShowAll(show => !show);
		};

		useImperativeHandle(ref, () => ({
			getBlockId: () => block.id,
			getSelectedOptions: () => selectedOptions,
			clearFilter: () => {
				setKeyword("");
				setSelectedOptions([]);
				setShowAll(false);
			}
		}));

		return (
			<AccordionBox
				summaryTitle={block.title}
				selectedValuesCount={selectedOptions.length}
				details={
					<div className="px-4 pb-4 flex flex-col gap-2">
						{block.options.length > 10 && (
							<Input
								size={ButtonSizesEnum.SM}
								LeftSideIcon={<SearchIcon />}
								placeholder={t("search")}
								value={keyword}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)}
							/>
						)}
						<div className="flex flex-wrap gap-2">
							{filteredOptions.slice(0, showAll ? filteredOptions.length : maxVisibleCount).map((opt, i) => (
								<div key={`filter-block-${index}-option-${i}`}>
									<Chip
										className="cursor-pointer"
										chipStyle={isOptionSelected(opt.value) ? ChipStyles.SUCCESS : ChipStyles.DEFAULT}
										onClick={() => toggleOption(opt)}
										text={
											<Text variants={TextVariantsEnum.Caption3} style={TextStylesEnum.Hint}>
												{opt.label}
											</Text>
										}
									/>
								</div>
							))}
						</div>
						{!showAll && filteredOptions.length > maxVisibleCount && (
							<Button
								className="w-fit"
								contentWrapperClassName="flex items-center gap-2 mx-auto"
								size={ButtonSizesEnum.SM}
								onClick={toggleAll}
							>
								{t("n_more", { count: filteredOptions.length - maxVisibleCount })}
							</Button>
						)}
					</div>
				}
			/>
		);
	}
);

export default FilterBlock;
