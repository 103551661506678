import { orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

import colors from "./colors";

declare module "@mui/material/styles" {
	interface Theme {
		status: {
			danger: string;
		};
	}
	// allow configuration using `createTheme`
	interface ThemeOptions {
		status?: {
			danger?: string;
		};
	}
}

const theme = createTheme({
	status: {
		danger: orange[500]
	},
	palette: {
		primary: colors.palette.colors.primary
	}
});

export default theme;
