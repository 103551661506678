import type { FC } from "react";
import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";

import { ButtonBase } from "@mui/material";

import { differenceWith, pick } from "ramda";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm, useWatch } from "react-hook-form";

import { useTranslation } from "react-i18next";

import PlusIcon from "assets/icons/filled/add.svg?react";

import { Chip, ErrorHint, FormBlock } from "shared/Components";
import { useCompetition } from "shared/hooks";
import type { CompetitionModel, OrganizationModel } from "shared/types";
import { CompetitionTypes } from "shared/types";
import { ImageSizesEnum, Img, Input, Modal, Text } from "shared/uikit";
import { ModalSizesEnum } from "shared/uikit/BaseDialog";
import { TextVariantsEnum } from "shared/uikit/types";

import { getDateTime } from "utils/getDateTime";
import { phoneValidator, validateURL } from "utils/serviceUtils/validators";

const AddRefereeOrgModal = lazy(() => import("./AddRefereeOrgModal"));

interface CompetitionSettingsForm
	extends Pick<
		CompetitionModel,
		"id" | "name" | "phone" | "website" | "description" | "type" | "startsOn" | "endsOn"
	> {}

interface CompetitionSettingsModalProps {
	competitionId?: string;
	overview?: CompetitionModel;
}

const CompetitionSettingsModal: FC<CompetitionSettingsModalProps> = ({ competitionId, overview }) => {
	const { t } = useTranslation();

	const {
		setCompetitionSettingsModal,
		getCompetitionReferees,
		updateCompetitionSettings,
		getOverview,
		getData: getCompetitionData
	} = useCompetition();
	const { loadingReferees, loadingOverview, submitting, overview: competitionOverview } = getCompetitionData();

	const [competitionRefereesOrgs, setCompetitionRefereesOrgs] = useState<OrganizationModel[]>([]);
	const [refereesOrgs, setRefereesOrgs] = useState<OrganizationModel[]>([]);
	const [showAddOrgs, setShowAddOrgs] = useState(false);
	const [customIsDirty, setCustomIsDirty] = useState(false);

	const {
		control,
		formState: { isDirty, isValid, errors },
		reset,
		handleSubmit
	} = useForm<CompetitionSettingsForm>({
		mode: "onChange",
		defaultValues: overview
			? {
					...pick(["id", "name", "phone", "website", "description", "type", "startsOn", "endsOn"], overview)
				}
			: undefined
	});

	const [startsOn, endsOn, type] = useWatch({
		control,
		name: ["startsOn", "endsOn", "type"]
	});

	const invalidDates = useMemo(() => {
		const end = endsOn ? getDateTime(endsOn) : null;
		const start = startsOn ? getDateTime(startsOn) : null;

		return !start || !start.isValid || !end || !end.isValid || end < start || start.year < new Date().getFullYear() - 5;
	}, [startsOn, endsOn]);

	useEffect(() => {
		const fetchData = async (competitionId: string) => {
			const overview = await getOverview(competitionId, true);
			if (overview) {
				reset({
					...pick(["id", "name", "phone", "website", "description", "type", "startsOn", "endsOn"], overview)
				});
			}
		};

		if (!overview && competitionId) {
			fetchData(competitionId);
		}

		if (overview?.id || competitionId) {
			getCompetitionReferees(overview?.id || competitionId || "").then(refereesOrgs => {
				setCompetitionRefereesOrgs(refereesOrgs);
				setRefereesOrgs(refereesOrgs);
			});
		}
	}, [reset, overview, competitionId, getOverview, getCompetitionReferees]);

	useEffect(() => {
		setCustomIsDirty(
			refereesOrgs.length !== competitionRefereesOrgs.length ||
				!!differenceWith((x, y) => x.id === y.id, refereesOrgs, competitionRefereesOrgs)?.length
		);
	}, [refereesOrgs, competitionRefereesOrgs]);

	const onSubmit: SubmitHandler<CompetitionSettingsForm> = useCallback(
		async data => {
			const addedOrgs = refereesOrgs
				.filter(x => !competitionRefereesOrgs.some(org => org.id === x.id))
				.map(x => ({ id: x.id }));
			const removedOrgs = competitionRefereesOrgs
				.filter(x => !refereesOrgs.some(org => org.id === x.id))
				.map(x => ({ id: x.id }));

			const initData = overview || competitionOverview;

			await updateCompetitionSettings({
				competitionId: overview?.id || competitionId || competitionOverview?.id || "",
				name: initData?.name !== data.name ? data.name : undefined,
				startsOn: initData?.startsOn !== data.startsOn ? getDateTime(data.startsOn!).toISODate()! : undefined,
				endsOn: initData?.endsOn !== data.endsOn ? getDateTime(data.endsOn!).toISODate()! : undefined,
				phone: initData?.phone !== data.phone ? data.phone : undefined,
				website: initData?.website !== data.website ? data.website : undefined,
				description: initData?.description !== data.description ? data.description : undefined,
				refereeOrganizations: {
					add: addedOrgs,
					remove: removedOrgs
				}
			});

			setCompetitionSettingsModal();
		},
		[
			refereesOrgs,
			competitionRefereesOrgs,
			overview,
			competitionOverview,
			updateCompetitionSettings,
			competitionId,
			setCompetitionSettingsModal
		]
	);

	const maxStartDate = useMemo(() => (endsOn ? getDateTime(endsOn).minus({ day: 1 }).toJSDate() : undefined), [endsOn]);

	const minEndDate = useMemo(
		() => (startsOn ? getDateTime(startsOn).plus({ day: 1 }).toJSDate() : undefined),
		[startsOn]
	);

	const handleCloseAddRefereeOrgModal = useCallback((refereeOrgs?: OrganizationModel[]) => {
		if (refereeOrgs?.length) {
			setRefereesOrgs(refereeOrgs);
		}
		setShowAddOrgs(false);
	}, []);

	const toggleOrg = (org: OrganizationModel) => {
		const newSelectedOrgs = [...refereesOrgs];
		const index = refereesOrgs.findIndex(x => x.id === org.id);
		if (index > -1) {
			newSelectedOrgs.splice(index, 1);
		} else {
			newSelectedOrgs.push(org);
		}
		setRefereesOrgs(newSelectedOrgs);
	};

	return (
		<>
			<Modal
				open={!showAddOrgs}
				onClose={() => setCompetitionSettingsModal()}
				title="Competition settings"
				sizes={ModalSizesEnum.SMALL}
				loading={loadingReferees || loadingOverview}
				loadingText={"Loading competition data..."}
				actions={[
					{
						text: "Save changes",
						onClick: handleSubmit(onSubmit),
						disabled: submitting || (!customIsDirty && !isDirty) || !isValid || invalidDates,
						loading: submitting
					}
				]}
			>
				<div className="p-6 h-full flex gap-2 justify-between flex-col lg:gap-6 lg:flex-row">
					<div className="flex flex-col gap-4 flex-1">
						<Text variants={TextVariantsEnum.H7}>Competition details</Text>
						<Controller
							name="name"
							control={control}
							rules={{
								required: "Name is required",
								minLength: {
									value: 3,
									message: "Name must be at least 3 characters"
								},
								maxLength: {
									value: 50,
									message: "Name must be less than 50 characters"
								}
							}}
							render={({ field: { onChange, value } }) => (
								<FormBlock labelClassname="mb-2" label="Competition name" textVariant={TextVariantsEnum.Caption2}>
									<Input
										placeholder="Competition name"
										onChange={onChange}
										value={value}
										error={!!errors.name?.message}
										errorText={errors.name?.message}
									/>
								</FormBlock>
							)}
						/>
						{/*<FormBlock labelClassname="mb-2" label="Abbreviated name" textVariant={TextVariantsEnum.Caption2}>*/}
						{/*	<Input disabled placeholder="Abbreviated name" onChange={() => {}} value="" />*/}
						{/*</FormBlock>*/}
						{/*<Controller*/}
						{/*	name="type"*/}
						{/*	control={control}*/}
						{/*	render={({ field: { value } }) => (*/}
						{/*		<FormBlock label="Competition type">*/}
						{/*			/!* WE DON'T HAVE CHANGE TYPE FOR NOW *!/*/}
						{/*			<RadioGroup name="type-group-radio-block" value={value} onChange={() => {}} className="flex flex-row">*/}
						{/*				<RadioBtn*/}
						{/*					value={CompetitionTypes.SEASON}*/}
						{/*					label={renderCompetitionType(CompetitionTypes.SEASON, t)}*/}
						{/*					className="mr-6"*/}
						{/*				/>*/}
						{/*				<RadioBtn*/}
						{/*					value={CompetitionTypes.TOURNAMENT}*/}
						{/*					label={renderCompetitionType(CompetitionTypes.TOURNAMENT, t)}*/}
						{/*				/>*/}
						{/*			</RadioGroup>*/}
						{/*		</FormBlock>*/}
						{/*	)}*/}
						{/*/>*/}
						<div className="flex flex-wrap gap-2">
							<Controller
								name="startsOn"
								control={control}
								rules={{
									required: true,
									validate: data => {
										const luxonDate = data ? getDateTime(data) : null;
										return luxonDate
											? !luxonDate.isValid
												? t("error:incorrect", { field: t("date.start_date").toLowerCase() })
												: true
											: true;
									}
								}}
								render={({ field: { onChange, value } }) => (
									<FormBlock
										labelClassname="mb-2"
										containerClassName="flex-1"
										label="Start date"
										textVariant={TextVariantsEnum.Caption2}
									>
										<Input
											placeholder="Start date"
											onChange={onChange}
											value={value}
											calendar
											maxDate={maxStartDate}
											error={!!errors.startsOn?.message || invalidDates}
										/>
									</FormBlock>
								)}
							/>
							<Controller
								name="endsOn"
								control={control}
								rules={{
									required: true,
									validate: data => {
										const luxonDate = data ? getDateTime(data) : null;
										return luxonDate
											? !luxonDate.isValid
												? t("error:incorrect", { field: t("date.end_date").toLowerCase() })
												: true
											: true;
									}
								}}
								render={({ field: { onChange, value } }) => (
									<FormBlock
										labelClassname="mb-2"
										containerClassName="flex-1"
										label="End date"
										textVariant={TextVariantsEnum.Caption2}
									>
										<Input
											placeholder="End date"
											onChange={onChange}
											value={value}
											calendar
											minDate={minEndDate}
											error={!!errors.endsOn?.message || invalidDates}
										/>
									</FormBlock>
								)}
							/>
						</div>
						{invalidDates && <ErrorHint text={"Invalid dates"} />}

						<div className="flex flex-col gap-4 mt-2">
							<Controller
								name="phone"
								control={control}
								rules={{
									validate: data => {
										const isValid = data ? phoneValidator(data) : true;
										return isValid || t("error:incorrect", { field: t("phone_number").toLowerCase() });
									}
								}}
								render={({ field: { value, onChange } }) => (
									<FormBlock label={t("phone")} textVariant={TextVariantsEnum.Caption2} labelClassname="mb-2">
										<Input
											mask="+999 9999 99999"
											placeholder="+356 0000 0000"
											onChange={onChange}
											value={value}
											error={!!errors?.phone?.message}
											errorText={errors?.phone?.message}
											id="enter-phone-number"
										/>
									</FormBlock>
								)}
							/>
							<Controller
								name="website"
								control={control}
								rules={{
									validate: data => {
										const isValid = data ? validateURL(data) : true;
										return isValid || t("error:incorrect", { field: t("website") });
									}
								}}
								render={({ field: { value, onChange } }) => (
									<FormBlock label="Website" textVariant={TextVariantsEnum.Caption2} labelClassname="mb-2">
										<Input
											placeholder="www.yourwebsite.com"
											onChange={onChange}
											value={value}
											error={!!errors?.website?.message}
											errorText={errors?.website?.message}
											id="enter-website"
										/>
									</FormBlock>
								)}
							/>
							{type === CompetitionTypes.TOURNAMENT && (
								<Controller
									name="description"
									control={control}
									render={({ field: { value, onChange } }) => (
										<FormBlock label={t("description")} textVariant={TextVariantsEnum.Caption2} labelClassname="mb-2">
											<Input onChange={onChange} value={value} minRows={5} multipleRows={8} placeholder={t("about")} />
										</FormBlock>
									)}
								/>
							)}
						</div>

						<FormBlock label="Official admins" labelClassname="my-2" textVariant={TextVariantsEnum.H7}>
							<div className="flex items-center flex-wrap gap-2">
								{refereesOrgs.map(org => (
									<Chip
										key={org.id}
										secondary
										className="inline-flex h-8 rounded-xl"
										icon={
											<Img src={org.logo} alt={org.name} circle size={ImageSizesEnum.Small24} className="block mr-1" />
										}
										text={org.name}
										onCollapse={() => toggleOrg(org)}
									/>
								))}

								<ButtonBase
									className="w-6 h-6 rounded-full inline-flex align-baseline"
									onClick={() => setShowAddOrgs(true)}
								>
									<PlusIcon className="svg-paths:stroke-white svg-paths:fill-gray-400" />
								</ButtonBase>
							</div>
						</FormBlock>
					</div>
					{/* TODO: uncomment when info is available */}
					{/* <DividerWithText vertical /> 
					 <div className="flex flex-col gap-4 flex-1">
						<Text variants={TextVariantsEnum.H7}>Additional settings</Text>
						<SettingsSwitchOption
							title="Enforce T-shirt number for players"
							description="Description text"
							onChange={() => {}}
							value={false}
						/>
						<SettingsSwitchOption
							title="Enforce digital checkin"
							description="Description text"
							onChange={() => {}}
							value={false}
							subOptions={[
								{
									title: "Enforce age verification",
									onChange: () => {},
									value: false
								},
								{
									title: "Enforce payment status",
									onChange: () => {},
									value: false
								},
								{
									title: "Enforce photo verification",
									onChange: () => {},
									value: false
								}
							]}
						/>
						<SettingsSwitchOption
							title="Allow coaching multiple teams"
							description="Description text"
							onChange={() => {}}
							value={false}
						/>
						<SettingsSwitchOption
							title="Enable Roster freeze"
							description="Description text"
							onChange={() => {}}
							value={false}
						/>
						<FormBlock label="Centers for each">
							<RadioGroup name="type-group-radio-block" value={""} onChange={() => {}} className="flex flex-row">
								<RadioBtn value={CompetitionTypes.SEASON} label="Gender, Age, Level, Group" className="mr-4" />
								<RadioBtn value={CompetitionTypes.TOURNAMENT} label="Gender, Age, Level" />
							</RadioGroup>
						</FormBlock>
					</div> */}
					{/* <DividerWithText vertical /> */}
					{/* <div className="flex flex-col gap-4 flex-1"> */}
					{/* <Text variants={TextVariantsEnum.H7}>Admins</Text>
						<FormBlock label="Schedule admins" labelClassname="mb-2" textVariant={TextVariantsEnum.H7}>
							<Chip
								icon={<Img src={""} alt={"John Carson"} size={ImageSizesEnum.Small16} circle className="block mr-1" />}
								secondary
								className="inline-flex mr-2 mb-2 h-8 rounded-xl"
								text="John Carson"
								onCollapse={() => {}}
							/>
							<ButtonBase className="w-6 h-6 rounded-full inline-flex align-middle">
								<IconPlus className="svg-paths:stroke-white svg-paths:fill-gray-400" />
							</ButtonBase>
						</FormBlock> */}
					{/* <FormBlock label="Official admins" labelClassname="mb-2" textVariant={TextVariantsEnum.H7}>
							{refereesOrgs.map(org => (
								<Chip
									key={org.id}
									secondary
									className="inline-flex mr-2 mb-2 h-8 rounded-xl"
									icon={
										<Img src={org.logo} alt={org.name} circle size={ImageSizesEnum.Small24} className="block mr-1" />
									}
									text={org.name}
									onCollapse={() => toggleOrg(org)}
								/>
							))}
							<ButtonBase
								className="w-6 h-6 rounded-full inline-flex align-baseline"
								onClick={() => setShowAddOrgs(true)}
							>
								<IconPlus className="svg-paths:stroke-white svg-paths:fill-gray-400" />
							</ButtonBase>
						</FormBlock> */}
					{/* <FormBlock label="Governing bodies" labelClassname="mb-2" textVariant={TextVariantsEnum.H7}>
							<Chip text="MYSA" secondary className="inline-flex mr-2 mb-2 h-8 rounded-xl" onCollapse={() => {}} />
							<ButtonBase className="w-6 h-6 rounded-full inline-flex align-middle">
								<IconPlus className="svg-paths:stroke-white svg-paths:fill-gray-400" />
							</ButtonBase>
						</FormBlock> */}
					{/* </div> */}
					{/* <DividerWithText vertical /> */}
					{/* <div className="flex flex-col gap-4 flex-1" /> */}
				</div>
			</Modal>
			{showAddOrgs && (
				<Suspense>
					<AddRefereeOrgModal
						competitionId={overview?.id || competitionId || ""}
						refereeOrgs={refereesOrgs}
						onClose={handleCloseAddRefereeOrgModal}
					/>
				</Suspense>
			)}
		</>
	);
};

export default CompetitionSettingsModal;
