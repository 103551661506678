import type { FC } from "react";
import React from "react";

import SkeletonColumnLoader from "./Column";

import type { SkeletonLoaderProps } from "./index";
import { Column } from "../SimpleTable/style";

const SkeletonRowLoader: FC<Pick<SkeletonLoaderProps, "rowHeight" | "columnList" | "columnWidth">> = ({
	rowHeight,
	columnList,
	columnWidth
}) => (
	<div className="w-full flex" style={{ height: rowHeight }}>
		{columnList.map((column, columnIndex) => (
			<Column
				key={columnIndex}
				style={{ width: columnWidth(columnIndex) }}
				className={`${column?.alignment ? `alignment_${column.alignment}` : ""}`}
			>
				{column?.loaderTemplate ? <>{column.loaderTemplate}</> : <SkeletonColumnLoader type={column?.loaderType} />}
			</Column>
		))}
	</div>
);

export default SkeletonRowLoader;
