import type { FC } from "react";
import React, { useCallback } from "react";

import { CardWrapper } from "shared/Components";

import type { PostModel } from "shared/types";

import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

export enum PostActions {
	REMOVE = "REMOVE",
	REPORT = "REPORT",
	REMOVE_COMMENT = "REMOVE_COMMENT",
	UPDATE_POST = "UPDATE_POST"
}

interface PostProps {
	data: PostModel;
	onPostAction?: (params: { postId: string; action: PostActions; data?: PostModel }) => void;
}

const Post: FC<PostProps> = ({ data, onPostAction }) => {
	const handlePostAction = useCallback(
		(params: { postId: string; action: PostActions; data?: PostModel }) => {
			onPostAction && onPostAction(params);
		},
		[onPostAction]
	);

	return (
		<CardWrapper noPadding>
			<div className="p-4">
				<Header post={data} className="mb-4" onPostAction={handlePostAction} />
				<Content content={data.body?.markdown} postId={data.id} attachments={data.attachments || []} />
			</div>
			<Footer
				postId={data.id}
				creator={data.creator!.name!}
				reactionsInfo={data.reaction}
				commentCount={data?.commentCount}
			/>
		</CardWrapper>
	);
};

export default Post;
