import React, { Suspense, lazy } from "react";

import config from "config/appConfig";

import { Route } from "react-router-dom";

import { getPaths } from "core/getPaths";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const CompetitionGroupPageLayout = lazy(
	() => import("modules/CompetitionGroup/View/layouts/CompetitionGroupPageLayout")
);

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));

const CompetitionGroupOverviewPage = lazy(() => import("./Pages/CompetitionGroup/Overview"));
const CompetitionGroupPlayersPage = lazy(() => import("./Pages/CompetitionGroup/Players"));
const CompetitionGroupStandingsPage = lazy(() => import("./Pages/CompetitionGroup/Standings"));
const CompetitionGroupStatsDetailedPage = lazy(() => import("./Pages/CompetitionGroup/Stats/detailed"));
const CompetitionGroupStatsPage = lazy(() => import("./Pages/CompetitionGroup/Stats/index"));
const CompetitionGroupTeamsPage = lazy(() => import("./Pages/CompetitionGroup/Teams"));
const CompetitionGroupMatchesPage = lazy(() => import("./Pages/CompetitionGroup/Matches"));
const CompetitionGroupPlayoffsPage = lazy(() => import("./Pages/CompetitionGroup/Playoffs"));

import { routes } from "./constants";

const { CORE_URLS } = config;
const { competition } = getPaths();

class CompetitionApp implements IApp {
	getRoutes = () => [
		<Route path={routes.group.getPath()} key="competition-group-routes">
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key={routes.group.getElementKey()}
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={`${routes.group.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<CompetitionGroupPageLayout homePageUrl={CORE_URLS.HOME} competitionPageUrl={competition.getPath()} />
					</Suspense>
				}
			>
				<Route
					key={`${routes.group.getElementKey()}/id`}
					path={`${routes.group.getPath()}/:groupId`}
					element={
						<Suspense>
							<CompetitionGroupOverviewPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.group.getElementKey()}/id/teams`}
					path={`${routes.group.getPath()}/:groupId/teams`}
					element={
						<Suspense>
							<CompetitionGroupTeamsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.group.getElementKey()}/id/matches`}
					path={`${routes.group.getPath()}/:groupId/matches`}
					element={
						<Suspense>
							<CompetitionGroupMatchesPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.group.getElementKey()}/id/players`}
					path={`${routes.group.getPath()}/:groupId/players`}
					element={
						<Suspense>
							<CompetitionGroupPlayersPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.group.getElementKey()}/id/standings`}
					path={`${routes.group.getPath()}/:groupId/standings`}
					element={
						<Suspense>
							<CompetitionGroupStandingsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.group.getElementKey()}/id/stats`}
					path={`${routes.group.getPath()}/:groupId/stats`}
					element={
						<Suspense>
							<CompetitionGroupStatsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.group.getElementKey()}/id/playoffs`}
					path={`${routes.group.getPath()}/:groupId/playoffs`}
					element={
						<Suspense>
							<CompetitionGroupPlayoffsPage />
						</Suspense>
					}
				/>
			</Route>
			<Route
				key={`${routes.group.getElementKey()}/id/stats/entity/detail`}
				path={`${routes.group.getPath()}/:groupId/stats/:entity/:stat`}
				element={
					<Suspense>
						<CompetitionGroupStatsDetailedPage />
					</Suspense>
				}
			/>
		</Route>
	];
}

export default CompetitionApp;
