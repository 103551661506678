import { Suspense, lazy } from "react";

import config from "config/appConfig";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));

const LeagueLayout = lazy(() => import("modules/League/View/layouts/LeagueLayout"));
const LeaguePageLayout = lazy(() => import("modules/League/View/layouts/LeaguePageLayout"));

const FindSeasonPage = lazy(() => import("./Pages/FindSeason"));
const InvitePeoplePage = lazy(() => import("./Pages/InvitePeople"));
const LeagueAddressPage = lazy(() => import("./Pages/LeagueAddress"));
const LeagueNamePage = lazy(() => import("./Pages/LeagueName"));
const LeagueStoryPage = lazy(() => import("./Pages/LeagueStory"));
const SeasonCreatePage = lazy(() => import("./Pages/Season/Create"));
const SetupLeagueCategoryPage = lazy(() => import("./Pages/Setup/Category"));
const SetupLeagueGenderPage = lazy(() => import("./Pages/Setup/Gender"));
const SetupLeagueLevelPage = lazy(() => import("./Pages/Setup/Level"));
const UploadLeagueLogoPage = lazy(() => import("./Pages/UploadLeagueLogo"));

const LeagueOverviewPage = lazy(() => import("./Pages/League/Overview"));
const LeagueStructurePage = lazy(() => import("./Pages/League/Structure"));
const LeagueStatsPage = lazy(() => import("./Pages/League/Stats/index"));
const LeagueDetailedStatsPage = lazy(() => import("./Pages/League/Stats/detailed"));
const LeagueFinancePage = lazy(() => import("./Pages/League/Finance"));
const LeagueStorePage = lazy(() => import("./Pages/League/Store"));

import LeagueCompetitionsPage from "./Pages/League/Competitions";
import { routes } from "./constants";

const { CORE_URLS, GLOBAL_CONSTANTS } = config;

class LeagueApp implements IApp {
	getRoutes = () => [
		<Route path={routes.league.getPath()} key="league-routes">
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key="league-form-routes"
			element={
				<Suspense>
					<LeagueLayout />
				</Suspense>
			}
		>
			<Route
				key={routes.league.create.name.getElementKey()}
				path={routes.league.create.name.getPath()}
				element={
					<Suspense>
						<LeagueNamePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.league.create.address.getElementKey()}
				path={routes.league.create.address.getPath()}
				element={
					<Suspense>
						<LeagueAddressPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.league.create.logo.getElementKey()}
				path={routes.league.create.logo.getPath()}
				element={
					<Suspense>
						<UploadLeagueLogoPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.league.create.story.getElementKey()}
				path={routes.league.create.story.getPath()}
				element={
					<Suspense>
						<LeagueStoryPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.league.invite.people.getElementKey()}
				path={routes.league.invite.people.getPath()}
				element={
					<Suspense>
						<InvitePeoplePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.league.setup.level.getElementKey()}
				path={routes.league.setup.level.getPath()}
				element={
					<Suspense>
						<SetupLeagueLevelPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.league.setup.category.getElementKey()}
				path={routes.league.setup.category.getPath()}
				element={
					<Suspense>
						<SetupLeagueCategoryPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.league.setup.gender.getElementKey()}
				path={routes.league.setup.gender.getPath()}
				element={
					<Suspense>
						<SetupLeagueGenderPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.league.season.create.getElementKey()}
				path={routes.league.season.create.getPath()}
				element={
					<Suspense>
						<SeasonCreatePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.league.season.find.getElementKey()}
				path={routes.league.season.find.getPath()}
				element={
					<Suspense>
						<FindSeasonPage />
					</Suspense>
				}
			/>
		</Route>,
		<Route
			key={routes.league.getElementKey()}
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={`${routes.league.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<LeaguePageLayout homePageUrl={CORE_URLS.HOME} />
					</Suspense>
				}
			>
				<Route
					key={`${routes.league.getElementKey()}/id`}
					path={`${routes.league.getPath()}/:id`}
					element={
						<Suspense>
							<LeagueOverviewPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.league.getElementKey()}/id/structure`}
					path={`${routes.league.getPath()}/:id/structure`}
					element={
						<Suspense>
							<LeagueStructurePage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.league.getElementKey()}/id/competitions`}
					path={`${routes.league.getPath()}/:id/competitions`}
					element={
						<Suspense>
							<LeagueCompetitionsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.league.getElementKey()}/id/stats`}
					path={`${routes.league.getPath()}/:id/stats`}
					element={
						<Suspense>
							<LeagueStatsPage />
						</Suspense>
					}
				/>
				{GLOBAL_CONSTANTS.ENABLE_PAYMENT_FUNCTIONALITY && (
					<Route
						key={`${routes.league.getElementKey()}/id/finance`}
						path={`${routes.league.getPath()}/:id/finance`}
						element={
							<Suspense>
								<LeagueFinancePage />
							</Suspense>
						}
					/>
				)}
				<Route
					key={`${routes.league.getElementKey()}/id/store`}
					path={`${routes.league.getPath()}/:id/store`}
					element={
						<Suspense>
							<LeagueStorePage />
						</Suspense>
					}
				/>
			</Route>
			<Route
				key={`${routes.league.getElementKey()}/id/stats/entity/detail`}
				path={`${routes.league.getPath()}/:id/stats/:entity/:stat`}
				element={
					<Suspense>
						<LeagueDetailedStatsPage />
					</Suspense>
				}
			/>
		</Route>
	];
}

export default LeagueApp;
