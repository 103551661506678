import React, { useCallback, useEffect, useState } from "react";

import clsx from "clsx";

import AddIcon from "assets/icons/filled/add.svg?react";
import MinusIcon from "assets/icons/filled/remove.svg?react";

import { Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

interface CounterProps {
	val?: number;
	disableLess?: boolean;
	disableMore?: boolean;
	onAdd: () => void;
	onRemove: () => void;
}

const Counter: React.FC<CounterProps> = ({ val, disableLess, disableMore, onAdd, onRemove }) => {
	const [value, setValue] = useState(val || 0);

	useEffect(() => {
		setValue(val || 0);
	}, [val]);

	const handleRemove = useCallback(() => {
		if (!disableLess) {
			setValue(val => val - 1);
			onRemove();
		}
	}, [disableLess, onRemove]);

	const handleAdd = useCallback(() => {
		if (!disableMore) {
			setValue(val => val + 1);
			onAdd();
		}
	}, [disableMore, onAdd]);

	return (
		<div className="flex items-center">
			<div
				id="minus-team"
				className={`${value > 0 ? "cursor-pointer" : "cursor-not-allowed"}`}
				onClick={() => value > 0 && handleRemove()}
			>
				<MinusIcon
					className={clsx(
						"w-5 h-5",
						value > 0 && !disableLess
							? "svg-first-path:stroke-gray-500 svg-first-path:fill-gray-500"
							: "svg-first-path:stroke-gray-200 svg-first-path:fill-gray-200"
					)}
				/>
			</div>
			<div className="w-[33px] select-none flex justify-center">
				<Text variants={TextVariantsEnum.BodyMedium} style={TextStylesEnum.Default}>
					{value}
				</Text>
			</div>
			<div id="add-team" className="cursor-pointer" onClick={handleAdd}>
				<AddIcon
					className={clsx(
						"w-5 h-5",
						!disableMore
							? "svg-first-path:stroke-gray-500 svg-first-path:fill-gray-500"
							: "svg-first-path:stroke-gray-200 svg-first-path:fill-gray-200"
					)}
				/>
			</div>
		</div>
	);
};

export default React.memo(Counter);
