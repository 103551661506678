import { styled } from "@mui/material";

export const ButtonGroupWrapper = styled("div")`
	&.vertical button {
		&:first-of-type {
			border-bottom-right-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
		&:last-child {
			border-top-right-radius: 0 !important;
			border-top-left-radius: 0 !important;
		}
	}

	&.linear button {
		&:first-of-type {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
		&:last-child {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}
`;
