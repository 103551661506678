import React, { forwardRef, useMemo } from "react";

import type { ButtonProps } from "@mui/material";

import clsx from "clsx";

import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

import LoaderIcon from "assets/icons/animated/loader.svg?react";

import { LoaderWrapper, UIKitButton } from "./style";

import type { ButtonsPropsBase } from "../types";
import { ButtonSizesEnum } from "../types";

export interface ButtonPropsInterface extends ButtonsPropsBase {
	size?: ButtonSizesEnum;
	fixed?: boolean;
	whiteBg?: boolean;
	transparentBg?: boolean;
	children?: ButtonProps["children"];
	startIcon?: ButtonProps["startIcon"];
	endIcon?: ButtonProps["endIcon"];
	iconButton?: { icon: JSX.Element; circular?: boolean };
	className?: string;
	linkClassName?: string;
	dontFillSvg?: boolean;
	type?: ButtonProps["type"];
	active?: boolean;
	loading?: boolean;
	fillColor?: string;
	textColor?: string;
	id?: string;
	contentWrapperClassName?: string;
	noPadding?: boolean;

	// secondary only works for filled buttons as per design system
	secondary?: boolean;
	danger?: boolean;
	dangerText?: boolean;

	link?: string | LinkProps | {};
}

const Button = forwardRef<any, ButtonPropsInterface>(
	(
		{
			children,
			size = "small",
			variant = "filled",
			startIcon,
			endIcon,
			fixed,
			secondary,
			iconButton,
			onClick,
			noPadding,
			className,
			linkClassName,
			dontFillSvg,
			whiteBg,
			transparentBg,
			active,
			loading,
			fillColor,
			textColor,
			contentWrapperClassName,
			link,
			...props
		},
		ref
	) => {
		const variantClass = secondary ? `variant_${variant}-secondary` : `variant_${variant}`;

		const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			onClick && onClick(e);
		};

		const linkUrl = useMemo(
			() => (link ? (typeof link === "string" ? link : (link as LinkProps)?.to) : undefined),
			[link]
		);

		const Btn = iconButton ? (
			<UIKitButton
				{...props}
				ref={ref}
				onClick={handleClick}
				fillColor={fillColor}
				textColor={textColor}
				className={clsx(
					`icon-button_${variant} ${size} ${variantClass} ${className}`,
					size === ButtonSizesEnum.AUTO && "w-auto h-auto",
					size === ButtonSizesEnum.XS && "w-7 h-7",
					size === ButtonSizesEnum.UNDER_SM && "w-8 h-8",
					size === ButtonSizesEnum.SM && "w-9 h-9",
					size === ButtonSizesEnum.MD && "w-[42px] h-[42px]",
					size === ButtonSizesEnum.LG && "h-11 w-11",
					size === ButtonSizesEnum.XL && "h-[52px] w-[52px]",
					size === ButtonSizesEnum.XXL && "h-[64px] w-[64px]",
					iconButton.circular ? "rounded-full" : "rounded-lg",
					dontFillSvg && "dontFillSvg",
					whiteBg && "bg-white",
					transparentBg && "bg-transparent",
					active && "active"
				)}
			>
				{iconButton.icon}
			</UIKitButton>
		) : (
			<UIKitButton
				{...props}
				ref={ref}
				onClick={handleClick}
				fillColor={fillColor}
				textColor={textColor}
				className={clsx(
					`rounded-lg items-center ${variantClass} ${className}`,
					size === ButtonSizesEnum.AUTO && "w-auto h-auto",
					size === ButtonSizesEnum.XS && !noPadding && "py-2 px-4",
					size === ButtonSizesEnum.SM && !noPadding && "py-[12px] px-4",
					size === ButtonSizesEnum.MD && `${!noPadding && "py-[16px] px-6"} ${fixed && "w-48 px-0 text-center"}`,
					size === ButtonSizesEnum.LG && `w-full sm:w-[25rem] ${!noPadding && "py-[12px]"}`,
					dontFillSvg && "dontFillSvg",
					whiteBg && "bg-white",
					transparentBg && "bg-transparent",
					active && "active"
				)}
			>
				{startIcon && <span className="mr-2">{startIcon}</span>}
				<span
					className={clsx(
						"shrink-0 font-bold pointer-events-none",
						size === ButtonSizesEnum.XS && "text-xs",
						size === ButtonSizesEnum.SM && "text-sm leading-[16px]",
						size === ButtonSizesEnum.MD && "text-base leading-[16px]",
						size === ButtonSizesEnum.LG && "text-base text-center",
						size === ButtonSizesEnum.XL && "text-base text-center",
						size === ButtonSizesEnum.XXL && "text-base text-center",
						contentWrapperClassName
					)}
				>
					{loading ? (
						<LoaderWrapper className={variantClass}>
							<LoaderIcon className="w-4 h-4" />
						</LoaderWrapper>
					) : (
						children
					)}
				</span>
				{endIcon && <span className="ml-2">{endIcon}</span>}
			</UIKitButton>
		);

		if (linkUrl) {
			const linkProps = typeof link !== "string" ? { ...link } : {};
			return (
				<Link {...linkProps} to={linkUrl} className={clsx("no-underline cursor-pointer", linkClassName)}>
					{Btn}
				</Link>
			);
		}

		return Btn;
	}
);

export default Button;
