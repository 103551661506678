import React, { forwardRef, useEffect, useImperativeHandle } from "react";

import { Controller, useForm } from "react-hook-form";

import type { ClubModel } from "shared/types";
import { Input } from "shared/uikit";
import { phoneValidator, validateURL } from "utils/serviceUtils/validators";

export type StoryTemplateMethods = {
	getInfo: () => {
		data: Partial<ClubModel>;
		isValid: boolean;
	};
	triggerForm: () => void;
};

interface StoryTemplateProps {
	defaultValues?: Partial<ClubModel>;
	values?: Partial<ClubModel>;
	onValidateForm?: (valid: boolean) => void;
}

const StoryTemplate = forwardRef<StoryTemplateMethods, StoryTemplateProps>(
	({ defaultValues, values, onValidateForm }, ref) => {
		const { control, getValues, formState, trigger, reset } = useForm<Partial<ClubModel>>({
			defaultValues: defaultValues || {
				about: "",
				phone: "",
				website: ""
			}
		});

		const { isValid, errors } = formState;

		useEffect(() => {
			if (values) {
				reset(values);
			}
		}, [reset, values]);

		useEffect(() => {
			onValidateForm && onValidateForm(isValid);
		}, [isValid, onValidateForm]);

		useImperativeHandle(ref, () => ({
			getInfo: () => ({
				data: getValues(),
				isValid
			}),
			triggerForm: () => {
				trigger();
			}
		}));

		return (
			<>
				<div data-animate>
					<Controller
						name="about"
						control={control}
						rules={{
							maxLength: { value: 2000, message: "About section is too long" }
						}}
						render={({ field: { onChange, value } }) => (
							<Input
								headline="About"
								placeholder="Enter your story"
								onChange={onChange}
								value={value}
								multipleRows={4}
								error={!!errors?.about?.message}
								errorText={errors?.about?.message}
								id="enter-story"
							/>
						)}
					/>
				</div>
				<div className="mt-8 grid grid-cols-2 gap-6" data-animate>
					<div>
						<Controller
							name="phone"
							rules={{
								validate: data => {
									const isValid = data ? phoneValidator(data) : true;
									return isValid || "Incorrect phone number";
								}
							}}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input
									headline="Phone"
									mask="+999 9999 99999"
									placeholder="+356 0000 0000"
									onChange={onChange}
									value={value}
									error={!!errors?.phone?.message}
									id="enter-phone-number"
								/>
							)}
						/>
					</div>
					<div>
						<Controller
							name="website"
							rules={{
								validate: data => {
									const isValid = data ? data && validateURL(data) : true;
									return isValid || "Incorrect Website";
								}
							}}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Input
									headline="Website"
									placeholder="www.yourwebsite.com"
									onChange={onChange}
									value={value}
									error={!!errors?.website?.message}
									id="enter-website"
								/>
							)}
						/>
					</div>
				</div>
			</>
		);
	}
);

export default React.memo(StoryTemplate);
