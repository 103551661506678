import type { FC } from "react";
import React, { useCallback } from "react";

import type { OutlinedTextFieldProps } from "@mui/material";

import clsx from "clsx";

import LoaderIcon from "assets/icons/animated/loader.svg?react";

import ErrorMessage from "./ErrorMessage";
import { InfoText, UIKitInput } from "./style";

import type { BaseInputProps } from "./index";
import { ButtonSizesEnum, TextVariantsEnum } from "../types";

export interface InputProps extends BaseInputProps {
	onChange: OutlinedTextFieldProps["onChange"];
	handleKeyDown?: OutlinedTextFieldProps["onKeyDown"];
	tabIndex?: number;
	hideBorders?: boolean;
	minRows?: number;
	// Number of rows accepted
	multipleRows?: number;
	hintError?: boolean;
	inputRef?: OutlinedTextFieldProps["inputRef"];
	noPadding?: boolean;
}

const InputBase: FC<InputProps> = ({
	RightSideIcon,
	LeftSideIcon,
	loading,
	multipleRows,
	disabled,
	onBlur,
	info,
	tabIndex,
	error,
	errorText,
	className,
	size = ButtonSizesEnum.MD,
	hideBorders,
	minRows,
	hintError,
	warning,
	warningText,
	warningTextType,
	noPadding,
	handleKeyDown,
	inputRef,
	onChange,
	restrictInput,
	type,
	...props
}) => {
	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			if (restrictInput) {
				event.target.value = event.target.value.replace(/[^\p{L}\p{N}\p{Zs}\-.,()\p{M}]/gu, "").trimStart();
			}
			if (type && type === "number") {
				event.target.value = event.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
			}
			onChange && onChange(event);
		},
		[onChange, restrictInput, type]
	);

	const handleBlur = useCallback(() => {
		onBlur && onBlur();
	}, [onBlur]);

	return (
		<div className="relative">
			<UIKitInput
				autoComplete={props.disableAutoComplete ? "off" : "on"}
				multiline={!!multipleRows}
				maxRows={multipleRows}
				inputRef={inputRef}
				InputProps={{
					startAdornment: LeftSideIcon && <div className="mr-1">{LeftSideIcon}</div>,
					endAdornment: loading ? <LoaderIcon /> : RightSideIcon && <div className="ml-1">{RightSideIcon}</div>
				}}
				minRows={minRows}
				disabled={disabled}
				onBlur={handleBlur}
				onKeyDown={handleKeyDown}
				inputProps={{ tabIndex: tabIndex || 0 }}
				error={error}
				helperText={!hintError && !!error && errorText ? errorText : undefined}
				className={clsx(
					`${className}`,
					size === ButtonSizesEnum.SM && "h-10",
					hideBorders && "hide-borders",
					noPadding && "no-padding",
					!multipleRows && `size_${size}`
				)}
				onChange={handleChange}
				type={type}
				{...props}
			/>
			{info && (
				<InfoText variants={TextVariantsEnum.Caption3} className="absolute bottom-0 right-4 h-full flex items-center">
					{info}
				</InfoText>
			)}
			{hintError && !!error && errorText && <ErrorMessage className="mt-2" text={errorText} />}
			{!!warning && warningText && <ErrorMessage className="mt-2" type={warningTextType} text={warningText} />}
		</div>
	);
};

export default InputBase;
