import type { ReactNode } from "react";
import React, { memo } from "react";

import clsx from "clsx";

import { Link } from "react-router-dom";

import type { MenuDotsOption } from "shared/uikit";
import { MenuDots, SkeletonBase, Text } from "shared/uikit";
import { ButtonSizesEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

export interface InfoBoxProps {
	title: string;
	titleHref?: string;
	subtitle: string | ReactNode;
	subtitleHref?: string;
	infoExtra?: ReactNode;
	titleAction?: ReactNode;
	icon?: ReactNode;
	className?: string;
	loading?: boolean;
	fullScreenIcon?: boolean;
	link?: string;
	alignment?: "center" | "start" | "end";
	options?: MenuDotsOption[];
}

const InfoBox: React.FC<InfoBoxProps> = memo(
	({
		title,
		titleHref,
		subtitle,
		subtitleHref,
		titleAction,
		icon,
		className,
		loading,
		fullScreenIcon,
		link,
		alignment = "center",
		infoExtra,
		options
	}) => {
		if (loading) {
			return (
				<div className={clsx(className, "flex", `items-${alignment}`)}>
					<SkeletonBase variant="circular" width={40} height={40} className="mr-3" />
					<div className="w-9/12 shrink">
						<SkeletonBase width={100} height={16} />
						<SkeletonBase width={75} height={16} />
					</div>
				</div>
			);
		}

		const content = (
			<div className={clsx(className, "flex gap-4", `items-${alignment}`)}>
				<div
					className={clsx(
						"w-10 h-10 rounded-full overflow-hidden bg-lightGreen shrink-0 flex items-center justify-center",
						fullScreenIcon ? "p-0" : "p-2.5"
					)}
				>
					{icon}
				</div>
				<div className="flex-auto shrink truncate">
					<Text variants={TextVariantsEnum.Caption3} className="truncate max-w-full">
						{titleHref ? (
							<a href={titleHref} target="_blank" rel="noreferrer noopener">
								{title}
							</a>
						) : (
							title
						)}
					</Text>
					{typeof subtitle === "string" ? (
						<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.BodySmall} className="mt-1 truncate">
							{subtitleHref ? (
								<a href={subtitleHref} target="_blank" rel="noreferrer noopener">
									{subtitle}
								</a>
							) : (
								subtitle
							)}
						</Text>
					) : (
						subtitle
					)}
					{infoExtra}
				</div>
				{!!options?.length && (
					<div className="flex shrink-0">
						<MenuDots size={ButtonSizesEnum.XL} options={options} bordered horizontal />
					</div>
				)}
				{titleAction}
			</div>
		);

		return link ? <Link to={link}>{content}</Link> : content;
	}
);

export default InfoBox;
