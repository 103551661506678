import {
	AdminApp,
	AuthApp,
	ClubApp,
	CompetitionApp,
	CompetitionGroupApp,
	FacilityApp,
	InvitationApp,
	LeagueApp,
	MatchApp,
	MemberApp,
	MessagingApp,
	RefereeManagerApp,
	TeamApp
} from "apps";

import appConfig from "config/appConfig";

import type { IApp } from "shared/types";

export default (): { apps: IApp[] } => {
	const adminApp = new AdminApp();
	const authApp = new AuthApp();
	const leagueApp = new LeagueApp();
	const clubApp = new ClubApp();
	const facilityApp = new FacilityApp();
	const refereeManagerApp = new RefereeManagerApp();
	const memberApp = new MemberApp();
	const competitionApp = new CompetitionApp();
	const competitionGroupApp = new CompetitionGroupApp();
	const invitationApp = new InvitationApp();
	const teamApp = new TeamApp();
	const matchApp = new MatchApp();
	const messagingApp = new MessagingApp();

	const apps: any[] = [
		adminApp,
		authApp,
		invitationApp,
		memberApp,
		leagueApp,
		clubApp,
		facilityApp,
		refereeManagerApp,
		competitionApp,
		competitionGroupApp,
		teamApp,
		matchApp
	];

	if (appConfig.GLOBAL_CONSTANTS.ENABLE_MESSAGING_FUNCTIONALITY) {
		apps.push(messagingApp);
	}

	return {
		apps
	};
};
