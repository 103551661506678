import React, { memo, useCallback } from "react";

import { Scrollbars } from "react-custom-scrollbars-2";

import type { BaseCreateType } from "shared/types";
import { ImageSizesEnum, Img, SkeletonBase, Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

export interface CardProps {
	title: string;
	data: BaseCreateType[];
	onSelectItem?: (id: string) => void;
	loading?: boolean;
}

const Card: React.FC<CardProps> = memo(({ title, data, onSelectItem, loading }) => {
	const handleSelect = useCallback(
		(id: string) => {
			onSelectItem && onSelectItem(id);
		},
		[onSelectItem]
	);

	if (loading && !data?.length) {
		return (
			<div className="w-full h-[340px] bg-white rounded-xl py-4">
				<SkeletonBase width={100} height={28} fullScale className="mx-4" />
				<Scrollbars className="mt-3 w-full" autoHeight autoHeightMin={200} autoHeightMax={280} autoHide>
					{Array.from(Array(5)).map((item, index) => (
						<div key={index} className="flex items-center px-4 mb-4">
							<div className="mr-4 shrink-0">
								<SkeletonBase width={40} height={40} variant="circular" />
							</div>
							<div className="shrink w-10/12">
								<SkeletonBase width={"40%"} height={19} fullScale className="mb-1" />
								<SkeletonBase width={"70%"} height={15} fullScale />
							</div>
						</div>
					))}
				</Scrollbars>
			</div>
		);
	}

	return (
		<div className="w-full h-[340px] bg-white rounded-xl py-4">
			<Text variants={TextVariantsEnum.H4} className="px-4">
				{title}
			</Text>
			<Scrollbars className="mt-3 w-full" autoHeight autoHeightMin={200} autoHeightMax={280} autoHide>
				{data.map((item, idx) => (
					<div
						id={`${title.toLowerCase()}-item-${idx + 1}`}
						key={item.id}
						className="flex items-center px-4 mb-4 cursor-pointer"
						onClick={() => handleSelect(item.id)}
					>
						<div className="mr-4 shrink-0">
							<Img src={item.logo} alt={item.name} size={ImageSizesEnum.Small40} circle />
						</div>
						<div className="shrink w-10/12">
							<Text variants={TextVariantsEnum.H7} className="mb-1 truncate">
								{item.name}
							</Text>
							<Text variants={TextVariantsEnum.BodySmall} style={TextStylesEnum.Hint} className="truncate">
								{item?.location?.address}
							</Text>
						</div>
					</div>
				))}
			</Scrollbars>
		</div>
	);
});

export default Card;
