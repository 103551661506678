import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Accordion, AccordionDetails, Link } from "@mui/material";

import clsx from "clsx";

import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import GobletIcon from "assets/icons/filled/goblet.svg?react";
import ArrowIcon from "assets/icons/outlined/chevron-right.svg?react";

import { Table } from "shared/Components";
import type { TableColumnType } from "shared/Components/Table/Components/SimpleTable";
import { ColumnAlignment } from "shared/Components/Table/Components/SimpleTable";
import { useGroup } from "shared/hooks";
import type { CompetitionTeamsStandingModel, FullTeamModel } from "shared/types";
import { MatchResultEnum } from "shared/types";
import { Button, ImageSizesEnum, Img, SkeletonBase, Text } from "shared/uikit";

import { ButtonSizesEnum, ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import colors from "theme/colors";

import { StandingAccordionSummary } from "./styles";

interface Props {
	opened?: boolean;
	expandable?: boolean;
	hideColumns?: ("played" | "won" | "lost" | "draw" | "goals" | "goalsDifference" | "points" | "form" | "next")[];
	short?: boolean;
}

interface StandingProps extends Props {
	standing: CompetitionTeamsStandingModel;
	teamPageUrl: string;
	onExpand?: (key: string | boolean) => void;
	loading?: never;
	title?: string;
}

interface StandingLoadingProps extends Props {
	standing?: never;
	teamPageUrl?: never;
	onExpand?: never;
	loading?: boolean;
	title?: never;
}

const Standing: React.FC<StandingProps | StandingLoadingProps> = memo(
	({
		standing,
		opened,
		expandable = true,
		onExpand,
		loading = false,
		hideColumns = [],
		title,
		short = false,
		teamPageUrl
	}) => {
		const { t } = useTranslation();

		const { getGroupTeams, getData: getGroupData } = useGroup();
		const { loadingGroupTeams } = getGroupData();

		const [expanded, setExpanded] = useState(opened);
		const [teams, setTeams] = useState<FullTeamModel[]>(standing?.teams || []);

		const fetchTeams = useCallback(
			async (groupId: string) => {
				let competitionTeams = await getGroupTeams(groupId);
				if (competitionTeams?.some(team => !Number.isNaN(team?.standing?.position))) {
					competitionTeams = competitionTeams.sort((a, b) => (a.standing?.position || 0) - (b.standing?.position || 0));
				}
				setTeams(competitionTeams as FullTeamModel[]);
			},
			[getGroupTeams]
		);

		useEffect(() => {
			setExpanded(opened);

			if (opened && !standing?.teams && standing?.group?.id) {
				fetchTeams(standing.group.id);
			}
		}, [opened, fetchTeams, standing?.teams, standing?.group?.id]);

		const toggle = useCallback(() => {
			if (expandable && standing?.group?.id && onExpand) {
				onExpand && onExpand(expanded ? false : standing.group.id);
			}
		}, [onExpand, standing?.group?.id, expanded, expandable]);

		const tableColumns: TableColumnType[] = useMemo(() => {
			const columnList: TableColumnType[] = [
				{
					label: "#",
					width: 40,
					alignment: ColumnAlignment.center,
					Cell: ({ rowIndex }: { rowIndex: number }) => rowIndex + 1,
					dataKey: "number"
				},
				{
					label: t("team"),
					minWidth: short ? 210 : 320,
					Cell: ({ rowData: { name, club, id } }: { rowData: FullTeamModel }) => (
						<Link
							to={`${teamPageUrl}/${id}`}
							component={RouterLink}
							className="w-full flex items-center gap-4 no-underline"
						>
							<Img src={club?.logo} alt={name} size={ImageSizesEnum.Small32} circle className="shrink-0" />
							<div className="w-full shrink-1">
								<Text variants={TextVariantsEnum.Caption3} className="truncate w-[90%]">
									{name}
								</Text>
								<Text variants={TextVariantsEnum.BodySmall} style={TextStylesEnum.Hint} className="truncate mt-0.5">
									{club?.name}
								</Text>
							</div>
						</Link>
					),
					dataKey: "team"
				}
			];

			if (!hideColumns?.some(x => x === "played")) {
				columnList.push({
					label: "PL",
					alignment: ColumnAlignment.center,
					Cell: ({ rowData: { standing } }: { rowData: FullTeamModel }) => {
						const draw = (standing?.matchesPlayed || 0) - (standing?.matchesWon || 0) - (standing?.matchesLost || 0);
						return (
							<Text variants={TextVariantsEnum.Numbers14}>
								{(standing?.matchesWon || 0) + (draw < 0 ? 0 : draw) + (standing?.matchesLost || 0)}
							</Text>
						);
					},
					dataKey: "played"
				});
			}

			if (!hideColumns?.some(x => x === "won")) {
				columnList.push({
					label: "W",
					alignment: ColumnAlignment.center,
					Cell: ({ rowData: { standing } }: { rowData: FullTeamModel }) => (
						<Text variants={TextVariantsEnum.Numbers14}>{standing?.matchesWon || 0}</Text>
					),
					dataKey: "won"
				});
			}

			if (!hideColumns?.some(x => x === "draw")) {
				columnList.push({
					label: "D",
					alignment: ColumnAlignment.center,
					Cell: ({ rowData: { standing } }: { rowData: FullTeamModel }) => {
						const draw = (standing?.matchesPlayed || 0) - (standing?.matchesWon || 0) - (standing?.matchesLost || 0);
						return <Text variants={TextVariantsEnum.Numbers14}>{draw < 0 ? 0 : draw}</Text>;
					},
					dataKey: "draw"
				});
			}

			if (!hideColumns?.some(x => x === "lost")) {
				columnList.push({
					label: "L",
					alignment: ColumnAlignment.center,
					Cell: ({ rowData: { standing } }: { rowData: FullTeamModel }) => (
						<Text variants={TextVariantsEnum.Numbers14}>{standing?.matchesLost || 0}</Text>
					),
					dataKey: "lost"
				});
			}

			if (!hideColumns?.some(x => x === "goals")) {
				columnList.push({
					label: "+/-",
					alignment: ColumnAlignment.center,
					minWidth: 75,
					Cell: ({ rowData: { standing } }: { rowData: FullTeamModel }) => (
						<Text variants={TextVariantsEnum.Numbers14}>{`${standing?.goalsScored || 0}/${
							standing?.goalsConceded || 0
						}`}</Text>
					),
					dataKey: "goals"
				});
			}

			if (!hideColumns?.some(x => x === "goalsDifference")) {
				columnList.push({
					label: "GD",
					alignment: ColumnAlignment.center,
					Cell: ({ rowData: { standing } }: { rowData: FullTeamModel }) => (
						<Text variants={TextVariantsEnum.Numbers14}>
							{(standing?.goalsScored || 0) - (standing?.goalsConceded || 0)}
						</Text>
					),
					dataKey: "goalsDifference"
				});
			}

			if (!hideColumns?.some(x => x === "points")) {
				columnList.push({
					label: "PTS",
					alignment: ColumnAlignment.center,
					minWidth: 50,
					Cell: ({ rowData: { standing } }: { rowData: FullTeamModel }) => {
						const draw = (standing?.matchesPlayed || 0) - (standing?.matchesWon || 0) - (standing?.matchesLost || 0);
						return (
							<Text variants={TextVariantsEnum.Caption3}>
								{standing?.points !== undefined ? standing.points : 3 * (standing?.matchesWon || 0) + draw}
							</Text>
						);
					},
					dataKey: "pts"
				});
			}

			if (!hideColumns?.some(x => x === "form")) {
				columnList.push({
					label: "Form",
					width: 120,
					Cell: ({ rowData: { previousMatches, id } }: { rowData: FullTeamModel }) => (
						<div className="flex gap-[6px]">
							{!!previousMatches?.length
								? previousMatches.map(match => (
										<div
											key={`team-${id}-match-${match.id}-${match.result}`}
											className={clsx(
												"w-[14px] h-[18px] rounded",
												match.result === MatchResultEnum.WON
													? "bg-primary-500"
													: match.result === MatchResultEnum.LOST
														? "bg-red-500"
														: "bg-gray-300"
											)}
										/>
									))
								: "-"}
						</div>
					),
					dataKey: "form"
				});
			}

			if (!hideColumns?.some(x => x === "next")) {
				columnList.push({
					label: t("next"),
					width: 80,
					Cell: ({ rowData: { nextMatches } }: { rowData: FullTeamModel }) => {
						if (nextMatches?.length) {
							const opponentTeam = nextMatches[0]!.opponentTeam;
							return (
								<Link to={`${teamPageUrl}/${opponentTeam.id}`} component={RouterLink} className="no-underline">
									<Img src={opponentTeam?.club?.logo} alt={opponentTeam?.name} size={ImageSizesEnum.Small32} circle />
								</Link>
							);
						}
						return "-";
					},
					dataKey: "next"
				});
			}

			return columnList;
		}, [hideColumns, short, teamPageUrl, t]);

		const isHighlightedRow = useCallback(
			(index: number) =>
				standing?.highlightedTeamIds?.length && teams?.[index]?.id
					? standing.highlightedTeamIds!.includes(teams[index]!.id)
					: false,
			[teams, standing?.highlightedTeamIds]
		);

		const teamPosition = useMemo(() => {
			let position = 0;
			if (standing?.positionTeamId) {
				const positionTeam = teams.find(x => x.id === standing?.positionTeamId);
				position = positionTeam?.standing?.position || 0;
			}
			return position;
		}, [standing?.positionTeamId, teams]);

		if (loading) {
			return (
				<Accordion
					disableGutters
					elevation={0}
					square
					expanded={!!opened}
					className="bg-white w-full rounded-xl before:hidden border-gray-100 border-[1px]"
				>
					<StandingAccordionSummary
						className={clsx(
							"w-full px-4 border-gray-100 border-solid cursor-pointer",
							expanded ? "py-4 border-b-[1px]" : "py-3"
						)}
					>
						<div className="flex justify-between w-full cursor-pointer">
							<div className="flex items-center">
								<SkeletonBase
									width={40}
									height={40}
									variant="circular"
									fullScale
									className={clsx(expanded ? "mr-4" : "mr-3")}
								/>
								<div>
									<SkeletonBase width={120} height={23} fullScale />
									{expanded && <SkeletonBase width={60} height={22} />}
								</div>
							</div>
							<div className="flex items-center">
								<SkeletonBase width={130} height={19} fullScale />
								{expandable && <SkeletonBase width={24} height={24} variant="circular" fullScale className="ml-4" />}
							</div>
						</div>
					</StandingAccordionSummary>
					<AccordionDetails className="p-0">
						<Table columns={tableColumns} data={[]} loading={true} hideBorderWrapper />
					</AccordionDetails>
				</Accordion>
			);
		}

		return (
			<Accordion
				disableGutters
				elevation={0}
				square
				expanded={opened}
				onChange={toggle}
				className="bg-white w-full rounded-xl before:hidden border-gray-100 border-[1px]"
			>
				<StandingAccordionSummary
					className={clsx(
						"w-full px-4 border-gray-100 border-solid cursor-pointer",
						expanded ? "py-4 border-b-[1px]" : "py-3"
					)}
				>
					<div className="flex justify-between w-full cursor-pointer">
						<div className="flex items-center">
							{title ? (
								<Text variants={TextVariantsEnum.H6}>{title}</Text>
							) : (
								<>
									<Img
										src={standing?.group?.competition?.logo}
										alt={standing?.group?.competition?.name}
										size={expanded ? ImageSizesEnum.Small40 : ImageSizesEnum.Small32}
										circle
										className={clsx(expanded ? "mr-4" : "mr-3")}
									/>
									<div>
										<Text variants={TextVariantsEnum.H6}>{standing?.group?.competition?.name}</Text>
										{expanded && (
											<Text variants={TextVariantsEnum.Caption3} style={TextStylesEnum.Hint}>
												{standing?.group?.division?.name || standing?.group?.competition?.league?.name}
											</Text>
										)}
									</div>
								</>
							)}
						</div>
						<div className="flex items-center">
							{!!teamPosition && (
								<>
									{teamPosition === 1 && (
										<div className="mr-2">
											<GobletIcon className="w-4 h-4" />
										</div>
									)}
									<Text variants={TextVariantsEnum.H7} style={TextStylesEnum.Hint}>
										#{teamPosition} {t("place")}
									</Text>
								</>
							)}
							{expandable && (
								<Button
									className="ml-4"
									variant={ButtonVariantsEnum.FILLED}
									fillColor={colors.palette.colors.gray["50"]}
									size={ButtonSizesEnum.XS}
									aria-label="Expand"
									iconButton={{
										icon: (
											<ArrowIcon
												className={clsx(
													"w-4 h-4 svg-paths:fill-transparent svg-paths:stroke-gray-300",
													expanded ? "-rotate-90" : "rotate-90"
												)}
											/>
										),
										circular: true
									}}
								/>
							)}
						</div>
					</div>
				</StandingAccordionSummary>
				<AccordionDetails className="p-0">
					<Table
						columns={tableColumns}
						data={teams}
						loading={loadingGroupTeams}
						hideBorderWrapper
						isHighlightedRow={isHighlightedRow}
					/>
				</AccordionDetails>
			</Accordion>
		);
	}
);

export default Standing;
