import type { FC, ReactNode } from "react";
import React, { useCallback } from "react";

import { DialogActions, DialogTitle } from "@mui/material";

import clsx from "clsx";

import LoaderIcon from "assets/icons/animated/loader.svg?react";

import CloseIcon from "assets/icons/outlined/close.svg?react";

import { Loader } from "shared/uikit";

import { UIKitDialog, UiKitDialogContent } from "./style";

import type { ButtonPropsInterface } from "../Button";
import Button from "../Button";
import Text from "../Text";
import { ButtonSizesEnum, ButtonVariantsEnum, TextVariantsEnum } from "../types";

export enum ModalSizesEnum {
	SMALL480 = "small480",
	LARGE800 = "large800",
	SMALL = "small",
	MEDIUM = "medium",
	MID_LARGE = "mid-large",
	LARGE = "large",
	FULLSCREEN = "fullscreen",
	TINY = "tiny"
}

export interface ActionModel extends ButtonPropsInterface {
	text: string;
	index?: string;
	loading?: boolean;
	hidden?: boolean;
}

export interface DialogProps {
	open: boolean;
	onClose?: () => void;
	children?: React.ReactNode;
	oppositeActions?: ReactNode[];
	actions?: ActionModel[];
	title?: string;
	description?: string;
	loading?: boolean;
	loadingText?: string;
	aria?: {
		labelledBy: string;
		describedBy: string;
	};
	sizes?: ModalSizesEnum;
	fixedHeightSize?: ModalSizesEnum;
	titlePosition?: "left" | "center" | "right";
	nonClosable?: boolean;
	invisibleWrapper?: boolean;
	className?: string;
	autoHeight?: boolean;
	fullWidth?: boolean;
}

const BaseDialog: FC<DialogProps> = ({
	open,
	onClose,
	title,
	description,
	aria,
	children,
	loading,
	loadingText,
	oppositeActions,
	actions,
	sizes = ModalSizesEnum.MEDIUM,
	titlePosition = "left",
	nonClosable = false,
	fixedHeightSize,
	invisibleWrapper,
	className,
	autoHeight,
	fullWidth
}) => {
	const handleClose = useCallback(() => {
		onClose && onClose();
	}, [onClose]);

	return (
		<UIKitDialog
			open={open}
			onClose={handleClose}
			aria-labelledby={aria?.labelledBy || "dialog-title"}
			aria-describedby={aria?.describedBy || "dialog-description"}
			className={clsx(`sizes_${sizes}`, fullWidth && "full-width")}
			invisibleWrapper={invisibleWrapper}
			autoHeight={autoHeight}
		>
			{title && (
				<DialogTitle className={clsx(`select-none relative text-xl leading-[23px] font-bold text-${titlePosition}`)}>
					<div>
						{title}
						{description && (
							<Text variants={TextVariantsEnum.BodySmall} className="text-hint font-normal">
								{description}
							</Text>
						)}
					</div>
					{!nonClosable && (
						<div className="absolute top-0 right-6 h-full flex items-center">
							<Button
								variant={ButtonVariantsEnum.GHOST}
								iconButton={{
									icon: <CloseIcon />,
									circular: true
								}}
								onClick={handleClose}
							/>
						</div>
					)}
				</DialogTitle>
			)}
			<UiKitDialogContent
				className={clsx(
					fixedHeightSize && `fixed-height_${fixedHeightSize}`,
					!title && "no-header",
					!actions?.length && "no-footer",
					className
				)}
			>
				{loading ? (
					<div className="w-full h-full flex flex-col justify-center items-center">
						<Loader className="w-[70px] h-[70px]" />
						{loadingText && (
							<Text className="mt-14" variants={TextVariantsEnum.H7}>
								{loadingText}
							</Text>
						)}
					</div>
				) : (
					children
				)}
			</UiKitDialogContent>
			{(!!actions?.length || !!oppositeActions?.length) && (
				<DialogActions className={clsx("p-4", oppositeActions?.length && "justify-between")}>
					{!!oppositeActions?.length && (
						<div className="flex items-center gap-2">{oppositeActions.map(action => action)}</div>
					)}
					{!!actions?.length && (
						<div className="flex flex-1 items-center justify-end gap-2">
							{actions.map(({ onClick, text, index, loading, hidden, ...rest }, i) =>
								!hidden ? (
									<Button
										{...rest}
										startIcon={loading ? <LoaderIcon /> : undefined}
										size={ButtonSizesEnum.MD}
										key={index || i}
										onClick={onClick}
									>
										{text}
									</Button>
								) : null
							)}
						</div>
					)}
				</DialogActions>
			)}
		</UIKitDialog>
	);
};

export default BaseDialog;
