import React from "react";

import { SkeletonForm } from "./style";

interface SkeletonBaseProps {
	variant?: "text" | "rectangular" | "rounded" | "circular" | undefined;
	animation?: "wave" | "pulse" | false;
	width?: string | number;
	height?: string | number;
	className?: string;
	fullScale?: boolean;
}

const SkeletonBase: React.FC<SkeletonBaseProps> = ({
	variant = "text",
	animation = "wave",
	width = "100%",
	height = "100%",
	className = "",
	fullScale = false
}) => (
	<SkeletonForm
		variant={variant}
		animation={animation}
		width={width}
		height={height}
		className={`${fullScale ? "full-scale" : ""} ${className}`}
	/>
);

export default SkeletonBase;
