import { Box, styled } from "@mui/material";

import { Text } from "shared/uikit";

import colors from "theme/colors";

export const Card = styled(Box)`
	width: auto;
	height: 100%;

	background: ${colors.palette.colors.white};

	border: 1px solid ${colors.palette.colors.gray["100"]};

	border-radius: 12px;

	position: relative;

	transition: border-color 0.15s ease-in-out;

	cursor: pointer;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&.active {
		border-color: ${colors.palette.colors.primary["500"]};
		box-shadow: 0 0 0 1px ${colors.palette.colors.primary["500"]};

		.role-icon {
			.stroke {
				path {
					stroke: ${colors.palette.colors.primary["500"]};
				}
			}
			.fill {
				path {
					fill: ${colors.palette.colors.primary["500"]};
				}
			}
		}

		.checkmark-wrapper {
			opacity: 1;

			&.checkbox {
				svg {
					opacity: 1;
				}
			}
		}
	}

	&.disabled {
		cursor: not-allowed;

		opacity: 0.3;
	}

	&.horizontal {
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	&.maxWidth {
		${props => props.theme.breakpoints.up("md")} {
			width: 100%;
			max-width: 12.5rem;
		}
	}
`;

export const CardIconWrapper = styled(Box)`
	svg {
		width: 100%;
		height: 100%;
	}
`;

export const CardCheckmarkWrapper = styled(Box)`
	position: absolute;

	top: 12px;
	right: 12px;

	opacity: 0;

	transition: opacity 0.15s ease-in-out;

	&.checkbox {
		opacity: 1;

		width: 20px;
		height: 20px;

		border-radius: 4px;
		border: 1px solid ${colors.palette.colors.gray["200"]};

		svg {
			transition: opacity 0.15s ease-in-out;

			transform: translate(-1px, -1px);

			opacity: 0;

			rect {
				fill: ${colors.palette.colors.primary["500"]};
			}

			path {
				fill: none;
				stroke: ${colors.palette.colors.white};
			}
		}
	}

	&.active {
		opacity: 1;
	}
`;

export const Description = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
`;
