import type { FC } from "react";
import React from "react";

import clsx from "clsx";

import { useDelayUnmount } from "shared/hooks";

export enum AnimateFromEnum {
	TOP = "top",
	BOTTOM = "bottom",
	LEFT = "left",
	RIGHT = "right"
}

interface TransitionProps {
	children: React.ReactNode;
	isMounted: boolean;
	className?: string;
	animateFrom?: AnimateFromEnum;
}

const Transition: FC<TransitionProps> = ({ children, className, isMounted, animateFrom = AnimateFromEnum.TOP }) => {
	const shouldDisplay = useDelayUnmount(isMounted, 300);

	return !shouldDisplay ? null : (
		<div
			className={clsx(
				`flex-1 transition-all w-full duration-300 animate-children-${animateFrom}`,
				isMounted ? "relative translate-y-0 opacity-100" : "absolute translate-y-4 opacity-0",
				className
			)}
		>
			{children}
		</div>
	);
};

export default Transition;
