import { styled } from "@mui/material";

import colors from "theme/colors";

export const ContentWrapper = styled("div")`
	color: ${colors.palette.colors.gray["500"]};
	font-weight: 400;

	a {
		font-weight: 700;
		color: ${colors.palette.colors.primary["600"]};
	}
`;

export const SwiperCarouselWrapper = styled("div")`
	.swiper-pagination {
		z-index: 50;
		bottom: 6px;
	}
`;
