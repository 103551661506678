import type { FC } from "react";
import React, { useCallback, useEffect, useState } from "react";

import { Checkbox } from "@mui/material";

import clsx from "clsx";

import CheckboxIcon from "assets/icons/filled/checkbox.svg?react";
import CheckboxEmptyIcon from "assets/icons/outlined/checkbox-empty.svg?react";

import { StyledCheckboxBtn } from "./style";

import { Text } from "../index";
import { ButtonSizesEnum, TextStylesEnum, TextVariantsEnum } from "../types";

interface CheckboxBtnProps {
	id?: string;
	label?: string;
	defaultChecked?: boolean;
	value?: boolean;
	rtl?: boolean;
	className?: string;
	onChange?: (checked: boolean, event?: React.SyntheticEvent<Element, Event>) => void;
	wrapperSize?: ButtonSizesEnum;
	disabled?: boolean;
	ariaLabel?: string;
}

const CheckboxBtn: FC<CheckboxBtnProps> = ({
	id,
	label,
	value = false,
	defaultChecked = false,
	rtl = false,
	className = "",
	onChange,
	wrapperSize = ButtonSizesEnum.MD,
	disabled,
	ariaLabel
}) => {
	const [selected, setSelected] = useState(value || defaultChecked);

	useEffect(() => {
		setSelected(value);
	}, [value]);

	const handleChange = useCallback(
		(event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
			setSelected(checked);
			onChange && onChange(checked, event);
		},
		[onChange]
	);

	return (
		<StyledCheckboxBtn
			control={<Checkbox id={id} icon={<CheckboxEmptyIcon />} checkedIcon={<CheckboxIcon />} />}
			label={
				label && (
					<Text variants={TextVariantsEnum.BodySmall} style={TextStylesEnum.Default} className="select-none">
						{label}
					</Text>
				)
			}
			checked={selected}
			defaultChecked={defaultChecked}
			className={clsx(className, rtl && "rtl", `style_${wrapperSize}`)}
			onChange={handleChange}
			disabled={disabled}
			aria-label={ariaLabel || label || "Checkbox"}
		/>
	);
};

export default CheckboxBtn;
