import type { FC } from "react";
import React, { memo, useMemo } from "react";

import clsx from "clsx";

import { StyledH1, StyledH2, StyledH3, StyledH4, StyledH5, StyledH6, StyledP, StyledSPAN } from "./style";

import { TextStylesEnum, TextVariantsEnum } from "../types";

interface TextProps {
	children?: React.ReactNode;
	variants?: TextVariantsEnum;
	style?: TextStylesEnum;
	className?: string;
	title?: string;
	setInnerHTML?: string;
}

const Text: FC<TextProps> = memo(
	({ children, className, title, variants = TextVariantsEnum.BodyMedium, style = TextStylesEnum.Default }) => {
		return useMemo(() => {
			switch (variants) {
				case TextVariantsEnum.H1:
					return (
						<StyledH1 title={title} className={`styles_${style} font-semibold text-4xl leading-[42px] ${className}`}>
							{children}
						</StyledH1>
					);

				case TextVariantsEnum.H2:
				case TextVariantsEnum.Title:
					return (
						<StyledH2
							title={title}
							className={clsx(
								`styles_${style} ${className}`,
								variants === TextVariantsEnum.Title
									? "text-[40px] font-bold leading-[46px]"
									: "text-[32px] leading-[38px] font-semibold"
							)}
						>
							{children}
						</StyledH2>
					);

				case TextVariantsEnum.H3:
					return (
						<StyledH3 title={title} className={`styles_${style} font-medium text-[28px] leading-[33px] ${className}`}>
							{children}
						</StyledH3>
					);

				case TextVariantsEnum.H4:
					return (
						<StyledH4 title={title} className={`styles_${style} font-semibold text-2xl leading-[28px] ${className}`}>
							{children}
						</StyledH4>
					);

				case TextVariantsEnum.H5:
					return (
						<StyledH5 title={title} className={`styles_${style} text-2xl leading-[28px] ${className}`}>
							{children}
						</StyledH5>
					);

				case TextVariantsEnum.H6:
					return (
						<StyledH6 title={title} className={`styles_${style} font-bold text-xl leading-[23px] ${className}`}>
							{children}
						</StyledH6>
					);

				case TextVariantsEnum.H7:
					return (
						<StyledP title={title} className={`styles_${style} font-semibold text-base leading-[19px] ${className}`}>
							{children}
						</StyledP>
					);

				case TextVariantsEnum.H8:
					return (
						<StyledP title={title} className={`styles_${style} text-xl leading-6 font-semibold ${className}`}>
							{children}
						</StyledP>
					);

				case TextVariantsEnum.SPAN:
					return (
						<StyledSPAN title={title} className={`styles_${style} font-semibold text-base leading-[19px] ${className}`}>
							{children}
						</StyledSPAN>
					);

				case TextVariantsEnum.Caption:
					return (
						<StyledP title={title} className={`styles_${style} text-xs leading-[14px] ${className}`}>
							{children}
						</StyledP>
					);

				case TextVariantsEnum.Caption2:
					return (
						<StyledP title={title} className={`styles_${style} text-xs leading-[14px] font-medium ${className}`}>
							{children}
						</StyledP>
					);

				case TextVariantsEnum.Caption3:
					return (
						<StyledP title={title} className={`styles_${style} text-sm leading-[16px] font-medium ${className}`}>
							{children}
						</StyledP>
					);

				case TextVariantsEnum.INHERIT_SPAN:
					return (
						<StyledSPAN title={title} className={`styles_${style} ${className}`}>
							{children}
						</StyledSPAN>
					);

				case TextVariantsEnum.BodyLarge:
				case TextVariantsEnum.BodyMedium:
				case TextVariantsEnum.BodySmall:
				case TextVariantsEnum.BodyXSmall:
					return (
						<StyledP
							title={title}
							className={clsx(
								`styles_${style}`,
								className,
								variants === TextVariantsEnum.BodyLarge && "text-xl leading-[24px]",
								variants === TextVariantsEnum.BodyMedium && "text-base leading-[24px]",
								variants === TextVariantsEnum.BodySmall && "text-[13px] leading-[15px]",
								variants === TextVariantsEnum.BodyXSmall && "text-[8px] leading-[12px]"
							)}
						>
							{children}
						</StyledP>
					);

				case TextVariantsEnum.Numbers14:
					return (
						<StyledP title={title} className={`styles_${style} text-sm font-normal ${className}`}>
							{children}
						</StyledP>
					);

				case TextVariantsEnum.ERROR:
					return (
						<StyledP title={title} className={`styles_${style} text-[0.75rem] leading-[1.2] text-red-600 ${className}`}>
							{children}
						</StyledP>
					);

				default:
					return (
						<StyledP title={title} className={clsx(className)}>
							{children}
						</StyledP>
					);
			}
		}, [children, className, style, title, variants]);
	}
);

export default Text;
