import { Suspense, lazy } from "react";

import config from "config/appConfig";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));
const TeamPageLayout = lazy(() => import("modules/Team/View/layouts/TeamPageLayout"));

const TeamCoachesPage = lazy(() => import("./Pages/Team/Coaches"));
const TeamMatchesPage = lazy(() => import("./Pages/Team/Matches"));
const TeamOverviewPage = lazy(() => import("./Pages/Team/Overview"));
const TeamPlayersPage = lazy(() => import("./Pages/Team/Players"));
const TeamStandingsPage = lazy(() => import("./Pages/Team/Standings"));
const TeamStatsPage = lazy(() => import("./Pages/Team/Stats/index"));
const TeamStatsDetailedPage = lazy(() => import("./Pages/Team/Stats/detailed"));

import { routes } from "./constants";

const { CORE_URLS } = config;

class TeamApp implements IApp {
	getRoutes = () => [
		<Route path={routes.team.getPath()} key="team-routes">
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key={routes.team.getElementKey()}
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={`${routes.team.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<TeamPageLayout homePageUrl={CORE_URLS.HOME} />
					</Suspense>
				}
			>
				<Route
					key={`${routes.team.getElementKey()}/id`}
					path={`${routes.team.getPath()}/:id`}
					element={
						<Suspense>
							<TeamOverviewPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.team.getElementKey()}/id/players`}
					path={`${routes.team.getPath()}/:id/players`}
					element={
						<Suspense>
							<TeamPlayersPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.team.getElementKey()}/id/events`}
					path={`${routes.team.getPath()}/:id/events`}
					element={
						<Suspense>
							<TeamMatchesPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.team.getElementKey()}/id/standings`}
					path={`${routes.team.getPath()}/:id/standings`}
					element={
						<Suspense>
							<TeamStandingsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.team.getElementKey()}/id/stats`}
					path={`${routes.team.getPath()}/:id/stats`}
					element={
						<Suspense>
							<TeamStatsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.team.getElementKey()}/id/coaches`}
					path={`${routes.team.getPath()}/:id/coaches`}
					element={
						<Suspense>
							<TeamCoachesPage />
						</Suspense>
					}
				/>
				{/* <Route
					key={`${routes.team.getElementKey()}/id/competitions`}
					path={`${routes.team.getPath()}/:id/competitions`}
					element={<Suspense><TeamCompetitionsPage /></Suspense>}
				/> */}
			</Route>
			<Route
				key={`${routes.team.getElementKey()}/id/stats/competitionId/entity/detail`}
				path={`${routes.team.getPath()}/:id/stats/:competitionId/:entity/:stat`}
				element={
					<Suspense>
						<TeamStatsDetailedPage />
					</Suspense>
				}
			/>
		</Route>
	];
}

export default TeamApp;
