import { useParams } from "react-router-dom";

import { getPaths } from "core/getPaths";
import { view } from "modules/League";

const { competition: competitionRoutes } = getPaths();

const LeagueCompetitionsPage = () => {
	const { id } = useParams();

	return <view.LeagueCompetitions id={id!} competitionTeamPage={competitionRoutes.getPath()} />;
};
export default LeagueCompetitionsPage;
