import React, { memo } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import type { StoreModel } from "shared/types";
import type { MenuDotsOption } from "shared/uikit";
import { Button, ImageSizesEnum, Img, MenuDots, SkeletonBase, Text } from "shared/uikit";
import { ButtonSizesEnum, TextVariantsEnum } from "shared/uikit/types";

import CardWrapper from "../CardWrapper";

export interface StoreCardProps {
	store?: StoreModel;
	loading?: boolean;
	showButton?: boolean;
	isCanEdit?: boolean;
	options?: MenuDotsOption[];
	clickable?: boolean;
}

const StoreCard: React.FC<StoreCardProps> = memo(
	({ store, loading, showButton = false, isCanEdit = false, options = [], clickable = false }) => {
		const { t } = useTranslation();

		const handleVisitStore = () => {
			if (store?.link) {
				window.open(store.link, "_blank");
			}
		};

		if (loading && !store) {
			return (
				<CardWrapper noPadding className="p-4">
					<div className="flex justify-between gap-2">
						<div className="flex items-center gap-4">
							<SkeletonBase width={64} height={64} variant={"circular"} fullScale />
							<div>
								<SkeletonBase width={80} height={19} fullScale />
								<SkeletonBase width={120} height={15} fullScale className="mt-1" />
							</div>
						</div>
						<div className={clsx("flex justify-end", isCanEdit ? "items-start" : "items-center")}>
							<SkeletonBase width={24} height={24} fullScale />
						</div>
					</div>
					{showButton && <SkeletonBase width="100%" height={56} fullScale className="mt-4" />}
				</CardWrapper>
			);
		}

		return (
			<CardWrapper
				noPadding
				className={clsx("p-4", clickable && !showButton && "cursor-pointer")}
				onClick={() => clickable && !showButton && handleVisitStore()}
			>
				<div className="flex justify-between gap-2">
					<div className="flex items-center gap-4">
						<Img src={store?.logo} alt={store?.name || ""} size={ImageSizesEnum.Small64} circle />
						<div>
							<Text variants={TextVariantsEnum.H7}>{store!.name}</Text>
							<Text variants={TextVariantsEnum.BodySmall} className="text-hint">
								{store!.link}
							</Text>
						</div>
					</div>
					<div className={clsx("flex justify-end", isCanEdit ? "items-start" : "items-center")}>
						<MenuDots horizontal options={options} />
					</div>
				</div>
				{showButton && (
					<Button className="mt-4 w-full" size={ButtonSizesEnum.MD} onClick={handleVisitStore}>
						{t("visit_store")}
					</Button>
				)}
			</CardWrapper>
		);
	}
);

export default StoreCard;
