import { DateTime } from "luxon";

export const getMatchTime = (startsAt: string | null, endsAt: string | null) => {
	if (startsAt) {
		const startTime = DateTime.fromISO(startsAt);
		const endTime = endsAt ? DateTime.fromISO(endsAt) : undefined;

		const formattedStartTime = startTime.toFormat("h:mm a").toLowerCase().replace(" ", "");
		const formattedEndTime = endTime ? endTime.toFormat("h:mm a").toLowerCase().replace(" ", "") : undefined;

		return `${formattedStartTime}${formattedEndTime ? ` - ${formattedEndTime}` : ""}`;
	}

	return "-";
};

export const getMatchStartTime = (startsAt: string | null) => {
	if (startsAt) {
		const startTime = DateTime.fromISO(startsAt);
		return startTime.toFormat("h:mm a").toUpperCase();
	}

	return "-";
};

export const getMatchStartDate = (startsAt: string | null) => {
	if (startsAt) {
		const startTime = DateTime.fromISO(startsAt);
		return startTime.toFormat("ccc d, LLL");
	}

	return "-";
};
