import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "@mui/material";
import { DateTime } from "luxon";

import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import CompetitionSettingsModal from "modules/Competition/View/Components/CompetitionSettingsModal";

import { StatusBox, Table } from "shared/Components";
import type { EmptyTableConfigModel } from "shared/Components/Table";
import type { TableColumnType } from "shared/Components/Table/Components/SimpleTable";
import { ColumnVerticalAlignment } from "shared/Components/Table/Components/SimpleTable";
import { useCompetition, useLeague } from "shared/hooks";
import type { CompetitionModel, LeagueCompetitionModel } from "shared/types";
import { EntitySoccerLeagueRoles, InvitationStatus } from "shared/types";
import { TableColumnLoaderType } from "shared/types/Table";
import { Button, ImageSizesEnum, Img, MenuDots, Text } from "shared/uikit";
import type { ActionModel } from "shared/uikit/BaseDialog";
import type { MenuDotsOption } from "shared/uikit/MenuDots";
import { ButtonSizesEnum, ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";
import { isRoleAvailable } from "utils/isRoleAvailable";
import { capitalize } from "utils/serviceUtils/helpers";

const CreateLeagueCompetitionModal = lazy(() => import("shared/templates/CreateLeagueCompetitionModal"));
const InviteClubsToCompetitionModal = lazy(() => import("shared/templates/InviteClubsToCompetitionModal"));
const ConfirmationDialog = lazy(() => import("shared/Components/ConfirmationDialog"));

interface LeagueCompetitionsPageProps {
	id: string;
	competitionTeamPage: string;
}

const LeagueCompetitionsPage: React.FC<LeagueCompetitionsPageProps> = ({ id, competitionTeamPage }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { getCompetitions, getData: getLeagueData } = useLeague();
	const { competitions, loadingCompetitions: loading, userLeagueRoles, nextListingCompetitionsToken } = getLeagueData();

	const { setCompetitionSettingsModal, deleteCompetition, getData: getCompetitionData } = useCompetition();
	const { competitionSettingsModal, submitting } = getCompetitionData();

	const [warningModal, setWarningModal] = useState<{
		open: boolean;
		title: string;
		message: string;
		onClose: () => void;
		actions: ActionModel[];
	}>();

	const [createCompetitionDialog, setCreateCompetitionDialog] = useState<{
		onClose: (competition?: CompetitionModel) => void;
	}>();

	const [inviteClubsToCompetitionDialog, setInviteClubsToCompetitionDialog] = useState<{
		competitionId: string;
		onClose: (competition?: CompetitionModel) => void;
	}>();

	const fetchData = useCallback(
		(token?: string | null) => {
			getCompetitions({ leagueId: id, after: token || null });
		},
		[getCompetitions, id]
	);

	useEffect(() => {
		fetchData(null);
	}, [fetchData]);

	const handleLoadMore = useCallback(() => {
		nextListingCompetitionsToken && fetchData(nextListingCompetitionsToken);
	}, [nextListingCompetitionsToken, fetchData]);

	const isAdmin = useMemo(
		() => isRoleAvailable(userLeagueRoles, [EntitySoccerLeagueRoles.OWNER, EntitySoccerLeagueRoles.MANAGER]),
		[userLeagueRoles]
	);

	const getOptions = useCallback(
		(data: LeagueCompetitionModel): MenuDotsOption[] => {
			const BASE_OPTS = [
				{
					name: t("view_competition"),
					onClick: () => {
						navigate(`${competitionTeamPage}/${data.id}`);
					}
				}
			];

			if (isAdmin) {
				BASE_OPTS.push(
					{
						name: t("manage_competition"),
						onClick: () => setCompetitionSettingsModal(data.id)
					},
					{
						name: t("invitation:invite_clubs"),
						onClick: () => {
							setInviteClubsToCompetitionDialog({
								competitionId: data.id,
								onClose: () => {
									setInviteClubsToCompetitionDialog(undefined);
								}
							});
						}
					}
				);

				if (!data?.hasPlayedMatches && !data?.hasPayments) {
					BASE_OPTS.push({
						name: t("competition:delete_competition"),
						onClick: () => {
							setWarningModal({
								open: true,
								title: t("competition:do_you_want_to_remove_competition"),
								message: t("competition:do_you_want_to_remove_competition_desc"),
								onClose: () => {
									setWarningModal(undefined);
								},
								actions: [
									{
										text: t("close"),
										variant: ButtonVariantsEnum.OUTLINED,
										onClick: () => setWarningModal(undefined)
									},
									{
										text: t("remove"),
										variant: ButtonVariantsEnum.DANGER,
										onClick: async () => {
											setWarningModal(undefined);
											await deleteCompetition(data.id);
											fetchData(null);
										}
									}
								]
							});
						}
					});
				}
			}

			return BASE_OPTS;
		},
		[
			isAdmin,
			navigate,
			competitionTeamPage,
			setCompetitionSettingsModal,
			setInviteClubsToCompetitionDialog,
			fetchData,
			deleteCompetition,
			t
		]
	);

	const tableColumns: TableColumnType[] = useMemo(
		() => [
			{
				label: t("name"),
				verticalAlignment: ColumnVerticalAlignment.top,
				minWidth: 550,
				Cell: ({ rowData: { id, name, startsOn, endsOn, logo } }: { rowData: LeagueCompetitionModel }) => {
					const startAt = DateTime.fromISO(startsOn);
					const endAt = DateTime.fromISO(endsOn);

					return (
						<Link
							to={`${competitionTeamPage}/${id}`}
							component={RouterLink}
							className="flex items-start no-underline w-full"
						>
							<div className="mr-3">
								<Img src={logo} alt={name} size={ImageSizesEnum.Small32} circle />
							</div>
							<div>
								<Text variants={TextVariantsEnum.BodyMedium}>{name}</Text>
								<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.BodyMedium}>
									{startAt?.isValid ? startAt.toFormat("LL/dd/yyyy") : ""} -{" "}
									{endAt?.isValid ? endAt.toFormat("LL/dd/yyyy") : ""}
								</Text>
							</div>
						</Link>
					);
				},
				dataKey: "name"
			},
			{
				label: t("type"),
				verticalAlignment: ColumnVerticalAlignment.top,
				width: 140,
				Cell: ({ rowData: { type } }: { rowData: LeagueCompetitionModel }) => capitalize(type),
				dataKey: "type"
			},
			{
				label: t("team_status"),
				verticalAlignment: ColumnVerticalAlignment.top,
				minWidth: 70,
				Cell: ({
					rowData: {
						acceptedClubCount,
						acceptedTeamCount,
						appliedTeamCount,
						appliedClubCount,
						rejectedClubCount,
						rejectedTeamCount
					}
				}: {
					rowData: LeagueCompetitionModel;
				}) => (
					<div className="flex items-center">
						<StatusBox
							className="w-[43px]"
							status={InvitationStatus.ACTIVE}
							text={(acceptedClubCount + acceptedTeamCount).toString()}
						/>
						<StatusBox
							className="w-[43px]"
							status={InvitationStatus.PENDING}
							text={(appliedClubCount + appliedTeamCount).toString()}
						/>
						<StatusBox
							className="w-[43px]"
							status={InvitationStatus.REJECTED}
							text={(rejectedClubCount + rejectedTeamCount).toString()}
						/>
					</div>
				),
				dataKey: "statuses"
			},
			{
				label: "",
				verticalAlignment: ColumnVerticalAlignment.top,
				width: 60,
				Cell: ({ rowData }: { rowData: LeagueCompetitionModel }) => <MenuDots options={getOptions(rowData)} />,
				dataKey: "options",
				loaderType: TableColumnLoaderType.menuDots
			}
		],
		[getOptions, competitionTeamPage, t]
	);

	const handleCreateNew = useCallback(() => {
		setCreateCompetitionDialog({
			onClose: (competition?: CompetitionModel) => {
				setCreateCompetitionDialog(undefined);
				if (competition) {
					getCompetitions({ leagueId: id });
				}
			}
		});
	}, [getCompetitions, id]);

	const filterBlock = useMemo(
		() =>
			isAdmin ? (
				<div className="flex items-center flex-end">
					<Button size={ButtonSizesEnum.SM} onClick={handleCreateNew}>
						{t("create_competition")}
					</Button>
				</div>
			) : undefined,
		[handleCreateNew, isAdmin, t]
	);

	const emptyStateConfig: EmptyTableConfigModel = useMemo(
		() => ({
			text: t("you_dont_have_any_in_your_yet", {
				showEntity: t("competition").toLowerCase(),
				parentEntity: t("league").toLowerCase()
			}),
			btnText: t("create_competition"),
			onBtnClick: handleCreateNew
		}),
		[handleCreateNew, t]
	);

	return (
		<>
			<Table
				columns={tableColumns}
				data={competitions || []}
				loading={submitting || (loading && !nextListingCompetitionsToken)}
				loadingMore={loading && !!nextListingCompetitionsToken}
				totalDataCount={30}
				rowHeight={80}
				label={t("competitions")}
				emptyStateConfig={emptyStateConfig}
				filterBlock={filterBlock}
				tableSpacingClasses="pl-4"
				infiniteScroll
				onLoadMore={handleLoadMore}
			/>
			{competitionSettingsModal && (
				<CompetitionSettingsModal
					competitionId={
						typeof competitionSettingsModal === "string" ? (competitionSettingsModal as string) : undefined
					}
					overview={
						typeof competitionSettingsModal === "object" ? (competitionSettingsModal as CompetitionModel) : undefined
					}
				/>
			)}
			{!!createCompetitionDialog && (
				<Suspense>
					<CreateLeagueCompetitionModal leagueId={id} onClose={createCompetitionDialog.onClose} />
				</Suspense>
			)}
			{!!inviteClubsToCompetitionDialog && (
				<Suspense>
					<InviteClubsToCompetitionModal
						competitionId={inviteClubsToCompetitionDialog.competitionId}
						onClose={inviteClubsToCompetitionDialog.onClose}
					/>
				</Suspense>
			)}
			{!!warningModal?.open && (
				<Suspense>
					<ConfirmationDialog
						open
						onClose={warningModal.onClose}
						actions={warningModal.actions}
						title={warningModal.title}
						message={warningModal.message}
					/>
				</Suspense>
			)}
		</>
	);
};

export default LeagueCompetitionsPage;
