import type { FC } from "react";
import React from "react";

import LoaderIcon from "assets/icons/animated/spinner.svg?react";

interface LoaderProps {
	className?: string;
}

const Loader: FC<LoaderProps> = ({ className = "" }) => <LoaderIcon className={className} />;

export default Loader;
