import type React from "react";

import type { DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";

export const vibrateDevice = () => {
	if (window.navigator.vibrate) {
		window.navigator.vibrate(100);
	}
};

export const getDraggableItemStyle = (
	isDragging: boolean,
	draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
	customStyles: React.CSSProperties = {}
) => ({
	...draggableStyle,
	top: "auto !important", // fix position on dragging
	left: "auto !important",
	...customStyles
});
