import { ButtonBase, css, styled } from "@mui/material";

import colors from "theme/colors";

export const UIKitButton = styled(ButtonBase, {
	shouldForwardProp: prop =>
		prop !== "fillColor" && prop !== "variant" && prop !== "textColor" && prop !== "danger" && prop !== "dangerText"
})<{
	variant?: "filled" | "outlined" | "link";
	fillColor?: string;
	textColor?: string;
	danger?: boolean;
	dangerText?: boolean;
}>`
	transition:
		0.2s opacity,
		0.2s background-color ease-in-out;

	&.active {
		outline: 2px solid ${colors.palette.colors.primary[400]};
	}

	&.variant {
		&_danger {
			background-color: ${colors.palette.colors.red[600]};
			color: ${colors.palette.colors.red[50]};
			svg {
				fill: ${colors.palette.colors.white};
			}
			&:hover {
				background-color: ${colors.palette.colors.red[700]};
			}
			&:focus-visible {
				outline: 2px solid ${colors.palette.colors.red[400]};
			}
		}

		&_danger-secondary {
			background-color: ${colors.palette.colors.red[50]};
			color: ${colors.palette.colors.red[600]};
			svg {
				fill: ${colors.palette.colors.red[600]};
			}
			&:hover {
				background-color: ${colors.palette.colors.red[100]};
			}
			&:focus-visible {
				outline: 2px solid ${colors.palette.colors.red[200]};
			}
		}

		&_filled {
			background-color: ${colors.palette.colors.primary[500]};
			color: ${colors.palette.colors.white};
			svg {
				fill: ${colors.palette.colors.white};
			}
			&:hover {
				background-color: ${colors.palette.colors.primary[600]};
			}
			&:focus-visible {
				outline: 2px solid ${colors.palette.colors.primary[400]};
			}
		}

		&_filled-secondary {
			background-color: ${colors.palette.colors.lightGreen};
			color: ${colors.palette.colors.primary[700]};
			svg {
				fill: ${colors.palette.colors.primary[700]};
			}
			&:hover {
				background-color: ${colors.palette.colors.lightGreenHover};
			}
			&:focus-visible {
				outline: 2px solid ${colors.palette.colors.lightGreen};
			}
		}

		&_outlined {
			outline: 1px solid ${({ danger }) => (danger ? colors.palette.colors.red[600] : colors.palette.colors.gray[200])};
			svg {
				fill: ${({ danger }) => (danger ? colors.palette.colors.red[600] : colors.palette.colors.lightBlack)};
			}
			&:hover {
				outline-color: ${({ danger, dangerText }) =>
					danger || dangerText ? colors.palette.colors.red[500] : colors.palette.colors.primary[300]};
			}
			&:focus-visible {
				outline: 2px solid
					${({ danger, dangerText }) =>
						danger || dangerText ? colors.palette.colors.red[500] : colors.palette.colors.primary[300]};
			}
			${({ danger, dangerText }) =>
				(danger || dangerText) &&
				css`
					color: ${colors.palette.colors.red[600]};
				`}
		}

		&_ghost {
			svg {
				fill: ${colors.palette.colors.lightBlack};
			}
			&:hover {
				outline-color: ${colors.palette.colors.primary[300]};
			}
			&:focus-visible {
				outline: 2px solid ${colors.palette.colors.primary[300]};
			}
		}

		&_link {
			color: ${colors.palette.colors.primary[500]};
			svg {
				fill: ${colors.palette.colors.primary[500]};
			}
			&:hover {
				background-color: ${colors.palette.colors.primary[100]};
			}
			&:focus-visible {
				outline: 2px solid ${colors.palette.colors.primary[300]};
			}
		}
	}

	&.dontFillSvg svg {
		fill: none;
	}

	&.icon-button_filled {
		&.xsmall svg {
			width: 12px;
			height: 12px;
		}
		&.small svg {
			width: 16px;
			height: 16px;
		}
		&.medium svg {
			width: 18px;
			height: 18px;
		}
		&.large svg {
			width: 24px;
			height: 24px;
		}
		&.large svg {
			width: 20px;
			height: 20px;
		}
	}

	${({ fillColor }) => css`
		background-color: ${fillColor} !important;
	`}

	${({ textColor }) => css`
		color: ${textColor} !important;
	`}

	&:disabled {
		background-color: ${colors.palette.colors.gray[50]};

		color: ${colors.palette.colors.gray[200]};

		outline-color: ${colors.palette.colors.gray[50]};

		cursor: not-allowed;

		svg {
			fill: ${colors.palette.colors.gray[200]};
		}
	}
`;

export const LoaderWrapper = styled("div")`
	&.variant_filled,
	&.variant_danger {
		svg circle {
			stroke: ${colors.palette.colors.white};
		}
	}
`;
