import React, { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import type { Control, FieldErrors, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { Controller, useWatch } from "react-hook-form";

import TrashIcon from "assets/icons/outlined/trash.svg?react";
import type { SetupCategoryModel } from "shared/types";
import { AgeGroupEnum } from "shared/types";
import { Button, Input, Switch, Text } from "shared/uikit";
import type { DropdownOptionModel } from "shared/uikit/Input/Dropdown";

import { ButtonSizesEnum, ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { getYearDateByAgeValue } from "utils/getAge";

import { getDateTime } from "utils/getDateTime";

import { SettingsRow } from "./style";

const behaviorList: DropdownOptionModel[] = [
	// { id: "over", label: "Over", value: ClubCategoryBehavior.OVER }, // reenable it once BE ready for it
	{ id: "under", label: "Under", value: AgeGroupEnum.UNDER }
	// { id: "open", label: "Open", value: ClubCategoryBehavior.OPEN } // reenable it once BE ready for it
];

const ageList: DropdownOptionModel[] = [...Array(100)].map((x, i) => ({
	label: `${i + 1}`,
	value: i + 1
}));

interface RenderCategoryModel {
	category: SetupCategoryModel & { fieldId: string };
	categoryIndex: number;
	totalCount: number;
	onRemove: (category?: SetupCategoryModel) => void;
	trigger: UseFormTrigger<{ categories: SetupCategoryModel[] }>;
	control: Control<{ categories: SetupCategoryModel[] }, any>;
	setValue: UseFormSetValue<{ categories: SetupCategoryModel[] }>;
	errors: FieldErrors<{ categories: SetupCategoryModel[] }>;
	modalView?: boolean;
	isRevalidating: boolean;
	categoryOptions?: boolean;
}

interface OptsArray {
	label: string;
	value: number;
}

const CategoryRow: React.FC<RenderCategoryModel> = ({
	category,
	categoryIndex,
	totalCount,
	onRemove,
	trigger,
	control,
	setValue,
	errors,
	modalView,
	isRevalidating,
	categoryOptions
}) => {
	const [year, setYear] = useState<number | undefined>(
		category?.value ? getYearDateByAgeValue(category.value)?.year + (category?.yearOffset || 0) : undefined
	);

	const [yearOffset, setYearOffset] = useState(category?.yearOffset || 0);

	const [behavior, categoryValue, date] = useWatch({
		control,
		name: [
			`categories.${categoryIndex}.behavior`,
			`categories.${categoryIndex}.value`,
			`categories.${categoryIndex}.date`
		]
	});

	const buildName = ({ behavior, age }: { behavior?: AgeGroupEnum; age?: number }) => {
		if (behavior || age) {
			const letter = behavior
				? behavior === AgeGroupEnum.UNDER
					? "U"
					: behavior === AgeGroupEnum.OVER
						? "O"
						: "Open"
				: undefined;

			return `${letter || ""}${age || ""}`;
		}
		return "";
	};

	const handleChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newYear = Number(e.target.value.replace(/\D/g, "").slice(0, 4));
		setYear(newYear);

		if (!isNaN(newYear)) {
			let dateTime = getDateTime(date);
			dateTime = dateTime.set({ year: newYear });
			setValue(`categories.${categoryIndex}.date`, dateTime.toJSDate());

			if (categoryValue) {
				const { year } = getYearDateByAgeValue(categoryValue);
				setYearOffset(newYear - year);
			}
		}
	};

	useEffect(() => {
		if (categoryValue) {
			const newName = buildName({
				behavior: behavior || undefined,
				age: categoryValue
			});

			setValue(`categories.${categoryIndex}.name`, newName);

			isRevalidating && trigger();
		}
	}, [categoryValue, behavior, categoryIndex, trigger, setValue, isRevalidating]);

	const matchFormatOptions = useMemo(() => {
		const res: OptsArray[] = [];
		for (let i = 6; i <= 11; i++) {
			const label = `${i} players`;
			res.push({
				value: i,
				label
			});
		}
		return res;
	}, []);

	const minDate = useMemo(
		() =>
			categoryValue
				? DateTime.now()
						.set({ year: DateTime.now().year - categoryValue + 1 + yearOffset, month: 1, day: 1 })
						.toJSDate()
				: undefined,
		[categoryValue, yearOffset]
	);

	const maxDate = useMemo(
		() =>
			categoryValue
				? DateTime.now()
						.set({ year: DateTime.now().year - categoryValue + 1 + yearOffset, month: 12, day: 31 })
						.toJSDate()
				: undefined,
		[categoryValue, yearOffset]
	);

	const inputSize = useMemo(() => (modalView ? ButtonSizesEnum.UNDER_MD : ButtonSizesEnum.LG), [modalView]);

	return (
		<div
			className={"mb-4 pb-4 border-b-[1px] border-b-gray-200 last:mb-0 last:pb-0 last:border-0 relative"}
			key={category.fieldId}
		>
			{totalCount > 1 && (
				<Button
					variant={ButtonVariantsEnum.DANGER}
					secondary
					dontFillSvg
					transparentBg
					size={ButtonSizesEnum.XS}
					onClick={() => onRemove(category)}
					className="remove absolute top-1 -right-4 z-10"
					startIcon={<TrashIcon className="w-4 h-4 svg-paths:stroke-red-600" />}
				>
					Delete category
				</Button>
			)}
			<Text style={TextStylesEnum.Hint} variants={TextVariantsEnum.Caption3} className="mb-2">
				Category {categoryIndex + 1}
			</Text>
			<SettingsRow className="w-full relative gap-2 grid items-end">
				<Controller
					name={`categories.${categoryIndex}.behavior`}
					control={control}
					rules={{
						required: "Behavior is required"
					}}
					render={({ field: { onChange, value } }) => (
						<Input
							id={`behaviour-dropdown-${categoryIndex + 1}`}
							dropdown
							headline="Behaviour"
							boldHeadline
							size={inputSize}
							onChange={onChange}
							value={value}
							defaultValue=""
							options={behaviorList}
							placeholder="Select"
							className="w-full"
							error={!!errors?.["categories"]?.[categoryIndex]?.["behavior"]?.["message"]}
						/>
					)}
				/>
				<Controller
					name={`categories.${categoryIndex}.value`}
					control={control}
					rules={{
						required: "Age is required"
					}}
					render={({ field: { onChange, value } }) => (
						<Input
							id={`age-dropdown-${categoryIndex + 1}`}
							dropdown
							headline="Age"
							boldHeadline
							size={inputSize}
							onChange={val => {
								onChange(val);

								const { year, date } = getYearDateByAgeValue(val);
								setYear(year);
								setYearOffset(0);
								setValue(`categories.${categoryIndex}.date`, date);
							}}
							value={value}
							defaultValue=""
							options={ageList}
							placeholder="Select"
							className="w-full"
							error={!!errors?.categories?.[categoryIndex]?.value?.message}
						/>
					)}
				/>
				<Controller
					name={`categories.${categoryIndex}.date`}
					control={control}
					rules={{
						validate: data => {
							const luxonDate = DateTime.fromJSDate(new Date(data));
							return !behavior ||
								(behavior !== AgeGroupEnum.OPEN && !data) ||
								(data && luxonDate?.invalidReason) ||
								luxonDate?.year < 1900
								? "Incorrect Date"
								: true;
						}
					}}
					render={({ field: { onChange, value } }) => (
						<Input
							headline="Cutoff"
							boldHeadline
							calendar
							size={inputSize}
							onChange={val => {
								onChange(val);
								isRevalidating && trigger();
							}}
							dateFormat={"LLLL, d"}
							value={value}
							placeholder="Date"
							className="w-full"
							disabled={!behavior || behavior === AgeGroupEnum.OPEN || !categoryValue}
							error={!!errors?.categories?.[categoryIndex]?.date?.message}
							minDate={minDate}
							maxDate={maxDate}
						/>
					)}
				/>
				<Input
					size={inputSize}
					value={year}
					onChange={handleChangeYear}
					placeholder="Year"
					className="w-full"
					disabled={!behavior || behavior === AgeGroupEnum.OPEN || !categoryValue}
				/>
			</SettingsRow>
			<div className="mt-4">
				<Controller
					name={`categories.${categoryIndex}.name`}
					control={control}
					rules={{ required: "Name is required", min: 2 }}
					render={({ field: { onChange, value } }) => (
						<Input
							headline="Category name"
							boldHeadline
							id={`category-name-${categoryIndex + 1}`}
							size={inputSize}
							onChange={val => {
								onChange(val);
								isRevalidating && trigger();
							}}
							value={value}
							placeholder="Enter name"
							className="w-full"
							error={!!errors?.categories?.[categoryIndex]?.name?.message}
							errorText={errors?.categories?.[categoryIndex]?.name?.message}
						/>
					)}
				/>
			</div>
			{categoryOptions && (
				<div className="mt-4 grid gap-x-2 gap-y-5 items-start grid-cols-3">
					<Controller
						control={control}
						name={`categories.${categoryIndex}.matchFormat`}
						rules={{ required: "Match format is required" }}
						render={({ field: { onChange, value } }) => (
							<Input
								onChange={onChange}
								value={value}
								size={inputSize}
								headline="Match format"
								placeholder="Select"
								dropdown
								options={matchFormatOptions}
								error={!!errors?.categories?.[categoryIndex]?.matchFormat?.message}
								errorText={errors?.categories?.[categoryIndex]?.matchFormat?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name={`categories.${categoryIndex}.duration`}
						rules={{
							required: "Duration is required",
							validate: value => {
								const val = Number(value);
								return val < 10 ? "Min 10" : val > 120 ? "Max 120" : true;
							}
						}}
						render={({ field: { onChange, value } }) => (
							<Input
								onChange={onChange}
								value={value}
								size={inputSize}
								headline="Duration"
								placeholder="0"
								type="number"
								error={!!errors?.categories?.[categoryIndex]?.duration?.message}
								errorText={errors?.categories?.[categoryIndex]?.duration?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name={`categories.${categoryIndex}.includeHalftime`}
						defaultValue={false}
						render={({ field: { onChange, value } }) => (
							<div className="flex items-center gap-3 mt-8">
								<Switch onChange={onChange} checked={value} />
								<Text variants={TextVariantsEnum.BodySmall}>Include halftime</Text>
							</div>
						)}
					/>
					<Controller
						control={control}
						name={`categories.${categoryIndex}.matchBuffer`}
						rules={{
							required: "Match buffer is required",
							validate: value => {
								const val = Number(value);
								return val < 5 ? "Min 5" : val > 60 ? "Max 60" : true;
							}
						}}
						render={({ field: { onChange, value } }) => (
							<Input
								onChange={onChange}
								value={value}
								size={inputSize}
								headline="Match buffer"
								placeholder="60"
								type="number"
								error={!!errors?.categories?.[categoryIndex]?.matchBuffer?.message}
								errorText={errors?.categories?.[categoryIndex]?.matchBuffer?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name={`categories.${categoryIndex}.refereePerGame`}
						rules={{
							required: "Referees per game is required",
							validate: value => {
								const val = Number(value);
								return val > 4 ? "Max 4" : true;
							}
						}}
						render={({ field: { onChange, value } }) => (
							<Input
								onChange={onChange}
								value={value}
								size={inputSize}
								headline="Referees per game"
								placeholder="1"
								type="number"
								error={!!errors?.categories?.[categoryIndex]?.refereePerGame?.message}
								errorText={errors?.categories?.[categoryIndex]?.refereePerGame?.message}
							/>
						)}
					/>
				</div>
			)}
		</div>
	);
};

export default CategoryRow;
