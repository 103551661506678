import type { ReactNode } from "react";
import React, { Fragment, useCallback, useEffect, useMemo, useRef } from "react";

import { Accordion } from "@mui/material";

import clsx from "clsx";

import ChevronIcon from "assets/icons/outlined/chevron-right.svg?react";

import { useOnScreen, useWindowSize } from "shared/hooks";

import { TableColumnLoaderType } from "shared/types/Table";
import { Checkbox, Text } from "shared/uikit";

import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { Column, ExpandIconWrapper, Row, TableAccordionDetails, TableAccordionSummary, Wrapper } from "./style";

import { SkeletonLoader } from "../index";

export enum TableSpecialColumnType {
	checkbox = "checkbox",
	expand = "expand"
}

export enum ColumnAlignment {
	center = "center",
	left = "left",
	right = "right"
}

export enum ColumnVerticalAlignment {
	center = "center",
	top = "top",
	bottom = "bottom"
}

export const ExpandableColumnDataKey = "__expandable__";

export interface TableColumnType {
	label: string | React.ReactNode;
	alignment?: ColumnAlignment;
	verticalAlignment?: ColumnVerticalAlignment;
	minWidth?: number;
	maxWidth?: number;
	width?: number;
	Cell?: ({ rowData }: { rowData: any; rowIndex: number }) => string | ReactNode;
	expandCell?: ({ rowData }: { rowData: any; rowIndex: number; isExpanded: boolean }) => string | ReactNode;
	dataKey: string;
	type?: TableSpecialColumnType;
	loaderTemplate?: ReactNode;
	loaderType?: TableColumnLoaderType;
}

export interface SimpleTableProps {
	columns: TableColumnType[];
	data: any[];
	dontTruncate?: boolean;

	tableSpacingClasses?: string;

	width?: number;
	height?: number;

	headerHeight: number;
	rowHeight: number;

	hideHeader?: boolean;

	checkable?: boolean;
	blockCheckAll?: boolean;
	checkableColumnWidth: number;
	checkableColumnPosition?: "left" | "right";
	isRowCheckable?: (data: any) => boolean;
	isRowChecked?: (index: number) => boolean;
	isHighlightedRow?: (index: number) => boolean;
	hideNonCheckables?: boolean;
	onToggleCheckRow?: ({
		isChecked,
		toggledIndex,
		checkedRows
	}: {
		isChecked: boolean;
		toggledIndex?: number;
		checkedRows?: number[];
	}) => void;
	checkboxVerticalAlignment?: ColumnVerticalAlignment;

	expandable?: boolean;
	isRowExpandable?: (data: any) => boolean;
	onExpandRow?: (index: number, expanded: boolean) => void;

	loading?: boolean;
	loadingMore?: boolean;

	pageSize?: number;

	noColumnsBottomBorder?: boolean;

	infiniteScroll?: boolean;
	onLoadMore?: () => void;
}

const SimpleTable: React.FC<SimpleTableProps> = ({
	data,
	columns,
	tableSpacingClasses,
	dontTruncate,
	rowHeight,
	headerHeight,
	checkable,
	blockCheckAll,
	expandable,
	isRowCheckable,
	isRowChecked,
	isRowExpandable,
	checkableColumnWidth,
	checkableColumnPosition = "left",
	checkboxVerticalAlignment,
	hideNonCheckables = false,
	onToggleCheckRow,
	hideHeader = false,
	onExpandRow,
	loading = false,
	loadingMore,
	pageSize,
	noColumnsBottomBorder,
	isHighlightedRow,
	infiniteScroll,
	onLoadMore
}) => {
	const wrapperRef = React.useRef<HTMLDivElement>(null);

	const { width: screenWidth } = useWindowSize(200);

	const [columnList, setColumnList] = React.useState(columns || []);
	const [checkableIndexes, setCheckableIndexes] = React.useState<number[]>([]);
	const [checkedRowIndexes, setCheckedRowIndexes] = React.useState<number[]>([]);

	const [expandableIndexes, setExpandableIndexes] = React.useState<number[]>([]);
	const [expandedRowIndexes, setExpandedRowIndexes] = React.useState<number[]>([]);

	const lastItemRef = useRef<HTMLHRElement | null>(null);
	const onScreen = useOnScreen(lastItemRef);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const wrapperWidth = useMemo(() => wrapperRef.current?.clientWidth || 0, [screenWidth, wrapperRef.current]);

	const availableSpacePerColumn = useMemo(() => {
		if (wrapperWidth && columnList.length) {
			let busySpace = 0;
			let hardcodedWidthColumn = 0;
			columnList.forEach(column => {
				if (column?.width) {
					busySpace += column.width;
					hardcodedWidthColumn++;
				}
			});

			const available = wrapperWidth - busySpace;
			let tempAvailablePerColumn = available / (columnList.length - hardcodedWidthColumn);

			if (columnList.some(x => x?.minWidth && x.minWidth > tempAvailablePerColumn)) {
				// recalculate with using minWidth
				busySpace = 0;
				hardcodedWidthColumn = 0;
				columnList.forEach(column => {
					if (column?.width || (column?.minWidth && column?.minWidth > tempAvailablePerColumn)) {
						busySpace +=
							(column?.minWidth && column.minWidth > tempAvailablePerColumn ? column!.minWidth : column.width) || 0;
						hardcodedWidthColumn++;
					}
				});

				const available = wrapperWidth - busySpace;
				tempAvailablePerColumn = available / (columnList.length - hardcodedWidthColumn);
			}

			if (columnList.some(x => x?.maxWidth && x.maxWidth < tempAvailablePerColumn)) {
				// recalculate with using maxWidth
				busySpace = 0;
				hardcodedWidthColumn = 0;
				columnList.forEach(column => {
					if (column?.width || (column?.maxWidth && column?.maxWidth < tempAvailablePerColumn)) {
						busySpace +=
							(column?.maxWidth && column.maxWidth < tempAvailablePerColumn ? column!.maxWidth : column.width) || 0;
						hardcodedWidthColumn++;
					}
				});

				const available = wrapperWidth - busySpace;
				tempAvailablePerColumn = available / (columnList.length - hardcodedWidthColumn);
			}

			return tempAvailablePerColumn;
		}
		return 0;
	}, [columnList, wrapperWidth]);

	const _columnWidth = useMemo(
		() => (index: number) => {
			const widths = columnList[index]
				? {
						minWidth:
							columnList[index]?.minWidth && !isNaN(columnList[index]!.minWidth!)
								? columnList[index]!.minWidth!
								: undefined,
						maxWidth:
							columnList[index]?.maxWidth && !isNaN(columnList[index]!.maxWidth!)
								? columnList[index]!.maxWidth!
								: undefined,
						width: columnList[index]?.width && !isNaN(columnList[index]!.width!) ? columnList[index]!.width! : undefined
					}
				: {
						minWidth: undefined,
						maxWidth: undefined,
						width: undefined
					};

			let width = widths?.width || widths?.minWidth || availableSpacePerColumn;
			if (!widths?.width && widths.minWidth && availableSpacePerColumn > widths.minWidth) {
				width = availableSpacePerColumn;
			}

			if (widths?.minWidth && width < widths.minWidth) {
				width = widths.minWidth;
			}

			if (widths?.maxWidth && width > widths.maxWidth) {
				width = widths.maxWidth;
			}

			return width;
		},
		[columnList, availableSpacePerColumn]
	);

	const toggleSelectAllRow = useCallback(() => {
		const isCheckAll = checkedRowIndexes.length !== checkableIndexes.length;
		setCheckedRowIndexes(isCheckAll ? checkableIndexes : []);

		onToggleCheckRow && onToggleCheckRow({ isChecked: isCheckAll, checkedRows: checkableIndexes });
	}, [checkableIndexes, checkedRowIndexes, onToggleCheckRow]);

	const isCheckedRow = useCallback(
		(index: number) => (isRowChecked ? isRowChecked(index) : checkedRowIndexes.indexOf(index) > -1),
		[checkedRowIndexes, isRowChecked]
	);

	const isCheckableRow = useCallback((index: number) => checkableIndexes.indexOf(index) > -1, [checkableIndexes]);

	const isRowExpanded = useCallback((index: number) => expandedRowIndexes.indexOf(index) > -1, [expandedRowIndexes]);

	const toggleCheckboxRow = useCallback(
		(index: number) => {
			if (isRowChecked) {
				onToggleCheckRow && onToggleCheckRow({ isChecked: !isRowChecked(index), toggledIndex: index });
			} else {
				const checkedIndex = checkedRowIndexes.findIndex(x => x === index);
				if (checkedIndex === -1) {
					if (checkableIndexes.includes(index)) {
						setCheckedRowIndexes(x => [...x, index]);
						onToggleCheckRow && onToggleCheckRow({ isChecked: true, toggledIndex: index });
					}
				} else {
					setCheckedRowIndexes(x => x.filter(i => i !== index));
					onToggleCheckRow && onToggleCheckRow({ isChecked: false, toggledIndex: index });
				}
			}
		},
		[checkedRowIndexes, checkableIndexes, onToggleCheckRow, isRowChecked]
	);

	const toggleExpand = useCallback(
		(index: number, expanded: boolean) => {
			setExpandedRowIndexes(x => (expanded ? x.filter(i => i !== index) : [...x, index]));
			onExpandRow && onExpandRow(index, !expanded);
		},
		[onExpandRow]
	);

	useEffect(() => {
		onScreen && onLoadMore && onLoadMore();
	}, [onScreen, onLoadMore]);

	useEffect(() => {
		let indexes: any[] = [];
		if (typeof isRowCheckable === "function") {
			data.forEach((currentData, index) => {
				if (isRowCheckable(currentData)) {
					indexes.push(index);
				}
			});

			setCheckableIndexes(indexes);
		} else {
			indexes = data.map((val, index) => (!val._expandData ? index : null)).filter(x => x !== null);
			setCheckableIndexes([...indexes]);
		}
	}, [isRowCheckable, data]);

	useEffect(() => {
		let indexes: any[] = [];
		if (typeof isRowExpandable === "function") {
			data.forEach((currentData, index) => {
				if (isRowExpandable(currentData)) {
					indexes.push(index);
				}
			});

			setExpandableIndexes(indexes);
		} else {
			indexes = data.map((val, index) => (!val._expandDataContent ? index : null)).filter(x => x !== null);
			setExpandableIndexes([...indexes]);
		}
	}, [isRowExpandable, data]);

	useEffect(() => {
		const newColumnList = [...columns];

		if (checkable) {
			newColumnList[checkableColumnPosition === "left" ? "unshift" : "push"]({
				width: checkableColumnWidth,
				alignment: ColumnAlignment.left,
				verticalAlignment: checkboxVerticalAlignment || ColumnVerticalAlignment.center,
				label: "",
				type: TableSpecialColumnType.checkbox,
				dataKey: "__checkbox__",
				loaderType: TableColumnLoaderType.checkbox
			});
		}

		if (expandable) {
			const expandableColumnConfig = {
				width: 64,
				alignment: ColumnAlignment.center,
				label: "",
				type: TableSpecialColumnType.expand,
				dataKey: ExpandableColumnDataKey,
				loaderType: TableColumnLoaderType.expand
			};
			const columnIndex = newColumnList.findIndex(x => x.dataKey === ExpandableColumnDataKey);
			if (columnIndex > -1) {
				newColumnList[columnIndex] = {
					...expandableColumnConfig,
					...newColumnList[columnIndex]
				};
			}
		}

		setColumnList([...newColumnList]);
	}, [
		columns,
		checkable,
		checkableColumnWidth,
		checkableColumnPosition,
		hideNonCheckables,
		expandable,
		checkboxVerticalAlignment
	]);

	const ExpandeButton = useCallback(
		(rowIndex: number) => {
			const expanded = isRowExpanded(rowIndex);

			return (
				<ExpandIconWrapper onClick={() => toggleExpand(rowIndex, expanded)} aria-label="Expand">
					<ChevronIcon
						className={clsx(
							"w-4 h-4 transition-transform svg-paths:stroke-gray-300",
							expanded ? "-rotate-90" : "rotate-90"
						)}
					/>
				</ExpandIconWrapper>
			);
		},
		[isRowExpanded, toggleExpand]
	);

	const columnStyles = useCallback(
		(width: number) => ({
			width,
			minWidth: width,
			maxWidth: width
		}),
		[]
	);

	const RenderRow = useCallback(
		(rowIndex: number, rowData: any) => {
			const isChecked = isCheckedRow(rowIndex);
			const isCheckable = isCheckableRow(rowIndex);
			const isHighlighted = isHighlightedRow ? isHighlightedRow(rowIndex) : false;

			return (
				<Row
					className={clsx(
						"w-full flex",
						data.length - 1 === rowIndex && "last-row",
						isChecked && "bg-primary-40",
						isHighlighted && "bg-lightGray"
					)}
					style={{ height: rowHeight }}
				>
					{columnList.map((column, columnIndex) => {
						const columnContent =
							column?.type !== TableSpecialColumnType.checkbox && column?.type !== TableSpecialColumnType.expand
								? column?.Cell && column.Cell({ rowData, rowIndex })
								: undefined;

						return (
							<Column
								key={columnIndex}
								style={columnStyles(_columnWidth(columnIndex))}
								className={clsx(
									"column",
									column?.alignment && `alignment_${column?.alignment}`,
									column?.verticalAlignment && `vertical-alignment_${column?.verticalAlignment}`,
									!dontTruncate && "truncate",
									tableSpacingClasses
								)}
							>
								{columnContent && (
									<>
										{typeof columnContent === "string" ? (
											<Text
												className={clsx(!dontTruncate && "truncate")}
												title={columnContent}
												variants={TextVariantsEnum.BodyMedium}
											>
												{columnContent}
											</Text>
										) : (
											columnContent
										)}
									</>
								)}
								{column?.type === TableSpecialColumnType.checkbox && isCheckable && (
									<div className="pl-2">
										<Checkbox value={isCheckedRow(rowIndex)} onChange={() => toggleCheckboxRow(rowIndex)} />
									</div>
								)}
								{column?.type === TableSpecialColumnType.expand &&
									expandableIndexes.includes(rowIndex) &&
									ExpandeButton(rowIndex)}
							</Column>
						);
					})}
				</Row>
			);
		},
		[
			ExpandeButton,
			_columnWidth,
			columnList,
			columnStyles,
			data,
			expandableIndexes,
			isCheckedRow,
			isHighlightedRow,
			rowHeight,
			toggleCheckboxRow,
			dontTruncate,
			tableSpacingClasses,
			isCheckableRow
		]
	);

	const RenderExpandedRow = useCallback(
		(rowIndex: number, rowData: any, isExpanded: boolean) => {
			const columnIndex = columnList.findIndex(x => x.dataKey === ExpandableColumnDataKey);
			if (columnIndex > -1 && columnList[columnIndex]?.expandCell) {
				return columnList[columnIndex]!.expandCell({ rowData, rowIndex, isExpanded });
			}
			return null;
		},
		[columnList]
	);

	if (loading) {
		return (
			<Wrapper ref={wrapperRef}>
				{!!wrapperWidth && (
					<SkeletonLoader
						pageSize={pageSize || 5}
						columnWidth={_columnWidth}
						columnList={columnList}
						headerHeight={headerHeight}
						rowHeight={rowHeight}
						hideHeader={hideHeader}
					/>
				)}
			</Wrapper>
		);
	}

	return (
		<Wrapper ref={wrapperRef}>
			{!!wrapperWidth && (
				<>
					{!hideHeader && (
						<div className="flex">
							<div className="w-full flex" style={{ height: headerHeight }}>
								{columnList.map((column, columnIndex) => (
									<Column
										key={`table-head__column-${columnIndex}`}
										style={columnStyles(_columnWidth(columnIndex))}
										className={clsx(
											column?.alignment && `alignment_${column?.alignment}`,
											!dontTruncate && "truncate",
											tableSpacingClasses,
											noColumnsBottomBorder && "border-b-0"
										)}
									>
										{column?.label && (
											<>
												{typeof column.label === "string" ? (
													<Text
														style={TextStylesEnum.Hint}
														variants={TextVariantsEnum.H7}
														className={clsx(!dontTruncate && "truncate")}
													>
														{column.label}
													</Text>
												) : (
													column.label
												)}
											</>
										)}
										{column?.type === TableSpecialColumnType.checkbox && !blockCheckAll && (
											<div className="pl-2">
												<Checkbox
													value={checkedRowIndexes.length === checkableIndexes.length}
													onChange={toggleSelectAllRow}
												/>
											</div>
										)}
									</Column>
								))}
							</div>
						</div>
					)}
					{data.map((row, rowIndex) => (
						<Fragment key={rowIndex}>
							{expandable && expandableIndexes.includes(rowIndex) ? (
								<Accordion
									disableGutters
									elevation={0}
									square
									expanded={isRowExpanded(rowIndex)}
									className="bg-transparent before:hidden"
								>
									<TableAccordionSummary>{RenderRow(rowIndex, data[rowIndex])}</TableAccordionSummary>
									<TableAccordionDetails>
										{RenderExpandedRow(rowIndex, data[rowIndex], isRowExpanded(rowIndex))}
									</TableAccordionDetails>
								</Accordion>
							) : (
								RenderRow(rowIndex, data[rowIndex])
							)}
						</Fragment>
					))}
					{infiniteScroll && <hr ref={lastItemRef} className="opacity-0 h-0 border-0" />}
					{loadingMore && (
						<SkeletonLoader
							pageSize={2}
							columnWidth={_columnWidth}
							columnList={columnList}
							rowHeight={rowHeight}
							hideHeader
						/>
					)}
				</>
			)}
		</Wrapper>
	);
};

export default SimpleTable;
