import React from "react";

import config from "config/appConfig";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useUser } from "shared/hooks";
import useJwt from "shared/services/api/core/jwt";
import { UserRoleEnum } from "shared/types";

interface SecureRouteProps {
	roles?: UserRoleEnum[];
}

const SecureRoute = ({ roles = [] }: SecureRouteProps) => {
	const jwtService = useJwt();

	const { pathname, search } = useLocation();

	const { getData: getUserData } = useUser();
	const { myProfile } = getUserData();

	const hasJwt = jwtService.hasJwt();
	let isAllow = hasJwt;

	if (isAllow && roles?.length) {
		isAllow = roles.some(requiredRole => {
			if (requiredRole === UserRoleEnum.GLOBAL_ADMIN) {
				return !!myProfile?.isAdmin;
			}
			return false;
		});
	}

	if (!isAllow) {
		const query = new URLSearchParams("");
		query.set("redirectUrl", encodeURIComponent(`${pathname}${search}`));

		return (
			<Navigate
				to={{
					pathname: hasJwt ? config.CORE_URLS.HOME : `${config.CORE_URLS.LOGIN}`,
					search: query.toString()
				}}
				replace
			/>
		);
	}

	return <Outlet />;
};

export default SecureRoute;
