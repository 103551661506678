import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import LocationIcon from "assets/icons/outlined/location.svg?react";
import type { LocationModel } from "shared/types";

import { getGoogleMapsUrl } from "utils/getGoogleMapsUrl";

import type { InfoBoxProps } from "./index";

import { InfoBox } from "../index";

interface LocationInfoBoxProps extends Omit<InfoBoxProps, "title" | "subtitle"> {
	location?: LocationModel | null;
	subtitle?: string;
}

const LocationInfoBox: React.FC<LocationInfoBoxProps> = memo(({ location, subtitle, ...rest }) => {
	const { t } = useTranslation();

	return (
		<InfoBox
			{...rest}
			title={location?.address || "-"}
			titleHref={location ? getGoogleMapsUrl(location.lat, location.lng) : undefined}
			subtitle={subtitle || t("address")}
			icon={<LocationIcon className="w-5 h-5 svg-paths:stroke-primary-600" />}
		/>
	);
});

export default LocationInfoBox;
