import { styled } from "@mui/material";

import { Scrollbars } from "react-custom-scrollbars-2";

export const ScrollWrapper = styled(Scrollbars)`
	&.disabled {
		// override values
		& > .track-vertical {
			max-height: unset !important;
			min-height: unset !important;
			overflow: hidden !important;
		}
	}
`;
