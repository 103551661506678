import type { FC, ReactNode } from "react";
import React, { memo } from "react";

import clsx from "clsx";

import { Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

interface HeadlinesProps {
	step?: number;
	question: string;
	description: string | ReactNode;
	subtitle?: string;
	footerExtra?: ReactNode;
	noMargin?: boolean;
}

const Headlines: FC<HeadlinesProps> = memo(({ step, question, description, subtitle, footerExtra, noMargin }) => (
	<div className={clsx(!noMargin && "mb-6")}>
		{(subtitle || step !== undefined) && (
			<Text variants={TextVariantsEnum.H7} style={TextStylesEnum.Hint}>
				{subtitle || `Step ${step}`}
			</Text>
		)}
		<Text variants={TextVariantsEnum.H2} className="my-2">
			{question}
		</Text>
		<Text variants={TextVariantsEnum.BodyMedium} style={TextStylesEnum.Hint}>
			{description}
		</Text>
		{footerExtra}
	</div>
));

export default Headlines;
