import type { FC } from "react";
import React from "react";

import clsx from "clsx";

import InfoIcon from "assets/icons/filled/info.svg?react";

import { Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

interface ErrorHintProps {
	text?: string;
	showIcon?: boolean;
	type?: "warning" | "error";
	alignTop?: boolean;
	className?: string;
}

const ErrorHint: FC<ErrorHintProps> = ({
	text = "",
	showIcon = true,
	type = "error",
	alignTop = false,
	className = ""
}) => (
	<div className={clsx("flex", className, alignTop ? "items-start" : "items-center")}>
		{showIcon && (
			<InfoIcon
				className={clsx(
					"w-[1rem] h-[1rem]",
					type === "error" ? "mr-1 svg-paths:fill-red-600" : "mr-2 svg-paths:fill-yellow-500"
				)}
			/>
		)}
		<Text
			variants={type === "error" ? TextVariantsEnum.BodySmall : TextVariantsEnum.H7}
			style={type === "error" ? TextStylesEnum.Alert : TextStylesEnum.Hint}
		>
			{text}
		</Text>
	</div>
);

export default ErrorHint;
