import { ButtonBase, css, styled } from "@mui/material";

import colors from "theme/colors";

export const Wrapper = styled("div")`
	border-radius: 50%;

	overflow: hidden;

	&.bordered {
		border-radius: 0;
	}

	&.custom {
		border-radius: 0;
		overflow: visible;
	}
`;

export const paperStyle = {
	background: colors.palette.colors.white,
	border: "1px solid rgba(220, 229, 245, 0.5)",
	boxShadow: "0 0 12px rgba(0, 0, 0, 0.1)",
	borderRadius: "12px",
	padding: "6px 8px"
};

export const MoreActionsButton = styled(ButtonBase)`
	&:hover {
		background-color: ${colors.palette.colors.gray["50"]};
	}

	&.bordered {
		border: 1px solid ${colors.palette.colors.gray["200"]};
		border-radius: 6px;
	}
`;

export const DotsIconWrapper = styled("div", {
	shouldForwardProp: prop => prop !== "fillColor"
})<{ fillColor?: string }>(({ fillColor }) => {
	return css`
		svg path {
			${fillColor &&
			css`
				fill: ${fillColor};
			`}
		}
	`;
});
