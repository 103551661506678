import { styled } from "@mui/material";

import { Button } from "shared/uikit";

import colors from "theme/colors";

export const LevelManageIconWrapper = styled("div")`
	width: 24px;
	height: 100%;

	position: absolute;

	display: flex;
	align-items: center;

	cursor: pointer;

	&.remove {
		right: -40px;

		svg {
			path {
				&:first-of-type {
					fill: ${colors.palette.colors.gray["100"]};
					stroke: ${colors.palette.colors.gray["100"]};
				}

				&:nth-of-type(2),
				&:nth-of-type(3) {
					stroke: ${colors.palette.colors.gray["500"]};
				}
			}
		}
	}
`;

export const StyledButton = styled(Button)`
	svg {
		path {
			stroke: ${colors.palette.colors.primary["500"]};
		}
	}
`;

export const SettingsRow = styled("div")`
	grid-template-columns: 29fr 21fr 35fr 15fr;
`;
