import type { ReactNode } from "react";
import React, { useCallback } from "react";

import clsx from "clsx";

import { Text } from "shared/uikit";

import { ButtonSizesEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

import { StyledForm } from "./styles";

export interface FormProps {
	children: ReactNode;
	title?: string | ReactNode;
	subtitle?: string;
	size?: ButtonSizesEnum;
	onSubmit?: () => void;
	keepTitleTag?: boolean;
	className?: string;
}

const Form: React.FC<FormProps> = ({
	children,
	title,
	subtitle,
	size = ButtonSizesEnum.SM,
	onSubmit,
	keepTitleTag,
	className
}) => {
	const handleSubmit = useCallback(
		e => {
			e.preventDefault();
			onSubmit && onSubmit();
		},
		[onSubmit]
	);

	return (
		<StyledForm className={clsx("p-10", `size_${size}`, className)} onSubmit={handleSubmit}>
			{typeof title === "string" || keepTitleTag ? (
				<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.H1}>
					{title}
				</Text>
			) : (
				title
			)}
			{subtitle && (
				<div className="mt-2">
					<Text style={TextStylesEnum.Default} variants={TextVariantsEnum.BodyMedium}>
						{subtitle}
					</Text>
				</div>
			)}
			<div className={clsx((title || subtitle) && "pt-8")}>{children}</div>
		</StyledForm>
	);
};

export default Form;
