import { styled } from "@mui/material";

import colors from "theme/colors";

export const StyledForm = styled("form")`
	background-color: ${colors.palette.colors.white};

	border: 1px solid ${colors.palette.colors.gray["100"]};
	border-radius: 12px;

	width: 480px;

	&.size {
		&_medium {
			width: 684px;
		}
	}
`;
