import React, { Suspense, lazy } from "react";

import config from "config/appConfig";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));

const FacilityLayout = lazy(() => import("modules/Facility/View/layouts/FacilityLayout"));
const FacilityPageLayout = lazy(() => import("modules/Facility/View/layouts/FacilityPageLayout"));

const FacilityAddressPage = lazy(() => import("./Pages/FacilityAddress"));
const FacilityFieldsPage = lazy(() => import("./Pages/FacilityFields"));
const FacilityLogoPage = lazy(() => import("./Pages/FacilityLogo"));
const FacilityPhotosPage = lazy(() => import("./Pages/FacilityPhotos"));
const FacilityNamePage = lazy(() => import("./Pages/FacilityName"));
const FacilityOverviewPage = lazy(() => import("./Pages/FacilityOverview"));
const FacilityStoryPage = lazy(() => import("./Pages/FacilityStory"));
const InvitePeoplePage = lazy(() => import("./Pages/InvitePeople"));
const SetupFieldsPage = lazy(() => import("./Pages/SetupFields"));

import { routes } from "./constants";

const { CORE_URLS } = config;

class FacilityApp implements IApp {
	getRoutes = () => [
		<Route path={routes.facility.getPath()} key="facility-routes">
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key="facility-form-routes"
			element={
				<Suspense>
					<FacilityLayout />
				</Suspense>
			}
		>
			<Route
				key={routes.facility.create.name.getElementKey()}
				path={routes.facility.create.name.getPath()}
				element={
					<Suspense>
						<FacilityNamePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.facility.create.address.getElementKey()}
				path={routes.facility.create.address.getPath()}
				element={
					<Suspense>
						<FacilityAddressPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.facility.create.logo.getElementKey()}
				path={routes.facility.create.logo.getPath()}
				element={
					<Suspense>
						<FacilityLogoPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.facility.create.story.getElementKey()}
				path={routes.facility.create.story.getPath()}
				element={
					<Suspense>
						<FacilityStoryPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.facility.create.photos.getElementKey()}
				path={routes.facility.create.photos.getPath()}
				element={
					<Suspense>
						<FacilityPhotosPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.facility.invite.people.getElementKey()}
				path={routes.facility.invite.people.getPath()}
				element={
					<Suspense>
						<InvitePeoplePage />
					</Suspense>
				}
			/>
			<Route
				key={routes.facility.setup.fields.getElementKey()}
				path={routes.facility.setup.fields.getPath()}
				element={
					<Suspense>
						<SetupFieldsPage />
					</Suspense>
				}
			/>
		</Route>,
		<Route
			key={routes.facility.getElementKey()}
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={`${routes.facility.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<FacilityPageLayout homePageUrl={CORE_URLS.HOME} />
					</Suspense>
				}
			>
				<Route
					key={`${routes.facility.getElementKey()}/id`}
					path={`${routes.facility.getPath()}/:id`}
					element={
						<Suspense>
							<FacilityOverviewPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.facility.getElementKey()}/id/fields`}
					path={`${routes.facility.getPath()}/:id/fields`}
					element={
						<Suspense>
							<FacilityFieldsPage />
						</Suspense>
					}
				/>
			</Route>
		</Route>
	];
}

export default FacilityApp;
