import type { FC } from "react";
import React, { useMemo } from "react";

import clsx from "clsx";

import ChartNegativeIcon from "assets/icons/colored/chart-negative.svg?react";
import ChartPositiveIcon from "assets/icons/colored/chart-positive.svg?react";
import ArrowIcon from "assets/icons/outlined/arrow-right.svg?react";
import type { MenuDotsOption } from "shared/uikit";
import { MenuDots, SkeletonBase, Text } from "shared/uikit";
import { MenuListPositionOpts, TextVariantsEnum } from "shared/uikit/types";

import { formatMoney } from "utils/formatMoney";

import { formatPercentages } from "utils/formatPercentages";

import CardWrapper from "../CardWrapper";

interface PaymentStatBlockProps {
	title: string;
	currentAmount?: number;
	previousAmount?: number;
	loading?: boolean;
	periodLabel?: string;
	inverted?: boolean;
	opts?: MenuDotsOption[];
}

const PaymentStatBlock: FC<PaymentStatBlockProps> = ({
	title,
	loading,
	currentAmount,
	previousAmount,
	periodLabel = "month",
	inverted,
	opts
}) => {
	const growPercentage = useMemo(() => {
		if (currentAmount !== undefined && previousAmount !== undefined) {
			return previousAmount ? (currentAmount * 100) / previousAmount : 100;
		}
		return 0;
	}, [currentAmount, previousAmount]);

	if (loading) {
		return (
			<CardWrapper>
				<SkeletonBase width={70} height={19} fullScale />
				<SkeletonBase width="30%" height={42} fullScale className="mt-0.5" />
				<div className="flex items-center mt-0.5">
					<SkeletonBase width={40} height={20} fullScale className="mr-2" />
					<SkeletonBase width="50%" height={14} fullScale className="mt-0.5" />
				</div>
			</CardWrapper>
		);
	}

	return (
		<CardWrapper className="h-full">
			<div className="flex items-center justify-between">
				<Text variants={TextVariantsEnum.H7} className="text-primary-600">
					{title}
				</Text>
				{opts && <MenuDots popoverPosition={MenuListPositionOpts.TOP_RIGHT} options={opts} />}
			</div>
			<Text variants={TextVariantsEnum.H1}>{currentAmount ? `${formatMoney(currentAmount)}` : "$0"}</Text>
			<div className="flex items-center">
				<div className="mr-2">
					{inverted ? (
						<>
							{!growPercentage ? (
								<ChartPositiveIcon className="w-10 h-5" />
							) : (
								<ChartNegativeIcon className="w-10 h-5" />
							)}
						</>
					) : (
						<>
							{growPercentage ? <ChartPositiveIcon className="w-10 h-5" /> : <ChartNegativeIcon className="w-10 h-5" />}
						</>
					)}
				</div>
				<ArrowIcon
					className={clsx(
						"w-4 h-4 mr-1",
						inverted
							? growPercentage
								? "-rotate-90 svg-paths:stroke-red-500"
								: "rotate-90 svg-paths:stroke-primary-500"
							: growPercentage
								? "-rotate-90 svg-paths:stroke-primary-500"
								: "rotate-90 svg-paths:stroke-red-500"
					)}
				/>
				<Text variants={TextVariantsEnum.Caption2} className="text-hint">
					{growPercentage > 0 ? "+" : "-"}
					{formatPercentages(growPercentage)} from last {periodLabel}
				</Text>
			</div>
		</CardWrapper>
	);
};

export default PaymentStatBlock;
