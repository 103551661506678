import React, { memo } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import { GroupStatusEnum, InvitationStatus, MatchCalculatedStatusEnum, MatchStatusEnum } from "shared/types";
import { PaymentStatus, PendingPaymentStatusEnum } from "shared/types/PaymentModel";
import { Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";
import { renderStatus } from "utils/renderStatus";

export interface StatusBoxProps {
	status?:
		| InvitationStatus
		| MatchStatusEnum
		| MatchCalculatedStatusEnum
		| PendingPaymentStatusEnum
		| GroupStatusEnum
		| PaymentStatus;
	className?: string;
	textClassName?: string;
	text?: string;
}

const StatusBox: React.FC<StatusBoxProps> = memo(({ status, className, text, textClassName }) => {
	const { t } = useTranslation();

	return (
		<div className={clsx("flex items-center", className)}>
			{status && (
				<div
					className={clsx(
						"mr-1 w-[6px] h-[6px] rounded-full overflow-hidden",
						[
							InvitationStatus.PENDING,
							InvitationStatus.PRIVATE,
							MatchStatusEnum.NEEDS_TEAMS,
							MatchStatusEnum.NEEDS_TIME,
							MatchStatusEnum.NEEDS_LOCATION,
							MatchStatusEnum.NEEDS_REFEREES,
							MatchCalculatedStatusEnum.CREATED,
							PendingPaymentStatusEnum.subscriptionInProgress,
							GroupStatusEnum.SCHEDULE_CREATED,
							PaymentStatus.PENDING
						].includes(status)
							? "bg-yellow-500"
							: [
										InvitationStatus.REJECTED,
										InvitationStatus.REMOVED,
										PaymentStatus.CANCELED,
										PaymentStatus.FAILED,
										PendingPaymentStatusEnum.lastPaymentFailed
								  ].includes(status as InvitationStatus | PendingPaymentStatusEnum)
								? "bg-red-500"
								: status === MatchCalculatedStatusEnum.SCHEDULED
									? "bg-blue-500"
									: "bg-primary-500"
					)}
				/>
			)}
			<Text variants={TextVariantsEnum.BodyMedium} className={textClassName}>
				{text || renderStatus(status || null, t) || "-"}
			</Text>
		</div>
	);
});

export default StatusBox;
