import { Suspense, lazy } from "react";

import appConfig from "config/appConfig";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const AccountLocationLayout = lazy(() => import("modules/App/View/layouts/AccountLocationLayout"));
const BasicLayout = lazy(() => import("shared/templates/Layouts/BasicLayout"));

const AuthCompletePage = lazy(() => import("./Pages/AuthComplete"));
const CreateAccountPage = lazy(() => import("./Pages/CreateAccount"));
const CreatedAccountPage = lazy(() => import("./Pages/CreatedAccount"));
const FillAccountPage = lazy(() => import("./Pages/FillAccount"));
const LocationAccountPage = lazy(() => import("./Pages/LocationAccount"));
const LogoutPage = lazy(() => import("./Pages/Logout"));
const SignInPage = lazy(() => import("./Pages/SignIn"));
const AccountTypePage = lazy(() => import("./Pages/AccountType"));
const ConfirmEmailPage = lazy(() => import("./Pages/ConfirmEmail"));
const CreateGuardianIntroPage = lazy(() => import("./Pages/Guardian/CreateIntro"));
const CreateGuardianPage = lazy(() => import("./Pages/Guardian/Create"));
const ChildProfilePage = lazy(() => import("./Pages/Guardian/ChildProfile"));
const FillProfileChildPage = lazy(() => import("./Pages/Guardian/FillProfileChildPage"));
const FillProfileGuardian = lazy(() => import("./Pages/Guardian/FillProfileGuardian"));
const FBAuthenticatePage = lazy(() => import("./Pages/FBAuthenticate"));
const OTPPage = lazy(() => import("./Pages/OTPPage"));
const LoginPage = lazy(() => import("./Pages/Login"));
const ForgotPasswordPage = lazy(() => import("./Pages/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("./Pages/ResetPassword"));
const RegisterPage = lazy(() => import("./Pages/Register"));

import { EnvStages } from "shared/types";

import { routes } from "./constants";

const isFBAuthEnabled = appConfig.GLOBAL_CONSTANTS.ENV_STAGE === EnvStages.DEV;

class AuthApp implements IApp {
	getRoutes = () => [
		<Route
			path={routes.auth.getPath()}
			key="auth-routes"
			element={
				<Suspense>
					<BasicLayout />
				</Suspense>
			}
		>
			{this.getPublicRoutes()}
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>,
		<Route
			key="auth-account-location-wrapper-route"
			element={
				<Suspense>
					<AccountLocationLayout />
				</Suspense>
			}
		>
			<Route
				key={routes.auth.account.location.getElementKey()}
				path={routes.auth.account.location.getPath()}
				element={
					<Suspense>
						<LocationAccountPage />
					</Suspense>
				}
			/>
		</Route>
	];

	getPublicRoutes = () => [
		<Route
			key={routes.auth.signIn.getElementKey()}
			path={routes.auth.signIn.getPath()}
			element={
				<Suspense>
					<SignInPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.accountType.getElementKey()}
			path={routes.auth.accountType.getPath()}
			element={
				<Suspense>
					<AccountTypePage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.emailConfirmation.getElementKey()}
			path={routes.auth.emailConfirmation.getPath()}
			element={
				<Suspense>
					<ConfirmEmailPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.createGuardian.getElementKey()}
			path={routes.auth.createGuardian.getPath()}
			element={
				<Suspense>
					<CreateGuardianIntroPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.guardian.create.getElementKey()}
			path={routes.auth.guardian.create.getPath()}
			element={
				<Suspense>
					<CreateGuardianPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.guardian.childProfile.getElementKey()}
			path={routes.auth.guardian.childProfile.getPath()}
			element={
				<Suspense>
					<ChildProfilePage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.guardian.fillProfile.getElementKey()}
			path={routes.auth.guardian.fillProfile.getPath()}
			element={
				<Suspense>
					<FillProfileGuardian />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.guardian.fillChildProfile.getElementKey()}
			path={routes.auth.guardian.fillChildProfile.getPath()}
			element={
				<Suspense>
					<FillProfileChildPage />
				</Suspense>
			}
		/>,
		<Route
			key={`${routes.auth.fbAuthenticate.getElementKey()}`}
			path={`${routes.auth.fbAuthenticate.getPath()}`}
			element={
				isFBAuthEnabled ? (
					<Suspense>
						<FBAuthenticatePage />
					</Suspense>
				) : null
			}
		/>,
		<Route
			key={routes.auth.complete.getElementKey()}
			path={routes.auth.complete.getPath()}
			element={
				<Suspense>
					<AuthCompletePage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.otp.getElementKey()}
			path={routes.auth.otp.getPath()}
			element={
				<Suspense>
					<OTPPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.login.getElementKey()}
			path={routes.auth.login.getPath()}
			element={
				<Suspense>
					<LoginPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.forgotPassword.getElementKey()}
			path={routes.auth.forgotPassword.getPath()}
			element={
				<Suspense>
					<ForgotPasswordPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.resetPassword.getElementKey()}
			path={routes.auth.resetPassword.getPath()}
			element={
				<Suspense>
					<ResetPasswordPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.register.getElementKey()}
			path={routes.auth.register.getPath()}
			element={
				<Suspense>
					<RegisterPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.logout.getElementKey()}
			path={routes.auth.logout.getPath()}
			element={
				<Suspense>
					<LogoutPage />
				</Suspense>
			}
		/>
	];

	getSecuredRoutes = () => [
		<Route
			key={routes.auth.account.create.getElementKey()}
			path={routes.auth.account.create.getPath()}
			element={
				<Suspense>
					<CreateAccountPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.account.fill.getElementKey()}
			path={routes.auth.account.fill.getPath()}
			element={
				<Suspense>
					<FillAccountPage />
				</Suspense>
			}
		/>,
		<Route
			key={routes.auth.account.created.getElementKey()}
			path={routes.auth.account.created.getPath()}
			element={
				<Suspense>
					<CreatedAccountPage />
				</Suspense>
			}
		/>
	];
}

export default AuthApp;
