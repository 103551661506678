import type { FC } from "react";
import React, { Suspense, lazy, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { ButtonVariantsEnum } from "shared/uikit/types";
import colors from "theme/colors";

const ConfirmationDialog = lazy(() => import("shared/Components/ConfirmationDialog"));

interface ConfirmLeaveDialogProps {
	open: boolean;
	onClose: () => void;
	onQuit: () => void;
	title: string;
	message: string;
}

const ConfirmLeaveDialog: FC<ConfirmLeaveDialogProps> = ({ open, onClose, onQuit, title, message }) => {
	const { t } = useTranslation();

	const actions = useMemo(() => {
		return [
			{
				variant: ButtonVariantsEnum.OUTLINED,
				text: t("cancel"),
				onClick: onClose
			},
			{
				fillColor: colors.palette.colors.red["600"],
				text: t("quit"),
				onClick: onQuit
			}
		];
	}, [onClose, onQuit, t]);

	return (
		<Suspense>
			<ConfirmationDialog open={open} onClose={onClose} title={title} message={message} actions={actions} />
		</Suspense>
	);
};

export default ConfirmLeaveDialog;
