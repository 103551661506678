import { Popper, styled } from "@mui/material";

export const NavbarDialogWrapper = styled(Popper, { shouldForwardProp: prop => prop !== "maxHeight" })<{
	maxHeight?: number;
}>`
	background-color: #ffffff;
	border: 1px solid rgba(220, 229, 245, 0.5);
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	overflow: hidden;
	width: 25rem;
	z-index: 9999;
`;
