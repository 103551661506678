import type { FC } from "react";
import React, { useMemo } from "react";

import { DateTime } from "luxon";

// import { useGoogleTimezone } from "shared/hooks";
import { Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";

import { getDateTime } from "utils/getDateTime";

interface TimeBaseProps {
	time?: DateTime | Date | string | null;
	// coordinates?: number[] | null;
}

const TimeBase: FC<TimeBaseProps> = ({ time }) => {
	// const { getTimeZoneByCoordinates } = useGoogleTimezone();

	// const [addressTime, setAddressTime] = useState("");

	const timeString = useMemo(() => {
		if (time) {
			const luxonTime = getDateTime(time);
			return luxonTime.toLocaleString(DateTime.TIME_SIMPLE);
		}
		return "";
	}, [time]);

	// const fetchTime = useCallback(
	// 	async (coordinates: number[], time: DateTime) => {
	// 		const timeZoneId = await getTimeZoneByCoordinates(coordinates);
	// 		if (timeZoneId) {
	// 			setAddressTime(time.setZone(timeZoneId).toLocaleString(DateTime.TIME_SIMPLE));
	// 		}
	// 	},
	// 	[getTimeZoneByCoordinates]
	// );
	//
	// useEffect(() => {
	// 	if (coordinates && time) {
	// 		fetchTime(coordinates, getDateTime(time));
	// 	}
	// }, [coordinates, time, fetchTime]);

	return (
		<Text variants={TextVariantsEnum.INHERIT_SPAN}>
			{timeString}
			{/*{addressTime && addressTime !== timeString ? ` / ${addressTime}` : ""}*/}
		</Text>
	);
};

export default TimeBase;
