import type { FC } from "react";
import React, { useEffect, useMemo } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import ArrowRightIcon from "assets/icons/filled/caret-right.svg?react";
import TickIcon from "assets/icons/filled/tick.svg?react";

import PasswordTooltip from "shared/templates/LoginRegisterTemplate/style";
import { Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";

const PasswordRulesBox: FC<{ inline?: boolean; password: string; handleAllRulesValid?: (val: boolean) => void }> = ({
	password,
	inline,
	handleAllRulesValid
}) => {
	const { t } = useTranslation();

	const passwordRules = useMemo(
		() => [
			{
				text: t("error:be_range_characters_in_length", { range: "8-20" }),
				isValid: !!password && password?.trim().length >= 8 && password?.trim().length <= 20
			},
			{
				text: t("error:have_at_least_one_capital_letter"),
				isValid: password && /[A-Z]/.test(password?.trim())
			},
			{
				text: t("error:have_at_least_one_lowercase_letter"),
				isValid: password && /[a-z]/.test(password?.trim())
			},
			{
				text: t("error:have_at_least_one_number"),
				isValid: password && /[0-9]/.test(password?.trim())
			}
		],
		[password, t]
	);

	useEffect(() => {
		handleAllRulesValid && handleAllRulesValid(passwordRules.every(rule => rule.isValid));
	}, [handleAllRulesValid, passwordRules]);

	return (
		<div className={clsx("h-full flex", !inline && "absolute top-0 left-[calc(100%+16px)] items-start justify-center")}>
			<PasswordTooltip className="w-72 border-gray-100 bg-white p-4 rounded-xl relative">
				{!inline && <ArrowRightIcon className="transform rotate-180 absolute top-4 -left-4 svg-paths:fill-white" />}
				<Text variants={TextVariantsEnum.H7}>{t("password_must")}:</Text>
				<div className="mt-2 flex flex-col gap-3">
					{passwordRules.map((rule, i) => (
						<div key={`password-rule-${i}`} className="flex items-center gap-1.5">
							{rule.isValid ? (
								<TickIcon
									width={16}
									height={16}
									className="svg-first-path:fill-green-600 svg-first-path:stroke-green-600"
								/>
							) : (
								<TickIcon
									width={16}
									height={16}
									className="svg-first-path:fill-gray-200 svg-first-path:stroke-gray-200"
								/>
							)}

							<Text variants={TextVariantsEnum.BodySmall}>{rule.text}</Text>
						</div>
					))}
				</div>
			</PasswordTooltip>
		</div>
	);
};

export default PasswordRulesBox;
