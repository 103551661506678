import type React from "react";
import { useEffect } from "react";

import type { Scrollbars } from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
	scrollRef?: React.MutableRefObject<Scrollbars | null>;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ scrollRef }) => {
	const location = useLocation();

	useEffect(() => {
		if (scrollRef?.current) {
			scrollRef.current?.scrollToTop();
		} else {
			window.scrollTo(0, 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return null;
};

export default ScrollToTop;
