import React, { memo, useCallback, useMemo } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import GobletIcon from "assets/icons/filled/goblet.svg?react";

import { Repeatable } from "shared/Components";
import type { BaseCreateType, LogoModel } from "shared/types";
import { StatEntityEnum, StatTypeEnum } from "shared/types";

import { Button, ImageSizesEnum, Img, SkeletonBase, Text } from "shared/uikit";
import { ButtonSizesEnum, ButtonVariantsEnum, TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";
import { uuidv4 } from "utils/serviceUtils/helpers";

const skeletonArr = [{ id: uuidv4() }, { id: uuidv4() }, { id: uuidv4() }];

export interface StatViewDataModel extends Pick<BaseCreateType, "id" | "name"> {
	logo?: LogoModel | null;
	score: number;
	secondaryEntities: (Pick<BaseCreateType, "id" | "name"> & { logo?: LogoModel | null })[];
}

export interface StatCardProps {
	loading?: boolean;
	title?: string;
	link?: string;
	data: StatViewDataModel[];
	onSelectItem?: (id: string) => void;
	fullMode?: boolean;
	stat?: StatTypeEnum | string;
	entity?: StatEntityEnum | string;
	teamPageUrl?: string;
	profilePageUrl?: string;
	limit?: number; // works only for non full mode
}

const StatCard: React.FC<StatCardProps> = memo(
	({ loading, title, link, data, onSelectItem, fullMode, stat, entity, teamPageUrl, profilePageUrl, limit = 3 }) => {
		const { t } = useTranslation();

		const navigate = useNavigate();

		const handleSelect = useCallback(
			(id: string) => {
				if (onSelectItem) {
					onSelectItem(id);
				}

				if (entity === StatEntityEnum.TEAM && teamPageUrl) {
					navigate(`${teamPageUrl}/${id}`);
				}

				if (entity === StatEntityEnum.PLAYER && profilePageUrl) {
					navigate(`${profilePageUrl}/${id}`);
				}
			},
			[onSelectItem, entity, teamPageUrl, profilePageUrl, navigate]
		);

		const icon = useMemo(() => {
			switch (stat) {
				case StatTypeEnum.GOALS_SCORED:
				case StatTypeEnum.GOALS_PER_MATCH:
					return <GobletIcon className="w-4 h-4 svg-paths:fill-gray-400" />;

				default:
					return null;
			}
		}, [stat]);

		const titleText = useMemo(() => {
			if (title) {
				return title;
			}

			switch (stat) {
				case StatTypeEnum.GOALS_SCORED:
					return t("match:goals");

				case StatTypeEnum.GOALS_PER_MATCH:
					return t("match:goals_per_game");

				case StatTypeEnum.MATCHES_PLAYED:
					return t("match:matches_played");

				case StatTypeEnum.STARS_RECEIVED:
					return t("match:stars");

				case StatTypeEnum.RED_CARDS_RECEIVED:
					return t("match:red_cards");

				case StatTypeEnum.YELLOW_CARDS_RECEIVED:
					return t("match:yellow_cards");

				default:
					return "";
			}
		}, [stat, title, t]);

		const textVariant = useMemo(
			() => (fullMode ? TextVariantsEnum.BodyMedium : TextVariantsEnum.Numbers14),
			[fullMode]
		);

		const sortedData = useMemo(() => data.sort((a, b) => (b?.score || 0) - (a?.score || 0)), [data]);

		if (loading) {
			return (
				<div className="bg-white rounded-xl border-gray-100 border-[1px]">
					<div className="p-4 border-gray-100 border-b-[1px] flex items-center">
						{fullMode && icon && <SkeletonBase width={16} height={16} variant="circular" fullScale className="mr-4" />}
						<SkeletonBase width={65} height={fullMode ? 19 : 16} fullScale />
					</div>
					<div className={clsx(!fullMode && "px-4")}>
						{skeletonArr.map(item => (
							<div
								key={item.id}
								className={clsx(
									"flex justify-between items-center border-gray-100 border-b-[1px]",
									fullMode && "last:border-b-0",
									fullMode ? "py-3 px-6" : "py-2.5"
								)}
							>
								<div className="w-8/12 flex items-center">
									{fullMode && <SkeletonBase width={8} height={16} variant="rectangular" fullScale className="mr-6" />}
									<SkeletonBase
										width={fullMode ? 48 : 24}
										height={fullMode ? 48 : 24}
										variant="circular"
										className="mr-2"
										fullScale
									/>
									<SkeletonBase width={80} height={fullMode ? 24 : 16} fullScale />
								</div>
								<div className="w-4/12 flex items-center justify-end">
									<SkeletonBase width={16} height={fullMode ? 24 : 16} fullScale />
								</div>
							</div>
						))}
					</div>
					{!fullMode && (
						<div className="flex items-center justify-center py-4">
							<SkeletonBase width={45} height={16} fullScale />
						</div>
					)}
				</div>
			);
		}

		return (
			<div className="bg-white rounded-xl border-gray-100 border-[1px]">
				<div className={clsx("py-4 border-gray-100 border-b-[1px] flex items-center", fullMode ? "px-6" : "px-4")}>
					{fullMode && icon && <div className="mr-4">{icon}</div>}
					<Text variants={fullMode ? TextVariantsEnum.H7 : TextVariantsEnum.Caption3} style={TextStylesEnum.Hint}>
						{titleText}
					</Text>
				</div>
				<div className={clsx("min-h-[135px]", !fullMode && "px-4")}>
					{(fullMode ? sortedData : sortedData.slice(0, limit)).map((item, idx) => (
						<div
							id={`${titleText.toLowerCase()}-item-${idx + 1}`}
							key={`${titleText}-${item.id}`}
							className={clsx(
								"flex justify-between items-center cursor-pointer border-gray-100 border-b-[1px] last:border-b-0",
								fullMode ? "py-3" : "py-2.5"
							)}
							onClick={() => handleSelect(item.id)}
						>
							<div className="w-8/12 flex items-center">
								{fullMode && (
									<Text variants={textVariant} className="w-14 text-center">
										{idx + 1}
									</Text>
								)}
								<div className={clsx("relative shrink-0", fullMode ? "mr-4" : "mr-2")}>
									<Img
										src={item.logo}
										alt={item.name}
										size={fullMode ? ImageSizesEnum.Small48 : ImageSizesEnum.Small24}
										circle
									/>
									{fullMode && !!item?.secondaryEntities?.length && (
										<div className="absolute bottom-0 right-0 border-[1px] border-white rounded-full">
											<Repeatable
												imgUrls={item.secondaryEntities.map(x => ({ logo: x.logo, alt: x.name || "" }))}
												totalCount={item.secondaryEntities?.length}
												imgSize={ImageSizesEnum.Small16}
											/>
										</div>
									)}
								</div>
								<Text variants={textVariant} className="shrink w-10/12 truncate">
									{item.name}
								</Text>
							</div>
							<div className={clsx("w-4/12 flex items-center justify-end", fullMode && "pr-6")}>
								{!fullMode && !!item?.secondaryEntities?.length && (
									<Repeatable
										imgUrls={item.secondaryEntities.map(x => ({ logo: x.logo, alt: x.name || "" }))}
										totalCount={item.secondaryEntities?.length}
										imgSize={ImageSizesEnum.Small24}
									/>
								)}
								<Text variants={textVariant} className="ml-1 min-w-[36px] text-right">
									{item.score || 0}
								</Text>
							</div>
						</div>
					))}
				</div>
				{!fullMode && (
					<div className="flex items-center justify-center px-4 pb-4">
						<Button
							size={ButtonSizesEnum.SM}
							variant={ButtonVariantsEnum.FILLED}
							link={link}
							className="w-full"
							linkClassName="w-full"
							secondary
						>
							{t("see_all")}
						</Button>
					</div>
				)}
			</div>
		);
	}
);

export default StatCard;
