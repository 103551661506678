import type { ReactNode } from "react";

import AngryIcon from "assets/icons/reactions/reaction-angry.svg?react";
import DislikeIcon from "assets/icons/reactions/reaction-dislike.svg?react";
import LoveIcon from "assets/icons/reactions/reaction-heart.svg?react";
import LaughIcon from "assets/icons/reactions/reaction-laughing.svg?react";
import LikeIcon from "assets/icons/reactions/reaction-like.svg?react";
import LovedIcon from "assets/icons/reactions/reaction-loved.svg?react";
import SadIcon from "assets/icons/reactions/reaction-sad.svg?react";
import WowIcon from "assets/icons/reactions/reaction-wow.svg?react";

import { PostReactionSkins, PostReactionTypes } from "shared/types";
import colors from "theme/colors";

export interface EmotionIconModel {
	value: PostReactionTypes;
	icon: ReactNode;
	label: string;
	colorized?: boolean;
}

export interface ToneModel {
	value: PostReactionSkins;
	color: string;
}

export const emotionIcons: EmotionIconModel[] = [
	{
		value: PostReactionTypes.LOVE,
		icon: <LoveIcon />,
		label: "Love",
		colorized: false
	},
	{
		value: PostReactionTypes.LIKE,
		icon: <LikeIcon />,
		label: "Like",
		colorized: true
	},
	{
		value: PostReactionTypes.LAUGH,
		icon: <LaughIcon />,
		label: "Haha",
		colorized: true
	},
	{
		value: PostReactionTypes.LOVING,
		icon: <LovedIcon />,
		label: "Loving",
		colorized: true
	},
	{
		value: PostReactionTypes.WOW,
		icon: <WowIcon />,
		label: "Wow",
		colorized: true
	},
	{
		value: PostReactionTypes.SAD,
		icon: <SadIcon />,
		label: "Sad",
		colorized: true
	},
	{
		value: PostReactionTypes.ANGRY,
		icon: <AngryIcon />,
		label: "Angry",
		colorized: true
	},
	{
		value: PostReactionTypes.DISLIKE,
		icon: <DislikeIcon />,
		label: "Dislike",
		colorized: true
	}
];

export const skinOptions: ToneModel[] = [
	{ value: PostReactionSkins.DEFAULT, color: colors.palette.colors.reactionColors.default },
	{ value: PostReactionSkins.LIGHT, color: colors.palette.colors.reactionColors.light },
	{ value: PostReactionSkins.MEDIUM_LIGHT, color: colors.palette.colors.reactionColors.mediumLight },
	{ value: PostReactionSkins.MEDIUM_DARK, color: colors.palette.colors.reactionColors.mediumDark },
	{ value: PostReactionSkins.DARK, color: colors.palette.colors.reactionColors.dark }
];
