import { FormControlLabel, styled } from "@mui/material";

import colors from "theme/colors";

export const StyledCheckboxBtn = styled(FormControlLabel)`
	margin: 0 0 0 -8px;

	.MuiButtonBase-root {
		svg {
			rect {
				stroke: ${colors.palette.colors.gray["200"]};
			}
		}

		&:hover {
			svg {
				rect {
					stroke: ${colors.palette.colors.gray["300"]};
				}
			}
		}

		&.Mui-checked {
			svg {
				rect {
					stroke: none;
					fill: ${colors.palette.colors.primary["500"]};
				}
			}

			&:hover {
				svg {
					rect {
						stroke: none;
						fill: ${colors.palette.colors.primary["600"]};
					}
				}
			}
		}

		&.Mui-disabled {
			svg {
				rect {
					stroke: none;
					fill: ${colors.palette.colors.gray["200"]};
				}
			}
		}
	}

	&.rtl {
		flex-direction: row-reverse;

		margin: 0;
	}

	&.style {
		&_small {
			margin-left: -6px;

			span {
				padding: 4px;
			}
		}

		&_medium {
			span {
				padding: 9px;
			}
		}
	}
`;
