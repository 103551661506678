import type { FC, ReactNode } from "react";
import React, { memo } from "react";

import clsx from "clsx";

import type { LogoModel, MediaModel } from "shared/types";
import { ImageSizesEnum, Img, Text } from "shared/uikit";
import { TextVariantsEnum } from "shared/uikit/types";
import { uuidv4 } from "utils/serviceUtils/helpers";

interface RepeatableProps {
	imgUrls?: { logo?: LogoModel | null; alt: string }[];
	repeatableEls?: { id: string; el: ReactNode }[];
	totalCount?: number;
	displayCount?: number;
	className?: string;
	imgSize?: ImageSizesEnum;
	bordered?: boolean;
}

const Repeatable: FC<RepeatableProps> = memo(
	({
		imgUrls = [],
		repeatableEls = [],
		totalCount,
		displayCount = 3,
		className,
		imgSize = ImageSizesEnum.Small32,
		bordered
	}) => (
		<div className={clsx("flex items-center", className)}>
			{!!imgUrls?.length &&
				imgUrls.slice(0, displayCount).map((img, index) => (
					<div
						key={(img?.logo as MediaModel)?.id || uuidv4()}
						className={clsx(
							"first:ml-0",
							imgSize === ImageSizesEnum.Small16 ? "-ml-1.5" : imgSize === ImageSizesEnum.Small24 ? "-ml-2" : "-ml-4"
						)}
						style={{ zIndex: displayCount - index }}
					>
						<Img
							src={img?.logo}
							alt={img.alt}
							circle
							size={imgSize}
							className={clsx(bordered && "border-2 border-white box-content rounded-full")}
						/>
					</div>
				))}
			{!!repeatableEls?.length &&
				repeatableEls.slice(0, displayCount).map((item, index) => (
					<div
						key={item.id}
						className={clsx(
							"first:ml-0",
							imgSize === ImageSizesEnum.Small16 ? "-ml-1.5" : imgSize === ImageSizesEnum.Small24 ? "-ml-2" : "-ml-4",
							bordered && "border-2 border-white box-content rounded-full"
						)}
						style={{ zIndex: displayCount - index }}
					>
						{item.el}
					</div>
				))}
			{totalCount !== undefined && totalCount - displayCount > 0 && (
				<Text variants={TextVariantsEnum.BodyMedium} className="ml-1">
					+{totalCount - displayCount}
				</Text>
			)}
		</div>
	)
);

export default Repeatable;
