import React, { memo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Text } from "shared/uikit";
import { TextStylesEnum, TextVariantsEnum } from "shared/uikit/types";

export interface ReadMoreProps {
	text: string;
	limit?: number;
}

const ReadMore: React.FC<ReadMoreProps> = memo(({ text, limit }) => {
	const { t } = useTranslation();

	const [lim, setLim] = useState<number | undefined>(limit);

	const toggleLim = () => {
		setLim(!lim ? limit : undefined);
	};

	return (
		<>
			{lim ? text.slice(0, lim) : text}
			{lim && text.length > lim && "..."}
			{limit && text.length > limit && (
				<span onClick={toggleLim} className="inline-flex">
					<Text variants={TextVariantsEnum.INHERIT_SPAN} style={TextStylesEnum.Primary} className="cursor-pointer">
						&nbsp;
						{lim ? t("read_more") : t("show_less")}
					</Text>
				</span>
			)}
		</>
	);
});

export default ReadMore;
