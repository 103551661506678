import { styled } from "@mui/material";

import colors from "theme/colors";

export const RefereeCard = styled("div")`
	display: flex;
	align-items: center;

	width: fit-content;
	height: 32px;

	background-color: ${colors.palette.colors.white};

	border: 1px solid ${colors.palette.colors.gray["200"]};
	border-radius: 12px;

	padding: 4px 8px;
`;

export const AvatarWrapper = styled("div")`
	width: 24px;
	height: 24px;

	border-radius: 50%;

	overflow: hidden;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

export const PersonItem = styled("div")`
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
`;
