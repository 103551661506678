import { AccordionDetails, AccordionSummary, ButtonBase, styled } from "@mui/material";

import colors from "theme/colors";

export const Wrapper = styled("div")`
	width: 100%;
`;

export const Row = styled("div")`
	&.last-row {
		.column {
			border-bottom: none;
		}
	}
`;

export const Column = styled("div")`
	display: flex;
	align-items: center;

	height: 100%;

	padding: 8px;

	border-bottom: 1px solid ${colors.palette.colors.gray["100"]};

	&.alignment {
		&_center {
			justify-content: center;
		}

		&_right {
			justify-content: flex-end;
		}
	}

	&.vertical-alignment {
		&_top {
			align-items: flex-start;
		}

		&_bottom {
			align-items: flex-end;
		}
	}
`;

export const ExpandIconWrapper = styled(ButtonBase)`
	width: 24px;
	height: 24px;

	border-radius: 50%;

	overflow: hidden;

	background-color: ${colors.palette.colors.gray["50"]};
`;

export const TableAccordionSummary = styled(AccordionSummary)`
	min-height: auto;

	padding: 0;

	.MuiAccordionSummary-content {
		width: 100%;

		margin: 0;

		cursor: default;
	}
`;

export const TableAccordionDetails = styled(AccordionDetails)`
	position: relative;

	padding: 0;

	&:before,
	&:after {
		content: "";

		display: block;

		position: absolute;

		top: 0;

		width: 8px;
		height: 100%;

		background-color: ${colors.palette.colors.gray["100"]};

		z-index: 5;
	}

	&:before {
		left: 0;
	}

	&:after {
		right: 0;
	}
`;
