import React, { useState } from "react";

import clsx from "clsx";

import { ActivateCard } from "shared/Components";
import type { GenderEnum } from "shared/types";

import { genderList } from "../../data";

interface GenderFormProps {
	values?: GenderEnum[];
	wrapperClassname?: string;
	onChange?: (selected: GenderEnum[]) => void;
}

const GenderForm: React.FC<GenderFormProps> = ({ wrapperClassname = "", values, onChange }) => {
	const [selectedGenders, setSelectedGenders] = useState<GenderEnum[]>(values || []);

	const toggleGender = (val: GenderEnum) => {
		const index = selectedGenders.indexOf(val);
		const genders = [...selectedGenders];

		if (index === -1) {
			genders.push(val);
		} else {
			genders.splice(index, 1);
		}

		setSelectedGenders(genders);
		onChange && onChange(genders);
	};

	return (
		<div className={clsx(wrapperClassname, "grid gap-2 grid-cols-3")}>
			{genderList.map((item, index) => (
				<div key={index} className="w-auto h-[140px]">
					<ActivateCard
						icon={item.icon}
						title={item.label}
						active={selectedGenders.indexOf(item.value) !== -1}
						onClick={() => toggleGender(item.value)}
						checkbox
						id={item.id}
					/>
				</div>
			))}
		</div>
	);
};

export default React.memo(GenderForm);
