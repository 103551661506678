import type { FC } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";

import { Scrollbars, type positionValues } from "react-custom-scrollbars-2";

import { getPaths } from "core/getPaths";
import { EventCard, SocialEventCard } from "modules/Member/View/Components";
import { CardWrapper } from "shared/Components";
import type { CalendarEventModel, EventModel, MatchModel } from "shared/types";

const { match: matchRoutes } = getPaths();
const matchPageUrl = matchRoutes.match.getPath();

const loaderOffset = 188; // loader height (172px) + padding (16px)

interface Props {
	data: (EventModel | MatchModel | CalendarEventModel)[];
	loading?: boolean;
	onLoadMore?: () => void;
	onLoadBefore?: () => void;
	title?: string;
	filtersBlock?: React.ReactNode;
	allowReverseScroll?: boolean;
}

const EventsListingTemplate: FC<Props> = ({
	data,
	loading,
	onLoadMore,
	onLoadBefore,
	title,
	filtersBlock,
	allowReverseScroll
}) => {
	const scrollerRef = useRef<(Scrollbars & { view: HTMLElement }) | null>(null);
	const lastScrollElInfo = useRef({
		id: "",
		offset: 0
	});

	const [scrollingDown, setScrollingDown] = useState(false);

	useEffect(() => {
		const currentScroller = scrollerRef.current;
		const handleFrameScroll = (event: WheelEvent) => {
			const scrollTop = currentScroller?.getScrollTop() || 0;
			if (scrollTop < loaderOffset && event.deltaY < 0) {
				const koeff = event.deltaY <= -10 ? 0.01 : 0.65; // 65% of default speed scroll
				// reduce scroll speed to be sure user literally want to load previous events
				currentScroller?.scrollTop(scrollTop - event.deltaY * koeff);
			}
		};

		if (allowReverseScroll) {
			currentScroller?.view?.addEventListener("wheel", handleFrameScroll, { passive: false });
		}

		return () => {
			currentScroller?.view?.removeEventListener("wheel", handleFrameScroll);
		};
	}, [allowReverseScroll]);

	useEffect(() => {
		if (!loading && !!data.length && allowReverseScroll) {
			if (scrollerRef.current && !scrollingDown) {
				const el = document.getElementById(lastScrollElInfo.current.id);
				if (el) {
					scrollerRef.current.scrollTop(el.offsetTop - lastScrollElInfo.current.offset);
				} else {
					scrollerRef.current.scrollTop(loaderOffset);
				}
			}
		}
	}, [data.length, loading, scrollingDown, allowReverseScroll]);

	const handleScroll = useCallback(
		(values: positionValues) => {
			if (values.top >= 0.99 && !loading) {
				setScrollingDown(true);
				onLoadMore?.();
			}
		},
		[loading, onLoadMore]
	);

	const handleStopScroll = useCallback(() => {
		if (scrollerRef.current && allowReverseScroll) {
			const scrolledTop = scrollerRef.current.getScrollTop();
			if (!scrolledTop) {
				if (!loading && data.length) {
					const firstEvent = data[0] as EventModel | MatchModel | CalendarEventModel;
					let id = "";

					if (!(firstEvent as EventModel)?.soccer && !(firstEvent as MatchModel)?.group) {
						id = (firstEvent as CalendarEventModel).id!;
					} else {
						id = (firstEvent as EventModel)?.soccer?.match?.id || (firstEvent as MatchModel)?.id;
					}

					const elId = `event-id-${id}`;
					const el = document.getElementById(elId);
					if (el) {
						lastScrollElInfo.current = {
							id: elId,
							offset: el.offsetTop
						};
					}

					setScrollingDown(false);
					onLoadBefore?.();
				}
			} else if (scrolledTop < loaderOffset) {
				scrollerRef.current.view?.scroll({
					top: loaderOffset,
					behavior: "smooth"
				});
			}
		}
	}, [onLoadBefore, loading, allowReverseScroll, data]);

	const renderCard = useCallback(
		() =>
			data?.map(e => {
				if (!(e as EventModel)?.soccer && !(e as MatchModel)?.group) {
					return <SocialEventCard event={e as CalendarEventModel} key={(e as CalendarEventModel).id!} />;
				}
				return (
					<EventCard
						event={e as EventModel | MatchModel}
						matchPageUrl={matchPageUrl}
						key={(e as EventModel)?.soccer?.match?.id || (e as MatchModel)?.id}
					/>
				);
			}),
		[data]
	);

	return (
		<div className={clsx("w-full flex flex-col gap-4 flex-grow")}>
			{title && <CardWrapper className="p-4 flex flex-col" title={title} titleWrapperClassName="p-0" />}
			{filtersBlock && <CardWrapper className="p-4 flex items-center justify-between">{filtersBlock}</CardWrapper>}
			<div className="flex-grow">
				<Scrollbars
					ref={scrollerRef}
					className="w-full flex flex-col gap-4"
					height="100%"
					autoHide
					onUpdate={handleScroll}
					onScrollStop={handleStopScroll}
					renderView={props => <div {...props} className="flex flex-col gap-4 mb-0" />}
				>
					{allowReverseScroll && (
						<div>
							<EventCard loading />
						</div>
					)}
					{!!data?.length && renderCard()}
					{loading && (
						<>
							<EventCard loading />
							<EventCard loading />
						</>
					)}
				</Scrollbars>
			</div>
		</div>
	);
};

export default EventsListingTemplate;
