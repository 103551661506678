import { Suspense, lazy } from "react";

import config from "config/appConfig";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));
const MatchPageLayout = lazy(() => import("modules/Match/View/layouts/MatchPageLayout"));

const MatchOverviewPage = lazy(() => import("./Pages/MatchOverview"));
const MatchStandingsPage = lazy(() => import("./Pages/MatchStandings"));
const MatchTeamsPage = lazy(() => import("./Pages/MatchTeams"));

import { routes } from "./constants";

const { CORE_URLS } = config;

class MatchApp implements IApp {
	getRoutes = () => [
		<Route path={routes.match.getPath()} key="match-routes">
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key={routes.match.getElementKey()}
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={`${routes.match.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<MatchPageLayout homePageUrl={CORE_URLS.HOME} />
					</Suspense>
				}
			>
				<Route
					key={`${routes.match.getElementKey()}/:id`}
					path={`${routes.match.getPath()}/:id`}
					element={
						<Suspense>
							<MatchOverviewPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.match.getElementKey()}/:id/teams`}
					path={`${routes.match.getPath()}/:id/teams`}
					element={
						<Suspense>
							<MatchTeamsPage />
						</Suspense>
					}
				/>
				<Route
					key={`${routes.match.getElementKey()}/:id/standings`}
					path={`${routes.match.getPath()}/:id/standings`}
					element={
						<Suspense>
							<MatchStandingsPage />
						</Suspense>
					}
				/>
			</Route>
		</Route>
	];
}

export default MatchApp;
