import { pick } from "ramda";

import type { FullTeamModel, PlayerModel, StatItemModel } from "shared/types";
import { StatEntityEnum } from "shared/types";

import type { StatViewDataModel } from "../shared/Components/StatCard";

export const convertToStat = (
	data: StatItemModel,
	statEntity: StatEntityEnum,
	score?: number | null
): StatViewDataModel => {
	let properScore = score || 0;
	if (properScore.toString().includes(".")) {
		properScore = Number(score?.toFixed(2));
	}
	const entity = statEntity === StatEntityEnum.PLAYER ? data.player : data.team;
	return {
		id: entity!.id!,
		name: entity?.name || "",
		logo: (entity as PlayerModel)?.avatar || (entity as FullTeamModel)?.logo || (entity as FullTeamModel)?.club?.logo,
		score: properScore,
		secondaryEntities:
			statEntity === StatEntityEnum.PLAYER
				? (data?.teams || []).map(x => ({
						...pick(["id", "name"], x),
						logo: x?.logo || x?.club?.logo
					}))
				: []
	};
};
