import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material";

import TreeViewStepIconUrl from "assets/icons/outlined/tree-view-step.svg";

import colors from "theme/colors";

export const TickIconWrapper = styled("div")`
	position: absolute;
	top: 12px;
	right: calc(100% + 16px);
`;

export const TeamCountWrapper = styled("div")`
	position: absolute;
	top: 14px;
	left: calc(100% - 32px);

	white-space: nowrap;
`;

export const StyledAccordion = styled(Accordion)`
	width: calc(100% - 48px);

	position: relative;

	box-shadow: none;

	border: 1px solid ${colors.palette.colors.gray["200"]};

	padding: 0 16px;

	&.MuiAccordion-root {
		border-radius: 8px;

		margin: 0;
	}
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
	padding: 16px 0;
	min-height: auto;

	.MuiAccordionSummary-content {
		margin: 0;

		&.Mui-expanded {
			margin: 0;
		}
	}

	&.Mui-expanded {
		min-height: auto;
		padding-bottom: 7px;
	}
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
	padding: 6px 0 0 1px;

	border-top: 1px solid ${colors.palette.colors.gray["100"]};
`;

export const GendersWrapper = styled("div")`
	padding-left: 28px;

	position: relative;

	&:before {
		display: block;

		content: "";

		position: absolute;

		top: -4px;
		left: 6px;

		width: 1px;
		height: calc(100% - 20px);

		background-color: ${colors.palette.colors.gray["200"]};
	}
`;

export const GenderWrapper = styled("div")`
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;

	&:before {
		display: block;

		content: "";

		position: absolute;

		background: url(${TreeViewStepIconUrl}) center no-repeat;
		background-size: cover;

		width: 16px;
		height: 7px;

		top: 35%;
		right: calc(100% + 6.5px);
	}
`;

export const TeamsBlock = styled("div")`
	border-top: 1px solid ${colors.palette.colors.gray["100"]};
`;

export const TeamWrapper = styled("div")`
	background: ${colors.palette.colors.white};

	border: 1px solid ${colors.palette.colors.gray["200"]};
	border-radius: 12px;

	padding: 4px 8px;
	margin: 0 12px 12px 0;

	width: fit-content;

	display: inline-flex;
	align-items: center;
`;
