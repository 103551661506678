import type { FC } from "react";
import React from "react";

import { useTranslation } from "react-i18next";

import { CardWrapper } from "shared/Components";
import { SkeletonBase } from "shared/uikit";

const FavoriteCompetitionDivisionsLoader: FC = () => {
	const { t } = useTranslation();

	return (
		<CardWrapper title={t("quick_links")} loading>
			<div className="flex items-center gap-4">
				{Array.from(Array(3)).map((_, index) => (
					<CardWrapper key={`quick-link-loader-${index}`}>
						<div className="flex items-center gap-4">
							<SkeletonBase fullScale width={40} height={40} variant="circular" />
							<div>
								<SkeletonBase fullScale width={77} height={22} />
								<SkeletonBase fullScale width={150} height={18} className="mt-1" />
							</div>
						</div>
					</CardWrapper>
				))}
			</div>
		</CardWrapper>
	);
};

export default FavoriteCompetitionDivisionsLoader;
