import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { CardWrapper } from "shared/Components";

const EditStoreModal = lazy(() => import("shared/templates/EditStoreModal"));
const ConfirmationDialog = lazy(() => import("shared/Components/ConfirmationDialog"));

import StoreCard from "shared/Components/StoreCard";
import { useStore } from "shared/hooks";
import type { BackendEntityType, StoreModel } from "shared/types";
import { Button, type MenuDotsOption, Text } from "shared/uikit";
import type { ActionModel } from "shared/uikit/BaseDialog";
import { ButtonSizesEnum, ButtonVariantsEnum, TextVariantsEnum } from "shared/uikit/types";

interface StorePageTemplateProps {
	entityId: string;
	entityType: BackendEntityType;
	isCanEdit?: boolean;
}

const StorePageTemplate: React.FC<StorePageTemplateProps> = ({ entityId, entityType, isCanEdit = false }) => {
	const { t } = useTranslation();

	const { deleteStore, getEntityStores, getData: getStoreData } = useStore();
	const { submitting, loading } = getStoreData();

	const [store, setStore] = useState<StoreModel>();
	const [removeModal, setRemoveModal] = useState<{
		open: boolean;
		onClose: () => void;
		actions: ActionModel[];
	}>();
	const [editStoreDialog, setEditStoreDialog] = useState<{
		open: boolean;
		store: null | StoreModel;
	}>();

	const fetchData = useCallback(async () => {
		const entityStores = await getEntityStores(entityId);
		setStore(entityStores?.length ? entityStores[0] : undefined);
	}, [getEntityStores, entityId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleChangeStore = useCallback((store: null | StoreModel) => {
		setEditStoreDialog({
			open: true,
			store
		});
	}, []);

	const onCloseChangeStoreModal = useCallback((updatedStore?: null | StoreModel) => {
		setEditStoreDialog(undefined);

		if (updatedStore) {
			setStore(updatedStore);
		}
	}, []);

	const getOptions = useCallback(
		(item: StoreModel): MenuDotsOption[] => {
			const options: MenuDotsOption[] = [
				{
					name: t("visit_store"),
					onClick: () => {
						window.open(item.link, "_blank");
					}
				}
			];

			if (isCanEdit) {
				options.push(
					{
						name: t("manage_store"),
						onClick: () => {
							handleChangeStore(item);
						}
					},
					{
						name: t("delete_store"),
						onClick: () => {
							setRemoveModal({
								open: true,
								onClose: () => {
									setRemoveModal(undefined);
								},
								actions: [
									{
										text: t("close"),
										variant: ButtonVariantsEnum.OUTLINED,
										onClick: () => setRemoveModal(undefined)
									},
									{
										text: t("remove"),
										variant: ButtonVariantsEnum.DANGER,
										onClick: async () => {
											setRemoveModal(undefined);
											deleteStore(item.id);
											setStore(undefined);
										}
									}
								]
							});
						}
					}
				);
			}

			return options;
		},
		[deleteStore, handleChangeStore, isCanEdit, t]
	);

	return (
		<>
			<CardWrapper className="mb-2 flex items-center justify-between">
				<Text variants={TextVariantsEnum.H6}>{t("main_store")}</Text>
				{isCanEdit && !loading && !store && (
					<Button size={ButtonSizesEnum.SM} onClick={() => handleChangeStore(null)}>
						{t("create_store")}
					</Button>
				)}
			</CardWrapper>
			<div className="grid grid-cols-3">
				{store && (
					<StoreCard key={store.id} store={store} options={getOptions(store)} isCanEdit={isCanEdit} showButton />
				)}
				{(loading || submitting) && <StoreCard loading />}
			</div>
			{!!editStoreDialog?.open && isCanEdit && (
				<Suspense>
					<EditStoreModal
						entityId={entityId}
						entityType={entityType}
						editStore={editStoreDialog?.store}
						onClose={onCloseChangeStoreModal}
					/>
				</Suspense>
			)}
			{!!removeModal?.open && (
				<Suspense>
					<ConfirmationDialog
						open
						onClose={removeModal.onClose}
						actions={removeModal.actions}
						title={t("do_you_want_to_remove_store")}
						message={t("do_you_want_to_remove_store_desc")}
					/>
				</Suspense>
			)}
		</>
	);
};

export default StorePageTemplate;
