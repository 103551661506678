import { Suspense, lazy } from "react";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";
import { UserRoleEnum } from "shared/types";

const AdminLayout = lazy(() => import("shared/templates/Layouts/AdminLayout"));

const AdminDashboardPage = lazy(() => import("./Pages/Dashboard"));
const AdminUsersPage = lazy(() => import("./Pages/Users"));
const AdminReportedUsersPage = lazy(() => import("./Pages/ReportedUsers"));
const AdminReportedContentPage = lazy(() => import("./Pages/ReportedContent"));
const AdminManageClubsPage = lazy(() => import("./Pages/ManageClubs"));
const AdminManageLeaguesPage = lazy(() => import("./Pages/ManageLeagues"));
const AdminManageFacilitiesPage = lazy(() => import("./Pages/ManageFacilities"));
const AdminManageRefOrgsPage = lazy(() => import("./Pages/ManageRefOrgs"));

import { routes } from "./constants";

class AdminApp implements IApp {
	getRoutes = () => [
		<Route key="admin-routes" path={routes.admin.getPath()}>
			<Route element={<SecureRoute roles={[UserRoleEnum.GLOBAL_ADMIN]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key="admin-route"
			element={
				<Suspense>
					<AdminLayout />
				</Suspense>
			}
		>
			<Route
				key={routes.admin.dashboard.getElementKey()}
				path={routes.admin.dashboard.getPath()}
				element={
					<Suspense>
						<AdminDashboardPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.admin.users.getElementKey()}
				path={routes.admin.users.getPath()}
				element={
					<Suspense>
						<AdminUsersPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.admin.reportedUsers.getElementKey()}
				path={routes.admin.reportedUsers.getPath()}
				element={
					<Suspense>
						<AdminReportedUsersPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.admin.reportedContent.getElementKey()}
				path={routes.admin.reportedContent.getPath()}
				element={
					<Suspense>
						<AdminReportedContentPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.admin.manageClubs.getElementKey()}
				path={routes.admin.manageClubs.getPath()}
				element={
					<Suspense>
						<AdminManageClubsPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.admin.manageLeagues.getElementKey()}
				path={routes.admin.manageLeagues.getPath()}
				element={
					<Suspense>
						<AdminManageLeaguesPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.admin.manageFacilities.getElementKey()}
				path={routes.admin.manageFacilities.getPath()}
				element={
					<Suspense>
						<AdminManageFacilitiesPage />
					</Suspense>
				}
			/>
			<Route
				key={routes.admin.manageRefOrgs.getElementKey()}
				path={routes.admin.manageRefOrgs.getPath()}
				element={
					<Suspense>
						<AdminManageRefOrgsPage />
					</Suspense>
				}
			/>
		</Route>
	];
}

export default AdminApp;
