import React, { memo } from "react";

import clsx from "clsx";

export enum FootballCardType {
	GREEN = "GREEN",
	RED = "RED",
	YELLOW = "YELLOW"
}

export interface FootballCardProps {
	type?: FootballCardType;
}

const FootballCard: React.FC<FootballCardProps> = memo(({ type = FootballCardType.YELLOW }) => (
	<div
		className={clsx(
			"w-[14px] h-[18px] rounded",
			type === FootballCardType.GREEN
				? "bg-primary-500"
				: type === FootballCardType.RED
					? "bg-red-500"
					: "bg-yellow-400"
		)}
	/>
));

export default FootballCard;
