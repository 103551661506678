import type { FC } from "react";
import React, { useState } from "react";

import type { OutlinedTextFieldProps } from "@mui/material";

import EyeCrossedIcon from "assets/icons/outlined/eye-crossed.svg?react";
import EyeIcon from "assets/icons/outlined/eye.svg?react";
import { Button, Input } from "shared/uikit";
import type { BaseInputProps } from "shared/uikit/Input";
import { ButtonSizesEnum, ButtonVariantsEnum } from "shared/uikit/types";

import PasswordRulesBox from "../PasswordRulesBox";

interface Props {
	onChange: OutlinedTextFieldProps["onChange"];
	value: string;
	error?: string;
	placeholder?: string;
	setIsValidPassword?: (value: boolean) => void;
	includeRules?: boolean;
	onFocus?: BaseInputProps["onFocus"];
	onBlur?: BaseInputProps["onBlur"];
	size?: ButtonSizesEnum;
	headline?: string;
}

const PasswordInput: FC<Props> = ({
	onChange,
	value,
	error,
	placeholder,
	setIsValidPassword,
	includeRules,
	size,
	...props
}) => {
	const [showPassword, setShowPassword] = useState(false);
	return (
		<div className="relative">
			<Input
				{...props}
				onChange={onChange}
				value={value}
				placeholder={placeholder || "Enter your new password"}
				size={size || ButtonSizesEnum.UNDER_MD}
				error={!!error}
				errorText={error}
				type={showPassword ? "text" : "password"}
				RightSideIcon={
					<Button
						className="-mr-2"
						variant={ButtonVariantsEnum.GHOST}
						size={ButtonSizesEnum.SM}
						onClick={() => setShowPassword(!showPassword)}
						dontFillSvg
						iconButton={{
							icon: showPassword ? (
								<EyeCrossedIcon className="svg-paths:stroke-gray-300" />
							) : (
								<EyeIcon className="svg-paths:stroke-gray-300" />
							),
							circular: true
						}}
					/>
				}
			/>
			{includeRules && <PasswordRulesBox handleAllRulesValid={setIsValidPassword} password={value} />}
		</div>
	);
};

export default PasswordInput;
