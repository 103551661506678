import type { FC } from "react";
import React from "react";

import SkeletonHeaderLoader from "./Header";
import SkeletonRowLoader from "./Row";

import type { TableColumnType } from "../SimpleTable";

export interface SkeletonLoaderProps {
	hideHeader: boolean;
	headerHeight?: number;
	rowHeight: number;
	columnList: TableColumnType[];
	pageSize: number;
	columnWidth: (index: number) => number;
}

const SkeletonLoader: FC<SkeletonLoaderProps> = ({
	hideHeader,
	headerHeight,
	rowHeight,
	columnList,
	pageSize,
	columnWidth
}) => (
	<>
		{!hideHeader && (
			<SkeletonHeaderLoader headerHeight={headerHeight} columnList={columnList} columnWidth={columnWidth} />
		)}
		{Array.from(Array(pageSize).keys()).map((row, rowIndex) => (
			<SkeletonRowLoader key={rowIndex} columnWidth={columnWidth} columnList={columnList} rowHeight={rowHeight} />
		))}
	</>
);

export default SkeletonLoader;
