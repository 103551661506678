import type { FC } from "react";
import React from "react";

import { SkeletonBase } from "shared/uikit";

const PaginationLoader: FC = () => (
	<div className="flex items-center">
		<SkeletonBase width={110} height={32} fullScale />
		<SkeletonBase width={32} height={32} fullScale />
		<SkeletonBase width={32} height={32} fullScale />
		<SkeletonBase width={32} height={32} fullScale />
		<SkeletonBase width={110} height={32} fullScale />
	</div>
);

export default PaginationLoader;
