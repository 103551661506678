import type { ReactNode } from "react";
import React from "react";

import type { Control, FieldArrayWithId, FieldErrors, UseFormSetValue, UseFormTrigger } from "react-hook-form";

import { useTranslation } from "react-i18next";

import PlusIcon from "assets/icons/outlined/plus.svg?react";

import type { SetupCategoryModel } from "shared/types";
import { ButtonSizesEnum, ButtonVariantsEnum } from "shared/uikit/types";

import CategoryRow from "./CategoryRow";
import { StyledButton } from "./style";

interface AdvancedFormProps {
	onToggle: (category?: SetupCategoryModel) => void;
	maxCategoriesCount: number;
	fields: FieldArrayWithId<{ categories: SetupCategoryModel[] }, "categories", "fieldId">[];
	trigger: UseFormTrigger<{ categories: SetupCategoryModel[] }>;
	control: Control<{ categories: SetupCategoryModel[] }, any>;
	setValue: UseFormSetValue<{ categories: SetupCategoryModel[] }>;
	errors: FieldErrors<{ categories: SetupCategoryModel[] }>;
	isRevalidating: boolean;
	rootError?: ReactNode;
	modalView?: boolean;
	categoryOptions?: boolean;
}

const AdvancedForm: React.FC<AdvancedFormProps> = ({ onToggle, maxCategoriesCount, fields, rootError, ...rest }) => {
	const { t } = useTranslation();

	const RenderCategory = (
		category: SetupCategoryModel & { fieldId: string },
		index: number,
		arr: SetupCategoryModel[]
	) => (
		<CategoryRow
			key={category.fieldId}
			category={category}
			categoryIndex={index}
			totalCount={arr.length}
			onRemove={onToggle}
			{...rest}
		/>
	);

	return (
		<>
			<div>{fields.map(RenderCategory)}</div>
			{!!rootError && <div className="mt-3">{rootError}</div>}
			{fields.length < maxCategoriesCount && (
				<StyledButton
					size={ButtonSizesEnum.SM}
					variant={ButtonVariantsEnum.LINK}
					startIcon={<PlusIcon />}
					onClick={() => onToggle()}
					className="-ml-5 mt-2"
				>
					{t("add_category")}
				</StyledButton>
			)}
		</>
	);
};

export default React.memo(AdvancedForm);
