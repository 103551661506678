import { styled } from "@mui/material";

import colors from "theme/colors";

export const WrapperBtn = styled("div")`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: rgba(10, 16, 40, 0.85);

	transition: opacity 0.25s ease-in-out;

	z-index: 5;

	opacity: 0;

	.hover-el {
		opacity: 0;

		transition: opacity 0.25s ease-in-out;

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

		&_show {
			opacity: 1;
		}
	}

	&:hover,
	&.hover {
		opacity: 1;
	}

	&.uploading {
		opacity: 1;

		border-radius: 50%;
		background-color: white;

		border: 1px dashed ${colors.palette.colors.gray["200"]};
	}
`;
