import type { DropdownOptionModel } from "shared/uikit/Input/Dropdown";

export const generateDaysOfMonth = (defaultMonth?: number) => {
	const month = defaultMonth || 1;
	let daysCount = 31;
	const dayList: DropdownOptionModel[] = [];
	if ((month <= 7 && month % 2 === 0) || (month >= 7 && month % 2 !== 0)) {
		daysCount = 30;
	}
	if (month === 2) {
		daysCount = 28;
	}
	for (let i = 0; i < daysCount; i++) {
		dayList.push({ label: `${i + 1}`, value: i + 1 });
	}
	return dayList;
};
