import type { FC } from "react";
import React from "react";

import type { LinearProgressProps } from "@mui/material/LinearProgress/LinearProgress";

import clsx from "clsx";

import { StyledLinearProgress } from "./style";

interface ProgressBarProps extends LinearProgressProps {
	id?: string;
	className?: string;
}

const ProgressBar: FC<ProgressBarProps> = ({ id = "progress-bar", className, variant = "determinate", ...rest }) => (
	<StyledLinearProgress id={id} className={clsx(className)} variant={variant} {...rest} />
);

export default ProgressBar;
