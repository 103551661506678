import React, { useCallback, useEffect, useMemo, useRef } from "react";

import clsx from "clsx";

import { isEmpty } from "ramda";

import type { Accept, DropzoneInputProps } from "react-dropzone";

import { HiddenInput } from "./style";

const inputId = "hidden-file-selector";

interface HiddenFileSelectorProps {
	open?: boolean;
	availableFileTypes?: Accept;
	onSelect?: <T extends File>(acceptedFiles: T[] | FileList | null) => void;
	multiple?: boolean;
	disabled?: boolean;
	inputProps?: DropzoneInputProps;
}

let timeoutRef: ReturnType<typeof setTimeout> | null = null;

const HiddenFileSelector: React.FC<HiddenFileSelectorProps> = ({
	open,
	availableFileTypes = { "image/*": [] },
	onSelect,
	multiple,
	disabled,
	inputProps = {}
}) => {
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const correctFileTypes = useMemo(() => Object.keys(availableFileTypes).join(","), [availableFileTypes]);

	const handleSelect = useCallback(
		(files?: FileList | null) => {
			if (files !== undefined && onSelect && isEmpty(inputProps)) {
				onSelect(files);
			}
		},
		[onSelect, inputProps]
	);

	useEffect(() => {
		if (open) {
			if (fileInputRef?.current) {
				(fileInputRef.current as HTMLInputElement).value = "";
				fileInputRef.current.click();
			}

			// Define a function to check if user closed window modal without selected files
			document.body.onfocus = () => {
				if (timeoutRef) {
					clearTimeout(timeoutRef);
					timeoutRef = null;
				}

				timeoutRef = setTimeout(() => {
					if (onSelect && !fileInputRef?.current?.value.length && isEmpty(inputProps)) {
						onSelect(null);
					}
					document.body.onfocus = null;
				}, 500);
			};

			return () => {
				document.body.onfocus = null;
			};
		}
	}, [open, onSelect, inputProps]);

	return (
		<HiddenInput
			ref={fileInputRef}
			className={clsx(disabled && "pointer-events-none")}
			id={inputId}
			type="file"
			value={undefined}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				document.body.onfocus = null;
				if (timeoutRef) {
					clearTimeout(timeoutRef);
					timeoutRef = null;
				}
				handleSelect(e.target.files);
				// clear input state to allow select other files
				setTimeout(
					target => {
						target.value = "";
					},
					50,
					e.target
				);
			}}
			name="hidden-file-selector"
			accept={correctFileTypes}
			multiple={multiple}
			title=""
			{...inputProps}
		/>
	);
};

export default HiddenFileSelector;
