import type { ReactNode } from "react";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import type { PopoverOrigin } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";

import clsx from "clsx";

import DotsIcon from "assets/icons/filled/dots.svg?react";

import { Text, Tooltip } from "shared/uikit";

import { ButtonSizesEnum, MenuDotsVariants, MenuListPositionOpts, TextVariantsEnum } from "shared/uikit/types";

import colors from "theme/colors";

import { getAnchorOriginPos, getTransformOriginPos } from "utils/serviceUtils/helpers";

import { DotsIconWrapper, MoreActionsButton, Wrapper, paperStyle } from "./style";

export interface MenuDotsOption {
	name: string;
	onClick: () => void;
	value?: number;
	icon?: ReactNode;
	closeOnSelect?: boolean;
	submenuOptions?: MenuDotsOption[];
	id?: string;
}

interface MenuDotsProps {
	options: MenuDotsOption[];
	fillBg?: boolean;
	onClose?: () => void;
	horizontal?: boolean;
	dotColor?: string;
	customImage?: ReactNode;
	menuListPosition?: MenuListPositionOpts;
	popoverPosition?: MenuListPositionOpts;
	customWidth?: number;
	menuId?: string;
	bordered?: boolean;
	size?: ButtonSizesEnum;
	customActionEl?: ReactNode;
	open?: boolean;
	externalOpen?: boolean;
	variant?: MenuDotsVariants;
	className?: string;
}

let timeout: ReturnType<typeof setTimeout> | null = null;

const MenuDots: React.FC<MenuDotsProps> = memo(
	({
		options,
		onClose,
		horizontal = false,
		dotColor,
		customImage,
		menuListPosition = MenuListPositionOpts.BOTTOM_CENTER,
		popoverPosition = MenuListPositionOpts.TOP_LEFT,
		customWidth,
		menuId,
		bordered,
		size = ButtonSizesEnum.MD,
		customActionEl,
		open,
		externalOpen = false,
		fillBg,
		className,
		variant = MenuDotsVariants.DEFAULT
	}) => {
		const [currentMenuOptions, setCurrentMenuOptions] = useState<MenuDotsOption[]>(options);
		const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

		useEffect(() => {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}

			setCurrentMenuOptions(options);
		}, [options]);

		useEffect(() => {
			if (externalOpen && open && wrapperRef.current) {
				setAnchorEl(wrapperRef.current);
			}
		}, [externalOpen, open]);

		const wrapperRef = useRef<HTMLDivElement>(null);
		const show = Boolean(anchorEl);

		const handleClick = useCallback(
			(e?: React.MouseEvent<HTMLElement>) => {
				if (!externalOpen) {
					e?.stopPropagation();
					e?.preventDefault();

					if (wrapperRef.current) {
						setAnchorEl(wrapperRef.current);
					}
				}
			},
			[externalOpen]
		);

		const handleClose = useCallback(
			(e?: React.MouseEvent<HTMLElement>) => {
				e?.stopPropagation();

				setAnchorEl(null);
				onClose && onClose();

				if (timeout) {
					clearTimeout(timeout);
					timeout = null;
				}
				timeout = setTimeout(() => {
					setCurrentMenuOptions(options);
				}, 250);
			},
			[onClose, options]
		);

		const switchOptions = useCallback(
			(newOptionList: MenuDotsOption[]) => {
				setAnchorEl(null);
				onClose && onClose();

				setTimeout(() => {
					setCurrentMenuOptions(newOptionList);
					handleClick();
				}, 200);
			},
			[onClose, handleClick]
		);

		const anchorOriginPos: PopoverOrigin = useMemo(() => getAnchorOriginPos(menuListPosition), [menuListPosition]);

		const transformOriginPos: PopoverOrigin = useMemo(() => getTransformOriginPos(popoverPosition), [popoverPosition]);

		if (!options?.length) return null;

		return (
			<Wrapper
				ref={wrapperRef}
				className={clsx(bordered && "bordered", !!customActionEl && "custom", "w-fit", className)}
			>
				{customActionEl ? (
					<div id={menuId} onClick={handleClick}>
						{customActionEl}
					</div>
				) : (
					<Tooltip text="More actions">
						<MoreActionsButton
							id={menuId}
							onClick={handleClick}
							className={clsx(
								bordered && "bordered",
								fillBg && "bg-primary-500",
								size === ButtonSizesEnum.SM && "w-4 h-4",
								size === ButtonSizesEnum.MD && "w-6 h-6",
								size === ButtonSizesEnum.XL && "w-8 h-8"
							)}
						>
							{customImage ? (
								<div>{customImage}</div>
							) : (
								<DotsIconWrapper fillColor={dotColor || colors.palette.colors.gray["500"]}>
									<DotsIcon className={clsx("w-full h-full", horizontal && "rotate-90")} />
								</DotsIconWrapper>
							)}
						</MoreActionsButton>
					</Tooltip>
				)}
				<Menu
					anchorEl={anchorEl}
					open={show}
					onClose={e => handleClose(e as React.MouseEvent<HTMLElement>)}
					// getContentAnchorEl={null} // it fixes anchorOrigin vertical ingored prop
					anchorOrigin={anchorOriginPos}
					transformOrigin={transformOriginPos}
					PaperProps={{
						style: paperStyle,
						sx: {
							minWidth: customWidth || "200px",
							"& .MuiList-root": {
								padding: 0
							}
						}
					}}
				>
					{currentMenuOptions.map((item, index) => (
						<MenuItem
							className={clsx(
								"rounded-lg bg-transparent py-2.5 px-2",
								variant === MenuDotsVariants.ACTIVE ? "hover:bg-lightGreen" : "hover:bg-gray-50"
							)}
							id={item?.id || ""}
							onClick={e => {
								e.stopPropagation();
								if (item.submenuOptions) {
									switchOptions(item.submenuOptions);
								} else {
									item.onClick();
									if (item.closeOnSelect === undefined || item.closeOnSelect) {
										handleClose();
									}
								}
							}}
							key={index}
						>
							{item?.icon && <div className="w-6 h-6 flex justify-center items-center mr-2">{item.icon}</div>}
							<Text variants={TextVariantsEnum.Caption3}>{item.name}</Text>
						</MenuItem>
					))}
				</Menu>
			</Wrapper>
		);
	}
);

export default MenuDots;
