import { ButtonBase, styled } from "@mui/material";

export const El = styled("div")`
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	z-index: 5;

	border-radius: inherit;
`;

export const UploaderBlock = styled(ButtonBase)`
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	border-radius: inherit;

	z-index: 5;
`;
