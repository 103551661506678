import type { FC } from "react";
import React, { lazy, useEffect, useMemo, useRef, useState } from "react";

import { Link } from "@mui/material";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { Keyboard, Navigation, Pagination } from "swiper/modules";
import { SwiperSlide } from "swiper/react";

import type { SwiperRef } from "swiper/swiper-react";

import ArrowRightIcon from "assets/icons/outlined/arrow-right.svg?react";
import { getPaths } from "core/getPaths";
import { CardWrapper } from "shared/Components";

import { useCompetition } from "shared/hooks";
import type { BaseCreateType } from "shared/types";
import { Button, ImageSizesEnum, Img, Text } from "shared/uikit";
import { ButtonSizesEnum, ButtonVariantsEnum, TextVariantsEnum } from "shared/uikit/types";

import FavoriteCompetitionDivisionsLoader from "./FavoriteCompetitionDivisionLoader";

const SwiperCarousel = lazy(() => import("shared/Components/SwiperCarousel"));

const { competition: competitionRoutes, group: groupRoutes } = getPaths();

interface CompetitionDivisionQuickLinksProps {
	id?: string;
}

const CompetitionDivisionQuickLinks: FC<CompetitionDivisionQuickLinksProps> = ({ id = "quick-links-wrapper-id" }) => {
	const { t } = useTranslation();

	const { getFavoriteCompetitionDivisions, getData: getCompetitionData } = useCompetition();
	const {
		favoriteCompetition,
		favoriteCompetitions,
		favoriteCompetitionDivisions,
		favoriteCompetitionGroups,
		loadingFavoriteCompetitionDivisions
	} = getCompetitionData();

	const swiperRef = useRef<SwiperRef>(null);

	const [activeIndex, setActiveIndex] = useState(0);

	useEffect(() => {
		getFavoriteCompetitionDivisions();
	}, [getFavoriteCompetitionDivisions]);

	const links = useMemo(() => {
		const linkList: (BaseCreateType & { link: string; subtitle?: string })[] = [];
		if (favoriteCompetitions?.length) {
			favoriteCompetitions.forEach(x => {
				linkList.push({
					id: x.id!,
					name: x?.name,
					subtitle: x?.league?.name,
					logo: x?.logo || x?.league?.logo,
					link: `${competitionRoutes.getPath()}/${x.id}`
				});
			});
		} else if (favoriteCompetition) {
			linkList.push({
				id: favoriteCompetition.id!,
				name: favoriteCompetition?.name,
				subtitle: favoriteCompetition?.league?.name,
				logo: favoriteCompetition?.logo || favoriteCompetition?.league?.logo,
				link: `${competitionRoutes.getPath()}/${favoriteCompetition.id}`
			});
		}

		if (favoriteCompetitionGroups?.length) {
			favoriteCompetitionGroups.forEach(group => {
				linkList.push({
					id: group.id!,
					name: group.name,
					subtitle: group?.competition?.name,
					logo: group?.logo || group?.competition?.logo,
					link: `${groupRoutes.getPath()}/${group.id}`
				});
			});
		}

		if (favoriteCompetitionDivisions?.length) {
			favoriteCompetitionDivisions.forEach(division => {
				linkList.push({
					id: division.id!,
					name: division.name,
					subtitle: favoriteCompetition?.name,
					logo: division?.logo || favoriteCompetition?.logo || favoriteCompetition?.league?.logo,
					link: `${groupRoutes.getPath()}/${division.id}`
				});
			});
		}

		return linkList;
	}, [favoriteCompetition, favoriteCompetitions, favoriteCompetitionDivisions, favoriteCompetitionGroups]);

	if (loadingFavoriteCompetitionDivisions) {
		return <FavoriteCompetitionDivisionsLoader />;
	}

	if (!loadingFavoriteCompetitionDivisions && !links?.length) {
		return null;
	}

	return (
		<CardWrapper title={t("quick_links")} id={id}>
			<Button
				dontFillSvg
				size={ButtonSizesEnum.XS}
				variant={ButtonVariantsEnum.OUTLINED}
				onClick={() => swiperRef.current?.swiper.slidePrev()}
				className={clsx("bg-white absolute left-3 top-[55%] z-10", !activeIndex && "opacity-0 pointer-events-none")}
				iconButton={{
					icon: (
						<ArrowRightIcon
							className={clsx("w-5 h-5 transform rotate-180", activeIndex === 0 && "svg-paths:stroke-gray-200")}
						/>
					),
					circular: true
				}}
			/>
			<Button
				dontFillSvg
				size={ButtonSizesEnum.XS}
				variant={ButtonVariantsEnum.OUTLINED}
				onClick={() => swiperRef.current?.swiper.slideNext()}
				className="bg-white absolute right-3 top-[55%] z-10 shadow-[0_4px_4px_rgba(0,0,0,0.08)]"
				iconButton={{
					icon: (
						<ArrowRightIcon
							className={clsx("w-5 h-5", activeIndex === links.length - 1 && "svg-paths:stroke-gray-200")}
						/>
					),
					circular: true
				}}
			/>

			<SwiperCarousel
				ref={swiperRef}
				slidesPerView={3.2}
				slidesPerGroup={3}
				spaceBetween={16}
				className="h-full"
				speed={1000}
				keyboard={{
					enabled: true
				}}
				modules={[Keyboard, Navigation, Pagination]}
				onSlideChange={({ activeIndex }) => setActiveIndex(activeIndex)}
			>
				{links.map((link, index) => (
					<SwiperSlide key={`quick-link-${index}`}>
						<Link to={link.link} component={RouterLink} className="no-underline w-full">
							<CardWrapper>
								<div className="flex items-center gap-4">
									<Img src={link?.logo} alt={link?.name} size={ImageSizesEnum.Small40} circle />
									<div className="w-9/12">
										<Text variants={TextVariantsEnum.H7} className="whitespace-nowrap truncate">
											{link?.name}
										</Text>
										<Text variants={TextVariantsEnum.BodySmall} className="text-hint whitespace-nowrap truncate">
											{link?.subtitle}
										</Text>
									</div>
								</div>
							</CardWrapper>
						</Link>
					</SwiperSlide>
				))}
			</SwiperCarousel>
		</CardWrapper>
	);
};

export default CompetitionDivisionQuickLinks;
