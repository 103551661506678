import { Suspense, lazy } from "react";

import { Route } from "react-router-dom";

import SecureRoute from "shared/services/AuthDecorator/SecureRoute";
import type { IApp } from "shared/types";

const LoggedLayout = lazy(() => import("shared/templates/Layouts/LoggedLayout"));
const MessagingPageLayout = lazy(() => import("modules/Messaging/View/layouts/MessagingPageLayout"));

const MessagingChatPage = lazy(() => import("./Pages/MessagingChat"));

import { routes } from "./constants";

class MessagingApp implements IApp {
	getRoutes = () => [
		<Route path={routes.messaging.getPath()} key="messaging-routes">
			<Route element={<SecureRoute roles={[]} />}>{this.getSecuredRoutes()}</Route>
		</Route>
	];

	getSecuredRoutes = () => [
		<Route
			key={routes.messaging.getElementKey()}
			element={
				<Suspense>
					<LoggedLayout />
				</Suspense>
			}
		>
			<Route
				key={`${routes.messaging.getElementKey()}-page-wrapper`}
				element={
					<Suspense>
						<MessagingPageLayout />
					</Suspense>
				}
			>
				<Route
					key={`${routes.messaging.getElementKey()}/:id`}
					path={`${routes.messaging.getPath()}/:id`}
					element={
						<Suspense>
							<MessagingChatPage />
						</Suspense>
					}
				/>
			</Route>
		</Route>
	];
}

export default MessagingApp;
