import { CardWrapper } from "shared/Components";
import { SkeletonBase } from "shared/uikit";

const SkeletonEventCard = () => (
	<CardWrapper className="bg-white px-4 py-3 w-full">
		<div className="flex justify-between items-center">
			<SkeletonBase width={100} height={19} fullScale />
			<SkeletonBase width={100} height={19} fullScale />
		</div>
		<div className="flex justify-between items-start mt-2 relative">
			<div className="w-1/2 flex justify-center">
				<div className="flex flex-col items-center">
					<div className="relative">
						<SkeletonBase width={40} height={40} variant="circular" />
					</div>
					<SkeletonBase width={140} height={19} className="mt-2" />
					<SkeletonBase width={60} height={16} className="mt-0.5" />
				</div>
			</div>
			<div className="w-1/2 flex justify-center">
				<div className="flex flex-col items-center">
					<div className="relative">
						<SkeletonBase width={40} height={40} variant="circular" />
					</div>
					<SkeletonBase width={140} height={19} className="mt-2" />
					<SkeletonBase width={60} height={16} className="mt-0.5" />
				</div>
			</div>
			<div className="absolute w-1/3 top-1 inset-x-1/3">
				<SkeletonBase width={100} height={24} className="mx-auto" />
				<SkeletonBase width={100} height={19} className="mx-auto" />
			</div>
		</div>
		<div className="flex justify-between items-center mt-2">
			<div className="flex items-center">
				<SkeletonBase width={16} height={16} fullScale className="mr-2" />
				<SkeletonBase width={120} height={24} fullScale />
			</div>
			<div className="flex justify-end">
				<SkeletonBase width={50} height={26} fullScale className="mr-2" />
				<SkeletonBase width={50} height={26} fullScale className="mr-2" />
				<SkeletonBase width={50} height={26} fullScale />
			</div>
		</div>
	</CardWrapper>
);

export default SkeletonEventCard;
