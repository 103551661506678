import { ButtonBase, styled } from "@mui/material";

import colors from "theme/colors";

export const UICircularCheckbox = styled(ButtonBase)`
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background-color: ${colors.palette.colors.gray[50]};
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		color: ${colors.palette.colors.hint};
	}

	&.active {
		background-color: ${colors.palette.colors.primary[500]};
		p {
			color: #ffffff;
		}
	}
`;
