import { Suspense, forwardRef, lazy, useImperativeHandle, useState } from "react";

import { useShare } from "shared/hooks";
import type { EntityFollowListEnum, PostAttachmentModel } from "shared/types";

export interface ShareContentMethods {
	handleOpenConnectionModal: () => void;
	handleOpenFeedModal: () => void;
}

interface ShareContentProps {
	entityType?: EntityFollowListEnum;
	creatorName?: string;
	attachments?: PostAttachmentModel[];
	noIntroText?: boolean;
}

const ShareToConnectionsModal = lazy(() => import("shared/Components/ShareToConnectionsModal"));
const CreatePostModal = lazy(() => import("modules/Member/View/Components/CreatePostModal"));

const ShareContentBlock = forwardRef<ShareContentMethods, ShareContentProps>(
	({ entityType, creatorName, attachments, noIntroText }, ref) => {
		const [shareToConnectionModal, setShareToConnectionModal] = useState(false);
		const [shareToFeedModal, setShareToFeedModal] = useState<string | null>(null);

		const { prepareMessage } = useShare();

		useImperativeHandle(ref, () => ({
			handleOpenConnectionModal: () => setShareToConnectionModal(true),
			handleOpenFeedModal: async () => {
				const message = await prepareMessage({
					entityType,
					creatorName
				});

				setShareToFeedModal(message);
			}
		}));

		return (
			<>
				{shareToConnectionModal && (
					<Suspense>
						<ShareToConnectionsModal
							entityType={entityType}
							creatorName={creatorName}
							onClose={() => setShareToConnectionModal(false)}
							attachments={attachments}
							noIntroText={noIntroText}
						/>
					</Suspense>
				)}
				{shareToFeedModal && (
					<Suspense>
						<CreatePostModal
							localAttachments={attachments}
							defaultBody={shareToFeedModal}
							onClose={() => setShareToFeedModal(null)}
						/>
					</Suspense>
				)}
			</>
		);
	}
);

export default ShareContentBlock;
