import React, { memo } from "react";

import clsx from "clsx";

import { TableColumnLoaderType } from "shared/types/Table";
import { SkeletonBase } from "shared/uikit";

interface SkeletonColumnLoaderProps {
	type?: TableColumnLoaderType;
}

const SkeletonColumnLoader: React.FC<SkeletonColumnLoaderProps> = memo(
	({ type = TableColumnLoaderType.oneTextRow }): JSX.Element => {
		// if (type === TableColumnLoaderType.imageWthTwoTextRows) {
		// 	return (
		// 		<FlexContainer>
		// 			<SkeletonBase variant="rectangular" width={40} height={40} />
		// 			<Column className="with-image">
		// 				<SkeletonBase width={"75%"} />
		// 				<SkeletonBase width={"50%"} />
		// 			</Column>
		// 		</FlexContainer>
		// 	);
		// }
		//
		// if (type === TableColumnLoaderType.imageWthOneTextRow) {
		// 	return (
		// 		<FlexContainer>
		// 			<SkeletonBase variant="rectangular" width={40} height={40} />
		// 			<Column className="with-image">
		// 				<SkeletonBase width={"50%"} />
		// 			</Column>
		// 		</FlexContainer>
		// 	);
		// }

		if (type === TableColumnLoaderType.twoTextRows) {
			return (
				<div className={clsx("w-full flex flex-col")}>
					<SkeletonBase width={"75%"} />
					<SkeletonBase width={"45%"} />
				</div>
			);
		}

		if (type === TableColumnLoaderType.menuDots) {
			return <SkeletonBase variant="rectangular" width={10} height={28} />;
		}

		if (type === TableColumnLoaderType.checkbox) {
			return <SkeletonBase variant="rectangular" width={24} height={24} />;
		}

		if (type === TableColumnLoaderType.expand) {
			return <SkeletonBase variant="circular" width={32} height={32} />;
		}

		// type === TableColumnLoaderType.oneTextRow or others
		return <SkeletonBase width="45%" />;
	}
);

export default SkeletonColumnLoader;
