import { AccordionDetails, AccordionSummary, styled } from "@mui/material";

export const AccordionBoxSummary = styled(AccordionSummary)`
	min-height: auto;
	width: 100%;
	padding: 0;

	.MuiAccordionSummary-content {
		width: 100%;

		margin: 0;

		cursor: default;
	}
`;

export const AccordionBoxDetails = styled(AccordionDetails)`
	position: relative;

	padding: 0;
`;
