import type { FC } from "react";
import React, { useCallback, useMemo } from "react";

import { ButtonBase, Link, styled } from "@mui/material";

import clsx from "clsx";

import { Link as RouterLink } from "react-router-dom";

import CloseIcon from "assets/icons/filled/close-circle.svg?react";

import { Text } from "shared/uikit";
import { SizesEnum, TextVariantsEnum } from "shared/uikit/types";
import colors from "theme/colors";

export enum ChipStyles {
	DEFAULT = "DEFAULT",
	LIGHT_FILL = "LIGHT_FILL",
	WARNING = "WARNING",
	SUCCESS = "SUCCESS",
	ERROR = "ERROR"
}

const StyledChip = styled("div")`
	background-color: ${colors.palette.colors.gray[50]};
	border: 1px solid ${colors.palette.colors.gray[100]};

	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1rem;

	.highlight {
		font-size: 1rem;
	}

	&.success {
		background: ${colors.palette.colors.green["50"]};

		border: 2px solid ${colors.palette.colors.green["100"]};
	}

	&.style-chip {
		&_${ChipStyles.LIGHT_FILL} {
			border: none;
			background: ${colors.palette.colors.blue["50"]};
		}

		&_${ChipStyles.WARNING} {
			border: none;
			background: ${colors.palette.colors.yellow["100"]};
			color: ${colors.palette.colors.yellow["600"]};
		}

		&_${ChipStyles.SUCCESS} {
			background: ${colors.palette.colors.lightGreen};
			color: ${colors.palette.colors.primary["600"]};

			border: 1px solid ${colors.palette.colors.lightGreen};
		}

		&_${ChipStyles.ERROR} {
			border: none;
			background: ${colors.palette.colors.red["100"]};
			color: ${colors.palette.colors.red["600"]};
		}
	}
`;

interface ChipProps {
	text: React.ReactNode | string;
	icon?: React.ReactNode;
	onCollapse?: () => void;
	className?: string;
	onClick?: () => void;
	secondary?: boolean;
	textHint?: boolean;
	link?: string;
	size?: SizesEnum.XS | SizesEnum.MD;
	chipStyle?: ChipStyles;
}

const Chip: FC<ChipProps> = ({
	text,
	icon,
	onCollapse,
	className,
	onClick,
	secondary,
	textHint,
	link,
	size = SizesEnum.MD,
	chipStyle = ChipStyles.DEFAULT
}) => {
	const handleClick = useCallback(() => {
		onClick && onClick();
	}, [onClick]);

	const textColor = useMemo(() => {
		switch (chipStyle) {
			case ChipStyles.LIGHT_FILL:
				return "text-inactive";

			case ChipStyles.WARNING:
				return "text-yellow-600";

			case ChipStyles.SUCCESS:
				return "text-primary-600";

			case ChipStyles.ERROR:
				return "text-red-600";

			default:
				return "";
		}
	}, [chipStyle]);

	const chip = (
		<StyledChip
			className={clsx(
				size === SizesEnum.XS ? "py-0.5 px-1" : "py-1 px-2",
				"flex items-center gap-1 rounded-lg",
				secondary && "bg-white",
				textHint && "text-hint",
				`style-chip_${chipStyle}`,
				className
			)}
			onClick={handleClick}
		>
			{icon && <div className="overflow-hidden">{icon}</div>}
			{typeof text === "string" ? (
				<Text variants={TextVariantsEnum.BodySmall} className={textColor}>
					{text}
				</Text>
			) : (
				text
			)}
			{onCollapse && (
				<ButtonBase onClick={onCollapse}>
					<CloseIcon className="w-4 h-4 svg-first-path:fill-hint svg-first-path:stroke-hint" />
				</ButtonBase>
			)}
		</StyledChip>
	);

	if (link) {
		return (
			<Link to={link} component={RouterLink} className="no-underline">
				{chip}
			</Link>
		);
	}

	return chip;
};

export default Chip;
