import type { ReactNode } from "react";
import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { Amplify } from "aws-amplify";

import { theme as appTheme } from "theme/index";

import { AppConfig } from "../config";

interface AppWrapperProps {
	children?: ReactNode;
}

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolId: AppConfig.GLOBAL_CONSTANTS.AMPLIFY_POOL_ID,
			userPoolClientId: AppConfig.GLOBAL_CONSTANTS.AMPLIFY_CLIENT_ID,
			identityPoolId: AppConfig.GLOBAL_CONSTANTS.AMPLIFY_IDENTITY_POOL_ID,
			loginWith: {
				oauth: {
					domain: AppConfig.GLOBAL_CONSTANTS.AWS_COGNITO_DOMAIN_URL,
					scopes: ["email", "openid", "profile"],
					redirectSignIn: [`${window.location.origin}/auth/complete`],
					redirectSignOut: [`${window.location.origin}/auth/logout`],
					responseType: "code"
				}
			}
		}
	},
	Storage: {
		S3: {
			bucket: AppConfig.GLOBAL_CONSTANTS.AWS_BUCKET_NAME,
			region: AppConfig.GLOBAL_CONSTANTS.AMPLIFY_REGION
		}
	}
});

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => (
	<ThemeProvider theme={appTheme}>{children}</ThemeProvider>
);

export default AppWrapper;
