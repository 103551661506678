import type { FC } from "react";
import React, { useMemo } from "react";

import clsx from "clsx";

import type { PostReactionSkins, PostReactionTypes } from "shared/types";

import { ImageSizesEnum } from "shared/uikit";
import colors from "theme/colors";

import { emotionIcons, skinOptions } from "../Like/constants";
import { IconWrapper } from "../Like/style";

interface ReactionIconProps {
	type: PostReactionTypes;
	skin: PostReactionSkins;
	size?: ImageSizesEnum;
}

const ReactionIcon: FC<ReactionIconProps> = ({ type, skin, size = ImageSizesEnum.Small32 }) => {
	const reactionInfo = useMemo(() => emotionIcons.find(x => x.value === type), [type]);

	const reactionSkinColor = useMemo(
		() => skinOptions.find(x => x.value === skin)?.color || colors.palette.colors.reactionColors.default,
		[skin]
	);

	const sizeClass = useMemo(() => {
		switch (size) {
			case ImageSizesEnum.Small16:
				return "w-4 h-4";

			case ImageSizesEnum.Small20:
				return "w-5 h-5";

			case ImageSizesEnum.Small24:
				return "w-6 h-6";

			default:
				return "w-8 h-8";
		}
	}, [size]);

	return (
		<IconWrapper
			className={clsx("cursor-pointer [&>svg]:w-full [&>svg]:h-full reaction-icon", sizeClass)}
			color={reactionSkinColor}
		>
			{reactionInfo!.icon}
		</IconWrapper>
	);
};

export default ReactionIcon;
