import React, { useMemo } from "react";

import clsx from "clsx";

import FailIcon from "assets/icons/filled/close-circle.svg?react";
import InfoSignIcon from "assets/icons/filled/info.svg?react";
import SuccessIcon from "assets/icons/filled/tick.svg?react";

import type { TooltipBoxProps } from "shared/uikit";
import { Text, Tooltip } from "shared/uikit";

import { TextStylesEnum, TextVariantsEnum } from "../types";

export enum InfoIconType {
	INFO = "INFO",
	WARNING = "WARNING",
	SUCCESS = "SUCCESS",
	FAIL = "FAIL"
}

export enum InfoIconSize {
	Small16 = "Small16",
	Small24 = "Small24"
}

export interface InfoIconProps extends Omit<TooltipBoxProps, "children"> {
	regular?: boolean;
	iconSize?: InfoIconSize;
	iconClassName?: string;
	type?: InfoIconType;
	textVariant?: TextVariantsEnum;
	textStyle?: TextStylesEnum;
	noMargin?: boolean;
}

const InfoIcon: React.FC<InfoIconProps> = ({
	className,
	iconClassName,
	iconSize = InfoIconSize.Small16,
	arrow = true,
	regular = false,
	text,
	type = InfoIconType.INFO,
	textVariant = TextVariantsEnum.BodySmall,
	textStyle = TextStylesEnum.Hint,
	noMargin,
	...rest
}) => {
	const icon = useMemo(() => {
		const classes = clsx("cursor-pointer", !noMargin && "mr-1", iconClassName);
		const sizeClass = iconSize === InfoIconSize.Small16 ? "w-4 h-4" : "w-6 h-6";

		if (type === InfoIconType.SUCCESS) {
			return (
				<>
					<SuccessIcon
						className={clsx("svg-first-path:stroke-green-500 svg-first-path:fill-green-500", classes, sizeClass)}
					/>
				</>
			);
		}

		if (type === InfoIconType.FAIL) {
			return (
				<>
					<FailIcon className={clsx("svg-first-path:stroke-red-500 svg-first-path:fill-red-500", classes, sizeClass)} />
				</>
			);
		}

		return (
			<>
				<InfoSignIcon
					className={clsx(
						type === InfoIconType.WARNING ? "svg-paths:fill-orange-500" : "svg-paths:fill-gray-400",
						classes,
						sizeClass
					)}
				/>
			</>
		);
	}, [type, iconClassName, iconSize, noMargin]);

	if (regular) {
		return (
			<div className={clsx(className, "flex items-start")}>
				{icon}
				<Text variants={textVariant} style={textStyle}>
					{text}
				</Text>
			</div>
		);
	}

	return (
		<Tooltip arrow={arrow} text={text} {...rest}>
			{icon}
		</Tooltip>
	);
};

export default InfoIcon;
