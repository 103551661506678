import { Dialog, DialogContent, styled } from "@mui/material";

import colors from "theme/colors";

export const UIKitDialog = styled(Dialog, {
	shouldForwardProp: prop => prop !== "invisibleWrapper" && prop !== "autoHeight"
})<{ invisibleWrapper?: boolean; autoHeight?: boolean }>`
	.MuiBackdrop-root {
		background-color: rgba(6, 10, 33, 0.85);
	}

	.MuiPaper-root {
		box-shadow: unset;
		border-radius: 12px;
		overflow: hidden;

		.MuiDialogContent-root {
			padding: 0;
			border: ${({ invisibleWrapper }) => (invisibleWrapper ? "none" : `1px solid ${colors.palette.colors.gray[100]}`)};
		}
	}

	&.full-width {
		.MuiPaper-root {
			width: 100%;
		}
	}

	&.sizes {
		&_fullscreen {
			> .MuiDialog-container {
				> .MuiPaper-root {
					margin: ${({ invisibleWrapper }) => (invisibleWrapper ? "0" : "3rem")};
					width: 100%;
					max-width: 100%;
					height: 100vh;
					max-height: ${({ invisibleWrapper }) => (invisibleWrapper ? "100vh" : "calc(100vh - 6rem)")};
					background-color: ${({ invisibleWrapper }) => (invisibleWrapper ? "transparent" : "#ffffff")};
				}
			}
		}

		&_small480 {
			> .MuiDialog-container {
				> .MuiPaper-root {
					margin: 3rem;
					width: 100%;
					max-width: 30rem;
					height: ${({ autoHeight }) => (autoHeight ? "auto" : "100%")};
					max-height: ${({ autoHeight }) => (autoHeight ? "90vh" : "40rem")};
				}
			}
		}

		&_large800 {
			> .MuiDialog-container {
				> .MuiPaper-root {
					margin: 3rem;
					width: 100%;
					max-width: 50rem;
					height: ${({ autoHeight }) => (autoHeight ? "auto" : "100%")};
					max-height: ${({ autoHeight }) => (autoHeight ? "90vh" : "40rem")};
				}
			}
		}

		&_small {
			> .MuiDialog-container {
				> .MuiPaper-root {
					margin: 3rem;
					width: 100%;
					max-width: 37.5rem;
					height: ${({ autoHeight }) => (autoHeight ? "auto" : "100%")};
					max-height: ${({ autoHeight }) => (autoHeight ? "90vh" : "40rem")};
				}
			}
		}

		&_medium {
			> .MuiDialog-container {
				> .MuiPaper-root {
					margin: 3rem;
					width: 100%;
					max-width: 37.5rem;
					max-height: ${({ autoHeight }) => (autoHeight ? "90vh" : "45rem")};
				}
			}
		}

		&_mid-large {
			> .MuiDialog-container {
				> .MuiPaper-root {
					margin: 3rem;
					width: 100%;
					max-width: 45rem;
					max-height: ${({ autoHeight }) => (autoHeight ? "90vh" : "45rem")};
				}
			}
		}

		&_large {
			> .MuiDialog-container {
				> .MuiPaper-root {
					margin: 3rem;
					width: 100%;
					max-width: 62vw;
					max-height: ${({ autoHeight }) => (autoHeight ? "90vh" : "70vh")};
				}
			}
		}

		&_tiny {
			> .MuiDialog-container {
				> .MuiPaper-root {
					margin: 3rem;
					width: 100%;
					max-width: 25.5rem;
					max-height: ${({ autoHeight }) => (autoHeight ? "90vh" : "70vh")};
				}
			}
		}
	}
`;

const headerSize = 72;
const footerSize = 80;

export const UiKitDialogContent = styled(DialogContent)`
	border: 1px solid ${colors.palette.colors.gray[100]};

	&.fixed-height {
		&_large {
			height: calc(100vh - 128px - ${headerSize}px - ${footerSize}px);

			&.no-header {
				height: calc(100vh - 128px - ${headerSize}px);
			}

			&.no-footer {
				height: calc(100vh - 128px - ${footerSize}px);
			}

			&.no-header &.no-footer {
				height: calc(100vh - 128px);
			}
		}
	}
`;
