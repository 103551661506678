import type { FC, ReactNode } from "react";
import React, { Suspense, lazy, memo, useMemo } from "react";

import type { OutlinedTextFieldProps } from "@mui/material";

import clsx from "clsx";

import { Chip } from "shared/Components";

import type { DatepickerProps } from "./Datepicker";
import type { DropdownOptionModel, DropdownProps } from "./Dropdown";
import type { InputProps } from "./InputBase";
import InputBase from "./InputBase";
import type { MaskInputProps } from "./MaskInput";
import type { SearchProps } from "./Search";
import type { TimepickerProps } from "./Timepicker";

const MaskInput = lazy(() => import("./MaskInput"));
const Timepicker = lazy(() => import("./Timepicker"));
const Datepicker = lazy(() => import("./Datepicker"));
const Dropdown = lazy(() => import("./Dropdown"));
const Search = lazy(() => import("./Search"));

import { InputWrapper } from "./style";

import Text from "../Text";
import type { ErrorMessageTypes } from "../types";
import { ButtonSizesEnum, TextVariantsEnum } from "../types";

export enum InputVariantsEnum {
	FILLED = "filled",
	OUTLINED = "outlined",
	GHOST = "ghost"
}

export interface InputWrapperProps {
	headline?: string | ReactNode;
	boldHeadline?: boolean;
	headlineVariant?: TextVariantsEnum;
	variant?: InputVariantsEnum; // to cover base mui styles
	wrapClassName?: string;

	dropdown?: boolean;
	multiselect?: boolean;
	calendar?: boolean;
	search?: boolean;
	time?: boolean;

	inputChips?: (string | React.ReactNode)[];
	hideBorder?: boolean;
	maskChar?: string;
}

export interface BaseInputProps {
	value?: string | null;
	defaultValue?: string | DropdownOptionModel;

	label?: string;
	placeholder?: string;

	className?: string;
	RightSideIcon?: JSX.Element;
	LeftSideIcon?: JSX.Element;
	loading?: boolean;
	disabled?: boolean;
	error?: boolean;
	errorText?: string;
	size?: ButtonSizesEnum;

	type?: OutlinedTextFieldProps["type"];

	info?: string;

	onFocus?: (e) => void;
	onClick?: (e) => void;
	onBlur?: () => void;

	mask?: string;

	id?: string;

	warning?: boolean;
	warningText?: string;
	warningTextType?: ErrorMessageTypes;
	minRows?: number;

	disableAutoComplete?: boolean;
	variant?: "outlined" | "standard" | "filled";
	variantWrapper?: InputVariantsEnum; // to cover custom styles based on mui variants

	restrictInput?: boolean; // don't allow to use spaces in the beginning/at the end, don't allow to use special characters
}

const Input: FC<
	(
		| Omit<InputProps, "variant">
		| Omit<DropdownProps, "variant">
		| Omit<DatepickerProps, "variant">
		| Omit<TimepickerProps, "variant">
		| Omit<SearchProps, "variant">
	) &
		InputWrapperProps
> = memo(
	({
		headline,
		headlineVariant,
		boldHeadline,
		wrapClassName,
		dropdown,
		multiselect,
		search,
		calendar,
		time,
		value,
		inputChips,
		size = ButtonSizesEnum.MD,
		variant = InputVariantsEnum.OUTLINED,
		mask,
		hideBorder,
		disabled,
		...props
	}) => {
		const isRegularInput = !dropdown && !multiselect && !calendar && !time && !search;

		const variantWrapper = useMemo(
			() => (!["filled", "outlined"].includes(variant) ? (variant as InputVariantsEnum) : undefined),
			[variant]
		);

		const properVariant = useMemo(
			() => (["filled", "outlined"].includes(variant) ? (variant as "filled" | "outlined") : "outlined"),
			[variant]
		);

		return (
			<InputWrapper
				className={clsx(
					"flex flex-col w-full relative",
					headline && "gap-2",
					inputChips && "should-style rounded-lg",
					wrapClassName
				)}
			>
				{headline && (
					<>
						{typeof headline === "string" ? (
							<Text
								variants={
									headlineVariant ||
									(size === ButtonSizesEnum.SM || size === ButtonSizesEnum.UNDER_MD
										? TextVariantsEnum.Caption2
										: TextVariantsEnum.BodyMedium)
								}
								className={clsx(boldHeadline && "font-semibold", disabled && "text-gray-200")}
							>
								{headline}
							</Text>
						) : (
							headline
						)}
					</>
				)}
				{isRegularInput && (
					<>
						{mask ? (
							<Suspense>
								<MaskInput
									{...(props as MaskInputProps)}
									mask={mask}
									size={size}
									value={value || ""}
									variant={properVariant}
									hideBorders={!!inputChips?.length || hideBorder}
									disabled={disabled}
								/>
							</Suspense>
						) : (
							<InputBase
								{...(props as InputProps)}
								size={size}
								value={value || ""}
								variant={properVariant}
								hideBorders={!!inputChips?.length || hideBorder}
								disabled={disabled}
							/>
						)}
					</>
				)}
				{dropdown && (
					<Suspense>
						<Dropdown
							{...(props as DropdownProps)}
							size={size}
							variant={properVariant}
							variantWrapper={variantWrapper}
							value={value || ""}
							hideBorder={hideBorder}
							disabled={disabled}
						/>
					</Suspense>
				)}
				{calendar && (
					<Suspense>
						<Datepicker
							{...(props as DatepickerProps)}
							size={size}
							variant={properVariant}
							value={value || ""}
							disabled={disabled}
						/>
					</Suspense>
				)}
				{time && (
					<Suspense>
						<Timepicker
							{...(props as TimepickerProps)}
							size={size}
							value={value || ""}
							variant={properVariant}
							disabled={disabled}
						/>
					</Suspense>
				)}
				{search && (
					<Suspense>
						<Search {...(props as SearchProps)} value={value} variant={properVariant} size={size} disabled={disabled} />
					</Suspense>
				)}
				{inputChips && (
					<div className="flex items-center flex-wrap gap-2 pl-4 pb-3">
						{inputChips.map((chip, i) => (
							<Chip key={i} text={chip} />
						))}
					</div>
				)}
			</InputWrapper>
		);
	}
);

export default Input;
