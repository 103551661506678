import { DateTime, Interval } from "luxon";

export const calculateAge = (month?: number, day?: number, year?: number) => {
	if (month && year && day) {
		const luxonDate = DateTime.now().set({
			month,
			day,
			year
		});
		const diff = Interval.fromDateTimes(luxonDate, DateTime.now());
		return Math.floor(diff.length("year"));
	}
};
