import { css, styled } from "@mui/material";

import colors from "theme/colors";

const textStyles = css`
	&.styles {
		&_default {
			color: ${colors.palette.colors.gray[500]};
		}
		&_hint {
			color: ${colors.palette.colors.hint};
		}
		&_primary {
			color: ${colors.palette.colors.primary[600]};
		}
		&_alternative {
			color: ${colors.palette.colors.white};
		}
		&_alert {
			color: ${colors.palette.colors.red["600"]};
		}
		&_disabled {
			color: ${colors.palette.colors.gray["200"]};
		}
		&_inactive {
			color: ${colors.palette.colors.inactive};
		}

		& > span {
			font-size: inherit;
			font-weight: inherit;
			color: inherit;
		}
	}
`;

export const StyledH1 = styled("h1")`
	${textStyles}
`;

export const StyledH2 = styled("h2")`
	${textStyles}
`;

export const StyledH3 = styled("h3")`
	${textStyles}
`;

export const StyledH4 = styled("h4")`
	${textStyles}
`;

export const StyledH5 = styled("h5")`
	${textStyles}
`;

export const StyledH6 = styled("h6")`
	${textStyles}
`;

export const StyledP = styled("p")`
	${textStyles}
`;

export const StyledSPAN = styled("span")`
	${textStyles}
`;
