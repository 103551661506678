import { Suspense, lazy, useCallback, useMemo, useState } from "react";

import { Transition } from "shared/Components";
import { useCreateFlowRouter, useEffectOnce, useLeague } from "shared/hooks";
import { Headlines } from "shared/templates";

const CreateFlowNameTemplate = lazy(() => import("shared/templates/CreateFlowNameTemplate"));

const LeagueName = () => {
	const { checkIfMounted, getCreateFlowData: getCreateLeagueData } = useCreateFlowRouter();
	const { step } = getCreateLeagueData();

	const { updateNewLeague, getData: getLeagueData } = useLeague();
	const { newLeague } = getLeagueData();

	const [name, setName] = useState("");

	useEffectOnce(() => {
		newLeague?.name && setName(newLeague.name);
	});

	const isMounted = useMemo(() => checkIfMounted("name"), [checkIfMounted]);

	const handleChangeModelData = useCallback((data: any) => updateNewLeague(data), [updateNewLeague]);

	return (
		<Transition isMounted={isMounted}>
			<div className="create-process-padding pb-5">
				<div data-animate>
					<Headlines
						step={step}
						question="What’s your league name?"
						description="Enter official and full name for your league."
					/>
				</div>
				<Suspense>
					<CreateFlowNameTemplate
						name={name}
						setName={data => setName(data)}
						pageName="league"
						onUpdateModel={handleChangeModelData}
					/>
				</Suspense>
			</div>
		</Transition>
	);
};

export default LeagueName;
