import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";

import { Transition } from "shared/Components";

import { useCreateFlowRouter, useEffectOnce, useLeague } from "shared/hooks";
import { Headlines } from "shared/templates";
import type { LocationModel } from "shared/types";

const GoogleMap = lazy(() => import("shared/Components/GoogleMap"));

const LeagueAddress = () => {
	const { setAllowGoNext, getCreateFlowData: getCreateLeagueData, checkIfMounted } = useCreateFlowRouter();
	const { step } = getCreateLeagueData();

	const { updateNewLeague, getData: getLeagueData } = useLeague();
	const { newLeague } = getLeagueData();

	const [location, setLocation] = useState<LocationModel>();

	const isMounted = useMemo(() => checkIfMounted("address"), [checkIfMounted]);

	useEffect(() => {
		setAllowGoNext(false);
	}, [setAllowGoNext]);

	useEffectOnce(() => {
		newLeague?.location && setLocation(newLeague.location);
	});

	const onChangeAddress = useCallback(
		(newLocation: LocationModel) => {
			setAllowGoNext(true);
			updateNewLeague({ location: newLocation });
		},
		[setAllowGoNext, updateNewLeague]
	);

	return (
		<Transition isMounted={isMounted}>
			<div className="flex flex-col flex-1 h-full">
				<div className="create-process-padding" data-animate>
					<Headlines
						step={step}
						question="What’s your league address?"
						description="Enter full address for your league or choose it on a map."
					/>
				</div>
				<div className="w-full h-96 flex flex-col md:h-auto md:flex-1" data-animate>
					<Suspense>
						<GoogleMap
							val={location?.address}
							includeSearch={{
								placeholder: "Enter league address"
							}}
							onChange={onChangeAddress}
						/>
					</Suspense>
				</div>
			</div>
		</Transition>
	);
};

export default LeagueAddress;
